// TODO transform Rect => MinimalRect, remove MinimalRect
export type Rect = Omit<DOMRect, 'toJSON'>
export type MinimalRect = Pick<DOMRect, 'left' | 'top' | 'width' | 'height'>
export type SomeRect = DOMRect | Rect
export type AnyRect = SomeRect | MinimalRect

export function getIsElementInside(element: HTMLElement, container?: HTMLElement | undefined | null) {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container ? container.getBoundingClientRect() : getWindowRect();
  return getIsRectInside(elementRect, containerRect);
}

export function getIsRectInside(element: SomeRect, container: SomeRect) {
  const isLeft = container.left <= element.left;
  const isTop = container.top <= element.top;
  const isRight = container.right >= element.right;
  const isBottom = container.bottom >= element.bottom;
  const result = isLeft && isTop && isBottom && isRight;
  return {
    result,
    isLeft,
    isTop,
    isRight,
    isBottom,
  };
}

export function letMinimalRect(react: AnyRect): MinimalRect {
  const { height, left, top, width } = react;
  return {
    height, left, top, width,
  };
}

export function getWindowRect(): Rect {
  const { innerHeight, innerWidth } = window;
  return {
    top: 0,
    right: innerWidth,
    bottom: innerHeight,
    left: 0,
    height: innerHeight,
    width: innerWidth,
    x: 0,
    y: 0,
  };
}

export const getDelta = (start: MinimalRect, target: MinimalRect) => ({
  top: start.top - target.top,
  left: start.left - target.left,
  width: start.width / target.width,
  height: start.height / target.height,
});
export const isZero = (delta: MinimalRect) => delta.left === 0
  && delta.top === 0
  && delta.width === 1
  && delta.height === 1;
