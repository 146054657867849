import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedDateYearSelector, selectedStationIdSelector, setSelectedDateYear } from 'Features/App/appSlice';
import { useGetYearsByStationIdQuery } from 'services/river';
import { noSelectValue } from 'utils';
import { YearsSelect, YearsSelectProps } from '../YearsSelect';

type RiverYearsSelectProps = YearsSelectProps;

/**
 * Выборка годов на которые уже есть данные в БД.
 * * selected = selectedDate.year из appSlice;
 * * setSelected = dispatch selectedDate.year из appSlice; можно дополнить функцию с помощью передаваемого setSelected
 * @func
 * @param {RiverYearsSelectProps} props
 */
export const RiverYearsSelect = React.memo(({
  isCreatable,
  // selected,
  setSelected,
}: RiverYearsSelectProps) => {
  const dispatch = useDispatch();
  const postId = useSelector(selectedStationIdSelector);
  const { data: years = [], isFetching: isLoadingYears } = useGetYearsByStationIdQuery({ id: postId }, { skip: noSelectValue(postId) });

  const selectedYear = useSelector(selectedDateYearSelector);
  const setSelectedYear = React.useCallback((year: number) => {
    dispatch(setSelectedDateYear(year));
    setSelected && setSelected(year);
  }, [dispatch, setSelected]);

  return (
    <YearsSelect
      isCreatable={isCreatable}
      selected={selectedYear}
      setSelected={setSelectedYear}
      possibleYears={years}
      isLoadingYears={isLoadingYears}
    />
  );
});
RiverYearsSelect.displayName = 'RiverYearsSelect';
