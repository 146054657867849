import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedWaterAreaPostSelector, setSelectedLakeWaterAreaPost } from 'Features/App/lakeSlice';
import { LakeWaterAreaPost as LakeWaterAreaPostFromApi } from 'services/phoebeApi.generated';
import { useGetLakeWaterAreaPostsAllQuery } from 'services/lake/waterAreaPostsApi';
import { PostsSelect, OnSelectPost, WidthIdType } from '../PostsSelect';

type LakeWaterAreaPost = WidthIdType<LakeWaterAreaPostFromApi>
type WaterAreaPostsSelectProps = {
  disabled?: boolean,
  /** начальный выбранный пост, если нет его в сторе */
  selectedId?: number,
  // todo: поменять на onSelect
  handlerSelect?: OnSelectPost<LakeWaterAreaPost>,
  className?: string,
}

/**
 * Выборка постов на акватории для озер.
 * При передачи selectedId предусматривает автовыбор поста, если он отсутствует.
 * @func
 * @param {WaterAreaPostsSelectProps} props
 */
export const WaterAreaPostsSelect = React.memo(({
  disabled,
  handlerSelect,
  selectedId,
  ...props
}: WaterAreaPostsSelectProps) => {
  const dispatch = useDispatch();
  const selectedPost = useSelector(selectedWaterAreaPostSelector);

  const { data: posts = [], isLoading, isFetching } = useGetLakeWaterAreaPostsAllQuery();

  const getPostLabel = (post: LakeWaterAreaPost) => `${post.code} - ${post.type?.typeName}`;

  const onSelectPost: OnSelectPost<LakeWaterAreaPost> = React.useCallback((post) => {
    dispatch(setSelectedLakeWaterAreaPost(post));
    handlerSelect && handlerSelect(post);
  }, [dispatch, handlerSelect]);

  return (
    <PostsSelect<LakeWaterAreaPost>
      posts={posts as Array<LakeWaterAreaPost>}
      selectedId={selectedId}
      selectedPost={selectedPost}
      onSelectPost={onSelectPost}
      isDisabled={disabled || isLoading || isFetching}
      isLoading={isLoading || isFetching}
      getPostLabel={getPostLabel}
      {...props}
    />
  );
});
WaterAreaPostsSelect.displayName = 'WaterAreaPostsSelect';
