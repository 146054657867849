import { VocListsEntity, VocRegion } from 'services/phoebeApi.generated';

export type VocCodesMapType = {
  /** ключ-code является числовым значением */
  [code: number | string]: VocListsEntity,
}
export function getVocCodesMap(codes: Array<VocListsEntity>) {
  return codes.reduce((acc, cur) => {
    if (cur.code) acc[cur.code] = cur;
    return acc;
  }, {} as VocCodesMapType);
}

export type RegionCodesMapType = {
  /** ключ-code является числовым значением */
  [code: number | string]: VocRegion,
}
export function getRegionCodesMap(codes: Array<VocRegion>) {
  return codes.reduce((acc, cur) => {
    if (cur.code) acc[cur.code] = cur;
    return acc;
  }, {} as RegionCodesMapType);
}
