import { generatedApi } from '../phoebeApi.generated';
import { LAKE_POST_TAG, LAKE_POSTS_TAG } from './tags';

const postsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_POST_TAG, LAKE_POSTS_TAG],
  endpoints: {
    getLakePostsAll: {
      providesTags: [LAKE_POSTS_TAG],
    },
    getLakePostById: {
      providesTags: [LAKE_POST_TAG],
    },
    updateLakePost: {
      invalidatesTags: [LAKE_POST_TAG, LAKE_POSTS_TAG],
    },
    getLakePostsAllWithAnnualWaterTemperature: {
      providesTags: [LAKE_POSTS_TAG],
    },
    getLakePostsAllWithWaterLevelDayAvg: {
      providesTags: [LAKE_POSTS_TAG],
    },
    // deleteLakePostById: {
    //   invalidatesTags: [LAKE_POSTS_TAG],
    // },
  },
});

export const {
  useGetLakePostsAllQuery,
  useGetLakePostByIdQuery,
  useUpdateLakePostMutation,
  useGetLakePostsAllWithAnnualWaterTemperatureQuery,
  useGetLakePostsAllWithTotalThicknessIceQuery,
  useGetLakePostsAllWithWaterLevelDayAvgQuery,
} = postsApi;
