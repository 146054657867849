import { generatedApi } from '../phoebeApi.generated';
import { LAKE_FORM_DESCRIPTIONS_TAG } from './tags';

// теги не нужны, т.к. пока нет настроек для них (скорее всего не будет)
const lakeFormDescriptionsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_FORM_DESCRIPTIONS_TAG],
  endpoints: {
    getLakeFormDescriptionsByFormIdAndPostId: {
      providesTags: [LAKE_FORM_DESCRIPTIONS_TAG],
    },
  },
});

export const {
  useGetLakeFormDescriptionsByFormIdAndPostIdQuery,
} = lakeFormDescriptionsApi;
