import React from 'react';
import { DataErrorsType } from './types';

export function useFormErrors(errors: DataErrorsType = []) {
  const [showErrors, setShowErrors] = React.useState(false);
  const isDisabledErrors = React.useMemo(() => !errors.length, [errors.length]);

  const openErrorsDialog = React.useCallback(() => setShowErrors(true), []);
  const closeErrorsDialog = React.useCallback(() => setShowErrors(false), []);

  // чтобы при появлении ошибок открывался диалог
  React.useEffect(() => {
    if (errors.length) openErrorsDialog();
  }, [errors.length, openErrorsDialog]);

  return {
    showErrors,
    isDisabledErrors,
    openErrorsDialog,
    closeErrorsDialog,
  };
}
