// noinspection TypeScriptValidateJSTypes

import { generatedApi } from 'services/phoebeApi.generated';
import { stationYearsApi } from './api';

const customApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getUniqYearsForStations: build.query<string[], number[]>({
      async queryFn(ids: number[], { dispatch }) {
        try {
          const dispatcher = (id: number) => dispatch(stationYearsApi.endpoints.getYearsByStationId.initiate({ id }));
          const promises = ids.map(async (id) => {
            const query = await dispatcher(id);

            return query.data!;
          });
          const results = await Promise.all(promises);
          const uniqYears = [...new Set((results).flat())].sort((a, b) => Number(b) - Number(a));

          const result = uniqYears.filter((year) => !results.some(
            (resultYears) => !resultYears.includes(year),
          ));

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetUniqYearsForStationsQuery,
} = customApi;
