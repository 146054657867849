import React, { KeyboardEvent } from 'react';
import { TextInput, TextInputProps } from './TextInput';
import { isDiff } from './isDiff';

export type ValueType = string | number;
/** @param setValue подойдет, если происходит подмена значения при вводе, например, заменяется , на . */
export type TextInputBlurUpdateValueType = (event: React.FocusEvent<HTMLInputElement, Element>, setValue: SetTextInputBlurValue) => void
export type SetTextInputBlurValue = (value: ValueType) => void

export type TextInputBlurProps = TextInputProps & {
  resetInvalid?: boolean,
  name?: string,
  /** если нужно другое поведение на Enter (изначально расфокус при нажатии) */
  isOnEnterOther?: boolean,
  value: ValueType | undefined,
  /** обновление данных при onBlur */
  updateValue?: TextInputBlurUpdateValueType,
};

/**
 * Инпут, который изменяет данные только когда происходит onBlur.
 * Данные НЕ изменяется если инпут расфокусился с такими же данными какие и были.
 * ("7" и 7 будет считаться одинаковыми данными)
 */
const ThisTextInputBlur = React.forwardRef<HTMLInputElement, TextInputBlurProps>((props, ref) => {
  const {
    resetInvalid,
    isOnEnterOther,
    value: initialValue = '',
    updateValue,
    ...inputProps
  } = props;

  const [value, setValue] = React.useState<ValueType>(initialValue);

  // !возможно не нужно, т.к. при onBlur уже стоит setValue - протестировать на <DatePicker>
  // на случай, если меняется входное значение без изменений в текущем инпуте
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  // console.log('%c ThisTextInputBlur', 'color: gold', { resetInvalid, name: inputProps.name, initialValue, value });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
    inputProps.onChange && inputProps.onChange(e);
  };

  /*
  Изменяем данные когда теряем фокус
  и при этом значение было изменено (отлично от начального)
  и валидно, если установлен флаг сбрасывания на предыдущее значение
  */
  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { validity } = e.target;

    if (isDiff(initialValue, value) && (!resetInvalid || (resetInvalid && validity.valid))) {
      updateValue && updateValue(e, setValue);
    } else setValue(initialValue);
    inputProps.onBlur && inputProps.onBlur(e);
  };

  const onEnterBlur = React.useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    !isOnEnterOther && e.key === 'Enter' && (e.target as HTMLElement).blur();
    inputProps.onKeyPress && inputProps.onKeyPress(e);
  }, [inputProps, isOnEnterOther]);

  return (
    <TextInput
      ref={ref}
      value={value ?? ''}
      {...inputProps}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onEnterBlur}
    />
  );
});
ThisTextInputBlur.displayName = 'TextInputBlur';

/** Стилизованная текстовой инпут с изменением данных на onBlur, обернутая в forwardRef и memo */
export const TextInputBlur: React.FC<TextInputBlurProps> = React.memo(ThisTextInputBlur);
TextInputBlur.displayName = 'TextInputBlur';
