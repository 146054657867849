import { t } from 'locale';
import React from 'react';
import Select, { SingleValue } from 'react-select';
import { getStringMonthOrDay, hasValue } from 'utils';
import { requiredStyle } from './customStyles';

export const months = [t('key_812'), t('key_813'), t('key_814'), t('key_815'), t('key_816'), t('key_817'), t('key_818'), t('key_819'), t('key_820'), t('key_821'), t('key_822'), t('key_823')];

export type SetSelectedMonthFunction = (monthIndex: number, args: {monthNumber: number, index?: number, name?: string}) => void;
/**
 * Атрибуты компонента {@link MonthSelect}
 */
type MonthSelectProps = {
  /** true - обязателен для выбора */
  required?: boolean,
  disabled?: boolean,
  /** от 0 до 11 - число месяца по js'овски */
  selected: number | undefined,
  /** monthIndex от 0 до 11 */
  setSelected?: SetSelectedMonthFunction,
  /**  от 1 до 12, для ограничения Месяцев в списке при текущем годе */
  maxMonth?: number
  /** вспомогательный атрибут, полезно при изменении данных в массиве */
  name?: string,
  /** вспомогательный атрибут, полезно при изменении данных в массиве */
  index?: number, // полезно при изменении данных в массиве
}

type MonthOptionType = {
  /** число месяца: 01, 02, ..., 12 - для удобства поиска по вводу */
  value: string,
  /** от 0 до 11 - число месяца по js'овски. Для selected */
  monthIndex: number,
  /** название месяца */
  label: string,

}

/**
 * Выборка месяцев
 * @param {MonthSelectProps} props
 */
export function MonthSelect({ required, disabled, selected, setSelected, maxMonth = 12, name, index }: MonthSelectProps) {
  const customStyles = React.useMemo(() => ({
    // control: controlRequired,
    // @ts-ignore
    control: (provided, state) => ({
      ...provided,
      ...(required ? requiredStyle(state) : null),
      minWidth: 100,
    }),
  }), [required]);

  const options = React.useMemo<Array<MonthOptionType>>(() => months.slice(0, maxMonth).map((month, monthIndex) => ({
    value: `${getStringMonthOrDay(monthIndex + 1)}`,
    monthIndex,
    label: `${month}`,
  })), [maxMonth]);

  const selectedOption = React.useMemo<MonthOptionType | null>(() => (hasValue(selected) ? options[selected!] : null), [options, selected]);
  const handlerChange = (option: SingleValue<MonthOptionType> | undefined) => {
    setSelected && option && setSelected(option.monthIndex, { monthNumber: option.monthIndex + 1, name, index });
  };

  const noOptionsMessage = () => t('key_824');

  return (
    <Select
      isDisabled={disabled}
      classNamePrefix="react-select"
      placeholder={t('key_400')}
      options={options}
      value={selectedOption}
      noOptionsMessage={noOptionsMessage}
      onChange={handlerChange}
      styles={customStyles}
    />
  );
}
