import React from 'react';
import { getClientDateFormat } from 'utils';
import { DataErrorsType } from './types';
import './data-errors.css';

export type DataErrorsProps = {
  errors: DataErrorsType,
}

export const DataErrors = React.memo(({ errors }: DataErrorsProps) => {
  if (!errors?.length) return null;
  return (
    <>
      {
        React.Children.toArray(errors.map(({ error, errorDate, message }) => (
          <>
            <div className="pb-2">
              <strong>
                {getClientDateFormat(errorDate)}
              </strong>
              <p className={`data-error-message-${error ? 'danger' : 'warning'}`}>{message}</p>
            </div>
          </>
        )))
      }
    </>
  );
});
DataErrors.displayName = 'DataErrors';
