// import { createDivPortal } from '../utils/createPortal';
// import './Toaster.css';

export function createDivToaster() {
  if (!document.getElementById('toasters')) {
    // createDivPortal();
    const div = document.createElement('div');
    div.className = 'block';
    div.id = 'toasters';

    document.getElementById('portal')?.appendChild(div);
  }
}
