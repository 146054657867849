import React from 'react';
import { Link } from 'react-router-dom';
import './card-link.scss';
import { LinkType } from './types';

/** Атрибуты для компонент {@link CardLink} */
type CardLinkProps = LinkType & {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
  /** путь картинки, если при наведении на кнопку нужно показывать картинку */
  srcImage?: string,
}

/** * Кнопка-ссылка в стилизованной виде карточки. */
export const CardLink = React.memo(({ name, to, srcImage, onClick }: CardLinkProps) => (
  <div className="card-link text-sky-50 bg-sky-600 hover:bg-slate-50 hover:text-sky-600">
    <Link
      to={to}
      onClick={onClick && onClick}
    >
      {
        srcImage
          ? (
            <img
              className="card-img"
              src={srcImage}
              alt={name}
            />
          )
          : null
      }
      <span className="card-title">{name}</span>
    </Link>
  </div>
));
CardLink.displayName = 'CardLink';

/** Атрибуты для компонент {@link CardsLinkGrid} */
type CardsLinkGridProps = {
  // cardSize?: string; // динамически  - через динамический способ не получается - ругается
  children: React.ReactNode,
  className?: string,
}

/** Сетка для отображения кнопок-карточек.  */
export const CardsLinkGrid = React.memo(({
  className = '',
  children,
}: CardsLinkGridProps) => (
  <div
    className={`${className} grid gap-4 grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] auto-rows-[250px]`}
  >
    {children}
  </div>
));
CardsLinkGrid.displayName = 'CardsLinkGrid';
