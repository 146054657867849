import { t } from 'locale';
import { KeyboardEvent } from 'react';
import { noValue } from './general';

/** Индексы ячейки
 * @memberof utils
 * @alias CellIndexType
 */
export type CellIndexType = {
  rowIndex: number,
  columnIndex: number,
}

/**
 * & {@link CellIndexType}
 *
 * Важные аргументы для атрибута id (в input or select) для фокусировки элемента
 * @memberof utils
 * @alias IDFocusArgs
 */
type IDFocusArgs = CellIndexType & {
  prefix?: string,
}
/**
 * Строка для атрибута id (в input or select) для облегчения фокусировки элемента
 * @function
 * @param {IDFocusArgs} args
 * @param {CellIndexType} args
 * @memberof utils
 * @alias getIdFocus
 */
export const getIdFocus = ({ prefix = '', rowIndex, columnIndex }: IDFocusArgs): string => (
  `${prefix}${rowIndex}-${columnIndex}`
);

/**
 * Основные атрибуты (в input or select) для фокусировки элемента
 * и опознания объекта внутри массива данных.
 * @function
 * @param {IDFocusArgs} args
 * @memberof utils
 */
export const importantProps = ({ prefix, rowIndex, columnIndex }: IDFocusArgs) => ({
  id: getIdFocus({ prefix, rowIndex, columnIndex }),
  'data-row-index': rowIndex,
  'data-column-index': columnIndex,
  'data-prefix': prefix,
});

/**
 * Получаем индексы ячейки из dataset из input или select.
 * Атрибуты:
 * * data-column-index
 * * data-row-index
 *
 * Выкидывает ошибку если их не передали.
 * @param {DOMStringMap} dataset
 * @return {CellIndexType}
 * @memberof utils
 * */
export function getDatasetCellIndex(dataset: DOMStringMap): CellIndexType {
  const rowIndex: number | null = dataset.rowIndex ? +dataset.rowIndex : null;
  const columnIndex: number | null = dataset.columnIndex ? +dataset.columnIndex : null;

  const hasCellIndex = rowIndex !== null && columnIndex !== null;

  if (!hasCellIndex) {
    throw new Error(t('key_841'));
  }

  return {
    rowIndex,
    columnIndex,
  };
}

/**
 * Получаем индекс из dataset у элемента.
 * Атрибут:
 * * data-index
 *
 * Выкидывает ошибку если атрибут не передали.
 * @memberof utils
 * */
export function getDatasetIndex(target: EventTarget & HTMLElement, errorText = ''): number {
  const { dataset } = target;
  const index = dataset.index ? +dataset.index : undefined;
  if (noValue(index)) throw new Error(t('key_842', { errorText1: errorText }));
  return index!;
}

/** Сброс стандартного события для стрелок
 * @function
 * @memberof utils
 */
export const preventDefaultKeyboardArrows = (e: KeyboardEvent<HTMLInputElement | HTMLSelectElement>, keysArr?: string[]) => {
  const keys = keysArr || ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  if (keys.includes(e.key)) {
    e.preventDefault();
  }
};

/** получаем элемент из input or select по их id
 * @function
 * @param idFocus строка сформированная с помощью {@link getIdFocus}
 * @memberof utils
 * */
export const getElementByIdFocus = (idFocus: string): HTMLInputElement | HTMLSelectElement | null => (
  document.querySelector(`input[id="${idFocus}"]`)
  || document.querySelector(`select[id="${idFocus}"]`)
);
/** Фокус элемента, если он есть
 * @function
 * @memberof utils
*/
export const focusElement = (element: HTMLInputElement | HTMLSelectElement | null | undefined) => {
  if (element) {
    element.focus();
    element.scrollIntoView({ block: 'center', inline: 'center' });
  }
};

/**
 * Фокусирует элемент по префексу и индексам ячейки
 * @param {IDFocusArgs} args
 * @memberof utils
 */
export const focusCell = ({ prefix, rowIndex, columnIndex }: IDFocusArgs) => {
  const idFocus = getIdFocus({ prefix, rowIndex, columnIndex });
  focusCellByFocusId(idFocus);
};

/**
 * Фокусирует элемент по айди фокуса
 * @function
 * @param idFocus строка сформированная с помощью {@link getIdFocus}
 * @memberof utils
 * */
export const focusCellByFocusId = (idFocus: string) => {
  const element: HTMLInputElement | HTMLSelectElement | null = getElementByIdFocus(idFocus);
  focusElement(element);
};

/**
 * Фокус следующей ячейки при нажатии на стрелки
 * @memberof utils
 */
export const focusNextCellByArrow = (e: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
  preventDefaultKeyboardArrows(e);
  const { dataset } = e.target as EventTarget & (HTMLInputElement | HTMLSelectElement);
  const { rowIndex, columnIndex } = getDatasetCellIndex(dataset);
  const prefix: string = dataset.prefix || '';

  switch (e.key) {
    case 'ArrowUp':
      focusCell({ prefix, rowIndex: rowIndex - 1, columnIndex });
      break;
    case 'ArrowDown':
      focusCell({ prefix, rowIndex: rowIndex + 1, columnIndex });
      break;
    case 'ArrowLeft':
      focusCell({ prefix, rowIndex, columnIndex: columnIndex - 1 });
      break;
    case 'ArrowRight':
      focusCell({ prefix, rowIndex, columnIndex: columnIndex + 1 });
      break;
    default: break;
  }
};

// todo: объединить с focusNextCellByArrow позже, чтоб не было копипаста
/** Фокус следующей ячейки при нажатии на стрелки
 * @memberof utils
 */
export const focusNextCellByArrowUpDown = (e: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
  preventDefaultKeyboardArrows(e, ['ArrowUp', 'ArrowDown']);
  const { dataset } = e.target as EventTarget & (HTMLInputElement | HTMLSelectElement);
  const { rowIndex, columnIndex } = getDatasetCellIndex(dataset);
  const prefix: string = dataset.prefix || '';

  switch (e.key) {
    case 'ArrowUp':
      focusCell({ prefix, rowIndex: rowIndex - 1, columnIndex });
      break;
    case 'ArrowDown':
      focusCell({ prefix, rowIndex: rowIndex + 1, columnIndex });
      break;
    default: break;
  }
};
