import React, { memo, KeyboardEvent } from 'react';

export const genericReactMemo: <T>(component: T) => T = memo;

export const onEnterBlur = (e: KeyboardEvent<HTMLInputElement>) => {
  e.key === 'Enter' && (e.target as HTMLElement).blur();
};

// событие на колесико мыши, чтобы не выбирались значения при type=number
export const onWheelBlur = (e: React.WheelEvent<HTMLInputElement>) => {
  (e.target as HTMLElement).blur();
};
