import classNames from 'classnames';
import './Toaster.css';
import { createDivToaster } from './createDivToaster';

interface ToasterDefaultProps {
  color: string;
  text?: string;
}

export const toaster = {
  success: (text: string) => ToasterDefault({ color: 'success', text }),
  warning: (text: string) => ToasterDefault({ color: 'warning', text }),
  danger: (text: string) => ToasterDefault({ color: 'danger', text }),
  info: (text: string) => ToasterDefault({ color: 'info', text }),
};

export const ToasterDefault = (props: ToasterDefaultProps) => {
  const wrapper = classNames(
    `p-3 animation anim-open text-black border-l-8 bg-white border-${props.color} shadow font-semibold`,
    // `p-3 animation anim-open text-black border-l-8 bg-white border-${props.color}-400 shadow font-semibold`,
  );
  const spanClass = classNames(
    'close-toaster',
  );
  const bodyToasterClass = classNames(
    'body-toaster',
  );

  // const icon = classNames(
  //   'bp3-icon-standard bp3-icon-projects'
  // )
  createDivToaster();

  const toasters = document.getElementById('toasters');

  // основной блок, обертка
  const block = document.createElement('div');
  block.id = Math.random().toString();
  block.className = wrapper;

  // тело блока
  const body = document.createElement('div');
  body.className = bodyToasterClass;
  // div с текстом
  const textDiv = document.createElement('div');
  // текст по центру блока
  textDiv.style.alignSelf = 'center';
  // textDiv.textContent = `${props.text && props.text.length > 70 ? `${props.text?.slices(0, 300)} \n ${props.text?.slices(70, props.text?.length - 1)}` : props.text}` || null;
  textDiv.textContent = props.text || null;

  // div для closeIcon
  const spanDiv = document.createElement('div');
  // spanDiv.className = divSpan;

  // closeIcon
  const span = document.createElement('span');
  span.className = spanClass;
  span.setAttribute('role', 'button');
  span.setAttribute('tabIndex', '0');
  span.setAttribute('aria-hidden', 'true');
  span.innerHTML = '&times';

  // icon
  // const spanIcon = document.createElement('span');
  // spanIcon.className = icon;

  // добавление span в div
  spanDiv.appendChild(span);

  // body.appendChild(spanIcon)

  // добавление текста в тело блока
  body.appendChild(textDiv);
  // добавление span в тело блока
  body.appendChild(spanDiv);
  // добавление тела в блок
  block.appendChild(body);

  toasters?.prepend(block);

  const removeToast = setTimeout(() => toasters?.removeChild(block), 4000);
  span.onclick = () => {
    toasters?.removeChild(block);
    clearTimeout(removeToast);
  };
};

ToasterDefault.displayName = 'ToasterDefault';
