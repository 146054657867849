import { ValueType } from './TextInputBlur';

export const isDiff = (initialValue: ValueType, value: ValueType): boolean => {
  if (typeof initialValue === 'number' || typeof value === 'number') return isDiffNumbers(initialValue, value);
  return initialValue !== value;
};

/**
 * Проверка на одинаковость значений для чисел
 * true:
 *  * если начальное значение пустое, а значение - нет
 *  * если значение пустое, а начальное - нет
 *  * если числовые значения равны
 * @param initialValue начальное значение
 * @param value значение
 * @returns {boolean}
 */
const isDiffNumbers = (initialValue: ValueType, value: ValueType): boolean => (
  (initialValue !== '' && value === '')
  || (initialValue === '' && value !== '')
  || +initialValue !== +value
);
