import { clearUndefinedValues } from '@mono/utilities';
import { generatedApi, StationDto, WaterFlowCurveEntity } from 'services/phoebeApi.generated';
import { waterFlowApi } from 'services/river/waterFlow/api';
import {
  calculateWaterFlowByCurveEndpoint,
  calculateWaterFlowByLinearInterpolationEndpoint,
} from 'services/river/waterFlowTemp/api';

type SaveCurveForAnyTypeArgs = {
  stationId: number,
  targetYear: string,
  periodId: number | undefined,
  curveTypeId: number,
  curve: WaterFlowCurveEntity
}

type SaveCurveForAnyTypeResponse = WaterFlowCurveEntity;

type CalculateDwfForAnyMethodArgs = {
  methodId: number,
  station: StationDto,
  year: number | string,
  periodId: number,
  curveId: number | undefined,
  useStoutCorrect: boolean,
  coefficient: number | undefined,
}

const customApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    saveCurveForAnyType: build.mutation<SaveCurveForAnyTypeResponse, SaveCurveForAnyTypeArgs>({
      async queryFn({ curve, targetYear, periodId, stationId, curveTypeId }, { dispatch }) {
        const data = { ...curve } as SaveCurveForAnyTypeResponse;

        try {
          const updateCurvePoints = async () => {
            await dispatch(waterFlowApi.endpoints.updateWaterFlowPoints.initiate({
              curveId: curve.id!,
              body: curve.waterFlowPoints!,
            }));
          };
          const updateCurve = async () => {
            await dispatch(waterFlowApi.endpoints.updateWaterFlowCurveName.initiate({
              waterFlowCurveEntity: {
                ...curve,
                waterFlowPoints: undefined,
              },
            }));
          };
          const createQCurve = async () => {
            const result = await dispatch(waterFlowApi.endpoints.createWaterFlowPointsAndQCurve.initiate({
              id: stationId,
              year: targetYear,
              periodId: periodId!,
              body: curve.waterFlowPoints!,
            }));

            if ('data' in result) data.id = result.data.id;
          };
          const createCurveByCoefficients = async () => {
            const result = await dispatch(waterFlowApi.endpoints.createCurveByCoefficients.initiate({
              id: stationId,
              year: targetYear,
              curveTypeId,
              periodId: periodId!,
              waterFlowCurveDto: curve,
            }));

            if ('data' in result) data.id = result.data.id;
          };

          const isCreate = !curve.id;
          const isUpdate = !isCreate;
          const isQCurve = curveTypeId === 1;
          const isPolynomialCurve = curveTypeId === 2 || curveTypeId === 3 || curveTypeId === 4;

          if (isCreate && isQCurve) {
            await createQCurve();
          }

          if (isCreate && isPolynomialCurve) {
            await createCurveByCoefficients();
          }

          if (isUpdate && isQCurve) {
            if (curve.waterFlowPoints) {
              await updateCurvePoints();
            }
            await updateCurve();
          }

          if (isUpdate && isPolynomialCurve) {
            await updateCurve();
          }

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    calculateDwfForAnyMethod: build.mutation<void, CalculateDwfForAnyMethodArgs>({
      async queryFn({ methodId, station, curveId, periodId, useStoutCorrect, coefficient, year }, { dispatch }) {
        try {
          if (methodId === 1 || methodId === 2) {
            await dispatch(calculateWaterFlowByLinearInterpolationEndpoint.initiate({
              pythonInput: clearUndefinedValues({
                idx: station.stationIdx!,
                year: Number(year),
                periodId,
                format: 1,
                coefficient: Number(coefficient),
              }),
            }));

            return { data: undefined };
          }

          if (methodId === 3) {
            await dispatch(calculateWaterFlowByCurveEndpoint.initiate({
              year: String(year),
              id: station.id!,
              curveId: curveId!,
              periodId,
              useStoutCorrect,
            }));

            return { data: undefined };
          }

          throw new Error('Invalid method id');
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

// Сохранение или создание кривой любого типа и её точек
export const saveCurveForAnyTypeEndpoint = customApi.endpoints.saveCurveForAnyType;
export const calculateDwfForAnyMethodEndpoint = customApi.endpoints.calculateDwfForAnyMethod;
