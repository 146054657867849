import React from 'react';
import { IconButton, Title, TitleProps } from '@mono/ui-components';
import { useNavigate } from 'react-router-dom';

type ClosingTitleProps = {
  to?: string,
  close?: () => void,
  closeTooltip?: string,
} & TitleProps

/**
 * Заголовок с закрывающей кнопкой
 *
 * @param children текст заголовка
 * @param to путь перехода при закрытии
 * @param closeTooltip подсказка при наведении на крестик
 * @param close вызов функции при закрытии
 * @param tailwindFontSize "text-2xl" по умолчанию
 * @param tag "h2" по умолчанию
 */
export const ClosingTitle: React.FC<ClosingTitleProps> = React.memo(({
  children,
  closeTooltip = 'Закрыть',
  to,
  close,
}) => {
  const navigate = useNavigate();

  const handlerClose = () => {
    to && navigate(to);
    close && close();
  };

  return (
    <div className="flex items-center justify-between gap-1">
      <Title tailwindFontSize="text-2xl">{children}</Title>
      <IconButton title={closeTooltip} icon="cross" onClick={handlerClose} />
    </div>
  );
});

ClosingTitle.displayName = 'ClosingTitle';
