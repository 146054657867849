import React, { LegacyRef, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './dialog.css';
import { IconButton, Button, ButtonProps } from '..';

export interface DialogProps {
  children: ReactNode,
  isShow?: boolean,
  onClose?: any,
  cancelLabel?: string,
  confirmLabel?: string,
  confirmDisabled?: boolean,
  headerLabel?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  onCloseComplete?: any,
  closeTitle?: string,
  hasCancel?: boolean,
  hasConfirm?: boolean,
  footer?: ReactNode,
  cancelIntent?: ButtonProps['intent'],
  confirmIntent?: ButtonProps['intent'],
  /** tailwind высока h-${height}  */
  height?: string,
  /** tailwind ширина w-${height}  */
  width?: string,
  ref?: LegacyRef<HTMLDivElement> | undefined,
  contentClassName?: string,
}
const ThisDialog = React.forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const [portal] = React.useState(document.getElementById('portal'));

  /** Портал */
  return (
    portal ? ReactDOM.createPortal(
      <Children {...props} ref={ref} />,
      // <Children />,
      document.getElementById('portal') || document.createElement('div'),
    ) : null
  );
});
ThisDialog.displayName = 'ThisDialog';

export const Dialog = React.memo(ThisDialog);
Dialog.displayName = 'Dialog';

const Children = React.memo((props: DialogProps) => {
  const close = React.useCallback(() => {
    if (props.onClose) return props.onClose();
    if (props.onCloseComplete) return props.onCloseComplete();
    return null;
  }, [props]);

  const success = React.useCallback(() => {
    if (props.onConfirm) return props.onConfirm();
    if (props.onCloseComplete) return props.onCloseComplete();
    return null;
  }, [props]);

  const cancel = React.useCallback(() => {
    if (props.onCancel) return props.onCancel();
    close();
    return null;
  }, [close, props]);

  return (
    <>
      {props.isShow && (
        <div className="modal" ref={props.ref}>
          <div className={`modal-wrapper ${props.height ? `h-${props.height}` : ''} ${props.width ? `w-${props.width}` : 'w-10/12'}`}>
            <div className="modal-wrapper-content">
              <div className="p-4 pb-0 flex gap-2 items-start">
                <strong className="grow self-center text-xl">
                  <h2>{props.headerLabel || ''}</h2>
                </strong>

                <IconButton title={props.closeTitle || 'Закрыть'} icon="cross" onClick={props.onCloseComplete} />
              </div>

              <div className={`modal-content ${props.contentClassName}`}>
                {props.children}
              </div>

              {!props.footer ? (
                <div className="modal-footer-buttons">
                  {props.hasCancel ?? (
                    <Button intent={props.cancelIntent ?? 'default'} className="md:min-w-28" onClick={cancel}>
                      {props.cancelLabel || 'Отмена'}
                    </Button>
                  )}
                  {props.hasConfirm ?? (
                    <Button disabled={props.confirmDisabled} intent={props.confirmIntent ?? 'success'} className="md:min-w-28" onClick={success}>
                      {props.confirmLabel || 'Ок'}
                    </Button>
                  )}
                </div>
              )
                : props.footer}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
Children.displayName = 'ChildrenDialog';
