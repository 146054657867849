import React from 'react';
import { useGetVersionQuery } from 'services/actuatorApi';
import { getClientDateFormat } from 'utils';

export const AppVersion = React.memo(() => {
  const { data, isFetching, isLoading } = useGetVersionQuery();

  const { version = '', dateString = '' } = React.useMemo(() => {
    if (!isFetching && !isLoading) {
      return {
        version: data?.build?.version || '',
        dateString: data?.build?.time ? getClientDateFormat(data.build.time) : '',
      };
    }
    return {};
  }, [data?.build?.time, data?.build?.version, isFetching, isLoading]);

  return (
    <span className="absolute top-1 right-3 z-50 text-slate-300">
      {`v${version} - ${dateString}`}
    </span>
  );
});
AppVersion.displayName = 'AppVersion';
