import { t } from 'locale';
import React from 'react';
import {
  IconButton,
} from '@mono/ui-components';
import { swalConfirm } from 'Shared';

export type RemoveParameterFromDBProps = {
  remove: React.MouseEventHandler<HTMLButtonElement>,
  title?: string,
  message?: string,
  disabled?: boolean,
  /** если нужно удалить из массива параметров тега */
  isManyValues?: boolean,
} & React.HTMLAttributes<HTMLButtonElement>

/**
 * Кнопка удаления параметра из БД
 */
export function RemoveParameterFromDB({
  title,
  message = 'Вы хотите удалить параметр из Базы?',
  remove,
  disabled,
  isManyValues,
  ...restProps
}: RemoveParameterFromDBProps) {
  const removeParameter: React.MouseEventHandler<HTMLButtonElement> = React.useCallback((e) => {
    swalConfirm({
      message: `${title ? `${title}. ` : ''}${message}`,
      onConfirm: () => remove(e),
    });
  }, [message, remove, title]);

  return (
    <IconButton
      disabled={disabled}
      className="mb-0.5"
      title={t('key_703')}
      intent="danger"
      icon={isManyValues ? 'exclude-row' : 'graph-remove'}
      onClick={removeParameter}
      {...restProps}
    />
  );
}
