import { t } from 'locale';
import { LAKE, RIVER } from 'constants/paths';

export type PlacesPath = typeof RIVER | typeof LAKE;
type PlacesType = Readonly<Record<PlacesPath, string>>;

// key = path
export const places: PlacesType = {
  [RIVER]: t('key_559'),
  [LAKE]: t('key_560'),
};

export const placesFullNames: PlacesType = {
  [RIVER]: t('key_561'),
  [LAKE]: t('key_562'),
};
