import { generatedApi } from 'services/phoebeApi.generated';
import { SEASONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [SEASONS_TAG],
  endpoints: {
    getSeasonsWaterManagementsByStationId: {
      providesTags: [SEASONS_TAG],
    },
    updateSeasonsWaterManagements: {
      invalidatesTags: [SEASONS_TAG],
    },
    deleteSeasonsWaterManagementById: {
      invalidatesTags: [SEASONS_TAG],
    },
  },
});
export const {
  useGetSeasonsWaterManagementsByStationIdQuery,
  useUpdateSeasonsWaterManagementsMutation,
  useDeleteSeasonsWaterManagementByIdMutation,
} = api;
