import { generatedApi } from '../phoebeApi.generated';
import { CALCULATED_DATA_TAG, ICE_MEASUREMENTS_TAG, WATER_FLOW_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [CALCULATED_DATA_TAG, ICE_MEASUREMENTS_TAG, WATER_FLOW_TAG],
  endpoints: {
    monthlyProcess: {
      extraOptions: {
        // если ответ приходит xml или string, необходимо добавить это указывать в extraOptions
        xml: true,
      },
      invalidatesTags: [CALCULATED_DATA_TAG, ICE_MEASUREMENTS_TAG, WATER_FLOW_TAG],
    },
  },
});

export const {
  useAnnualProcessWithErrorsMutation,
  useHasMonthlyProcessingQuery,

  useMonthlyProcessMutation,
  useHasErrorsQuery,
} = api;
