// для удаления цикличных данных
export const JSOGdeleteCircular = (obj, level = 0, already = new WeakSet()) => {
  const newObj = {};
  switch (typeof obj) {
    case 'object':
      if (!obj) return obj;
      if (already.has(obj)) {
        return null;
      }
      already.add(obj);
      if (Array.isArray(obj)) {
        return obj.map((item) => JSOGdeleteCircular(item, level + 1, already));
      }
      Object.keys(obj).forEach((key) => {
        const val = JSOGdeleteCircular(obj[key], level + 1, already);
        newObj[key] = val;
      });
      already.delete(obj);
      return newObj;
    default:
      return obj;
  }
};
