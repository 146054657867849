import React from 'react';
import { classNames } from '@mono/ui-components';

type FieldLabelProps = {
  label: string,
  children: React.ReactNode,
  horizontal?: boolean,
  className?: string,
  labelClassName?: string,
}
export const FieldLabel = React.memo(({
  label,
  children,
  horizontal = false,
  className = '',
  labelClassName = '',
}: FieldLabelProps) => {
  const wrapClassName = classNames(
    'flex gap-1',
    {
      'items-center': horizontal,
      'flex-col': !horizontal,
    },
    className,
  );
  const thisLabelClassName = classNames(
    'font-medium',
    {
      'self-center': horizontal,
      'self-start': !horizontal,
    },
    labelClassName,
  );

  return (
    <div className={wrapClassName}>
      <span className={thisLabelClassName}>{label}</span>
      {children}
    </div>
  );
});
FieldLabel.displayName = 'FieldLabel';
