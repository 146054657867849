import { generatedApi } from 'services/phoebeApi.generated';
import { DEFAULT_CONFINES_TAG, CONFINES_TAG, FORM_DESCRIPTIONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [DEFAULT_CONFINES_TAG, CONFINES_TAG, FORM_DESCRIPTIONS_TAG],
  endpoints: {
    getConfinesByStationId: {
      // если arg.id(=postId) равен 0(=станция по умолчанию), то не нужно обновлять конфайнсы при изменении/удалении
      providesTags: (result, error, arg) => (arg?.id ? [CONFINES_TAG] : [DEFAULT_CONFINES_TAG]),
    },
    updateFormConfines: {
      invalidatesTags: [CONFINES_TAG, FORM_DESCRIPTIONS_TAG],
    },
    deleteConfinesById: {
      invalidatesTags: [CONFINES_TAG, FORM_DESCRIPTIONS_TAG],
    },
  },
});
export const {
  useGetConfinesByStationIdQuery,
  useUpdateFormConfinesMutation,
  useDeleteConfinesByIdMutation,
} = api;
