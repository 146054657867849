import React from 'react';

function createElementWithId(id: string) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addElementToBody(rootElem: any) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild!.nextElementSibling,
  );
}

export function usePortal(id: string = 'portal') {
  const portalNodeRef = React.useRef<any>(null);

  React.useEffect(() => {
    const existedParentNode = document.querySelector(`#${id}`);
    const parentNode = existedParentNode || createElementWithId(id);

    if (!existedParentNode) {
      addElementToBody(parentNode);
    }

    parentNode.appendChild(portalNodeRef.current);

    return function removePortalNode() {
      portalNodeRef.current.remove();
      if (!existedParentNode && !parentNode.childElementCount) {
        parentNode.remove();
      }
    };
  }, [id]);

  if (!portalNodeRef.current) {
    portalNodeRef.current = document.createElement('div');
  }

  return portalNodeRef.current;
}
