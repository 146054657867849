import {
  CalculatedDataEntity, CalculatedDataYearEntity,
  generatedApi,
} from 'services/phoebeApi.generated';
import { calculatedDataApi } from './api';

type CalcDataCombinedListArg = { postId: number, range: { start: string, end: string } }
type CalcDataCombinedListResult = CalcDataCombinedListArg & { result: CalculatedDataEntity[] }
type CalcDataYearCombinedListArg = { postId: number, range: { start: string, end: string } }
type CalcDataYearCombinedListResult = CalcDataYearCombinedListArg & { result: CalculatedDataYearEntity[] }

const customApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getLakeCalcDataCombinedList: build.query<CalcDataCombinedListResult[], { tag: string, targets: CalcDataCombinedListArg[] }>({
      async queryFn({ targets, tag }, { dispatch }) {
        try {
          const dispatcher = (args: { date1: string, date2: string, id: number, tag: string }) => dispatch(calculatedDataApi.endpoints.getLakeCalculatedDataByPostIdAndDateBetween.initiate(args));
          const promises = targets.map(async (args) => {
            const query = await dispatcher({
              id: args.postId,
              date1: args.range.start,
              date2: args.range.end,
              tag,
            });

            return { ...args, result: query.data! };
          });

          return { data: await Promise.all(promises) };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getLakeCalcDataYearCombinedList: build.query<CalcDataYearCombinedListResult[], { tag: string, targets: CalcDataYearCombinedListArg[] }>({
      async queryFn({ targets, tag }, { dispatch }) {
        try {
          const dispatcher = (args: { date1: string, date2: string, id: number, tag: string }) => dispatch(calculatedDataApi.endpoints.getLakeCalculatedDataYearByPostIdAndDateBetween.initiate(args));
          const promises = targets.map(async (args) => {
            const query = await dispatcher({
              id: args.postId,
              date1: args.range.start,
              date2: args.range.end,
              tag,
            });

            return { ...args, result: query.data! };
          });

          return { data: await Promise.all(promises) };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetLakeCalcDataCombinedListQuery,
  useGetLakeCalcDataYearCombinedListQuery,
} = customApi;
