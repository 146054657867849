import { t } from 'locale';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput } from '@mono/ui-components';
import * as yup from 'yup';

import { useLoginMutation } from 'services/auth';
import { isAuthSelector, setCurrentUser } from 'Features/Login/authSlice';
import { appProjectName } from 'constants/appName';
import { ErrorsMessage } from './ErrorMessage';

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required(t('key_541')),
  password: yup
    .string()
    .required(t('key_542')),
});

/**
 * Форма ввода данных для входа в приложение
 */
export const Login = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const [loginClick, { isLoading }] = useLoginMutation();

  const [user, setUser] = React.useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = React.useState<Array<string>>([]);

  const changeUser = React.useCallback((e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  }, []);
  const errorRemove = React.useCallback(() => setErrors([]), []);

  const onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    errorRemove();
    validationSchema
      .validate(user, { abortEarly: false })
      .then(() => {
        loginClick({ username: user.username, password: user.password })
          .unwrap()
          .then((responseUser) => responseUser && dispatch(setCurrentUser(responseUser.currentUser)));
      })
      .catch(({ errs }) => {
        setErrors(errs);
      });
  }, [dispatch, errorRemove, loginClick, user]);

  if (isAuth) return <Navigate to="/" replace state={{ from: location.pathname }} />;
  return (
    <div className="flex items-center justify-center h-full">
      <form onSubmit={onSubmit} className="border rounded bg-white shadow-md flex flex-col gap-2 p-12 tablet:p-8" style={{ flex: '0 1 450px' }}>
        <strong className="text-3xl font-semibold">{appProjectName}</strong>
        <ErrorsMessage title={t('key_543')} errors={errors} close={errorRemove} />

        <TextInput
          label={t('key_544')}
          autoFocus
          name="username"
          value={user.username}
          onChange={changeUser}
        />
        <TextInput
          type="password"
          label={t('key_545')}
          name="password"
          value={user.password}
          onChange={changeUser}
        />
        <Button type="submit" className="mt-2 self-end" isLoading={isLoading}>{t('key_546')}</Button>
      </form>
    </div>
  );
});
Login.displayName = 'Login';
export default Login;
