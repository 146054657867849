import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import './input.css';

export type TextInputProps = HTMLAttributes<HTMLInputElement>
  & {
    /** добавочный класс стилей */
    horizontal?: boolean,
    className?: string,
    required?: boolean,
    /** типы для инпуа поддерживаются стандартные, но лучше передовать такие */
    type?: 'text' | 'date' | 'datetime' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'time' | 'url' | 'week',
    /** название сверху */
    label?: string,
    isInvalid?: boolean,
    /** текст ошибки снизу, не зависит от isInvalid */
    errorMessage?: string,
    // ширина обертки инпута, пока так.
    width?: number | string,
    /** значение для width из tailwind */
    inputWidth?: number | string,
    value?: string | number,
    [key: string]: any,
  }

const ThisTextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    id,
    type,
    horizontal = false,
    className,
    label,
    isInvalid,
    errorMessage,
    width,
    inputWidth,
    ...restProps
  } = props;
  const classes = classNames(
    'input-text',
    'border rounded',
    `w-${inputWidth || 'full'}`,
    className,
  );

  return (
    <div className={`flex gap-1 ${horizontal ? 'items-center' : 'flex-col'}`} style={{ width: width || 'default' }}>
      {
        // label && <label className={`font-medium self-start ${horizontal ? 'self-center' : ''}`} htmlFor={id || label}>{`${label}${restProps.required ? '*' : ''}`}</label>
        label && <label className={`font-medium self-start ${horizontal ? 'self-center' : ''}`} htmlFor={id || label}>{label}</label>
      }
      <div>
        <input
          id={id || label}
          ref={ref}
          className={classes}
          type={type}
          aria-invalid={isInvalid}
          {...restProps}
        />
      </div>

      {
        errorMessage
          ? <span className="error-message self-start">{errorMessage}</span>
          : null
      }
    </div>
  );
});

ThisTextInput.displayName = 'ThisTextInput';
ThisTextInput.defaultProps = {
  type: 'text',
  isInvalid: false,
};

/** Стилизованная текстовой инпут, обернутая в forwardRef и memo */
export const TextInput = React.memo(ThisTextInput);
TextInput.displayName = 'TextInput';
