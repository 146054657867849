import React from 'react';
import { IconButton, Icon } from '@mono/ui-components';

/**
 * Атрибуты компонента {@link ErrorsMessage}
 * @alias ErrorsMessageProps
 */
type ErrorsMessageProps = {
  title?: string,
  errors: Array<string>,
  close: () => void,
}

// todo сделать красиво список по фрилансеру по ж И ПЕРЕНЕСТИ в ui-components
/**
 * При наличии массива ошибок выводит их в виде списка.
 * @function ErrorsMessage
 * @param {ErrorsMessageProps} props
 */
export function ThisErrorsMessage({ title = 'Ошибка!', errors, close }: ErrorsMessageProps) {
  if (!errors?.length) return null;
  return (
    <div className="border-danger border-l-4 rounded shadow flex bg-white p-4 gap-2">
      <Icon icon="error" className="m-1 text-danger" />
      <div className="flex-grow">
        <strong>{title}</strong>
        <ul className="max-h-32 overflow-auto">
          {errors.map((err) => <li key={err}>{err}</li>)}
        </ul>
      </div>
      <div>
        <IconButton icon="cross" onClick={close} />
      </div>
    </div>
  );
}

export const ErrorsMessage = React.memo(ThisErrorsMessage);
