import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedStationSelector, setSelectedStation, setSelectedDateYear } from 'Features/App/appSlice';
import { StationDto } from 'services/phoebeApi.generated';
import { useGetStationsAllQuery } from 'services/river';
import { PostsSelect, OnSelectPost, WidthIdType } from '../PostsSelect';

type RiverPost = WidthIdType<StationDto>
/**
 * Атрибуты компонента {@link RiverPostsSelect}
 */
type RiverPostsSelectProps = {
  disabled?: boolean,
  /** начальный выбранный пост, если нет его в глобальном хранилище */
  selectedId?: number,
  // todo: поменять на onSelect
  handlerSelect?: OnSelectPost<RiverPost>
  // !delete Использовать кастомный список постов
  forcePosts?: Array<RiverPost>,
  className?: string,
}

// ! С передачей posts использовать другой компонент, который лежит в Shared/Select
// <PostsSelect post={myPosts} ... >

/**
 * Выборка постов для рек с выгрузкой данных.
 *
 * * Сбрасывает общий (из appSlice) выбранный год при смене станции.
 * * При передачи selectedId предусматривает автовыбор поста, если он отсутствует.
 * @function
 * @param {RiverPostsSelectProps} props
 */
export const RiverPostsSelect = React.memo(({
  disabled, selectedId, handlerSelect, forcePosts, ...props
}: RiverPostsSelectProps) => {
  const dispatch = useDispatch();
  const selectedPost = useSelector(selectedStationSelector);

  const { data: posts = [], isLoading, isFetching } = useGetStationsAllQuery(undefined, { skip: !!forcePosts });

  const getPostLabel = (post: RiverPost) => `${post.stationIdx} - ${post.stationName}`;

  const onSelectPost: OnSelectPost<RiverPost> = React.useCallback((post) => {
    dispatch(setSelectedStation(post));
    // сбрасываем год, потому что года с данными выгружаются по станции
    dispatch(setSelectedDateYear(0));
    handlerSelect && handlerSelect(post);
  }, [dispatch, handlerSelect]);

  return (
    <PostsSelect<RiverPost>
      posts={forcePosts || posts as Array<RiverPost>}
      selectedId={selectedId}
      selectedPost={selectedPost}
      onSelectPost={onSelectPost}
      isDisabled={disabled || isLoading || isFetching}
      isLoading={isLoading || isFetching}
      getPostLabel={getPostLabel}
      {...props}
    />
  );
});
RiverPostsSelect.displayName = 'RiverPostsSelect';
