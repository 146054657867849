import { generatedApi } from 'services/phoebeApi.generated';
import { LAKE_SEASONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_SEASONS_TAG],
  endpoints: {
    getLakeSeasonsWaterManagementsByPostId: {
      providesTags: [LAKE_SEASONS_TAG],
    },
    updateLakeSeasonsWaterManagements: {
      invalidatesTags: [LAKE_SEASONS_TAG],
    },
    deleteLakeSeasonsWaterManagementById: {
      invalidatesTags: [LAKE_SEASONS_TAG],
    },
  },
});
export const {
  useGetLakeSeasonsWaterManagementsByPostIdQuery,
  useUpdateLakeSeasonsWaterManagementsMutation,
  useDeleteLakeSeasonsWaterManagementByIdMutation,
} = api;
