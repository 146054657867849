import {
  generatedApi, GetStationYearParametersByYearAndStationIdApiArg,
  GetStationYearParametersByYearAndStationIdApiResponse,
} from 'services/phoebeApi.generated';
import { getYearParametersByTags } from 'services/utils/getYearParametersByTags';
import { STATION_YEAR_PARAMETERS_TAG, STATION_YEARS_TAG, INHOMOGENEITY_LEVEL_DATE_TAG } from '../tags';

export const stationYearsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [STATION_YEAR_PARAMETERS_TAG, STATION_YEARS_TAG, INHOMOGENEITY_LEVEL_DATE_TAG],
  endpoints: {
    getStationYearParametersByYearAndStationId: {
      providesTags: [STATION_YEAR_PARAMETERS_TAG],
      // @ts-ignore. Если в аргументах приходит bool getParametersByTag, то получаем карту значений, распределенную по тегам
      transformResponse: (response: GetStationYearParametersByYearAndStationIdApiResponse, _, { getParametersByTag }: GetStationYearParametersByYearAndStationIdApiArg & { getParametersByTag?: boolean }) => {
        if (getParametersByTag && response?.data) {
          return {
            data: getYearParametersByTags(response.data),
            errors: response.errors,
          };
        }
        return response;
      },
    },
    updateStationYearParameters: {
      async onQueryStarted({ id, year, body: changedParameters }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          stationYearsApi.util.updateQueryData('getStationYearParametersByYearAndStationId', { id, year }, (draft) => {
            const path: any = {};
            draft?.data?.forEach((prev, i) => {
              const change = changedParameters.find((changed) => changed.tag === prev.tag);
              if (change) path[i] = change;
            });
            Object.assign(draft, path);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [STATION_YEAR_PARAMETERS_TAG],
    },
    deleteStationYearParameterById: {
      invalidatesTags: [STATION_YEAR_PARAMETERS_TAG, INHOMOGENEITY_LEVEL_DATE_TAG],
    },
    getYearsByStationId: {
      providesTags: [STATION_YEARS_TAG],
    },

    getInhomogeneityLevelDateByIdAndDate: {
      providesTags: [INHOMOGENEITY_LEVEL_DATE_TAG],
    },
    updateInhomogeneityLevelDateByIdAndDate: {
      invalidatesTags: [INHOMOGENEITY_LEVEL_DATE_TAG],
    },
  },
});

export const {
  /**
   * Если в аргументах приходит bool {getParametersByTag}, то получаем карту значений, распределенную по тегам,
   * т.е. data.data = YearParametersByTagsType
   * @see YearParametersByTagsType
   */
  useGetStationYearParametersByYearAndStationIdQuery,
  useUpdateStationYearParametersMutation,
  useDeleteStationYearParameterByIdMutation,

  useGetYearsByStationIdQuery,
  useLazyGetYearsByStationIdQuery,

  useGetInhomogeneityLevelDateByIdAndDateQuery,
  useUpdateInhomogeneityLevelDateByIdAndDateMutation,
} = stationYearsApi;
