import { t } from 'locale';
import React from 'react';
import Select, { SingleValue } from 'react-select';
import { genericReactMemo, hasValue } from 'utils';
import { requiredStyle, singleBoldValue } from './customStyles';

export type WidthIdType<P extends Partial<MinimalPostType>> = Required<Pick<P, 'id'>> & P
export type MinimalPostType = { id: number };
export type OnSelectPost<P> = (post: P) => void;
export type PostsSelectProps<P extends MinimalPostType> = {
  posts?: Array<P>,
  selectedId?: number,
  selectedPost?: { id: undefined } | P,
  onSelectPost: OnSelectPost<P>,
  isDisabled?: boolean,
  isLoading?: boolean,
  getPostLabel: (post: P) => string,
  className?: string,
}

type OptionType<P> = { value: number, post: P }

const customStyles = {
  singleValue: singleBoldValue,
  // @ts-ignore
  control: (provided, state) => ({
    ...provided,
    ...requiredStyle(state),
    minWidth: 180,
  }),
};

/**
 * Общий универсальный компонент для выборки передаваемого списка постов.
 *
 * При передачи selectedId предусматривает автовыбор поста, если он отсутствует.
 */
export const PostsSelect = genericReactMemo(<P extends MinimalPostType>({
  posts = [],
  selectedId,
  selectedPost = { id: undefined },
  onSelectPost,
  isDisabled,
  isLoading,
  getPostLabel,
  className,
}: PostsSelectProps<P>) => {
  const options = React.useMemo((): Array<OptionType<P>> => posts.map((post: P) => ({
    value: post.id as number,
    post,
  })), [posts]);

  /*
   1) Если есть selectedId, но нет selectedPost.id, то обновляем пост.
   2) Если selectedPost имеет только id, то обновляем пост.
   */
  React.useEffect(() => {
    // console.log('%c \n НЕТ ВЫБРАННОГО ПОСTА НО ЕСТЬ АЙДИ', 'color: skyblue', selectedPost, selectedId);
    if (
      (selectedPost.id === undefined && hasValue(selectedId) && options.length > 0)
      || (hasValue(selectedPost.id) && Object.keys(selectedPost).length === 1)
    ) {
      const foundOption = options.find((option) => option.value === selectedId);
      // console.log('%c \n НЕТ ВЫБРАННОГО ПОСTА НО ЕСТЬ АЙДИ', 'color: red', foundOption);
      foundOption && onSelectPost(foundOption.post);
    }
  }, [onSelectPost, options, selectedId, selectedPost, selectedPost.id]);

  const selectedOption = React.useMemo(() => {
    if (options.length && selectedPost.id !== undefined) {
      return { value: selectedPost.id, post: selectedPost };
    }
    return null;
  }, [options, selectedPost]);

  const handlerChange = React.useCallback((option: SingleValue<OptionType<P>>) => {
    option?.post && onSelectPost(option.post);
  }, [onSelectPost]);

  const getOptionLabel = React.useCallback((option: OptionType<P>) => getPostLabel(option.post), [getPostLabel]);

  return (
    <Select
      className={className}
      classNamePrefix="react-select"
      aria-invalid={!selectedPost.id}
      isDisabled={isDisabled}
      isLoading={isLoading}
      styles={customStyles}
      noOptionsMessage={() => t('key_791')}
      placeholder={t('key_792')}
      options={options}
      getOptionLabel={getOptionLabel}
      value={selectedOption}
      onChange={handlerChange}
    />
  );
});
(PostsSelect as React.FC).displayName = 'PostsSelect';
