export class Stack<T, RETURN_VALUE = void, B extends (data: T) => RETURN_VALUE = (data: T) => RETURN_VALUE> {
  private _onInit: any;

  private _checkToInit() {
    if (this._onInit !== 'init') {
      if (typeof this._onInit === 'function') this._onInit();
    }
  }

  private _store: { [key: number]: B } = {};
  private _added = 0;

  get length() {
    return Object.values(this._store).length;
  }

  add(value: B, key?: number) {
    this._checkToInit();
    const l = key ? key * -1 : this._added;
    this._added += 1;
    this._store[l] = value;
    return () => this.remove(l);
  }

  remove(index: number) {
    delete this._store[index];
  }

  drop() {
    this._added = 0;
    this._store = {};
  }

  resolve(data: T): RETURN_VALUE[] {
    return Object.values(this._store).map((el) => el(data));
  }

  // @ts-ignore
  resolveWidthArgs(...args: T): RETURN_VALUE[] {
    // @ts-ignore
    return Object.values(this._store).map((el) => el(...args));
  }

  /**
   * Switch another to stack resolve control on necessary
   * @param remote
   * @param callback
   */
  switch(remote: (arg0: (data: T) => void) => void) {
    this._onInit = () => remote((args) => this.resolve(args));
    return this;
  }
}
