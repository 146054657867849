import React, { HTMLAttributes } from 'react';
import classNames from 'classnames/bind';
import { IconName } from '@blueprintjs/icons';
import { IntentButtonType } from './Button';
import { Icon } from '../Icons/Icon';

export interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /** иконка из @see https://blueprintjs.com/docs/#icons */
  icon?: IconName;
  className?: string,
  /** вариант цвета */
  // intent?: 'none' | 'info' | 'success' | 'danger' | 'warning' | 'default';
  intent?: 'none' | IntentButtonType;
  /** размер шрифта иконки  */
  size?: number;
  disabled?: boolean;
}

const IconButtonThis = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    icon,
    className,
    intent,
    size,
    ...restProps
  } = props;
  const styles = {
    // intent: `text-white bg-${intent} hover:bg-${intent}-hover active:bg-${intent}-active focus:ring-2 focus:ring-${intent}-outline`,
    intent: `text-white bg-${intent} hover:bg-${intent}-hover active:bg-${intent}-active`,
    none: `bg-black bg-opacity-0 hover:bg-opacity-5 active:bg-opacity-10 disabled:bg-${intent} disabled:bg-opacity-10`,
  };
  const cx = classNames.bind(styles);

  const classes = cx(
    'flex px-2 py-2 rounded',
    // `focus:ring-2 focus:ring-${intent}-outline focus-visible:ring-2`,
    'disabled:opacity-50 disabled:pointer-events-none',
    {
      none: intent === 'none',
      intent: intent !== 'none',
    },
    className,
  );

  return (
    <button
      ref={ref}
      type="button"
      className={classes}
      {...restProps}
    >
      {icon ? <Icon icon={icon} className="pointer-events-none" size={size} /> : '.'}
    </button>
  );
});

IconButtonThis.displayName = 'IconButtonThis';
IconButtonThis.defaultProps = {
  intent: 'none',
  size: 16,
};

/** Стилизованная кнопка-иконка, обернутая в forwardRef и memo */
export const IconButton = React.memo(IconButtonThis);
IconButton.displayName = 'IconButton';
