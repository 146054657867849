import { generatedApi } from '../phoebeApi.generated';
import { LAKE_POST_YEARS_TAG, LAKE_INHOMOGENEITY_LEVEL_DATE_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_POST_YEARS_TAG, LAKE_INHOMOGENEITY_LEVEL_DATE_TAG],
  endpoints: {
    getYearsByPostId: {
      providesTags: [LAKE_POST_YEARS_TAG],
    },
    getLakeInhomogeneityLevelDateByPostIdAndDate: {
      providesTags: [LAKE_INHOMOGENEITY_LEVEL_DATE_TAG],
    },
    updateLakeInhomogeneityLevelDateByPostIdAndDate: {
      invalidatesTags: [LAKE_INHOMOGENEITY_LEVEL_DATE_TAG],
    },
    deleteLakePostYearParameterById: {
      invalidatesTags: [LAKE_POST_YEARS_TAG, LAKE_INHOMOGENEITY_LEVEL_DATE_TAG],
    },
  },
});

const customApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    // @ts-ignore
    getUniqueYearsForPosts: build.query<string[], number[]>({
      async queryFn(ids: number[], { dispatch }) {
        try {
          const dispatcher = (id: number) => dispatch(api.endpoints.getYearsByPostId.initiate({ id }));
          const promises = ids.map(async (id) => {
            const query = await dispatcher(id);
            return query.data!;
          });
          const results = await Promise.all(promises);
          const uniqueYears = [...new Set((results).flat())].sort((a, b) => Number(b) - Number(a));
          const result = uniqueYears.filter((year) => !results.some((years) => !years.includes(year)));
          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetYearsByPostIdQuery,
  useLazyGetYearsByPostIdQuery,
  useGetLakePostYearParametersByYearAndPostIdQuery,
  useDeleteLakePostYearParameterByIdMutation,

  useGetLakeInhomogeneityLevelDateByPostIdAndDateQuery,
  useUpdateLakeInhomogeneityLevelDateByPostIdAndDateMutation,
} = api;

export const {
  useGetUniqueYearsForPostsQuery,
} = customApi;
