import { t } from 'locale';
import React from 'react';
import serviceUnavailable from 'images/503_Service_Unavailable.png';

/**
 * Экран когда сервер не доступен
 */
export const TechnicalWork = React.memo(() => (
  <div className="overflow-hidden flex flex-col items-center justify-center h-full w-full">
    <span className="p-2 font-bold uppercase bg-primary-bg text-default text-[clamp(1rem,_-0.25rem_+_5.333vw,_3.5rem)]">
      {t('key_837')}
    </span>
    <img className="max-w-9/10 max-h-9/10" alt="serviceUnavailable" src={serviceUnavailable} />
  </div>
));

TechnicalWork.displayName = 'TechnicalWork';
