// eslint-disable-next-line camelcase
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16';
import classNames from 'classnames';

// eslint-disable-next-line camelcase
export type IconName = BlueprintIcons_16Id;
export { classNames };

const portal = document.createElement('div');
portal.setAttribute('id', 'portal');
document.body.appendChild(portal);

export * from './Button/Button';
export * from './Button/IconButton';
export * from './CardsList/CardsList';
export * from './Checkbox/Checkbox';
export * from './Dialog/Dialog';
export * from './Hooks/usePortal';
export * from './Icons/Icon';
// todo: временный файл - не забыть удалить
export * from './Icons/Icons';
export * from './Input/TextInput';
export * from './Input/TextInputBlur';
export * from './main';
export * from './Pop/Dropdown';
export * from './Pop/Poptip';
export * from './Pop/PopUtils';
export * from './Radio/Radio';
export * from './Spinner/BlockSpinner';
export * from './Spinner/Spinner';
export * from './Title/Title';
export * from './Toaster/Toaster';
export * from './Tooltip/Tooltip';
