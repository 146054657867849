// данные для всего приложения
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngExpression } from 'leaflet';
import { RootState } from 'Features/App/store';
import { StationDto } from 'services/phoebeApi.generated';
import { PlacesPath } from 'Features/Main/places';
import { RIVER } from 'constants/paths';
import { DateType, getMaxDate } from 'Shared';

export const APP_SLICE = 'app';

export type AppSliceState = {
  // для получения места используем useGetPlace, а это переменная для табов на начальной странице
  place: PlacesPath | string,
  // todo: перенести в riverSlice
  selectedStation: StationDto,
  selectedDate: DateType,
  endDate?: DateType,
  waterFlowIds?: Number[],
  position: LatLngExpression,
  zoom: number,
  printing: boolean,
}

const initialState: AppSliceState = {
  place: RIVER,
  selectedStation: { id: undefined },
  // по умолчанию день не выбран
  selectedDate: {
    ...getMaxDate(), year: 0, day: '', // возможно можно day: 0 - день не выбран
  },
  position: [61.587928, 90.018971],
  zoom: 4,
  printing: false,
};

const appSlice = createSlice({
  name: APP_SLICE,
  initialState,
  reducers: {
    setPlace: (state, { payload }: PayloadAction<PlacesPath | string>) => Object.assign(state, { place: payload }),
    setSelectedStation: (state, { payload }: PayloadAction<StationDto>) => Object.assign(state, { selectedStation: payload }),
    setSelectedDate: (state, { payload }: PayloadAction<DateType>) => Object.assign(state, { selectedDate: payload }),
    setSelectedDateYear: (state, { payload: year }: PayloadAction<DateType['year']>) => { state.selectedDate.year = year || 0; },
    setSelectedEndDate: (state, { payload }: PayloadAction<Partial<DateType>>) => Object.assign(state, { endDate: { ...state.selectedDate, ...(state.endDate || {}), ...payload } }),
    setSelectedWaterFlowIds: (state, { payload }: PayloadAction<Number[] | number>) => Object.assign(state, {
      waterFlowIds: typeof payload === 'number'
        ? [payload, ...(state.waterFlowIds || []).filter((v) => v !== payload)]
        : payload,
    }),
    resetSelectedDayAndMonth: (state) => {
      state.selectedDate = {
        ...initialState.selectedDate,
        year: state.selectedDate.year,
      };
    },

    // setSelectedDateMonth: (state, { payload: month }: PayloadAction<DateType['month']>) => { state.selectedDate.month = month; },
    // setSelectedDateDay: (state, { payload: day }: PayloadAction<DateType['day']>) => { state.selectedDate.day = day; },
    setPosition: (state, { payload }: PayloadAction<LatLngExpression>) => Object.assign(state, { position: payload, zoom: 7 }),
    // для logout - обновляет весь стейт слайса observations
    resetAppSlice: () => initialState,

    setPrinting: (state, { payload }: PayloadAction<boolean>) => Object.assign(state, { printing: payload }),
  },
});

export const selectedPlaceSelector = (state: RootState): PlacesPath | string => state[APP_SLICE].place;
export const selectedStationSelector = (state: RootState): StationDto => state[APP_SLICE].selectedStation;
export const selectedStationIdSelector = (state: RootState): number | -1 => selectedStationSelector(state).id || -1;
export const selectedStationIssueNumberSelector = (state: RootState): number | undefined => selectedStationSelector(state).issueNumber;

export const selectedDateSelector = (state: RootState): DateType => state[APP_SLICE].selectedDate;
export const selectedDateYearSelector = (state: RootState): DateType['year'] => state[APP_SLICE].selectedDate.year;
/** от 1 до 12 (например, для запроса по конкретному месяцу) */
export const selectedDateMonthNumberSelector = (state: RootState): DateType['month'] => state[APP_SLICE].selectedDate.month + 1;

export const selectedEndDateSelector = (state: RootState): DateType => state[APP_SLICE].endDate || state[APP_SLICE].selectedDate;

export const selectedWaterFlowIdsSelector = (state: RootState): number[] | undefined => state[APP_SLICE].waterFlowIds;

export const hasDaySelector = (state: RootState): boolean => !!state[APP_SLICE].selectedDate?.day;

export const positionSelector = (state: RootState) => ({ position: state[APP_SLICE].position || [61.587928, 90.018971], zoom: state[APP_SLICE].zoom });

export const printingSelector = (state: RootState): number[] | undefined => state[APP_SLICE].printing;

export const {
  setPlace,
  setSelectedStation,
  setSelectedDate,
  setSelectedDateYear,
  setSelectedEndDate,
  setSelectedWaterFlowIds,
  // setSelectedDateMonth,
  // setSelectedDateDay,
  setPosition,
  resetAppSlice,
  resetSelectedDayAndMonth,

  setPrinting,
} = appSlice.actions;
export default appSlice.reducer;
