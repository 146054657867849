import { generatedApi } from '../phoebeApi.generated';

const VOC_REGIONS_TAG = 'VOC_REGIONS_TAG';
const VOC_REGION_TAG = 'VOC_REGION_TAG';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [VOC_REGIONS_TAG, VOC_REGION_TAG],
  endpoints: {
    getVocRegionsAll: {
      providesTags: [VOC_REGIONS_TAG],
    },
    createVocRegion: {
      invalidatesTags: [VOC_REGIONS_TAG],
    },
    getVocRegionsByCode: {
      providesTags: [VOC_REGION_TAG],
    },
    updateVocRegion: {
      invalidatesTags: [VOC_REGION_TAG],
    },
    deleteVocRegionsByCode: {
      invalidatesTags: [VOC_REGIONS_TAG],
    },
  },
});

export const {
  useGetVocRegionsAllQuery,
  useCreateVocRegionMutation,
  useGetVocRegionsByCodeQuery,
  useUpdateVocRegionMutation,
  useDeleteVocRegionsByCodeMutation,
} = api;
