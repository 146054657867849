import { generatedApi } from './phoebeApi.generated';

const cowApi = generatedApi.enhanceEndpoints({
  endpoints: {
    cowSay: {
      extraOptions: {
        text: true,
        noToaster: true,
      },
    },
  },
});

export const {
  useCowSayQuery,
} = cowApi;
