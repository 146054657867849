import { t } from 'locale';
import React from 'react';
import { OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { combineStyles, controlMaxContent, maxContentStyle, requiredStyle } from 'Shared/Select/customStyles';

export type PossibleDaysType = Array<number>
export type ExtraInfoDays = { [day: string | number]: number[]; }

export type DaysSelectProps = {
  required?: boolean,
  selected: number | string,
  setSelected: (day: number | undefined, isNew?: boolean) => void,
  disabled?: boolean,
  possibleDays: PossibleDaysType,
  /** информация на день: чисто для water flow номера расходов... */
  extraInfoDays?: ExtraInfoDays,
  isLoading?: boolean,
}

type DayOptionType = {
  value: number,
  label: number | string,
}

export function PossibleDaysSelect({
  required = false,
  disabled = false,
  selected,
  setSelected,
  possibleDays,
  extraInfoDays,
  isLoading = false,
}: DaysSelectProps) {
  const customStyles = React.useMemo(() => {
    if (required) {
      return {
        control: combineStyles([requiredStyle, maxContentStyle]),
      };
    }
    return {
      control: controlMaxContent,
    };
  }, [required]);

  const options = React.useMemo<Array<DayOptionType>>(() => possibleDays.map((day) => ({
    value: day,
    // label: day,
    label: extraInfoDays?.[day] ? `${day} (№${extraInfoDays[day].join(', ')})` : day,
  })), [extraInfoDays, possibleDays]);

  const selectedOption = React.useMemo<DayOptionType | null>(() => (
    selected
      ? ({
        value: +selected,
        label: +selected,
      })
      : null
  ), [selected]);

  const handleChange = (newValue: OnChangeValue<DayOptionType, false>) => {
    if (!newValue) setSelected(undefined);
    else setSelected(+newValue.value);
  };
  // можно создавать от 1 до 31
  const isValidNewOption = (inputValue: string) => (
    !options.some((el) => +el.value === +inputValue)
    && +inputValue >= 1
    && +inputValue <= 31
  );

  const noOptionsMessageCreatableSelect = () => t('key_825');
  const loadingMessage = () => t('key_826');
  const formatCreateLabel = (inputValue: string) => <span className="text-info">{t('key_827', { inputValue1: inputValue })}</span>;

  return (
    <CreatableSelect
      isClearable
      formatCreateLabel={formatCreateLabel}
      isDisabled={disabled}
      classNamePrefix="react-select"
      placeholder=""
      allowCreateWhileLoading={false}
      isLoading={isLoading}
      loadingMessage={loadingMessage}
      // undefined - чтоб показывалось loadingMessage
      options={isLoading ? undefined : options}
      value={selectedOption}
      noOptionsMessage={noOptionsMessageCreatableSelect}
      onChange={handleChange}
      isValidNewOption={isValidNewOption}
      styles={customStyles}
    />
  );
}
