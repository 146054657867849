import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Spinner } from '@mono/ui-components';
import store from 'Features/App/store';
import App from 'Features/App/App';

import 'styles/index.scss';

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);
