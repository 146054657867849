import React from 'react';
import { SpinnerIcon } from './SpinnerIcon';

export function Spinner() {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <SpinnerIcon />
    </div>
  );
}
