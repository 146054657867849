import { generatedApi } from 'services/phoebeApi.generated';
import { LAKE_POSTS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_POSTS_TAG],
  endpoints: {
    getLakeBookPostsByIssueNumber: {
      providesTags: [LAKE_POSTS_TAG],
    },
  },
});

export const {
  useGetLakeBookPostsByIssueNumberQuery,

  useLakeExportMdsMutation,
  useLakeExportEmdsMutation,
  useLakeExportEdsMutation,
} = api;
