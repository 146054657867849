/*
  как узнать какие нужны теги/атрибуты ?
  посмотреть в базе
*/

// свойства прописаны так как лежат в базе теги
export const vocListTags = {
  device_suspended_sediment_discharge: 'device_suspended_sediment_discharge',
  device_water_flow: 'device_water_flow',
  features_measurement_sediment_discharge: 'features_measurement_sediment_discharge',
  features_measurement_sediment_discharge_channel: 'features_measurement_sediment_discharge_channel',
  features_measurement_water_flow: 'features_measurement_water_flow',
  features_measurement_water_flow_channel: 'features_measurement_water_flow_channel',
  gauge_datum: 'gauge_datum',
  gauge_line_number: 'gauge_line_number',
  ice_measurement: 'ice_measurement',
  ksvo: 'ksvo',
  ksvo_month: 'ksvo_month',
  ksvo_regime: 'ksvo_regime',
  mainstream: 'mainstream',
  method_suspended_sediment_discharge: 'method_suspended_sediment_discharge',
  method_water_flow: 'method_water_flow',
  precipitation: 'precipitation',
  seasons_water_management: 'seasons_water_management',
  silt_charge: 'silt_charge',
  suspended_sediment_discharge_flag: 'suspended_sediment_discharge_flag',
  water_flow_flag: 'water_flow_flag',
};

// свойства прописаны так как лежат в базе теги
export const vocListAttributes = {
  1: '1',
  2: '2',
  channel_number: 'channel_number',
  ksvo: 'ksvo',
  monitoring_program: 'monitoring_program',
  place: 'place',
  system: 'system',
  type: 'type',
  usage_code: 'usage_code',
};
