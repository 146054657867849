// place
export const LAKE = 'lake';
export const RIVER = 'river';

// main
export const LOGIN = 'login';
export const SETTINGS = 'settings';
export const MAP = 'map';

// menu
export const METADATA = 'metadata';
export const DATA_CORRECTION = 'data-correction';
export const CHARTS = 'charts';
export const DAILY_WATER_FLOW = 'daily-water-flow';
export const ANNUAL_DATA = 'annual-data';
export const REPORTS = 'reports';
export const FILES = 'files';

// Settings
export const NEW = 'new';
export const POSTS = 'posts';
export const POST_CONFINES = 'confines';
export const POST_OBSERVATION_AREA = 'observation-area';
export const POST_SEASONS = 'seasons';
export const POST_SEASONS_CONFINES = 'seasons-confines';
export const VOC_LISTS = 'voc-lists';

// Metadata
export const POST = 'post';
export const WATER_AREA_POST = 'water-area-post';

// Data-correction
export const BASIC_OBSERVATIONS = 'basic-observations';
export const ICE_MEASUREMENTS = 'ice-measurements';
export const SILT_MEASUREMENTS = 'silt-measurements';
export const FLOW_MEASUREMENTS = 'flow-measurements'; // общая вкладка расходов
export const WATER_FLOW = 'water-flow'; // таб вкладки FLOW_MEASUREMENTS
export const FLOW_CHANNEL = 'flow-channel';
export const CHANNEL_MEASUREMENTS = 'channel-measurement';
export const MEASUREMENTS_VERTICAL = 'measurement-vertical';
export const LENGTHWISE_SLOPE = 'lengthwise-slope';
export const GRANULOMETRY = 'granulometry';
export const SUSPENDED_SEDIMENT = 'suspended-sediment';
export const BOTTOM_SEDIMENT = 'bottom-sediment';
export const WAVE_OBSERVATIONS = 'wave-observations';

// Reports
export const EDS = 'eds';
export const EMDS = 'emds';
export const MDS = 'mds';
