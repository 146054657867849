export const STATIONS_TAG = 'STATIONS_TAG';
export const STATIONS_FOR_MAP_TAG = 'STATIONS_FOR_MAP_TAG';
export const STATION_TAG = 'STATION_TAG';
export const STATION_YEAR_PARAMETERS_TAG = 'STATION_YEAR_PARAMETERS';
export const STATION_YEARS_TAG = 'STATION_YEARS_TAG';
export const CONFINES_TAG = 'CONFINES_TAG';
export const SEASONS_TAG = 'SEASONS_TAG';
export const DEFAULT_CONFINES_TAG = 'DEFAULT_CONFINES_TAG';

// формы наблюдений
export const FORM_DESCRIPTIONS_TAG = 'FORM_DESCRIPTIONS_TAG';
export const BASIC_OBSERVATIONS_TAG = 'BASIC_OBSERVATIONS_TAG';
export const ICE_MEASUREMENTS_TAG = 'ICE_MEASUREMENTS_TAG';
export const GRANULOMETRY_TAG = 'GRANULOMETRY_TAG';
export const LENGTHWISE_SLOPE_TAG = 'LENGTHWISE_SLOPE_TAG';
export const SILT_MEASUREMENTS_TAG = 'SILT_MEASUREMENTS_TAG';
export const WATER_FLOW_TAG = 'WATER_FLOW_TAG';
export const WATER_FLOW_DAYS_TAG = 'WATER_FLOW_DAYS_TAG';
export const WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG = 'WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG';
export const WATER_FLOW_PROCESS_TAG = 'WATER_FLOW_PROCESS_TAG';

// Ежедневные расходы
export const WATER_FLOW_TEMP_TAG = 'WATER_FLOW_TEMP_TAG';
export const WATER_FLOW_COEFFICIENTS_TAG = 'WATER_FLOW_COEFFICIENTS_TAG';
export const WATER_FLOW_CURVE_TAG = 'WATER_FLOW_CURVE_TAG';
export const WATER_FLOW_TEMP_PERIODS_TAG = 'WATER_FLOW_TEMP_PERIODS_TAG';

// прочее
export const CALCULATED_DATA_TAG = 'CALCULATED_DATA_TAG';
export const KSVO_MONTH_TAG = 'KSVO_MONTH_TAG';
export const INHOMOGENEITY_LEVEL_DATE_TAG = 'INHOMOGENEITY_LEVEL_DATE_TAG';
export const SEDIMENT_DISCHARGE_TAG = 'SEDIMENT_DISCHARGE_TAG';
