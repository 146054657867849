import { CalculatedDataType } from 'services/types';

/** {@link CalculatedDataType} */
export type CalculatedDataByTagType = {
  [tag: string]: CalculatedDataType
}

/** {@link CalculatedDataByTagType} */
export type CalculatedDataDayType = {
  /** day - номер дня от 1 до 31 */
  [day: string]: CalculatedDataByTagType,
}

/** {@link CalculatedDataByTagType} */
export type CalculatedDataDecadeType = {
  /** decade - обычно от 1 до 3 */
  [decade: string]: CalculatedDataByTagType,
}

/**
 * Тип месячных данных: данные распределены на day, month и decade,
 * внутри которых по тегам распределены данные.
 * Также есть данные в нетронутом виде (calculatedData)
 *
 * {@link CalculatedDataDayType}, {@link CalculatedDataByTagType}, {@link CalculatedDataDecadeType}, {@link CalculatedDataType}
 */
export type MonthlyTablesDataType = {
  /** данные из calculated_data, распределенные по дням (теги где есть _day_) и по тегам внутри дня */
  day: CalculatedDataDayType,
  /** данные из calculated_data месяца (теги где есть _mon_), распределенные по тегам */
  month: CalculatedDataByTagType
  /** данные из calculated_data, распределенные по декадам (теги где есть _dec_) и по тегам внутри декады */
  decade: CalculatedDataDecadeType,
  /** данные как они пришли с сервера */
  calculatedData: CalculatedDataType[],
}

export const getRegexForTagType = (typeTag: 'day' | 'mon' | 'dec') => new RegExp(`^.{1,}_${typeTag}(_.{1,}$|$)`);

/**
 * Получаем словари данных распределенные по типу тега (day, month, decade)
 * @param calculatedData
 * @returns {MonthlyTablesDataType}
 */
export function getMonthlyTablesData(calculatedData: Array<CalculatedDataType>): MonthlyTablesDataType {
  const dayRegex = getRegexForTagType('day');
  const monthRegex = getRegexForTagType('mon');
  const decadeRegex = getRegexForTagType('dec');

  // распределяем данные в зависимости от типа тега, который отвечает за данные
  const monthlyTablesData: MonthlyTablesDataType = calculatedData.reduce((acc, current) => {
    const { tag = 'noTag', date1, decade } = current;

    if (dayRegex.test(tag)) {
      const day = date1 ? new Date(date1).getDate() : undefined;

      if (day) {
        if (!acc.day[day]) acc.day[day] = {};
        acc.day[day][tag] = current;
      }
    } else if (monthRegex.test(tag)) {
      acc.month[tag] = current;
    } else if (decadeRegex.test(tag)) {
      if (decade) {
        if (!acc.decade[decade]) acc.decade[decade] = {};
        acc.decade[decade][tag] = current;
      }
    }

    return acc;
  }, {
    day: {},
    month: {},
    decade: {},
    calculatedData,
  } as MonthlyTablesDataType);

  return monthlyTablesData;
}
