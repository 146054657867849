import { t } from 'locale';
export const localErrors = {
  POST_ALREADY_EXIST: t('key_740'),
  POST_NOT_FOUND: t('key_741'),
  WATER_OBJECT_NOT_FOUND: t('key_742'),
  POST_AND_YEAR_NOT_FOUND: t('key_743'),
  FILES_CREATION_ERROR: t('key_744'),
  ANNUAL_PROCESSING_ERROR: t('key_745'),
  MONTHLY_PROCESSING_ERROR: t('key_746'),
  XML_FILE_PROCESSING_ERROR: t('key_747'),
  XML_FILE_CREATION_ERROR: t('key_748'),
  TIME_PARSE_ERROR: t('key_749'),
  UNKNOWN_ERROR: t('key_750'),
  ROSS_UNAUTHORIZED_ERROR: t('key_751'),
  ROSS_STATION_NOT_FOUND: t('key_752'),
  WATER_FLOW_ALREADY_EXIST: t('key_753'),
  REQUIRED_VALUES_NOT_ENTERED: t('key_754'),
  WATER_FLOW_CALCULATION_ERROR: t('key_755'),
  WATER_FLOW_NUMBER_NOT_ENTERED: t('key_756'),
  WATER_FLOW_NUMBER_ALREADY_EXIST: t('key_757'),
  ICE_MEASUREMENT_PLACE_NOT_ENTERED: t('key_758'),
  UGMS_NOT_FOUND: t('key_759'),
  DAILY_WATER_FLOW_CALCULATION_ERROR: t('key_760'),
  COEFFICIENT_CALCULATION_ERROR: t('key_761'),
  STOUT_CORRECT_CALCULATION_ERROR: t('key_762'),
  CURVE_ALREADY_EXIST: t('key_763'),
  INTERVAL_ENTERED_INCORRECTLY: t('key_764'),
  INVALID_COEFFICIENT_VALUE: t('key_765'),
  WATER_FLOWS_ARE_NOT_ENOUGH: t('key_766'),
  CURVE_FITTING_ERROR: t('key_767'),
  DATA_CONTROL_FAILED: t('key_768'),
  PYTHON_MODULES_ERROR: t('key_769'),
  ISSUE_NUMBER_NOT_FOUND: t('key_770'),
} as const;
