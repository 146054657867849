import { generatedApi } from '../phoebeApi.generated';
import { getVocCodesMap } from './transform';

const VOC_LISTS_TAG = 'VOC_LISTS_TAG';
const VOC_LISTS_BY_TAG_TAG = 'VOC_LISTS_BY_TAG_TAG';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [VOC_LISTS_TAG, VOC_LISTS_BY_TAG_TAG],
  endpoints: {
    getVocListsAll: {
      providesTags: [VOC_LISTS_TAG],
    },
    updateVocLists: {
      invalidatesTags: [VOC_LISTS_TAG, VOC_LISTS_BY_TAG_TAG],
    },
    createVocLists: {
      invalidatesTags: [VOC_LISTS_TAG, VOC_LISTS_BY_TAG_TAG],
    },
    deleteVocListsById: {
      invalidatesTags: [VOC_LISTS_TAG, VOC_LISTS_BY_TAG_TAG],
    },
    getVocListsByTagAndAttribute: {
      // @ts-ignore если есть isVocCodesMap, то получем объекты по кодам
      transformResponse: (response, _, { isVocCodesMap }) => {
        if (isVocCodesMap) {
          return getVocCodesMap(response);
        }
        return response;
      },
      providesTags: [VOC_LISTS_BY_TAG_TAG],
    },
  },
});

export const {
  useGetVocListsAllQuery,
  useGetVocListsByIdQuery,
  useCreateVocListsMutation,
  useUpdateVocListsMutation,
  useDeleteVocListsByIdMutation,
  useGetVocListsByTagAndAttributeQuery,
} = api;
