import React, { ReactNode } from 'react';

/**
 * Атрибуты компонента {@link LowerButtonsContainer}
 */
type LowerButtonsContainerProps = {
  children: ReactNode,
  className?: string,
  /** [justify=end] */
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly',
}

/**
 * Обертка для кнопок, который должны находится внизу форм
 * @func
 * @param {LowerButtonsContainerProps} props
 */
export const LowerButtonsContainer = React.memo(({
  children,
  className,
  justify = 'end',
}: LowerButtonsContainerProps) => (
  <div className={`flex justify-${justify} items-center gap-3 ${className}`}>
    {children}
  </div>
));

LowerButtonsContainer.displayName = 'LowerButtonsContainer';
