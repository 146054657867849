import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './tooltip.css';

export interface TooltipProps {
  children: ReactNode,
  className?: string,
  content?: ReactNode,
  position?: 'top' | 'right' | 'bottom' | 'left',
}

const TooltipThis = React.forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const {
    children,
    content,
    position,
    className,
  } = props;
  const [visible, setVisible] = React.useState<boolean>(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const classes = classNames(
    'px-4 py-2 rounded absolute',
    'tooltip',
    position,
    className,
  );

  return (
    <span className="relative">
      {visible && <span ref={ref} className={classes}>{content}</span>}
      <span
        className="cursor-pointer"
        onMouseEnter={show}
        onMouseLeave={hide}
      >
        {children}
      </span>
    </span>
  );
});

TooltipThis.displayName = 'TooltipThis';
TooltipThis.defaultProps = {
  position: 'bottom',
};

/** Компонент, обернутый в forwardRef и memo */
export const Tooltip = React.memo(TooltipThis);
Tooltip.displayName = 'Tooltip';
