import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  label?: string,
  name?: string,
  checked?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  key?: number | string,
  id?: string | undefined,
  disabled?: boolean,
  className?: string,
}

// todo: Нужно довести до ума компонент. В частости htmlFor и id, т.е. связка через них
const ThisCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    className, label, name, checked, onChange, disabled, ...inputProps
  } = props;

  const classes = classNames(
    'flex items-center cursor-pointer',
    {
      'gap-2': Boolean(label),
    },
    className,
  );

  const idCheckbox = React.useMemo(() => `checkbox-${name}${inputProps.id || `${name}-${label}` || name}`, [inputProps.id, label, name]);

  return (
    <label htmlFor={idCheckbox} className={classes}>
      <input
        ref={ref}
        type="checkbox"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...inputProps}
        id={idCheckbox}
      />
      <span>{label}</span>
    </label>
  );
});

ThisCheckbox.displayName = 'ThisCheckbox';
ThisCheckbox.defaultProps = {
  label: '',
  name: '',
  checked: false,
};

/** Стилизованная текстовой инпут, обернутая в forwardRef и memo */
export const Checkbox = React.memo(ThisCheckbox);
Checkbox.displayName = 'Checkbox';
