import { generatedApi } from '../phoebeApi.generated';
import { LAKE_CALCULATED_DATA_TAG, LAKE_ICE_OBSERVATIONS_TAG } from './tags';

const postsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_CALCULATED_DATA_TAG, LAKE_ICE_OBSERVATIONS_TAG],
  endpoints: {
    lakeMonthlyProcess: {
      extraOptions: {
        xml: true,
      },
      invalidatesTags: [LAKE_CALCULATED_DATA_TAG, LAKE_ICE_OBSERVATIONS_TAG],
    },
  },
});

export const {
  useLakeAnnualProcessMutation,
  useHasLakeMonthlyProcessingQuery,

  useLakeMonthlyProcessMutation,
  useHasLakeErrorsQuery,
} = postsApi;
