import { IceMeasurementType } from 'services/types/api';
import { hasValue } from 'utils';

export type IceMeasurementsByPlaceType = {
  /** по местам распределены измерения */
  measurementsByPlace: {
    /** place - номер места */
    [place: number | string]: IceMeasurementType[]
  }
  hasTotalThicknessIce: boolean,
}

/**
 * По первоначальным данным получаем данные, распределенные по месту измерения,
 * также показывает наличие "Общая толщина льда" хоть в одном измерении
 *
 * @param measurements {@link IceMeasurementType}[]
 * @returns {IceMeasurementsByPlaceType} {@link IceMeasurementsByPlaceType}
 */
export function getIceMeasurementsForMonthlyTablesData(measurements: IceMeasurementType[]) {
  return measurements.reduce((acc, measurement) => {
    const { totalThicknessIce } = measurement;
    // место может быть в place или в iceMeasurmentPlace
    const place = measurement.iceMeasurmentPlace || measurement.place || undefined;

    // есть ли общая толщина льда
    if (!acc.hasTotalThicknessIce && hasValue(totalThicknessIce)) acc.hasTotalThicknessIce = true;

    // место не может быть 0
    if (place) {
      if (!acc.measurementsByPlace[place]) acc.measurementsByPlace[place] = [];
      acc.measurementsByPlace[place].push(measurement);
    }

    return acc;
  }, {
    measurementsByPlace: {},
    hasTotalThicknessIce: false,
  } as IceMeasurementsByPlaceType);
}
