import { generatedApi } from 'services/phoebeApi.generated';
import { FORM_DESCRIPTIONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [FORM_DESCRIPTIONS_TAG],
  endpoints: {
    getFormDescriptionsByFormId: {
      providesTags: [FORM_DESCRIPTIONS_TAG],
    },
  },
});

export const {
  useGetFormDescriptionsByFormIdQuery,
  useGetLakeFormDescriptionsByIdQuery,
} = api;
