// todo: временный файл - не забыть удалить

import { IconName } from '@blueprintjs/icons';

export const AddIcon = 'add' as IconName;
export const AddColumnLeftIcon = 'add-column-left' as IconName;
export const AddColumnRightIcon = 'add-column-right' as IconName;
export const AddLocationIcon = 'add-location' as IconName;
export const AddRowBottomIcon = 'add-row-bottom' as IconName;
export const AddRowTopIcon = 'add-row-top' as IconName;
export const AddToArtifactIcon = 'add-to-artifact' as IconName;
export const AddToFolderIcon = 'add-to-folder' as IconName;
export const AirplaneIcon = 'airplane' as IconName;
export const AlignCenterIcon = 'align-center' as IconName;
export const AlignJustifyIcon = 'align-justify' as IconName;
export const AlignLeftIcon = 'align-left' as IconName;
export const AlignmentBottomIcon = 'alignment-bottom' as IconName;
export const AlignmentHorizontalCenterIcon = 'alignment-horizontal-center' as IconName;
export const AlignmentLeftIcon = 'alignment-left' as IconName;
export const AlignmentRightIcon = 'alignment-right' as IconName;
export const AlignmentTopIcon = 'alignment-top' as IconName;
export const AlignmentVerticalCenterIcon = 'alignment-vertical-center' as IconName;
export const AlignRightIcon = 'align-right' as IconName;
export const AnnotationIcon = 'annotation' as IconName;
export const AppHeaderIcon = 'app-header' as IconName;
export const ApplicationIcon = 'application' as IconName;
export const ApplicationsIcon = 'applications' as IconName;
export const ArchiveIcon = 'archive' as IconName;
export const ArrayIcon = 'array' as IconName;
export const ArrayBooleanIcon = 'array-boolean' as IconName;
export const ArrayDateIcon = 'array-date' as IconName;
export const ArrayNumericIcon = 'array-numeric' as IconName;
export const ArrayStringIcon = 'array-string' as IconName;
export const ArrayTimestampIcon = 'array-timestamp' as IconName;
export const ArrowBottomLeftIcon = 'arrow-bottom-left' as IconName;
export const ArrowBottomRightIcon = 'arrow-bottom-right' as IconName;
export const ArrowDownIcon = 'arrow-down' as IconName;
export const ArrowLeftIcon = 'arrow-left' as IconName;
export const ArrowRightIcon = 'arrow-right' as IconName;
export const ArrowsHorizontalIcon = 'arrows-horizontal' as IconName;
export const ArrowsVerticalIcon = 'arrows-vertical' as IconName;
export const ArrowTopLeftIcon = 'arrow-top-left' as IconName;
export const ArrowTopRightIcon = 'arrow-top-right' as IconName;
export const ArrowUpIcon = 'arrow-up' as IconName;
export const AsteriskIcon = 'asterisk' as IconName;
export const AutomaticUpdatesIcon = 'automatic-updates' as IconName;
export const BacklinkIcon = 'backlink' as IconName;
export const BadgeIcon = 'badge' as IconName;
export const BanCircleIcon = 'ban-circle' as IconName;
export const BankAccountIcon = 'bank-account' as IconName;
export const BarcodeIcon = 'barcode' as IconName;
export const BlankIcon = 'blank' as IconName;
export const BlockedPersonIcon = 'blocked-person' as IconName;
export const BoldIcon = 'bold' as IconName;
export const BookIcon = 'book' as IconName;
export const BookmarkIcon = 'bookmark' as IconName;
export const BoxIcon = 'box' as IconName;
export const BriefcaseIcon = 'briefcase' as IconName;
export const BringDataIcon = 'bring-data' as IconName;
export const BuildIcon = 'build' as IconName;
export const CalculatorIcon = 'calculator' as IconName;
export const CalendarIcon = 'calendar' as IconName;
export const CameraIcon = 'camera' as IconName;
export const CaretDownIcon = 'caret-down' as IconName;
export const CaretLeftIcon = 'caret-left' as IconName;
export const CaretRightIcon = 'caret-right' as IconName;
export const CaretUpIcon = 'caret-up' as IconName;
export const CellTowerIcon = 'cell-tower' as IconName;
export const ChangesIcon = 'changes' as IconName;
export const ChartIcon = 'chart' as IconName;
export const ChatIcon = 'chat' as IconName;
export const ChevronBackwardIcon = 'chevron-backward' as IconName;
export const ChevronDownIcon = 'chevron-down' as IconName;
export const ChevronForwardIcon = 'chevron-forward' as IconName;
export const ChevronLeftIcon = 'chevron-left' as IconName;
export const ChevronRightIcon = 'chevron-right' as IconName;
export const ChevronUpIcon = 'chevron-up' as IconName;
export const CircleIcon = 'circle' as IconName;
export const CircleArrowDownIcon = 'circle-arrow-down' as IconName;
export const CircleArrowLeftIcon = 'circle-arrow-left' as IconName;
export const CircleArrowRightIcon = 'circle-arrow-right' as IconName;
export const CircleArrowUpIcon = 'circle-arrow-up' as IconName;
export const CitationIcon = 'citation' as IconName;
export const CleanIcon = 'clean' as IconName;
export const ClipboardIcon = 'clipboard' as IconName;
export const CloudIcon = 'cloud' as IconName;
export const CloudDownloadIcon = 'cloud-download' as IconName;
export const CloudUploadIcon = 'cloud-upload' as IconName;
export const CodeIcon = 'code' as IconName;
export const CodeBlockIcon = 'code-block' as IconName;
export const CogIcon = 'cog' as IconName;
export const CollapseAllIcon = 'collapse-all' as IconName;
export const ColumnLayoutIcon = 'column-layout' as IconName;
export const CommentIcon = 'comment' as IconName;
export const ComparisonIcon = 'comparison' as IconName;
export const CompassIcon = 'compass' as IconName;
export const CompressedIcon = 'compressed' as IconName;
export const ConfirmIcon = 'confirm' as IconName;
export const ConsoleIcon = 'console' as IconName;
export const ContrastIcon = 'contrast' as IconName;
export const ControlIcon = 'control' as IconName;
export const CreditCardIcon = 'credit-card' as IconName;
export const CrossIcon = 'cross' as IconName;
export const CrownIcon = 'crown' as IconName;
export const CubeIcon = 'cube' as IconName;
export const CubeAddIcon = 'cube-add' as IconName;
export const CubeRemoveIcon = 'cube-remove' as IconName;
export const CurvedRangeChartIcon = 'curved-range-chart' as IconName;
export const CutIcon = 'cut' as IconName;
export const CycleIcon = 'cycle' as IconName;
export const DashboardIcon = 'dashboard' as IconName;
export const DatabaseIcon = 'database' as IconName;
export const DataConnectionIcon = 'data-connection' as IconName;
export const DataLineageIcon = 'data-lineage' as IconName;
export const DeleteIcon = 'delete' as IconName;
export const DeltaIcon = 'delta' as IconName;
export const DeriveColumnIcon = 'derive-column' as IconName;
export const DesktopIcon = 'desktop' as IconName;
export const DiagnosisIcon = 'diagnosis' as IconName;
export const DiagramTreeIcon = 'diagram-tree' as IconName;
export const DirectionLeftIcon = 'direction-left' as IconName;
export const DirectionRightIcon = 'direction-right' as IconName;
export const DisableIcon = 'disable' as IconName;
export const DocumentIcon = 'document' as IconName;
export const DocumentOpenIcon = 'document-open' as IconName;
export const DocumentShareIcon = 'document-share' as IconName;
export const DollarIcon = 'dollar' as IconName;
export const DotIcon = 'dot' as IconName;
export const DoubleCaretHorizontalIcon = 'double-caret-horizontal' as IconName;
export const DoubleCaretVerticalIcon = 'double-caret-vertical' as IconName;
export const DoubleChevronDownIcon = 'double-chevron-down' as IconName;
export const DoubleChevronLeftIcon = 'double-chevron-left' as IconName;
export const DoubleChevronRightIcon = 'double-chevron-right' as IconName;
export const DoubleChevronUpIcon = 'double-chevron-up' as IconName;
export const DoughnutChartIcon = 'doughnut-chart' as IconName;
export const DownloadIcon = 'download' as IconName;
export const DragHandleHorizontalIcon = 'drag-handle-horizontal' as IconName;
export const DragHandleVerticalIcon = 'drag-handle-vertical' as IconName;
export const DrawIcon = 'draw' as IconName;
export const DrawerLeftIcon = 'drawer-left' as IconName;
export const DrawerLeftFilledIcon = 'drawer-left-filled' as IconName;
export const DrawerRightIcon = 'drawer-right' as IconName;
export const DrawerRightFilledIcon = 'drawer-right-filled' as IconName;
export const DriveTimeIcon = 'drive-time' as IconName;
export const DuplicateIcon = 'duplicate' as IconName;
export const EditIcon = 'edit' as IconName;
export const EjectIcon = 'eject' as IconName;
export const EmojiIcon = 'emoji' as IconName;
export const EndorsedIcon = 'endorsed' as IconName;
export const EnvelopeIcon = 'envelope' as IconName;
export const EqualsIcon = 'equals' as IconName;
export const EraserIcon = 'eraser' as IconName;
export const ErrorIcon = 'error' as IconName;
export const EuroIcon = 'euro' as IconName;
export const ExchangeIcon = 'exchange' as IconName;
export const ExcludeRowIcon = 'exclude-row' as IconName;
export const ExpandAllIcon = 'expand-all' as IconName;
export const ExportIcon = 'export' as IconName;
export const EyeOffIcon = 'eye-off' as IconName;
export const EyeOnIcon = 'eye-on' as IconName;
export const EyeOpenIcon = 'eye-open' as IconName;
export const FastBackwardIcon = 'fast-backward' as IconName;
export const FastForwardIcon = 'fast-forward' as IconName;
export const FeedIcon = 'feed' as IconName;
export const FeedSubscribedIcon = 'feed-subscribed' as IconName;
export const FilmIcon = 'film' as IconName;
export const FilterIcon = 'filter' as IconName;
export const FilterKeepIcon = 'filter-keep' as IconName;
export const FilterListIcon = 'filter-list' as IconName;
export const FilterOpenIcon = 'filter-open' as IconName;
export const FilterRemoveIcon = 'filter-remove' as IconName;
export const FlagIcon = 'flag' as IconName;
export const FlameIcon = 'flame' as IconName;
export const FlashIcon = 'flash' as IconName;
export const FloppyDiskIcon = 'floppy-disk' as IconName;
export const FlowBranchIcon = 'flow-branch' as IconName;
export const FlowEndIcon = 'flow-end' as IconName;
export const FlowLinearIcon = 'flow-linear' as IconName;
export const FlowReviewIcon = 'flow-review' as IconName;
export const FlowReviewBranchIcon = 'flow-review-branch' as IconName;
export const FlowsIcon = 'flows' as IconName;
export const FolderCloseIcon = 'folder-close' as IconName;
export const FolderNewIcon = 'folder-new' as IconName;
export const FolderOpenIcon = 'folder-open' as IconName;
export const FolderSharedIcon = 'folder-shared' as IconName;
export const FolderSharedOpenIcon = 'folder-shared-open' as IconName;
export const FollowerIcon = 'follower' as IconName;
export const FollowingIcon = 'following' as IconName;
export const FontIcon = 'font' as IconName;
export const ForkIcon = 'fork' as IconName;
export const FormIcon = 'form' as IconName;
export const FullCircleIcon = 'full-circle' as IconName;
export const FullscreenIcon = 'fullscreen' as IconName;
export const FullStackedChartIcon = 'full-stacked-chart' as IconName;
export const FunctionIcon = 'function' as IconName;
export const GanttChartIcon = 'gantt-chart' as IconName;
export const GeofenceIcon = 'geofence' as IconName;
export const GeolocationIcon = 'geolocation' as IconName;
export const GeosearchIcon = 'geosearch' as IconName;
export const GitBranchIcon = 'git-branch' as IconName;
export const GitCommitIcon = 'git-commit' as IconName;
export const GitMergeIcon = 'git-merge' as IconName;
export const GitNewBranchIcon = 'git-new-branch' as IconName;
export const GitPullIcon = 'git-pull' as IconName;
export const GitPushIcon = 'git-push' as IconName;
export const GitRepoIcon = 'git-repo' as IconName;
export const GlassIcon = 'glass' as IconName;
export const GlobeIcon = 'globe' as IconName;
export const GlobeNetworkIcon = 'globe-network' as IconName;
export const GraphIcon = 'graph' as IconName;
export const GraphRemoveIcon = 'graph-remove' as IconName;
export const GreaterThanIcon = 'greater-than' as IconName;
export const GreaterThanOrEqualToIcon = 'greater-than-or-equal-to' as IconName;
export const GridIcon = 'grid' as IconName;
export const GridViewIcon = 'grid-view' as IconName;
export const GroupedBarChartIcon = 'grouped-bar-chart' as IconName;
export const GroupObjectsIcon = 'group-objects' as IconName;
export const HandIcon = 'hand' as IconName;
export const HandDownIcon = 'hand-down' as IconName;
export const HandLeftIcon = 'hand-left' as IconName;
export const HandRightIcon = 'hand-right' as IconName;
export const HandUpIcon = 'hand-up' as IconName;
export const HatIcon = 'hat' as IconName;
export const HeaderIcon = 'header' as IconName;
export const HeaderOneIcon = 'header-one' as IconName;
export const HeaderTwoIcon = 'header-two' as IconName;
export const HeadsetIcon = 'headset' as IconName;
export const HeartIcon = 'heart' as IconName;
export const HeartBrokenIcon = 'heart-broken' as IconName;
export const HeatGridIcon = 'heat-grid' as IconName;
export const HeatmapIcon = 'heatmap' as IconName;
export const HelpIcon = 'help' as IconName;
export const HelperManagementIcon = 'helper-management' as IconName;
export const HighlightIcon = 'highlight' as IconName;
export const HighPriorityIcon = 'high-priority' as IconName;
export const HistoryIcon = 'history' as IconName;
export const HomeIcon = 'home' as IconName;
export const HorizontalBarChartIcon = 'horizontal-bar-chart' as IconName;
export const HorizontalBarChartAscIcon = 'horizontal-bar-chart-asc' as IconName;
export const HorizontalBarChartDescIcon = 'horizontal-bar-chart-desc' as IconName;
export const HorizontalDistributionIcon = 'horizontal-distribution' as IconName;
export const IdNumberIcon = 'id-number' as IconName;
export const ImageRotateLeftIcon = 'image-rotate-left' as IconName;
export const ImageRotateRightIcon = 'image-rotate-right' as IconName;
export const ImportIcon = 'import' as IconName;
export const InboxIcon = 'inbox' as IconName;
export const InboxFilteredIcon = 'inbox-filtered' as IconName;
export const InboxGeoIcon = 'inbox-geo' as IconName;
export const InboxSearchIcon = 'inbox-search' as IconName;
export const InboxUpdateIcon = 'inbox-update' as IconName;
export const InfoSignIcon = 'info-sign' as IconName;
export const InheritanceIcon = 'inheritance' as IconName;
export const InheritedGroupIcon = 'inherited-group' as IconName;
export const InnerJoinIcon = 'inner-join' as IconName;
export const InsertIcon = 'insert' as IconName;
export const IntersectionIcon = 'intersection' as IconName;
export const IpAddressIcon = 'ip-address' as IconName;
export const IssueIcon = 'issue' as IconName;
export const IssueClosedIcon = 'issue-closed' as IconName;
export const IssueNewIcon = 'issue-new' as IconName;
export const ItalicIcon = 'italic' as IconName;
export const JoinTableIcon = 'join-table' as IconName;
export const KeyIcon = 'key' as IconName;
export const KeyBackspaceIcon = 'key-backspace' as IconName;
export const KeyCommandIcon = 'key-command' as IconName;
export const KeyControlIcon = 'key-control' as IconName;
export const KeyDeleteIcon = 'key-delete' as IconName;
export const KeyEnterIcon = 'key-enter' as IconName;
export const KeyEscapeIcon = 'key-escape' as IconName;
export const KeyOptionIcon = 'key-option' as IconName;
export const KeyShiftIcon = 'key-shift' as IconName;
export const KeyTabIcon = 'key-tab' as IconName;
export const KnownVehicleIcon = 'known-vehicle' as IconName;
export const LabelIcon = 'label' as IconName;
export const LabTestIcon = 'lab-test' as IconName;
export const LayerIcon = 'layer' as IconName;
export const LayerOutlineIcon = 'layer-outline' as IconName;
export const LayersIcon = 'layers' as IconName;
export const LayoutIcon = 'layout' as IconName;
export const LayoutAutoIcon = 'layout-auto' as IconName;
export const LayoutBalloonIcon = 'layout-balloon' as IconName;
export const LayoutCircleIcon = 'layout-circle' as IconName;
export const LayoutGridIcon = 'layout-grid' as IconName;
export const LayoutGroupByIcon = 'layout-group-by' as IconName;
export const LayoutHierarchyIcon = 'layout-hierarchy' as IconName;
export const LayoutLinearIcon = 'layout-linear' as IconName;
export const LayoutSkewGridIcon = 'layout-skew-grid' as IconName;
export const LayoutSortedClustersIcon = 'layout-sorted-clusters' as IconName;
export const LearningIcon = 'learning' as IconName;
export const LeftJoinIcon = 'left-join' as IconName;
export const LessThanIcon = 'less-than' as IconName;
export const LessThanOrEqualToIcon = 'less-than-or-equal-to' as IconName;
export const LifesaverIcon = 'lifesaver' as IconName;
export const LightbulbIcon = 'lightbulb' as IconName;
export const LinkIcon = 'link' as IconName;
export const ListIcon = 'list' as IconName;
export const ListColumnsIcon = 'list-columns' as IconName;
export const ListDetailViewIcon = 'list-detail-view' as IconName;
export const LocateIcon = 'locate' as IconName;
export const LockIcon = 'lock' as IconName;
export const LogInIcon = 'log-in' as IconName;
export const LogOutIcon = 'log-out' as IconName;
export const ManualIcon = 'manual' as IconName;
export const ManuallyEnteredDataIcon = 'manually-entered-data' as IconName;
export const MapIcon = 'map' as IconName;
export const MapCreateIcon = 'map-create' as IconName;
export const MapMarkerIcon = 'map-marker' as IconName;
export const MaximizeIcon = 'maximize' as IconName;
export const MediaIcon = 'media' as IconName;
export const MenuIcon = 'menu' as IconName;
export const MenuClosedIcon = 'menu-closed' as IconName;
export const MenuOpenIcon = 'menu-open' as IconName;
export const MergeColumnsIcon = 'merge-columns' as IconName;
export const MergeLinksIcon = 'merge-links' as IconName;
export const MinimizeIcon = 'minimize' as IconName;
export const MinusIcon = 'minus' as IconName;
export const MobilePhoneIcon = 'mobile-phone' as IconName;
export const MobileVideoIcon = 'mobile-video' as IconName;
export const ModalIcon = 'modal' as IconName;
export const ModalFilledIcon = 'modal-filled' as IconName;
export const MoonIcon = 'moon' as IconName;
export const MoreIcon = 'more' as IconName;
export const MountainIcon = 'mountain' as IconName;
export const MoveIcon = 'move' as IconName;
export const MugshotIcon = 'mugshot' as IconName;
export const MultiSelectIcon = 'multi-select' as IconName;
export const MusicIcon = 'music' as IconName;
export const NewDrawingIcon = 'new-drawing' as IconName;
export const NewGridItemIcon = 'new-grid-item' as IconName;
export const NewLayerIcon = 'new-layer' as IconName;
export const NewLayersIcon = 'new-layers' as IconName;
export const NewLinkIcon = 'new-link' as IconName;
export const NewObjectIcon = 'new-object' as IconName;
export const NewPersonIcon = 'new-person' as IconName;
export const NewPrescriptionIcon = 'new-prescription' as IconName;
export const NewTextBoxIcon = 'new-text-box' as IconName;
export const NinjaIcon = 'ninja' as IconName;
export const NotEqualToIcon = 'not-equal-to' as IconName;
export const NotificationsIcon = 'notifications' as IconName;
export const NotificationsSnoozeIcon = 'notifications-snooze' as IconName;
export const NotificationsUpdatedIcon = 'notifications-updated' as IconName;
export const NumberedListIcon = 'numbered-list' as IconName;
export const NumericalIcon = 'numerical' as IconName;
export const OfficeIcon = 'office' as IconName;
export const OfflineIcon = 'offline' as IconName;
export const OilFieldIcon = 'oil-field' as IconName;
export const OneColumnIcon = 'one-column' as IconName;
export const OutdatedIcon = 'outdated' as IconName;
export const PageLayoutIcon = 'page-layout' as IconName;
export const PanelStatsIcon = 'panel-stats' as IconName;
export const PanelTableIcon = 'panel-table' as IconName;
export const PaperclipIcon = 'paperclip' as IconName;
export const ParagraphIcon = 'paragraph' as IconName;
export const PathIcon = 'path' as IconName;
export const PathSearchIcon = 'path-search' as IconName;
export const PauseIcon = 'pause' as IconName;
export const PeopleIcon = 'people' as IconName;
export const PercentageIcon = 'percentage' as IconName;
export const PersonIcon = 'person' as IconName;
export const PhoneIcon = 'phone' as IconName;
export const PieChartIcon = 'pie-chart' as IconName;
export const PinIcon = 'pin' as IconName;
export const PivotIcon = 'pivot' as IconName;
export const PivotTableIcon = 'pivot-table' as IconName;
export const PlayIcon = 'play' as IconName;
export const PlusIcon = 'plus' as IconName;
export const PolygonFilterIcon = 'polygon-filter' as IconName;
export const PowerIcon = 'power' as IconName;
export const PredictiveAnalysisIcon = 'predictive-analysis' as IconName;
export const PrescriptionIcon = 'prescription' as IconName;
export const PresentationIcon = 'presentation' as IconName;
export const PrintIcon = 'print' as IconName;
export const ProjectsIcon = 'projects' as IconName;
export const PropertiesIcon = 'properties' as IconName;
export const PropertyIcon = 'property' as IconName;
export const PublishFunctionIcon = 'publish-function' as IconName;
export const PulseIcon = 'pulse' as IconName;
export const RandomIcon = 'random' as IconName;
export const RecordIcon = 'record' as IconName;
export const RedoIcon = 'redo' as IconName;
export const RefreshIcon = 'refresh' as IconName;
export const RegressionChartIcon = 'regression-chart' as IconName;
export const RemoveIcon = 'remove' as IconName;
export const RemoveColumnIcon = 'remove-column' as IconName;
export const RemoveColumnLeftIcon = 'remove-column-left' as IconName;
export const RemoveColumnRightIcon = 'remove-column-right' as IconName;
export const RemoveRowBottomIcon = 'remove-row-bottom' as IconName;
export const RemoveRowTopIcon = 'remove-row-top' as IconName;
export const RepeatIcon = 'repeat' as IconName;
export const ResetIcon = 'reset' as IconName;
export const ResolveIcon = 'resolve' as IconName;
export const RigIcon = 'rig' as IconName;
export const RightJoinIcon = 'right-join' as IconName;
export const RingIcon = 'ring' as IconName;
export const RotateDocumentIcon = 'rotate-document' as IconName;
export const RotatePageIcon = 'rotate-page' as IconName;
export const RouteIcon = 'route' as IconName;
export const SatelliteIcon = 'satellite' as IconName;
export const SavedIcon = 'saved' as IconName;
export const ScatterPlotIcon = 'scatter-plot' as IconName;
export const SearchIcon = 'search' as IconName;
export const SearchAroundIcon = 'search-around' as IconName;
export const SearchTemplateIcon = 'search-template' as IconName;
export const SearchTextIcon = 'search-text' as IconName;
export const SegmentedControlIcon = 'segmented-control' as IconName;
export const SelectIcon = 'select' as IconName;
export const SelectionIcon = 'selection' as IconName;
export const SendMessageIcon = 'send-message' as IconName;
export const SendToIcon = 'send-to' as IconName;
export const SendToGraphIcon = 'send-to-graph' as IconName;
export const SendToMapIcon = 'send-to-map' as IconName;
export const SeriesAddIcon = 'series-add' as IconName;
export const SeriesConfigurationIcon = 'series-configuration' as IconName;
export const SeriesDerivedIcon = 'series-derived' as IconName;
export const SeriesFilteredIcon = 'series-filtered' as IconName;
export const SeriesSearchIcon = 'series-search' as IconName;
export const SettingsIcon = 'settings' as IconName;
export const ShapesIcon = 'shapes' as IconName;
export const ShareIcon = 'share' as IconName;
export const SharedFilterIcon = 'shared-filter' as IconName;
export const ShieldIcon = 'shield' as IconName;
export const ShopIcon = 'shop' as IconName;
export const ShoppingCartIcon = 'shopping-cart' as IconName;
export const SignalSearchIcon = 'signal-search' as IconName;
export const SimCardIcon = 'sim-card' as IconName;
export const SlashIcon = 'slash' as IconName;
export const SmallCrossIcon = 'small-cross' as IconName;
export const SmallMinusIcon = 'small-minus' as IconName;
export const SmallPlusIcon = 'small-plus' as IconName;
export const SmallTickIcon = 'small-tick' as IconName;
export const SnowflakeIcon = 'snowflake' as IconName;
export const SocialMediaIcon = 'social-media' as IconName;
export const SortIcon = 'sort' as IconName;
export const SortAlphabeticalIcon = 'sort-alphabetical' as IconName;
export const SortAlphabeticalDescIcon = 'sort-alphabetical-desc' as IconName;
export const SortAscIcon = 'sort-asc' as IconName;
export const SortDescIcon = 'sort-desc' as IconName;
export const SortNumericalIcon = 'sort-numerical' as IconName;
export const SortNumericalDescIcon = 'sort-numerical-desc' as IconName;
export const SplitColumnsIcon = 'split-columns' as IconName;
export const SquareIcon = 'square' as IconName;
export const StackedChartIcon = 'stacked-chart' as IconName;
export const StarIcon = 'star' as IconName;
export const StarEmptyIcon = 'star-empty' as IconName;
export const StepBackwardIcon = 'step-backward' as IconName;
export const StepChartIcon = 'step-chart' as IconName;
export const StepForwardIcon = 'step-forward' as IconName;
export const StopIcon = 'stop' as IconName;
export const StopwatchIcon = 'stopwatch' as IconName;
export const StrikethroughIcon = 'strikethrough' as IconName;
export const StyleIcon = 'style' as IconName;
export const SwapHorizontalIcon = 'swap-horizontal' as IconName;
export const SwapVerticalIcon = 'swap-vertical' as IconName;
export const SwitchIcon = 'switch' as IconName;
export const SymbolCircleIcon = 'symbol-circle' as IconName;
export const SymbolCrossIcon = 'symbol-cross' as IconName;
export const SymbolDiamondIcon = 'symbol-diamond' as IconName;
export const SymbolSquareIcon = 'symbol-square' as IconName;
export const SymbolTriangleDownIcon = 'symbol-triangle-down' as IconName;
export const SymbolTriangleUpIcon = 'symbol-triangle-up' as IconName;
export const TagIcon = 'tag' as IconName;
export const TakeActionIcon = 'take-action' as IconName;
export const TaxiIcon = 'taxi' as IconName;
export const TextHighlightIcon = 'text-highlight' as IconName;
export const ThIcon = 'th' as IconName;
export const ThDerivedIcon = 'th-derived' as IconName;
export const ThDisconnectIcon = 'th-disconnect' as IconName;
export const ThFilteredIcon = 'th-filtered' as IconName;
export const ThListIcon = 'th-list' as IconName;
export const ThumbsDownIcon = 'thumbs-down' as IconName;
export const ThumbsUpIcon = 'thumbs-up' as IconName;
export const TickIcon = 'tick' as IconName;
export const TickCircleIcon = 'tick-circle' as IconName;
export const TimeIcon = 'time' as IconName;
export const TimelineAreaChartIcon = 'timeline-area-chart' as IconName;
export const TimelineBarChartIcon = 'timeline-bar-chart' as IconName;
export const TimelineEventsIcon = 'timeline-events' as IconName;
export const TimelineLineChartIcon = 'timeline-line-chart' as IconName;
export const TintIcon = 'tint' as IconName;
export const TorchIcon = 'torch' as IconName;
export const TractorIcon = 'tractor' as IconName;
export const TrainIcon = 'train' as IconName;
export const TranslateIcon = 'translate' as IconName;
export const TrashIcon = 'trash' as IconName;
export const TreeIcon = 'tree' as IconName;
export const TrendingDownIcon = 'trending-down' as IconName;
export const TrendingUpIcon = 'trending-up' as IconName;
export const TruckIcon = 'truck' as IconName;
export const TwoColumnsIcon = 'two-columns' as IconName;
export const UnarchiveIcon = 'unarchive' as IconName;
export const UnderlineIcon = 'underline' as IconName;
export const UndoIcon = 'undo' as IconName;
export const UngroupObjectsIcon = 'ungroup-objects' as IconName;
export const UnknownVehicleIcon = 'unknown-vehicle' as IconName;
export const UnlockIcon = 'unlock' as IconName;
export const UnpinIcon = 'unpin' as IconName;
export const UnresolveIcon = 'unresolve' as IconName;
export const UpdatedIcon = 'updated' as IconName;
export const UploadIcon = 'upload' as IconName;
export const UserIcon = 'user' as IconName;
export const VariableIcon = 'variable' as IconName;
export const VerticalBarChartAscIcon = 'vertical-bar-chart-asc' as IconName;
export const VerticalBarChartDescIcon = 'vertical-bar-chart-desc' as IconName;
export const VerticalDistributionIcon = 'vertical-distribution' as IconName;
export const VideoIcon = 'video' as IconName;
export const VirusIcon = 'virus' as IconName;
export const VolumeDownIcon = 'volume-down' as IconName;
export const VolumeOffIcon = 'volume-off' as IconName;
export const VolumeUpIcon = 'volume-up' as IconName;
export const WalkIcon = 'walk' as IconName;
export const WarningSignIcon = 'warning-sign' as IconName;
export const WaterfallChartIcon = 'waterfall-chart' as IconName;
export const WidgetIcon = 'widget' as IconName;
export const WidgetButtonIcon = 'widget-button' as IconName;
export const WidgetFooterIcon = 'widget-footer' as IconName;
export const WidgetHeaderIcon = 'widget-header' as IconName;
export const WrenchIcon = 'wrench' as IconName;
export const ZoomInIcon = 'zoom-in' as IconName;
export const ZoomOutIcon = 'zoom-out' as IconName;
export const ZoomToFitIcon = 'zoom-to-fit' as IconName;
