/* eslint-disable indent */
// * свойства customStyles for <Select styles={customStyles} /> from react-select

import { ControlProps, CSSObjectWithLabel, GroupBase } from 'react-select';

type StateType<T> = ControlProps<T, false, GroupBase<T>>;
type StateIsMultiType<T> = ControlProps<T, true, GroupBase<T>>;
type FuncStyleType<T> = (state: StateType<T> | StateIsMultiType<T>) => CSSObjectWithLabel;

// % ______styles for controls ______
export const requiredStyle = <T>(state: StateType<T> | StateIsMultiType<T>): CSSObjectWithLabel => {
  const color = !state.hasValue ? state.theme.colors.danger : 'default';
  return {
    borderColor: color,
    '&:hover': { borderColor: color },
  };
};
export const unRequiredStyle = (): CSSObjectWithLabel => ({
    borderColor: 'default',
    '&:hover': { borderColor: 'default' },
  });
export const maxContentStyle = (): CSSObjectWithLabel => ({
  width: 'max-content',
});

export const boldValueStyle = (): CSSObjectWithLabel => ({
  fontWeight: 600,
});

// % ______ controls _____
export const controlRequired = <T>(provided: CSSObjectWithLabel, state: StateType<T>) => ({
  ...provided,
  ...requiredStyle(state),
});

export const controlMaxContent = (provided: CSSObjectWithLabel) => ({
  ...provided,
  ...maxContentStyle(),
});

export const controlRequiredMulti = <T>(provided: CSSObjectWithLabel, state: StateIsMultiType<T>) => ({
  ...provided,
  ...requiredStyle(state),
});

// singleValue
export const singleBoldValue = (provided: CSSObjectWithLabel) => ({
  ...provided,
  ...boldValueStyle(),
});

export const controlCombine = <T>(
  provided: CSSObjectWithLabel,
  state: StateType<T>,
  styles: Array<FuncStyleType<T>>,
) => {
  let controlStyle = provided;

  if (styles.length) {
    styles.forEach((style) => {
      controlStyle = {
        ...controlStyle,
        ...style(state),
      };
    });
  }

  return controlStyle;
};

/** Комбинирование стилей */
export const combineStyles = <T>(
  styles: Array<FuncStyleType<T>>,
) => (
  provided: CSSObjectWithLabel,
  state: StateType<T> | StateIsMultiType<T>,
) => {
    let controlStyle = {};

    if (styles.length) {
      styles.forEach((style) => {
        controlStyle = {
          ...controlStyle,
          ...style(state),
        };
      });
    }

    // чтобы не копировать кучу свойств из провайдера в фориче, а то их там много
    controlStyle = {
      ...provided,
      ...controlStyle,
    };

    return controlStyle;
  };
