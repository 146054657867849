import { t } from 'locale';
// ! если дерево большое, то долго добавляется (задержка перед показом окошка)
import { classNames, getButtonClass, IntentButtonType } from '@mono/ui-components';
import Swal from 'sweetalert2';

const getBtnClass = (intent: IntentButtonType) => classNames(
  getButtonClass(intent),
  'mx-2',
);

/**
 * Окно предупреждений
 */
export const SwalConfirm = Swal.mixin({
  customClass: {
    confirmButton: getBtnClass('info'),
    cancelButton: getBtnClass('default'),
    denyButton: getBtnClass('danger'),
  },
  buttonsStyling: false,

  heightAuto: false,
  allowOutsideClick: false,
  title: t('key_835'),
  showCancelButton: true,
  confirmButtonText: t('key_836'),
  cancelButtonText: t('key_45'),
  // focusCancel: true,
});

type swalConfirmArgs = {
  message?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  onDeny?: () => void,
}

/**
 * Замена window.confirm(message) там, где не нужны условия для окна
 *
 * @param {string} message
 * @param {function} onConfirm при согласии
 * @param {function} onCancel при отмене
 * @param {function} onDeny при отказе, если есть такая кнопка
 *
 * @example
 * swalConfirm({
 *   message: 'Продолжить?',
 *   onConfirm: () => { ... },
 * });
 */
export function swalConfirm({
  message,
  onConfirm,
  onCancel,
  onDeny,
}: swalConfirmArgs) {
  SwalConfirm.fire({
    text: message || '',
  }).then((result) => {
    const { isConfirmed, isDenied, isDismissed } = result;

    if (isConfirmed && onConfirm) onConfirm();
    else if (isDismissed && onCancel) onCancel();
    else if (isDenied && onDeny) onDeny();
  });
}

// ! Вынос возвращаемого значения в перемменную нужно потестировать
/**
 * Идентичен в работе window.confirm(message).
 * Однако нужно использовать непосредственно в условии.
 *
 * @param {string} message
 * @example
 * if (!isChangedFlowData || (isChangedFlowData && await swalIsConfirm('some message')))
 */
export async function swalIsConfirm(message: string) {
  const sc = await SwalConfirm.fire({
    text: message,
  }).then((result) => result.isConfirmed);
  return sc;
}
