import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import {
  LAKE, LOGIN, RIVER,
} from 'constants/paths';
import { useGetPlace } from 'Features/Main/useGetPlace';
import { NotFound } from 'Shared/NotFound';

import Login from 'Features/Login/Login';
import MainPage from 'Features/Main/MainPage';

import RiverRouter from './Router.River';
import LakeRouter from './Router.Lake';
import { PrivateNavigate } from './PrivateNavigate';

/**
 * Главные маршруты приложения с навигацией для вошедших пользователей
 * @memberof Routers
 */
function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${LOGIN}`} element={<Login />} />

        <Route path="/" element={<PrivateNavigate />}>
          <Route index element={<MainPage />} />
          <Route path=":place/*" element={<PlaceRouter />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default AppRouter;
/**
 * Маршруты приложения распределенные по месту (Реки, Озера)
 * @memberof Routers
 */
function PlaceRouter() {
  const place = useGetPlace();

  if (place === RIVER) return <RiverRouter />;
  if (place === LAKE) return <LakeRouter />;
  return <NotFound />;
}
