import { t } from 'locale';
import { IconButton } from '@mono/ui-components';
import { DataErrorsDialog } from './Dialog.DataErrors';
import { useFormErrors } from './useFormErrors';
import { DataErrorsType } from './types';
import './data-errors.css';

type DataErrorsDialogButtonProps = {
  errors: DataErrorsType | null,
  /** подсказка для кнопки */
  title?: string,
  /** className for IconButton */
  className?: string,
}

export function DataErrorsDialogButton({
  errors,
  title,
  className = '',
}: DataErrorsDialogButtonProps) {
  const {
    showErrors,
    isDisabledErrors,
    openErrorsDialog,
    closeErrorsDialog,
  } = useFormErrors(errors || undefined);

  return (
    <>
      <DataErrorsDialog errors={errors || undefined} isShow={showErrors} closeDialog={closeErrorsDialog} />
      <IconButton
        className={className}
        title={title || t('key_806')}
        disabled={isDisabledErrors}
        intent={isDisabledErrors ? 'none' : 'danger'}
        icon="error"
        onClick={openErrorsDialog}
      />
    </>
  );
}
