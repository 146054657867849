import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedPlaceSelector, setPlace } from 'Features/App/appSlice';
import { ButtonTab } from 'Shared/ButtonTab';
import { placesFullNames, PlacesPath } from './places';

export const PlacesTabs = React.memo(() => {
  const dispatch = useDispatch();
  const place = useSelector(selectedPlaceSelector);

  const onClickChangePlace = (selectedPlace: PlacesPath) => () => dispatch(setPlace(selectedPlace));

  return (
    <div className="space-x-2 text-center">
      {
        Object.keys(placesFullNames).map((placePath) => (
          <ButtonTab
            key={placePath}
            isActive={place === placePath}
            onClick={onClickChangePlace(placePath as PlacesPath)}
          >
            {placesFullNames[placePath as PlacesPath]}
          </ButtonTab>
        ))
      }
    </div>
  );
});

PlacesTabs.displayName = 'PlacesTabs';
