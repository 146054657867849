import { generatedApi, GetLakeCalculatedDataByPostIdAndDateApiArg, GetLakeCalculatedDataByPostIdAndDateApiResponse } from 'services/phoebeApi.generated';
import { getMonthlyTablesData } from 'services/utils';
import { LAKE_CALCULATED_DATA_TAG, LAKE_KSVO_MONTH_TAG } from '../tags';
import { calculatedDataTransformResponse } from './transformResponse';

export const calculatedDataApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_CALCULATED_DATA_TAG, LAKE_KSVO_MONTH_TAG],
  endpoints: {
    getLakeCalculatedDataByPostIdAndDate: {
      // @ts-ignore если есть isMonthlyTablesData, то получем преобразованные данные
      transformResponse: (response: GetLakeCalculatedDataByPostIdAndDateApiResponse, _, { isMonthlyData, isMonthlyTablesData }: GetLakeCalculatedDataByPostIdAndDateApiArg & { isMonthlyData?: boolean, isMonthlyTablesData?: boolean }) => {
        if (isMonthlyTablesData) {
          return getMonthlyTablesData(response);
        }
        // !delete
        if (isMonthlyData) {
          return calculatedDataTransformResponse(response);
        }
        return response;
      },
      providesTags: [LAKE_CALCULATED_DATA_TAG],
    },
    getLakeCalculatedDataByPostIdAndDateBetween: {
      providesTags: [LAKE_CALCULATED_DATA_TAG],
    },
    getLakeKsvoMonthByPostIdAndDate: {
      providesTags: [LAKE_KSVO_MONTH_TAG],
    },
    updateLakeKsvoMonth: {
      invalidatesTags: [LAKE_CALCULATED_DATA_TAG, LAKE_KSVO_MONTH_TAG],
    },
    deleteLakeCalculatedDatumById: {
      invalidatesTags: [LAKE_CALCULATED_DATA_TAG, LAKE_KSVO_MONTH_TAG],
    },
  },
});

export const {
  useGetLakeCalculatedDataByPostIdAndDateQuery,
  useGetLakeCalculatedDataByPostIdAndDateBetweenQuery,
  useGetLakeKsvoMonthByPostIdAndDateQuery,
  useUpdateLakeKsvoMonthMutation,
  useDeleteLakeCalculatedDatumByIdMutation,
} = calculatedDataApi;
