import { generatedApi } from 'services/phoebeApi.generated';
import { LENGTHWISE_SLOPE_TAG, STATION_YEARS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LENGTHWISE_SLOPE_TAG, STATION_YEARS_TAG],
  endpoints: {
    getLengthwiseSlopeByStationIdAndDate: {
      providesTags: [LENGTHWISE_SLOPE_TAG],
    },
    updateFormLengthwiseSlope: {
      invalidatesTags: [LENGTHWISE_SLOPE_TAG, STATION_YEARS_TAG],
    },
  },
});

export const {
  useGetLengthwiseSlopeByStationIdAndDateQuery,
  useLazyGetLengthwiseSlopeByStationIdAndDateQuery,
  useUpdateFormLengthwiseSlopeMutation,
} = api;
