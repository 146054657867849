import React from 'react';

interface RadioProps {
  id: string | number,
  disabled?: boolean,
  name: string,
  value: string,
  label?: string,
  checked?: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  // handlerChange: (value: string) => void
}

const ThisRadio = (props: RadioProps) => {
  const {
    id,
    name,
    value,
    label,
    disabled = false,
    checked = false,
    ...restProps
  } = props;

  return (
    <label htmlFor={`radio-${id}`} className="flex gap-1 items-center cursor-pointer">
      <input
        disabled={disabled}
        type="radio"
        id={`radio-${id}`}
        name={name}
        value={value}
        checked={checked}
        {...restProps}
      />
      <span>{label || value}</span>
    </label>
  );
};

export const Radio = React.memo(ThisRadio);
Radio.displayName = 'Radio';
