import React from 'react';
import { useCowSayQuery } from 'services/cowSayApi';
import { ResponseErrorType } from 'services/types/serverResponse';

type Error = ResponseErrorType | undefined;

/**
 * Проверка на работоспособность сервера каждые несколько минут
 * @method
 *  */
export const useCheckServiceUnavailable = () => {
  // проверка авторизации
  const { isFetching: isFetchingFirstCheck } = useCowSayQuery({ message: 'first_check' });

  // раз в 1 минут будет происходить запрос для имитации действия пользователя, чтоб не вылетала сессия
  // заодно проверка не упал ли сервер
  const { error: errorFakeAction } = useCowSayQuery(
    { message: "i'm_fake_user_action" },
    { pollingInterval: 120000 }, // 2 мин (120000)
  );
  // console.log('%c \n useCheck', 'color: coral', { errorFakeAction, isError });

  const isServiceUnavailable: boolean = React.useMemo(() => (
    !!(errorFakeAction as Error)?.message?.includes('SyntaxError:')
    || (errorFakeAction as Error)?.status === 404
    || (errorFakeAction as Error)?.status === 500
  ), [errorFakeAction]);

  return {
    isFetching: isFetchingFirstCheck,
    isServiceUnavailable,
  };
};
