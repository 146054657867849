// @ts-check

import { DateType } from 'Shared';
import { format, parse, set } from 'date-fns';

export const DATA_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
/** формат даты в импортируемых данных с росс */
export const AGK_IMPORTED_DATA_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";
export const REQUEST_DAY_FORMAT = 'dd.MM.yyyy';
export const REQUEST_MONTH_FORMAT = 'MM.yyyy';
/* формат выбранной даты дня, который встречается в данных, например, в параметрах и calculated data */
export const DATA_DAY_FORMAT = 'yyyy-MM-dd';

export const INPUT_FORMAT = {
  date: 'yyyy-MM-dd',
  time: 'HH:mm',
  datetime: "yyyy-MM-dd'T'HH:mm",
};

/**
 * Получает дату в нужном формате для запросов в зависимости от наличия дня.
 * @param {DateType | null} date
 * @returns {string} типа 'dd.MM.yyyy' or 'MM.yyyy' or ''
 * @memberof utils
 * */
export function getDateForRequest(date: DateType | null) {
  if (!date) return '';

  const { year, month, day } = date;
  return day
    ? format(new Date(year, month, day), REQUEST_DAY_FORMAT)
    : format(new Date(year, month), REQUEST_MONTH_FORMAT);
}

/**
 * Форматирет дату определенного типа в необходимый формат
 * @param {DateType | null} date
 * @param {string} someFormat
 * @return {string} по умолчанию "yyyy-MM-dd'T'HH:mm:ss" or ''
 * @memberof utils
 */
export const getDateFormat = (date: DateType | null, someFormat = DATA_DATE_FORMAT) => {
  if (!date) return '';
  return format(set(new Date(), {
    ...date, date: date?.day || 1, hours: 0, minutes: 0, seconds: 0,
  }), someFormat);
};

/**
 * Для даты устанавливает указанное время
 * @param {DateType | null} date
 * @param {string} time 'HH:mm'
 * @memberof utils
 */
export function getDateByTime(date: DateType | null, time: string) {
  if (!date) return '';
  const { year, month, day = 1 } = date;
  const parsedDate: Date = parse(time, 'HH:mm', new Date(year, month, day as number));
  return format(parsedDate, DATA_DATE_FORMAT);
}

/**
 * Парсит строку формата dd.MM.yyyy В {@link DateType}
 * @param {string} stringDate dd.MM.yyyy
 * @memberof utils
 */
export function parseByClientDateFormat(stringDate: string): DateType {
  const parsed = stringDate.split('.');
  return {
    day: Number(parsed[0]),
    month: Number(parsed[1]) - 1,
    year: Number(parsed[2]),
  };
}

export const getLastDayMonth = ({ year, month }: DateType) => year && new Date(year, month + 1, 0).getDate();
