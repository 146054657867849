// !избавиться, т.к. теперь в appSlice есть
import { places, PlacesPath } from 'Features/Main/places';
import { useParams } from 'react-router-dom';

/**
 * Получение места из маршрута
 * @returns river | lake | undefined
 */
export const useGetPlace = () => {
  const { place = '' } = useParams();
  // Если это известное место
  if (Object.keys(places).includes(place)) return place as PlacesPath;
  return undefined;
};
