export const LAKE_POST_TAG = 'LAKE_POST_TAG';
export const LAKE_POSTS_TAG = 'LAKE_POSTS_TAG';
export const LAKE_POST_YEARS_TAG = 'LAKE_POST_YEARS_TAG';
export const LAKE_WATER_AREA_POSTS_TAG = 'LAKE_WATER_AREA_POSTS_TAG';
export const LAKE_CONFINES_TAG = 'LAKE_CONFINES_TAG';
export const LAKE_SEASONS_TAG = 'LAKE_SEASONS_TAG';
export const LAKE_DEFAULT_CONFINES_TAG = 'LAKE_DEFAULT_CONFINES_TAG';
export const LAKE_OBSERVATION_POINTS_TAG = 'LAKE_OBSERVATION_POINTS_TAG';

export const LAKE_FORM_DESCRIPTIONS_TAG = 'LAKE_FORM_DESCRIPTIONS_TAG';
export const LAKE_BASIC_OBSERVATIONS_TAG = 'LAKE_BASIC_OBSERVATIONS_TAG';
export const LAKE_ICE_OBSERVATIONS_TAG = 'LAKE_ICE_OBSERVATIONS_TAG';
export const LAKE_WAVE_OBSERVATIONS_TAG = 'LAKE_WAVE_OBSERVATIONS_TAG';

export const LAKE_CALCULATED_DATA_TAG = 'LAKE_CALCULATED_DATA_TAG';
export const LAKE_KSVO_MONTH_TAG = 'LAKE_KSVO_MONTH_TAG';
export const LAKE_INHOMOGENEITY_LEVEL_DATE_TAG = 'LAKE_INHOMOGENEITY_LEVEL_DATE_TAG';
