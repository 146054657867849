import { t } from 'locale';
import React from 'react';
import { DataErrors, DataErrorsProps } from './DataErrors';

type DataErrorsDetailsProps = DataErrorsProps;

export const DataErrorsDetails = React.memo(({ errors }: DataErrorsDetailsProps) => {
  if (!errors?.length) return null;
  return (
    <details>
      <summary className="text-danger font-bold cursor-pointer">
        {t('key_807')}
      </summary>
      <DataErrors errors={errors} />
    </details>
  );
});
DataErrorsDetails.displayName = 'DataErrorsDetails';
