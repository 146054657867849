import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedPostIdSelector } from 'Features/App/lakeSlice';
import { selectedDateYearSelector, setSelectedDateYear } from 'Features/App/appSlice';
import { useGetYearsByPostIdQuery } from 'services/lake';
import { noSelectValue } from 'utils';
import { YearsSelect, YearsSelectProps } from '../YearsSelect';

type LakePostYearsSelectProps = YearsSelectProps;

/**
 * Выборка годов на которые уже есть данные а БД.
 * * selected = selectedDate.year из appSlice;
 * * setSelected = dispatch selectedDate.year из appSlice; можно дополнить функцию с помощью передаваемого setSelected
 * @func
 * @param {LakePostYearsSelectProps} props
 */
export const LakePostYearsSelect = React.memo(({
  isCreatable,
  // selected,
  setSelected,
}: LakePostYearsSelectProps) => {
  const dispatch = useDispatch();
  const postId = useSelector(selectedPostIdSelector);
  const { data: years = [], isFetching: isLoadingYears } = useGetYearsByPostIdQuery({ id: postId }, { skip: noSelectValue(postId) });

  const selectedYear = useSelector(selectedDateYearSelector);
  const setSelectedYear = React.useCallback((year: number) => {
    dispatch(setSelectedDateYear(year));
    setSelected && setSelected(year);
  }, [dispatch, setSelected]);

  return (
    <YearsSelect
      isCreatable={isCreatable}
      selected={selectedYear}
      setSelected={setSelectedYear}
      possibleYears={years}
      isLoadingYears={isLoadingYears}
    />
  );
});
LakePostYearsSelect.displayName = 'LakePostYearsSelect';
