import { t } from 'locale';
import React from 'react';
import Select, { CSSObjectWithLabel, SingleValue } from 'react-select';
import { GetVocListsByTagAndAttributeApiArg, VocListsEntity } from 'services/phoebeApi.generated';
import { getVocCodesMap, VocCodesMapType, useGetVocListsByTagAndAttributeQuery } from 'services/vocLists';
import { controlRequired } from './customStyles';

/**
 * Атрибуты компонента {@link SelectCode}
 */
type SelectCodeProps = {
  /** выбранный код - значение code */
  // selected: VocListsEntity['code'] | null,
  selected: number | undefined | null,
  setSelected: SetSelectedCodeFunction,
  required?: boolean,
  disabled?: boolean,
  /** позволяет отображать label при выборе кода с symbol, если он есть */
  isSymbolOptionLabel?: boolean,
  /** название выборки, например, "Отметка нуля" */
  label?: string,
  className?: string,
  /** полезно при изменении элемента */
  name?: string,
  /** больше как вспомогательный атрибут, полезно при изменении данных в массиве */
  index?: number, // полезно при изменении данных в массиве
} & GetVocListsByTagAndAttributeApiArg;

export type SetSelectedCodeFunction = (args: {
  name?: string,
  value: VocListsEntity['code'], // выбранный код
  code: VocListsEntity['code'] | null, // выбранный код (на всякий случай)
  item: VocListsEntity,
  index?: number,
}) => void;

/**
 * Выбор кода из списка БД-таблицы (vocList), где code - значение выбора (selected)
 * @param {SelectCodeProps} props
 * @param {string} props.tag тег для запроса списка из словаря, например, tag='gauge_datum'
 * @param {string | undefined} [props.attribute] атрибут в дополнении к тегу для уточнения списка
 */
export function SelectCode({
  required = false,
  disabled,
  selected,
  setSelected,
  tag,
  attribute,
  isSymbolOptionLabel,
  label,
  name,
  index,
  className,
}: SelectCodeProps) {
  const customStyles = React.useMemo(() => ({ control: required ? controlRequired : (provided: CSSObjectWithLabel) => ({ ...provided, minWidth: 180 }) }), [required]);

  const {
    data: vocList = [], isLoading, isFetching,
  } = useGetVocListsByTagAndAttributeQuery({ tag, attribute });
  // todo: вынести в трансформацию запроса
  const mapCodes: VocCodesMapType | undefined = React.useMemo(() => (
    vocList.length ? getVocCodesMap(vocList) : undefined
  ), [vocList]);

  const options = React.useMemo(() => vocList.map((item: VocListsEntity) => ({
    value: item.code!,
    item,
  })), [vocList]);

  const selectedOption = React.useMemo(() => (
    selected && mapCodes
      ? ({
        value: selected,
        item: mapCodes[selected],
      })
      : null // * если указать undefined, то визуальное выбранное значение в некоторых случаях не сбрасывается..
  ), [mapCodes, selected]);

  const onChangeSelected = React.useCallback((option: SingleValue<typeof selectedOption>) => {
    // setSelected(option?.value, option?.item || {});
    setSelected({
      value: option?.value,
      code: option?.value,
      item: option?.item || {},
      // вспомогательные данные при изменении данных в массиве
      name,
      index,
    });
  }, [index, name, setSelected]);

  const noOptionsMessage = () => t('key_828');
  const loadingMessage = () => t('key_829');
  const getOptionLabel = React.useCallback((option: typeof selectedOption) => {
    if (option) {
      const { item: { code, name: codeName, symbol } } = option;
      if (!code) return (option.value).toString();
      if (isSymbolOptionLabel) {
        // eslint-disable-next-line no-nested-ternary
        return symbol
          ? `${code} - ${symbol}`
          : codeName ? `${code} - ${codeName}`
            : `${code}`;
      }
      // eslint-disable-next-line no-nested-ternary
      return codeName
        ? `${code} - ${codeName}`
        : symbol ? `${code} - ${symbol}`
          : `${code}`;
    }
    return '';
  }, [isSymbolOptionLabel]);

  return (
    <div className={`flex gap-1 flex-col ${className}`}>
      {label && <span className="font-medium self-start">{label}</span>}
      <Select
        name={name}
        isClearable
        classNamePrefix="react-select"
        isDisabled={disabled}
        isLoading={isLoading || isFetching}
        placeholder={t('key_618')}
        options={isLoading || isFetching ? undefined : options}
        getOptionLabel={getOptionLabel}
        value={selectedOption}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onChange={onChangeSelected}
        styles={customStyles}
      />
    </div>
  );
}
