import { t } from 'locale';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select, { OnChangeValue } from 'react-select';
import { combineStyles, requiredStyle, maxContentStyle } from './customStyles';

export type PossibleYearsType = Array<string>

/**
 * Атрибуты компонента {@link }
 */
export type YearsSelectProps = {
  /** true - можно создавать год, которого нет в списке */
  isCreatable?: boolean,
  /** выбранный год */
  selected?: number | string,
  // setSelected?: (year: number, args?: { valueString: string, valueNumber: number, name?: string, isNew?: boolean }) => void,
  setSelected?: YearsSelectTypes.SetSelectedType,
}
export namespace YearsSelectTypes {
  export type SetSelectedType = (year: number, args?: { valueString: string, valueNumber: number, name?: string, isNew?: boolean }) => void;
}

/**
 * Атрибуты компонента {@link YearsSelect}
 */
export type YearsSelectAllProps = YearsSelectProps & {
  name?: string,
  disabled?: boolean,
  /** года в строковом виде */
  possibleYears?: PossibleYearsType,
  minYear?: number,
  maxYear?: number,
  isLoadingYears?: boolean,
}

type YearOptionType = {
  value: string,
  label: string,
  __isNew__?: boolean,
}

const customStyles = {
  control: combineStyles([requiredStyle, maxContentStyle]),
};

export const MIN_YEAR = 1800;
export const MAX_YEAR = new Date().getFullYear();

/**
 * Выборка возможных годов с возможностью создания года, которого нет в списке
 * @param {YearsSelectAllProps} props
 * @param {YearsSelectProps} props
 */
export function YearsSelect({
  name,
  disabled = false,
  selected,
  setSelected,
  possibleYears,
  minYear: minYearProp,
  isLoadingYears = false,
  isCreatable = false,
}: YearsSelectAllProps) {
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  const minYear = React.useMemo(() => (minYearProp === 0 ? 1 : minYearProp) || MIN_YEAR, [minYearProp]);

  // от переданного массива годов или от 1980 до текущего
  const years: Array<string> = React.useMemo(() => (
    possibleYears || Array.from({ length: currentYear - minYear + 1 }, (_, i) => (currentYear - i).toString())
  ), [currentYear, minYear, possibleYears]);

  const options = React.useMemo<Array<YearOptionType>>(() => years.map((year) => ({
    value: year,
    label: year,
  })), [years]);

  const selectedOption = React.useMemo<YearOptionType | null>(() => (
    selected
      ? ({
        value: selected.toString(),
        label: selected.toString(),
      })
      : null
  ), [selected]);

  const handleChange = (newValue: OnChangeValue<YearOptionType, false>) => {
    setSelected && newValue && setSelected(+newValue.value, {
      name,
      valueString: newValue.value,
      valueNumber: +newValue.value,
      // eslint-disable-next-line no-underscore-dangle
      isNew: newValue.__isNew__,
    });
  };
  // можно создавать от минимального года до текущего
  const isValidNewOption = (inputValue: string) => (
    !options.some((el) => el.value === inputValue)
    && (+inputValue >= minYear)
    && +inputValue <= currentYear
  );

  const noOptionsMessageSelect = () => t('key_830');
  const noOptionsMessageCreatableSelect = () => t('key_831', { minYear1: minYear, currentYear1: currentYear });
  const loadingMessage = () => t('key_832');
  const formatCreateLabel = (inputValue: string) => t('key_833', { inputValue1: inputValue });

  if (isCreatable) {
    return (
      <CreatableSelect
        formatCreateLabel={formatCreateLabel}
        isDisabled={disabled}
        classNamePrefix="react-select"
        placeholder={t('key_834')}
        allowCreateWhileLoading={false}
        isLoading={isLoadingYears}
        loadingMessage={loadingMessage}
        // undefined - чтоб показывалось loadingMessage
        options={isLoadingYears ? undefined : options}
        value={selectedOption}
        noOptionsMessage={noOptionsMessageCreatableSelect}
        onChange={handleChange}
        isValidNewOption={isValidNewOption}
        styles={customStyles}
      />
    );
  }
  return (
    <Select
      isDisabled={disabled}
      classNamePrefix="react-select"
      placeholder={t('key_834')}
      options={isLoadingYears ? undefined : options}
      value={selectedOption}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessageSelect}
      isLoading={isLoadingYears}
      onChange={handleChange}
      styles={customStyles}
    />
  );
}

// ----------------------- MultiYearsSelect ----------------------- //

export type MultiYearsSelectProps = {
  maxLength?: number
  // isCreatable?: boolean,
  /** выбранный год */
  selected?: Array<number | string>,
  setSelected?: (year: number[], isNew?: boolean[]) => void,
}

export type MultiYearsSelectAllProps = MultiYearsSelectProps & {
  disabled?: boolean,
  /** года в строковом виде */
  possibleYears?: PossibleYearsType
  isLoadingYears?: boolean,
  customStyles?: Array<typeof requiredStyle>,
}

const createCustomStyles = (styles: Array<typeof requiredStyle>) => ({
  control: combineStyles([requiredStyle, maxContentStyle, ...styles]),
});

export function MultiYearsSelect({
  disabled = false,
  selected,
  setSelected,
  possibleYears,
  isLoadingYears = false,
  maxLength = 4,
  customStyles: customStylesProp,
}: MultiYearsSelectAllProps) {
  const currentYear = React.useMemo(() => MAX_YEAR, []);
  // от переданного массива годов или от MIN_YEAR до текущего
  const years: Array<string> = React.useMemo(() => (
    possibleYears || Array.from({ length: currentYear - MIN_YEAR + 1 }, (_, i) => (currentYear - i).toString())
  ), [currentYear, possibleYears]);

  const customStylesByProps = React.useMemo(() => (customStylesProp ? createCustomStyles(customStylesProp) : customStyles), [customStylesProp]);

  const options = React.useMemo<Array<YearOptionType>>(() => years.map((year) => ({
    value: year,
    label: year,
  })), [years]);

  const selectedOption = React.useMemo<YearOptionType[] | null>(() => (
    selected?.length
      ? selected.map((el) => ({
        value: el.toString(),
        label: el.toString(),
      }))
      : null
  ), [selected]);

  const handleChange = (newValue: OnChangeValue<YearOptionType, true>) => {
    // eslint-disable-next-line no-underscore-dangle
    setSelected && newValue && setSelected(newValue.map((el) => +el.value), newValue.map((el) => !!el.__isNew__));
  };

  const noOptionsMessageSelect = () => t('key_830');
  const loadingMessage = () => t('key_832');

  return (
    <Select<YearOptionType, true>
      isMulti
      menuIsOpen={selected?.length === maxLength ? false : undefined}
      closeMenuOnSelect={false}
      isDisabled={disabled}
      classNamePrefix="react-select"
      placeholder={t('key_834')}
      options={isLoadingYears ? undefined : options}
      value={selectedOption}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessageSelect}
      isLoading={isLoadingYears}
      onChange={handleChange}
      styles={customStylesByProps}
    />
  );
}
