import { generatedApi } from 'services/phoebeApi.generated';
import { STATION_TAG, STATIONS_TAG, STATIONS_FOR_MAP_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [STATION_TAG, STATIONS_TAG, STATIONS_FOR_MAP_TAG],
  endpoints: {
    getStationsAll: {
      providesTags: [{ type: STATIONS_TAG, id: 'LIST' }],
    },
    getStationsAllForMap: {
      providesTags: [STATIONS_FOR_MAP_TAG],
    },
    getStationsAllWithAnnualWaterTemperature: {
      providesTags: [{ type: STATIONS_TAG, id: 'LIST' }],
    },
    getStationsAllWithWaterFlow: {
      providesTags: [{ type: STATIONS_TAG, id: 'LIST' }],
    },
    getStationsAllWithWaterLevelDayAvg: {
      providesTags: [{ type: STATIONS_TAG, id: 'LIST' }],
    },
    getStationById: {
      providesTags: [STATION_TAG],
    },
    updateStation: {
      invalidatesTags: [STATION_TAG, { type: STATIONS_TAG, id: 'LIST' }],
    },
  },
});
export const {
  useGetStationsAllQuery,
  useGetStationByIdQuery,
  useUpdateStationMutation,
  useGetStationsAllWithAnnualWaterTemperatureQuery,
  useGetStationsAllWithWaterFlowQuery,
  useGetStationsAllWithWaterLevelDayAvgQuery,
  useGetStationsAllWithTotalThicknessIceQuery,
  useGetStationsAllForMapQuery,
} = api;
