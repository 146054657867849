import { generatedApi } from 'services/phoebeApi.generated';
import { LAKE_DEFAULT_CONFINES_TAG, LAKE_CONFINES_TAG, LAKE_FORM_DESCRIPTIONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_DEFAULT_CONFINES_TAG, LAKE_CONFINES_TAG, LAKE_FORM_DESCRIPTIONS_TAG],
  endpoints: {
    getLakeConfinesByPostId: {
      // если arg.id(=postId) равен 0(=станция по умолчанию), то не нужно обновлять конфайнсы при изменении/удалении
      providesTags: (result, error, arg) => (arg?.id ? [LAKE_CONFINES_TAG] : [LAKE_DEFAULT_CONFINES_TAG]),
    },
    updateFormLakeConfines: {
      invalidatesTags: [LAKE_CONFINES_TAG, LAKE_FORM_DESCRIPTIONS_TAG],
    },
    deleteLakeConfinesById: {
      invalidatesTags: [LAKE_CONFINES_TAG, LAKE_FORM_DESCRIPTIONS_TAG],
    },
  },
});
export const {
  useGetLakeConfinesByPostIdQuery,
  useUpdateFormLakeConfinesMutation,
  useDeleteLakeConfinesByIdMutation,
} = api;
