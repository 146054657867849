import { generatedApi } from 'services/phoebeApi.generated';
import { SILT_MEASUREMENTS_TAG, STATION_YEARS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [SILT_MEASUREMENTS_TAG, STATION_YEARS_TAG],
  endpoints: {
    getSiltChargeByStationIdAndDate: {
      providesTags: [SILT_MEASUREMENTS_TAG],
    },
    updateFormSiltCharges: {
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [SILT_MEASUREMENTS_TAG, STATION_YEARS_TAG];
      },
    },
  },
});

export const {
  useGetSiltChargeByStationIdAndDateQuery,
  useLazyGetSiltChargeByStationIdAndDateQuery,
  useUpdateFormSiltChargesMutation,
} = api;
