import { AnyObject } from '../types';

export function isNumber(val: any): boolean {
  return val === +val;
}

export function nearest(target: number, value1: number, value2: number, parser?: (value: any) => number) {
  const parsedValue1 = parser ? parser(value1) : value1;
  const parsedValue2 = parser ? parser(value2) : value2;

  return Math.abs(target - parsedValue1) < Math.abs(target - parsedValue2) ? value1 : value2;
}

function isObject(object: object) {
  return object != null && typeof object === 'object';
}

export function isObjectEmpty(object: object) {
  return Object.keys(object).length === 0;
}

export function numericArray(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

const defaultParser = ((b: any) => b);

export function getMinMaxInArray<
  ARRAY_ELEMENT = number,
  ALLOW_TYPES = never,
  RETURN_TYPE = number | ALLOW_TYPES,
  >(
  array: ARRAY_ELEMENT[],
  customParser?: (b: ARRAY_ELEMENT, index: number, array: ARRAY_ELEMENT[]) => RETURN_TYPE,
) {
  const parser = customParser || defaultParser;

  const startV = parser(array[0], 0, array);
  let min: RETURN_TYPE = startV;
  let max: RETURN_TYPE = startV;
  let originMin: ARRAY_ELEMENT = array[0];
  let originMax: ARRAY_ELEMENT = array[0];
  array.forEach((el, index) => {
    const parsed = parser(el, index, array);
    if (!isNumber(parsed)) {
      return;
    }
    if (!isNumber(max) || parsed > max) {
      max = parsed;
      originMax = el;
    }
    if (!isNumber(min) || parsed < min) {
      min = parsed;
      originMin = el;
    }
  });

  if (customParser) {
    return { min, max, originMin, originMax };
  }

  return { min, max };
}

export const pick = <T extends object>(obj: T, ...keys: Array<keyof T>) => Object.fromEntries(
  keys
    .filter((key) => key in obj)
    .map((key) => [key, obj[key]]),
);

export const clearObjectEntries = <T extends object>(obj: T, compare: ((value: T[keyof T], key: string) => boolean)): T => Object.fromEntries(
  Object.entries(obj)
    .filter(([key, value]) => !compare(value, key)),
) as T;

export const clearUndefinedValues = <T extends object>(obj: T) => clearObjectEntries(obj, (value) => value === undefined);

export const keysOf = <T>(objs: T) => Object.keys(objs) as (keyof T)[];

// @ts-ignore
export function getById<T>(arr: T[], id: T['id']): T | undefined {
  // @ts-ignore
  return arr.find((el) => el.id === id);
}

export function getByField(arr: any[], field: string, value: any): any | undefined {
  return arr.find((el) => el[field] === value);
}

export class FakeId {
  static counter = 0;
  static idFabric = () => new FakeId().id;
  static clear(obj: AnyObject, field = 'id') {
    return clearObjectEntries(obj, (value, key) => key === field && value < 0);
  }
  static clearEach(arr: AnyObject[], field = 'id') {
    return arr.map((el) => FakeId.clear(el, field));
  }
  static getRealId(id: number) {
    return id >= 0 ? id : undefined;
  }

  id: number;
  constructor() {
    FakeId.counter += 1;
    this.id = -FakeId.counter;
  }
}

export function isEqual(obj1: object, obj2: object) {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i += 1) {
    // @ts-ignore
    const val1 = obj1[props1[i]];
    // @ts-ignore
    const val2 = obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

export function guaranteedArray<T>(val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val];
}

export function getLongest<T extends { length: number }>(arr: T[]): T {
  return arr.reduce((a, b) => (a.length > b.length ? a : b));
}

export function getLinearFunction(x1: number, y1: number, x2: number, y2: number) {
  const a = (y2 - y1) / (x2 - x1);
  const b = y1 - a * x1;
  return (x: number) => a * x + b;
}
