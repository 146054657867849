import { generatedApi } from 'services/phoebeApi.generated';
import { STATIONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [STATIONS_TAG],
  endpoints: {
    getBookStationsByIssueNumber: {
      providesTags: [STATIONS_TAG],
    },
  },
});

export const {
  useGetBookStationsByIssueNumberQuery,

  useExportEdsMutation,
  useExportEmdsMutation,
  useExportMdsMutation,
} = api;
