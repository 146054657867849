import { generatedApi } from 'services/phoebeApi.generated';
import store from 'Features/App/store';
import { LoginType } from './types';

export const LOGIN_STORAGE_ITEM = 'login';

export const getLoginFromLocalStorage = () => {
  const storageLoginState = window.localStorage.getItem(LOGIN_STORAGE_ITEM);
  const storageUser: LoginType | undefined = storageLoginState
    ? JSON.parse(storageLoginState)
    : undefined;

  return storageUser;
};
export const setLoginToLocalStorage = (data: LoginType) => {
  window.localStorage.setItem(LOGIN_STORAGE_ITEM, JSON.stringify(data));
};
export const removeLoginLocalStorage = () => {
  window.localStorage.removeItem(LOGIN_STORAGE_ITEM);
  window.localStorage.clear();
};

/**
 * Синхронизация с другими открытыми вкладками приложения.
 * Событие подхватывается слушателем, если localStorage был изменен в другом окне/вкладке
 * того же приложения, но не в контексте текущей вкладки.
 */
window.onstorage = (event: StorageEvent) => {
  const { key, newValue } = event;
  if (key !== LOGIN_STORAGE_ITEM) return;

  // Если меняется login:
  // приходит значение - вход,
  // нет значения - выход
  if (newValue) {
    // login
    const storageUser: LoginType = JSON.parse(newValue);
    // @ts-ignore
    store.dispatch(generatedApi.endpoints.loginSilent.initiate(storageUser));
    // const result = store.dispatch(loginApi.endpoints.loginSilentNew.initiate(storageUser));
    // return result.unsubscribe;
  } else {
    // @ts-ignore
    store.dispatch(generatedApi.endpoints.logoutSilent.initiate());
  }
};
