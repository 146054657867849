import { t } from 'locale';
import React from 'react';

import { Logo } from 'Features/Header/Logo';
import { PlacesTabs } from './PlacesTabs';

export const HeaderMainPage = React.memo(() => (
  <div className="p-2 text-center sticky z-20 top-0 left-0 bg-[color:var(--primary-bg-color)]">
    <Logo />

    <h3 className="pb-2 text-xl font-medium leading-6 my-0 mx-auto max-w-1/2">
      {t('key_547')}
    </h3>

    <PlacesTabs />
  </div>
));

HeaderMainPage.displayName = 'HeaderMainPage';
