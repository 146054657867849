import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { throwErrorByCodeStatus } from 'services/errors/throwErrorByCodeStatus';
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { JSOGdeleteCircular } from 'libs/jsog/JSOG.utils';
// @ts-ignore
import { JSOG } from 'libs/jsog/JSOG';
import { TokenExpiredError } from 'services/errors/TokenExpiredError';
import { toaster } from '@mono/ui-components';
import { ServerResponseError } from './types/serverResponse';
import { generatedApi } from './phoebeApi.generated';

export type BaseQueryFnError = FetchBaseQueryError & ServerResponseError & { message?: string };
export type PhoebeBaseQueryFn = BaseQueryFn<string | FetchArgs, any, BaseQueryFnError, {}, FetchBaseQueryMeta>
export type PhoebeFetchBaseFn = (config: FetchBaseQueryArgs) => PhoebeBaseQueryFn
type extraOptionType = {
  text?: boolean,
  xml?: boolean,
  toasterMessage?: string,
  // true - если не нужно вообще уведомление
  noToaster?: boolean,
}

// Обертка fetchBaseQuery для отловли ошибок
const phoebeFetchBase: PhoebeFetchBaseFn = (config) => async (args, store, extraOptions: extraOptionType) => {
  // @ts-ignore
  // console.log(store, store.getState(), store.getState()?.auth?.currentUser);

  const ftch = fetchBaseQuery(config) as PhoebeBaseQueryFn;
  const result = await ftch(extraOptions?.xml || extraOptions?.text ? {
    ...args as FetchArgs,
    responseHandler: (res) => {
      if (res.status < 299) return res.text();
      return res.json();
    },
  } : args, store, extraOptions);
  // const dispatch = useDispatch();
  // Если запрос вернул ошибку прогнать ее через throwErrorByCodeStatus и прокинуть message
  if (result.error) {
    // todo: перенести в throwErrorByCodeStatus (временный костыль)
    // console.log('%c phoebeFetchBase', 'color: red', result);
    if (typeof result.error.status === 'string') {
      throw Error((result.error as any)?.error || result.error?.status);
    } else {
      try {
        throwErrorByCodeStatus(result.meta?.response, result.error.data.path, result.error);
      } catch (err) {
        if (err instanceof TokenExpiredError) {
          // если вошедший пользователь есть, то делаем logout
          // нужно для случая выкидывания из сессии
          // @ts-ignore
          if (store.getState()?.auth?.currentUser) {
            // @ts-ignore
            store.dispatch(generatedApi.endpoints.logoutSilent.initiate());
          }
          !extraOptions?.noToaster && toaster.danger((err as Error).message);
        } else {
          !extraOptions?.noToaster && toaster.danger((extraOptions?.toasterMessage as string) || (err as Error).message);
        }
        result.error.message = (err as Error).message;
      }
    }
  }
  // @ts-ignore
  return JSOGdeleteCircular(JSOG.decode(result));
};

export const phoebeBaseQuery = phoebeFetchBase({ baseUrl: process.env.REACT_APP_API_PREFIX });
