import { generatedApi } from 'services/phoebeApi.generated';
import { BASIC_OBSERVATIONS_TAG, CALCULATED_DATA_TAG, STATION_YEARS_TAG, STATIONS_FOR_MAP_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [BASIC_OBSERVATIONS_TAG, STATION_YEARS_TAG, CALCULATED_DATA_TAG, STATIONS_FOR_MAP_TAG],
  endpoints: {
    getBasicObservationsByStationIdAndDate: {
      providesTags: [BASIC_OBSERVATIONS_TAG],
    },
    updateFormBasicObservations: {
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [BASIC_OBSERVATIONS_TAG, STATION_YEARS_TAG, CALCULATED_DATA_TAG, STATIONS_FOR_MAP_TAG];
      },
    },
  },
});

export const {
  useGetBasicObservationsByStationIdAndDateQuery,
  useLazyGetBasicObservationsByStationIdAndDateQuery,
  useUpdateFormBasicObservationsMutation,

  useLazyGetBasicObservationsFromWaterFlowQuery,
} = api;
