import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export type ButtonNavLinkProps = {
  children: string | ReactNode,
  to: string,
  onClick?: () => void,
  title?: string,
}

export const ButtonNavLink = React.memo(({ children, to, onClick, title }: ButtonNavLinkProps) => (
  <NavLink
    title={title}
    to={to}
    className="px-4 py-1 rounded-lg font-medium text-center
              hover:bg-black hover:bg-opacity-5"
    onClick={onClick}
  >
    {children}
  </NavLink>
));
ButtonNavLink.displayName = 'ButtonNavLink';
