import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectedPlaceSelector } from 'Features/App/appSlice';
import { places } from 'Features/Main/places';
import { appProjectName } from 'constants/appName';

/**
 * Кликабельный логотип приложения с переадресацией на главную страницу
 * @function
 */
export const LogoLink = React.memo(() => {
  const place = useSelector(selectedPlaceSelector);

  return (
    <NavLink
      to="/"
      className="logo grid text-center leading-[0.45rem]"
    >
      <Logo />

      {/* pb такой же как и leading выше */}
      {/* @ts-ignore */}
      <span className="pb-[0.45rem]">{places[place]}</span>
    </NavLink>
  );
});

LogoLink.displayName = 'LogoLink';

/**
 * Логотип приложения
 * @function
 * */
export const Logo = React.memo(() => (
  <span
    className="hover:text-sky-600 text-2xl font-bold"
    style={{ transition: 'color 0.1s linear' }}
  >
    {appProjectName}
  </span>
));

Logo.displayName = 'PhoebeText';
