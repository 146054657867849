import { t } from 'locale';
// нужно для <input type="month" />
type ValueType = string | number;
type isWithinMinMaxArgs = { value: ValueType, min?: number, max?: number }

export const getStringMonthOrDay = (mORd: number): string => mORd.toString().padStart(2, '0');

/**
 * Формирования label в виде "Название, единица измерения"
 * @func
 * @param name
 * @param unit
 * @memberof utils
 */
export const getLabelWithUnit = ({ unit, name = '' }: { unit?: string, name?: string }) => (unit ? `${name}, ${unit}` : name);

/**
 * Заменяет ',' на '.' в десятичных числах.
 * Если на конце числа ',' или '.' без продолжения, то символ удаляется
 * @func
 * @param value
 * @memberof utils
 */
export const replaceCommaToDot = (value: string) => {
  const lastChar = value.at(-1);
  if (lastChar === '.' || lastChar === ',') return value.slice(0, -1);
  return value.replace(',', '.');
};

/**
 * проверка на наличие значения, чтоб оно не было null or undefined, но 0 можно
 * @func
 * @param {string|number|null|undefined} value проверяемое значение
 * @returns {boolean} наличие значения
 * @memberof utils
 */
export const hasValue = (value: ValueType | null | undefined): value is ValueType => Boolean(value || value === 0);
export const noValue = (value: ValueType | null | undefined): value is null | undefined => !hasValue(value);

/** для тега select, где выбранное значение НЕ является -1 или undefined
 * @func
 * @memberof utils
 */
export const noSelectValue = (value: ValueType | undefined) => (value === '-1' || value === -1 || value === undefined);
/** для тега select, где выбранное значение есть и оно не -1
 * @func
 * @memberof utils
 */
export const hasSelectValue = (value: ValueType | undefined) => !noSelectValue(value);

/** Является ли строка целым числом
 * @func
 * @memberof utils
 * */
export const isInteger = (value: string | number | undefined): boolean => value !== undefined && /^\d+$/.test(value.toString());
export const notInteger = (value: string | number | undefined): boolean => !isInteger(value);

/**
 * Проверка входит ли число в предел значений, включая границы.
 * @param {string|number} value значение, которое проверяем
 * @param {number} [min] нижний предел
 * @param {number} [max] верхний предел
 * @memberof utils
 */
export function isWithinMinMax({ value, min, max }: isWithinMinMaxArgs): boolean {
  if (value === '') return true;

  const hasMin: boolean = hasValue(min);
  const hasMax: boolean = hasValue(max);

  let isValid = true;

  if (hasMin || hasMax) {
    const val: number = typeof value === 'string' ? +(value.replace(',', '.')) : value;
    // ! проверки через hasValue выше, а иначе ts ругается, несмотря на проверки
    const MIN = min as number;
    const MAX = max as number;

    if (!Number.isNaN(val)) {
      if (hasMin && hasMax) isValid = MIN <= val && val <= MAX;
      else if (hasMin) isValid = MIN <= val;
      else if (hasMax) isValid = val <= MAX;
    }
  }

  return isValid;
}
export const isOutsideMinMax = ({ value, min, max }: isWithinMinMaxArgs): boolean => !isWithinMinMax({ value, min, max });

/**
 * Получает первое попавшееся число из строки, если есть.
 * @func
 * @param {string} str
 * @returns {number|null}
 * @memberof utils
 */
export const takeNumberFromString = (str: string): number | null => {
  const value = /\d+/.exec(str);
  return value && +value;
};

/**
 * Получает первое попавшееся число с плавающей запятой из строки, если есть.
 * @func
 * @param {string} str
 * @returns {number} может вернуть число NaN
 * @memberof utils
 */
export const takeFloatFromString = (str: string): number => parseFloat(str.replace(',', '.'));

/**
 * Получаем часть пути из url, если она там есть.
 *
 * @param paths массив частей пути, один из которых нужно получить из url
 * @param url путь из которого ищем один из paths
 * @returns
 */
export const getPathPartFromUrl = (paths: string[], url: string) => {
  for (let i = 0; i < paths.length; i++) {
    if (new RegExp(`^.*\\/(${paths[i]})(\\/.*)?$`).test(url)) {
      return paths[i];
    }
  }
  return undefined;
};

/**
 * Math.Round с точностью округления до n-ого разряда
 * @param value округляемое значение
 * @param accuracy точность округления (например, 0 - целое, 1 - десятая, 2 - сотая и т.д.)
 * @returns например при accuracy=1: 5, 0, 1.2, 3.5, ...
 * @memberof utils
 */
export function mathRoundWithAccuracy(value: number, accuracy: number) {
  const digits = +'1'.padEnd(accuracy + 1, '0');
  return Math.round(value * digits) / digits;
}

/**
 * Получить математически округленное значение, если есть
 * @func
 * @param value
 * @param {number} [accuracy] количество цифр после запятой, при отсутствии округляет до целого числа
 * @returns например: 5.0, 0.0, 1.2, ...
 * @memberof utils
 */
export const toFixedValueWithAccuracy = (value: number | undefined | null, accuracy?: number) => (hasValue(value) ? value!.toFixed(accuracy) : value);

export type DictionaryType<T> = {
  [key: number | string]: T
}

// !костыльная проверка на тип при преобразовании массива в словарь  =\
/** выкинет ошибку, есл и это объект (не примитив) */
function checkElementTypeForDictionary(element: any, key: string) {
  if (typeof element === 'object') throw new Error(t('key_838', { key1: key }));
  if (typeof element === 'undefined') console.error(t('key_839', { key1: key }));
}

/**
 * * Преобразование массива элементов в словарь:
 * * а) где array[X][key] с УНИКАЛЬНЫМ значением присваивается элемент(объект) массива array
 * * б) если есть `key2`, то к выбранному array[X][key][key2] присваивается элемент массива array
 * **note:** Значения для`key` должны быть простым типом, не пустыми и УНИКАЛЬНЫМИ(иначе будет перезапись).
 */
export function getDictionaryByProperty<T extends {[key: string]: any}>(array: T[], key: keyof T) {
  if (array.length === 0) return {};

  checkElementTypeForDictionary(array[0][key], key as string);

  return array.reduce((acc, element) => {
    const elementKeyValue = element[key];

    if (elementKeyValue) {
      acc[elementKeyValue] = element;
    }
    return acc;
  }, {} as DictionaryType<T>);
}
/**
 * Группировка элементов по ключу. Значения array[X][key] должно быть простым типом и не пустым
 * @param array массив, который преобразовываем в словарь
 * @param key ключ из элемента массива, по которому будут сгруппированы в массив элементов
 */
export function getDictionaryForArrayElements<T extends {[key: string]: any}>(array: T[], key: keyof T): DictionaryType<T[]> {
  if (array.length === 0) return {};
  // !костыльная проверка на тип =\
  if (typeof array[0][key] === 'object') throw new Error(t('key_840'));

  return array.reduce((acc, element) => {
    const elementKeyValue = element[key];
    if (elementKeyValue && elementKeyValue !== 0) {
      if (!acc[elementKeyValue]) acc[elementKeyValue] = [];
      acc[elementKeyValue].push(element);
    }
    return acc;
  }, {} as DictionaryType<T[]>);
}
