import store from 'Features/App/store';
import { generatedApi } from '../phoebeApi.generated';
import { removeLoginLocalStorage, setLoginToLocalStorage } from './localStorage';
import { LoginApiResponse, LoginApiArg, LoginType } from './types';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const loginApi = generatedApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg: LoginApiArg) => ({
        url: 'auth/login',
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa(
            `${queryArg.username}:${queryArg.password}`,
          )}`,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const { data } = await queryFulfilled;
        data && setLoginToLocalStorage(data);
      },
    }),
    logout: builder.mutation({
      queryFn: () => ({ data: undefined }),
      async onQueryStarted() {
        removeLoginLocalStorage();
        store.dispatch(generatedApi.util.resetApiState());
      },
    }),

    // для входа в другой вкладке без запроса на сервер (данные передаем сами из localStorage)
    loginSilent: builder.mutation({
      queryFn: (data: LoginType) => ({ data }),
    }),
    // для выхода в другой вкладке без запроса на сервер (данные передаем сами пустые)
    logoutSilent: builder.mutation({
      queryFn: () => ({ data: undefined }),
      async onQueryStarted() {
        removeLoginLocalStorage();
        // сбрасываем кеш rtk-запросов
        store.dispatch(generatedApi.util.resetApiState());
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
} = loginApi;
