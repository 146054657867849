import { generatedApi } from 'services/phoebeApi.generated';
import { WATER_FLOW_TAG, WATER_FLOW_DAYS_TAG, WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG, WATER_FLOW_PROCESS_TAG, STATION_YEARS_TAG } from '../tags';

export const waterFlowApi = generatedApi.enhanceEndpoints({
  addTagTypes: [WATER_FLOW_TAG, WATER_FLOW_DAYS_TAG, WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG, STATION_YEARS_TAG, WATER_FLOW_PROCESS_TAG],
  endpoints: {
    getWaterByStationIdAndDate: {
      providesTags: [WATER_FLOW_TAG],
    },
    getWaterFlowDays: {
      providesTags: [WATER_FLOW_DAYS_TAG],
    },
    getWaterFlowDaysWithWaterFlowNumbers: {
      providesTags: [WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG],
    },
    updateFormWaterFlow: {
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [WATER_FLOW_DAYS_TAG, WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG, WATER_FLOW_TAG, STATION_YEARS_TAG];
      },
    },
    deleteWaterFlowById: {
      invalidatesTags: [WATER_FLOW_DAYS_TAG, WATER_FLOW_DAYS_WITH_WATER_FLOW_NUMBERS_TAG, WATER_FLOW_TAG],
    },
    waterFlowProcess: {
      // чет не сработало, возможно потому что вызов процесса идет после update, а там уже сработал тег
      // invalidatesTags: [WATER_FLOW_TAG],
      extraOptions: {
        xml: true,
      },
    },
  },
});

export const {
  useGetWaterByStationIdAndDateBetweenQuery,
  useUpdateFormWaterFlowMutation,
  useGetWaterByStationIdAndDateQuery,
  useLazyGetWaterByStationIdAndDateQuery,
  useGetWaterFlowByStationIdQuery,
  useGetWaterFlowChannelByIdQuery,
  useGetWaterFlowByIdQuery,
  useDeleteWaterFlowByIdMutation,

  useGetWaterFlowDaysQuery,
  useGetWaterFlowDaysWithWaterFlowNumbersQuery,
  useWaterFlowProcessMutation,
} = waterFlowApi;
