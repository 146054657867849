import classnames from 'classnames';
import React from 'react';
import { Clocks } from './ClockUtils';
import { Dropdown } from './Dropdown';

interface ITooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  clock: Clocks;
  classNames?: string;
  origin?: 'center' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'top-left' | string;
  duration?: number;
}

const TooltipThis = React.forwardRef<HTMLDivElement, ITooltipProps>((props, ref) => {
  const classNames = classnames(props.classNames);

  const [isShow, setIsShow] = React.useState(false);

  const showRef = React.useRef(() => setIsShow(true));
  const hideRef = React.useRef(() => setIsShow(false));

  return (
    <div
      ref={ref}
      className={classNames}
      onMouseEnter={showRef.current}
      onMouseLeave={hideRef.current}
    >
      <Dropdown
        isShow={isShow}
        origin={props.origin}
        duration={props.duration}
        content={props.content}
        time={props.clock}
      >
        {props.children}
      </Dropdown>
    </div>
  );
});
TooltipThis.displayName = 'ThisDialog';

export const Poptip = React.memo(TooltipThis);
Poptip.displayName = 'Dialog';
