import classNames from 'classnames';
import React from 'react';

export type TitleProps = {
  /** className fontSize from tailwind */
  tailwindFontSize?: string,
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
}

/**
 * Заголовок
 * @param [tag] "h2" по умолчанию
 * @param [tailwindFontSize] "text-xl" по умолчанию
 */
export const Title: React.FC<TitleProps & React.HTMLProps<HTMLHeadingElement>> = React.memo(({
  tailwindFontSize = 'text-xl',
  tag = 'h2',
  children,
  className,
}) => {
  const HeadingTag = tag;

  const thisClassName = classNames(
    'font-medium',
    tailwindFontSize,
    className,
  );

  return (
    <HeadingTag className={thisClassName}>{children}</HeadingTag>
  );
});

Title.displayName = 'Title';
