import { generatedApi } from '../phoebeApi.generated';
import { LAKE_WATER_AREA_POSTS_TAG } from './tags';

const waterAreaPostsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_WATER_AREA_POSTS_TAG],
  endpoints: {
    getLakeWaterAreaPostsAll: {
      providesTags: [LAKE_WATER_AREA_POSTS_TAG],
    },
  },
});

export const {
  useGetLakeWaterAreaPostsAllQuery,
} = waterAreaPostsApi;
