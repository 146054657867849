import React, { HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import './button.css';
import { Spinner } from '..';

export type IntentButtonType = 'info' | 'success' | 'danger' | 'warning' | 'default'
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset',
  /** рекомендуется передавать тип string | number */
  children: ReactNode
  /** добавочный класс стилей */
  className?: string,
  /** вариант цвета */
  intent?: IntentButtonType,
  /** внешний вид */
  minimal?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
}

const ButtonThis = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    type = 'button',
    children,
    className,
    intent = 'info',
    minimal = false,
    disabled,
    isLoading,
    ...restProps
  } = props;

  const minimalClass = `text-${intent} bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-10 disabled:text-gray-300`;

  const classes = classNames(
    getButtonClass(intent),
    {
      [minimalClass]: minimal,
    },
    className,
  );

  const loadBtnClass = classNames('load-button');

  return (
    <button
      disabled={disabled || isLoading}
      ref={ref}
      type={type}
      className={classes}
      {...restProps}
    >
      <div className={`${isLoading ? loadBtnClass : ''}`}>
        {
          isLoading ? <div className="w-4 h-4"><Spinner /></div> : null
        }
        {children}
      </div>
    </button>
  );
});

ButtonThis.displayName = 'ButtonThis';
/** Стилизованная кнопка, обернутая в forwardRef и memo */
export const Button = React.memo(ButtonThis);
Button.displayName = 'Button';

export const getButtonClass = (intent: IntentButtonType) => classNames(
  'button',
  `px-4 py-1 rounded font-medium text-white bg-${intent}`,
  'disabled:opacity-50 disabled:pointer-events-none',
  // `focus:ring-2 focus:ring-2 focus:ring-${intent}-outline`,
  `hover:bg-${intent}-hover`,
  `active:bg-${intent}-active`,
);
