import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { LOGIN } from 'constants/paths';
import { isAuthSelector } from 'Features/Login/authSlice';

const PrivateApp = React.lazy(() => import('./Private'));

/**
 * Пропускаем пользователя внутри приложения при успешном входе
 */
export function PrivateNavigate() {
  const isAuth = useSelector(isAuthSelector);

  if (!isAuth) return <Navigate to={`/${LOGIN}`} />;
  return <PrivateApp />;
}
