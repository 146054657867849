import classnames from 'classnames';
import { getIsElementInside } from '../utils/RectUtils';
import { Clocks, ClockSide } from './ClockUtils';
import { FindTime, TriggerType } from './Pop';

export const findTimeAlgorithm: FindTime = (context, setTime, isStoke = false) => {
  if (!context.popNode) {
    return;
  }

  const isInside = getIsElementInside(context.popNode, context.containerNode);
  if (isInside) {
    if (!isInside.result) {
      const isNegative = context.time === 12 || context.time < context.originalTime;
      const isNegativeFirst = isNegative && context.time >= context.originalTime;
      const roundedTime = isNegative ? ((isNegativeFirst ? context.originalTime : context.time) - 1) : context.time + 1;
      const isPossible = roundedTime <= 12 && roundedTime >= 1;

      if (isPossible) {
        setTime(roundedTime as Clocks);
      } else if (!isStoke) {
        setTime(null);
      }
    }
  }
};

export function buildTrigger(events: Array<Parameters<HTMLElement['addEventListener']>[0]>, extend?: {
  register: (node: HTMLElement, eventHandler: () => void) => void;
  unregister: (node: HTMLElement, eventHandler: () => void) => void;
}) {
  const trigger: TriggerType = (context, update) => {
    let timer: number | undefined;
    let isUpdateAfterEnd = false;

    if (!context.containerNode) {
      return () => {};
    }

    const eventHandler = () => {
      if (!timer) {
        update();

        timer = window.setTimeout(() => {
          timer = undefined;
          if (isUpdateAfterEnd) {
            isUpdateAfterEnd = false;
            update();
          }
        }, 225);
      } else {
        isUpdateAfterEnd = true;
      }
    };

    extend?.register(context.containerNode, eventHandler);
    events.forEach((event) => {
      context.containerNode?.addEventListener(event, eventHandler);
    });

    return () => {
      window.clearTimeout(timer);
      extend?.unregister(context.containerNode!, eventHandler);
      events.forEach((event) => {
        context.containerNode?.removeEventListener(event, eventHandler);
      });
    };
  };

  return trigger;
}

export function getTailwindByTime({
  stickySide,
  axis,
  cornerSide,
}: ClockSide) {
  const corner = { top: 'top-0', bottom: 'bottom-0', right: 'right-0', left: 'left-0' };
  const centers = { x: 'top-1/2 -translate-y-1/2', y: 'left-1/2 -translate-x-1/2' };
  const sides = { right: 'left-full', bottom: 'top-full', left: '-translate-x-full', top: 'top-0 -translate-y-full' };
  const def = 'absolute transform';

  return classnames(
    def,
    sides[stickySide],
    cornerSide ? corner[cornerSide] : centers[axis],
  );
}
