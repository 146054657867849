// Generated by CoffeeScript 1.10.0
let JSOG; let JSOG_OBJECT_ID; let hasCustomJsonificaiton; let isArray; let
  nextId;

JSOG = {};

nextId = 0;

isArray = Array.isArray || function (obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
};

hasCustomJsonificaiton = function (obj) {
  return obj.toJSON != null;
};

JSOG_OBJECT_ID = '__jsogObjectId';

JSOG.encode = function (original, idProperty, refProperty) {
  let doEncode; let idOf; let
    sofar;
  if (idProperty == null) {
    idProperty = '@id';
  }
  if (refProperty == null) {
    refProperty = '@ref';
  }
  sofar = {};
  idOf = function (obj) {
    if (!obj[JSOG_OBJECT_ID]) {
      obj[JSOG_OBJECT_ID] = `${nextId++}`;
    }
    return obj[JSOG_OBJECT_ID];
  };
  doEncode = function (original) {
    let encodeArray; let
      encodeObject;
    encodeObject = function (original) {
      let id; let key; let obj1; let obj2; let result; let
        value;
      id = idOf(original);
      if (sofar[id]) {
        return (
          obj1 = {},
          obj1[`${refProperty}`] = id,
          obj1
        );
      }
      result = sofar[id] = (
        obj2 = {},
        obj2[`${idProperty}`] = id,
        obj2
      );
      for (key in original) {
        value = original[key];
        if (key !== JSOG_OBJECT_ID) {
          result[key] = doEncode(value);
        }
      }
      return result;
    };
    encodeArray = function (original) {
      let val;
      return (function () {
        let i; let len; let
          results;
        results = [];
        for (i = 0, len = original.length; i < len; i++) {
          val = original[i];
          results.push(doEncode(val));
        }
        return results;
      }());
    };
    if (original == null) {
      return original;
    } if (hasCustomJsonificaiton(original)) {
      return original;
    } if (isArray(original)) {
      return encodeArray(original);
    } if (typeof original === 'object') {
      return encodeObject(original);
    }
    return original;
  };
  return doEncode(original);
};

JSOG.decode = function (encoded, idProperty, refProperty) {
  let doDecode; let
    found;
  if (idProperty == null) {
    idProperty = '@id';
  }
  if (refProperty == null) {
    refProperty = '@ref';
  }
  found = {};
  doDecode = function (encoded) {
    let decodeArray; let
      decodeObject;
    decodeObject = function (encoded) {
      let id; let key; let ref; let result; let
        value;
      ref = encoded[refProperty];
      if (ref != null) {
        ref = ref.toString();
      }
      if (ref != null) {
        return found[ref];
      }
      result = {};
      id = encoded[idProperty];
      if (id != null) {
        id = id.toString();
      }
      if (id) {
        found[id] = result;
      }
      for (key in encoded) {
        value = encoded[key];
        if (key !== idProperty) {
          result[key] = doDecode(value);
        }
      }
      return result;
    };
    decodeArray = function (encoded) {
      let value;
      return (function () {
        let i; let len; let
          results;
        results = [];
        for (i = 0, len = encoded.length; i < len; i++) {
          value = encoded[i];
          results.push(doDecode(value));
        }
        return results;
      }());
    };
    if (encoded == null) {
      return encoded;
    } if (isArray(encoded)) {
      return decodeArray(encoded);
    } if (typeof encoded === 'object') {
      return decodeObject(encoded);
    }
    return encoded;
  };
  return doDecode(encoded);
};

JSOG.stringify = function (obj) {
  return JSON.stringify(JSOG.encode(obj));
};

JSOG.parse = function (str) {
  return JSOG.decode(JSON.parse(str));
};

export {
  JSOG,
};
