import React from 'react';
import { classNames } from '@mono/ui-components';

// ! похожи стилями с ButtonNavLink

type TabProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  className?: string,
  children: React.ReactNode,
  isActive?: boolean,
}

export const ButtonTab = React.memo(({
  onClick,
  className = '',
  children,
  isActive = false,
  ...props
}: TabProps) => {
  const thisClassName = classNames(
    'button-tab',
    'px-4 py-1 rounded-lg rounded-lg font-medium text-center',
    'hover:bg-black hover:bg-opacity-5',
    className,
    {
      active: isActive,
    },
  );

  return (
    <button
      className={thisClassName}
      type="button"
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
});

ButtonTab.displayName = 'ButtonTab';
