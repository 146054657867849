import React, { ForwardedRef, ReactNode } from 'react';
import { Clocks, ClockSide, getSideByClock, safeClocks } from './ClockUtils';
import { getTailwindByTime } from './PopUtils';

export interface IPopupStatic {
  /** Target - элемент, относительно которого центрируется Pop */
  children: ReactNode,

  /** Content - содержимое Pop 'а */
  content: ReactNode

  /** Направление размещения Pop'a. Определяется размещением часовой стрелки на циферблате (от 1 до 12). */
  time: Clocks

  /** Переопределить функцию позиционирования, возвращает стили */
  render?: (clockSideInfo: ClockSide, popRef: ForwardedRef<HTMLDivElement>) => string
}

export const PopStatic = React.forwardRef<HTMLDivElement, IPopupStatic>(({
  children,
  content,
  time,
  render = getTailwindByTime,
}, ref) => {
  const safeTime = safeClocks(time);
  const clockSideInfo = getSideByClock(safeTime);
  const popupPositionClasses = render(clockSideInfo, ref);

  return (
    <div className="relative flex z-0">
      <div className="relative">
        <div ref={ref} className={popupPositionClasses}>
          {content || <div className="__zero__value__" />}
        </div>
        {children}
      </div>
    </div>
  );
});

PopStatic.displayName = 'PopStatic';
