import { Stack } from './Stack';

const EMPTY_VALUE = Symbol('EMPTY_VALUE');

export class TypeFilter {
  private _stack = new Stack();
  private _result: any = EMPTY_VALUE;

  static function(arg: any) {
    return new TypeFilter().function(arg);
  }
  static undefined(arg: any) {
    return new TypeFilter().undefined(arg);
  }
  static boolean(arg: any) {
    return new TypeFilter().boolean(arg);
  }
  static null(arg: any) {
    return new TypeFilter().null(arg);
  }
  static number(arg: any) {
    return new TypeFilter().number(arg);
  }
  static string(arg: any) {
    return new TypeFilter().string(arg);
  }

  function(r: any) {
    this._stack.add((v) => v && typeof v === 'function' && (this._result = r));
    return this;
  }
  boolean(r: any) {
    this._stack.add((v) => typeof v === 'boolean' && (this._result = r));
    return this;
  }
  number(r: any) {
    this._stack.add((v) => typeof v === 'number' && (this._result = r));
    return this;
  }
  undefined(r: any) {
    this._stack.add((v) => !v && typeof v === 'undefined' && (this._result = r));
    return this;
  }
  null(r: any) {
    this._stack.add((v) => !v && typeof v === 'object' && (this._result = r));
    return this;
  }
  string(r: any) {
    this._stack.add((v) => typeof v === 'string' && (this._result = r));
    return this;
  }
  byValue(v: any) {
    this._stack.resolve(v);
    return this._result === EMPTY_VALUE ? v : this._result;
  }
}
