import React from 'react';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import {
  CHARTS, DATA_CORRECTION, MAP, METADATA, SETTINGS, REPORTS, DAILY_WATER_FLOW, ANNUAL_DATA, FILES,
} from 'constants/paths';
import { NotFound } from 'Shared/NotFound';

// import SettingsRouter from 'Features/Settings/Router.Settings';
// import RiverPosts from 'Features/Metadata/River/RiverPosts';
// import RiverDataRouter from 'Features/DataCorrection/River/Router.RiverData';
// import Map from 'Features/Map/Map';
// import ChartsRouter from 'Features/Charts';
// import ReportsRouter from 'Features/Reports/Router.Reports';
// import DailyWaterFlow from 'Features/Charts/views/DailyWaterFLowModule';

const SettingsRouter = React.lazy(() => import('Features/Settings/River/Router.RiverSettings'));
const RiverPosts = React.lazy(() => import('Features/Metadata/River/RiverPosts'));
// const RiverDataRouter = React.lazy(() => import('Features/DataCorrection/River/Router.RiverData'));
const RiverDataRouter = React.lazy(() => import('Features/DataCorrection/River/Router.RiverData'));
const Map = React.lazy(() => import('Features/Map/River/Map'));
const ChartsRouter = React.lazy(() => import('Features/Charts'));
const ReportsRouterRiver = React.lazy(() => import('Features/Reports/River/Router.Reports'));
const DailyWaterFlow = React.lazy(() => import('Features/DailyWaterFlow'));
const AnnualProcessing = React.lazy(() => import('Features/AnnualProcessing/River/AnnualProcessing'));
const Files = React.lazy(() => import('Features/Files/Files'));

/**
 * Маршруты приложения по месту "Реки"
 * @memberof Routers
 */
function RiverRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to={`${METADATA}`} replace />} />
      <Route path={`${MAP}`} element={<Map />} />
      <Route path={`${METADATA}`} element={<RiverPosts />} />
      <Route path={`${DATA_CORRECTION}/*`} element={<RiverDataRouter />} />
      <Route path={`${CHARTS}/*`} element={<ChartsRouter />} />
      <Route path={`${DAILY_WATER_FLOW}/*`} element={<DailyWaterFlow />} />
      <Route path={`${ANNUAL_DATA}`} element={<AnnualProcessing />} />
      <Route path={`${SETTINGS}/*`} element={<SettingsRouter />} />
      <Route path={`${REPORTS}/*`} element={<ReportsRouterRiver />} />
      <Route path={`${FILES}`} element={<Files />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default RiverRouter;
