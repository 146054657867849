import React from 'react';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import {
  ANNUAL_DATA, DATA_CORRECTION, MAP, METADATA, SETTINGS, REPORTS, CHARTS,
} from 'constants/paths';
import { NotFound } from 'Shared/NotFound';

const LakeDataRouter = React.lazy(() => import('Features/DataCorrection/Lake/Router.LakeData'));
const LakeMetadataRouter = React.lazy(() => import('Features/Metadata/Lake/Router.LakeMetadata'));
const ChartsRouter = React.lazy(() => import('Features/Charts'));
const LakeMap = React.lazy(() => import('Features/Map/Lake/Map'));
const LakeAnnualProcessing = React.lazy(() => import('Features/AnnualProcessing/Lake/AnnualProcessing'));
const LakeSettingsRouter = React.lazy(() => import('Features/Settings/Lake/Router.LakeSettings'));
const LakeReportsRouter = React.lazy(() => import('Features/Reports/Lake/Router.Reports'));

/**
 * Маршруты приложения по месту "Озера"
 * @memberof Routers
 */
function LakeRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to={`${METADATA}`} replace />} />
      <Route path={`${METADATA}/*`} element={<LakeMetadataRouter />} />
      <Route path={`${DATA_CORRECTION}/*`} element={<LakeDataRouter />} />
      <Route path={`${MAP}`} element={<LakeMap />} />
      <Route path={`${REPORTS}/*`} element={<LakeReportsRouter />} />
      <Route path={`${ANNUAL_DATA}`} element={<LakeAnnualProcessing />} />
      <Route path={`${SETTINGS}/*`} element={<LakeSettingsRouter />} />
      <Route path={`${CHARTS}/*`} element={<ChartsRouter />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default LakeRouter;
