import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Features/App/store';
import { getLoginFromLocalStorage, UserType, loginApi } from 'services/auth';

export const AUTH_SLICE = 'auth';

type AuthSliceState = {
  currentUser: UserType | undefined,
  // acl: {
  //   regions: {
  //     [key: number]: string[]
  //   }
  //   station: {
  //     [key: number]: string[]
  //   }
  // } | null,
};
const initialState: AuthSliceState = {
  currentUser: undefined,
};

const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState: {
    ...getLoginFromLocalStorage(),
  },
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<UserType | undefined>) => Object.assign(state, { currentUser: payload }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      // @ts-ignore вход в другой вкладе
      loginApi.endpoints.loginSilent.matchFulfilled,
      (state, { payload }) => {
        state.currentUser = payload.currentUser;
      },
    );
    builder.addMatcher(
      // @ts-ignore выход в другой вкладе
      loginApi.endpoints.logoutSilent.matchFulfilled,
      () => initialState,
    );
    // builder.addMatcher(
    //   // @ts-ignore
    //   loginApi.endpoints.logout.matchFulfilled,
    //   () => initialState,
    // );
  },
});
export const isAuthSelector = (state: RootState) => !!state[AUTH_SLICE]?.currentUser;
export const userNameSelector = (state: RootState): string | undefined => state[AUTH_SLICE].currentUser?.username;

export default authSlice.reducer;

export const {
  setCurrentUser,
} = authSlice.actions;
