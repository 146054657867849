import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedPostSelector, setSelectedPost } from 'Features/App/lakeSlice';
import { LakePostDto } from 'services/phoebeApi.generated';
import { useGetLakePostsAllQuery } from 'services/lake/postsApi';
import { PostsSelect, WidthIdType, OnSelectPost } from '../PostsSelect';

type LakePost = WidthIdType<LakePostDto>

/**
 * Атрибуты компонента {@link LakePostsSelect}
 */
type LakePostsSelectProps = {
  disabled?: boolean,
  /** начальный выбранный пост, если нет его в глобальном хранилище */
  selectedId?: number,
  // todo: поменять на onSelect
  handlerSelect?: OnSelectPost<LakePost>,
  className?: string,
}

// ! С передачей posts использовать другой компонент, который лежит в Shared/Select
// <PostsSelect post={myPosts} ... >

/**
 * Выборка постов для озер с выгрузкой данных.
 *
 * При передачи {selectedId} предусматривает автовыбор поста, если он отсутствует.
 * @function
 * @param {LakePostsSelectProps} props
 */
export const LakePostsSelect = React.memo(({ disabled, selectedId, handlerSelect, ...props }: LakePostsSelectProps) => {
  const dispatch = useDispatch();
  const selectedPost = useSelector(selectedPostSelector);

  const { data: posts = [], isLoading, isFetching } = useGetLakePostsAllQuery();

  const getPostLabel = (post: LakePost) => `${post.ggiNumber} - ${post.postName}`;

  const onSelectPost: OnSelectPost<LakePost> = React.useCallback((post) => {
    dispatch(setSelectedPost(post));
    handlerSelect && handlerSelect(post);
  }, [dispatch, handlerSelect]);

  return (
    <PostsSelect<LakePost>
      posts={posts as Array<LakePost>}
      selectedId={selectedId}
      selectedPost={selectedPost}
      onSelectPost={onSelectPost}
      isDisabled={disabled || isLoading || isFetching}
      isLoading={isLoading || isFetching}
      getPostLabel={getPostLabel}
      {...props}
    />
  );
});
LakePostsSelect.displayName = 'LakePostsSelect';
