import { t } from 'locale';
import React from 'react';
import { Dialog } from '@mono/ui-components';
import './data-errors.css';
import { DataErrors, DataErrorsProps } from './DataErrors';

type DataErrorsDialogProps = {
  isShow?: boolean,
  closeDialog?: Function,
} & DataErrorsProps

export const DataErrorsDialog = React.memo(({
  errors, isShow = false, closeDialog,
}: DataErrorsDialogProps) => {
  // обрабатываю isShow тут, чтобы лишний раз не перерисовывать Dialog ))))
  if (!errors?.length || !isShow) return null;
  return (
    <Dialog
      isShow
      onCloseComplete={closeDialog}
      headerLabel={t('key_808')}
      hasCancel={false}
      hasConfirm={false}
      height="3/4"
    >
      <DataErrors errors={errors} />
    </Dialog>
  );
});
DataErrorsDialog.displayName = 'DataErrorsDialog';
