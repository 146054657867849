import React, { ReactNode, useEffect, useState } from 'react';
import { Clocks, safeClocks } from './ClockUtils';
import { PopStatic } from './PopStatic';
import { buildTrigger, findTimeAlgorithm } from './PopUtils';

//      11  12  1
//   10            2
//   9     [ ]     3
//   8             4
//      7   6   5

export interface IPop {
  /** Контейнер, за границы которого, не должен выходить Pop */
  containerNode?: HTMLElement | null

  /** Target, относительно содержимого, центрируется Pop  */
  children: ReactNode,

  /** Content - содержимое Pop`а */
  content: ReactNode

  /** Направление размещения Pop`a. Определяется размещением часовой стрелки на циферблате (от 1 до 12). */
  originalTime: Clocks

  /** Кастомизация динамического обновления (по умолчанию scroll eventListener) */
  customTrigger?: TriggerType

  /** Кастомизация поиска валидного времени */
  customFindTimeAlgorithm?: FindTime
}

export interface IPopDynamic extends IPop { }

export type TriggerType = (ctx: IPopContext, update: () => void) => (() => void) | void
export type FindTime = (context: IPopContext, setTime: (time: Clocks | null) => void, isStoke?: boolean) => void

interface IPopContext extends Pick<IPop, 'containerNode' | 'originalTime'> {
  popNode: HTMLElement | null
  time: Clocks
}

export function PopDynamic({
  containerNode,
  children,
  originalTime: originalTimeUnsafe,
  content,
  customFindTimeAlgorithm: findTime = findTimeAlgorithm,
  customTrigger: trigger = buildTrigger(['scroll']),
}: IPopDynamic) {
  const [popNode, setPopNode] = useState<HTMLElement | null>(null);
  const originalTime = safeClocks(originalTimeUnsafe);
  const [calculatedTime, setCalculatedTime] = useState<Clocks | null>(null);
  const time = calculatedTime ?? originalTime;

  const context = React.useMemo((): IPopContext => ({
    containerNode,
    popNode,
    originalTime,
    time,
  }), [containerNode, popNode, originalTime, time]);

  const createUpdateTime = React.useCallback((isStoke: boolean) => () => {
    findTime(context, setCalculatedTime, isStoke);
  }, [context, findTime]);

  // Слушатель внешних событий (вызывает перерасчет времени)
  useEffect(() => {
    const update = createUpdateTime(false);

    return trigger(context, update);
  }, [context, createUpdateTime, trigger]);

  // Вызывает пересчет времени при изменении контекста
  useEffect(() => {
    createUpdateTime(true)();
  }, [createUpdateTime]);

  return (
    <PopStatic ref={setPopNode} content={content} time={time}>
      {children}
    </PopStatic>
  );
}

export const Pop = React.memo(PopDynamic);
Pop.displayName = 'Pop';
