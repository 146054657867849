import React from 'react';
import { TextInputBlur } from '@mono/ui-components';

import {
  ExtraInfoDays,
  MonthSelect,
  PossibleDaysSelect,
  PossibleDaysType,
  PossibleYearsType,
  YearsSelect,
  YearsSelectTypes,
  YearsSelectAllProps,
} from './Select';

export const getMaxDate = (): DateType & DateDayType => {
  const d = new Date();
  return {
    year: d.getFullYear(),
    month: d.getMonth(),
    day: d.getDate(),
  };
};

/**
 * Тип даты приложения
 * @alias DateType
 * @memberof utils
 */
export type DateType = {
  year: number,
  /** от 0 до 11 */
  month: number,
  day?: number | '' | undefined,
};

export type DateDayType = { day: number | '' };

/**
 * Атрибуты компонента {@link PickDate} общие
 */
type PickDateProps = PossibleDaysProps & PossibleYearsProps & {
  required?: boolean
  noDay?: boolean
  date: DateType
  handlerSetDate: (date: DateType, isNew?: boolean) => void,
  /** реакт-элемент для выбора года, например, {@link RiverYearsSelect} или {@link LakePostYearsSelect} */
  CustomYearsSelect?: CustomYearsSelectType,
}

export type CustomYearsSelectType = React.FC<YearsSelectAllProps> | null;

/**
 * Атрибуты компонента {@link PickDate} для выбора дня
 */
export type PossibleDaysProps = {
  days?: PossibleDaysType,
  extraInfoDays?: ExtraInfoDays,
  isLoadingDays?: boolean,
}
/**
 * Атрибуты компонента {@link PickDate} для выбора годов
 */
export type PossibleYearsProps = {
  years?: PossibleYearsType,
  isLoadingYears?: boolean,
}
/**
 * Компонент выбора даты: год, месяц, число
 * @function PickDate
 * @param {PickDateProps} props
 * @param {PossibleDaysProps} props
 * @param {PossibleYearsProps} props
 */
export function ThisPickDate(props: PickDateProps) {
  const {
    required,
    days,
    extraInfoDays,
    years,
    isLoadingDays = false,
    isLoadingYears = false,
    noDay = false,
    date,
    handlerSetDate,
    CustomYearsSelect,
  } = props;

  const maxDate = React.useMemo(() => getMaxDate(), []);
  // Для ограничения выпадающего списка месяцев. + 1, потому что slices действует по кол-ву элементов
  const maxMonth = React.useMemo(() => (
    date.year === maxDate.year
      ? maxDate.month + 1
      : 12
  ), [date.year, maxDate.month, maxDate.year]);

  // !ПРОВЕРКИ нужны лишь тогда:
  // когда выбрана максимально возможный год - нужно проверить месяц
  // когда выбран максимально возможный год И месяц - нужно проверять день.
  // В остальных случаях тупо заносить

  // !а теперь стоит селектор, то в месяцы вообще можно сделать фильтр:
  // Если maxDate.year, то отсылаем maxDate.month в селектор и фильтруем там массив списка.
  // А день также проверять когда выбран максимально возможный год И месяц

  // ! - возможно понадобится сброс месяца и дня, как и раньше.
  const setSelectedYear: YearsSelectTypes.SetSelectedType = React.useCallback((value: number, args) => {
    handlerSetDate({ ...date, year: value }, args?.isNew);
  }, [date, handlerSetDate]);

  // ! возможно понадобится сброс дня
  const setSelectedMonth = React.useCallback((value: number) => {
    handlerSetDate({ ...date, month: value });
  }, [date, handlerSetDate]);

  const updateDay = React.useCallback((event: React.FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target;
    const thisDate = getDateByDay(date, +value, maxDate);
    handlerSetDate(thisDate);
  }, [date, handlerSetDate, maxDate]);

  const selectDay = React.useCallback((day: number | undefined) => {
    const thisDate = getDateByDay(date, day, maxDate);
    handlerSetDate(thisDate);
  }, [date, handlerSetDate, maxDate]);

  const DayComponent = React.useCallback(() => {
    // if (noDay || date.day === undefined) return null;
    if (noDay) return null;
    if (days) {
      return (
        <PossibleDaysSelect
          required={required}
          possibleDays={days}
          extraInfoDays={extraInfoDays}
          selected={date.day || ''}
          setSelected={selectDay}
          isLoading={isLoadingDays}
        />
      );
    }
    return (
      <TextInputBlur
        required={required}
        className="border"
        name="day"
        type="number"
        min="1"
        max="31"
        step="1"
        value={date.day || ''}
        updateValue={updateDay}
      />
    );
  }, [date.day, days, extraInfoDays, isLoadingDays, noDay, required, selectDay, updateDay]);
  return (
    <div className="grid grid-flow-col gap-2">
      {
        CustomYearsSelect
          ? <CustomYearsSelect isCreatable selected={date.year} setSelected={setSelectedYear} possibleYears={years} isLoadingYears={isLoadingYears} />
          : <YearsSelect isCreatable selected={date.year} setSelected={setSelectedYear} possibleYears={years} isLoadingYears={isLoadingYears} />
      }
      {/* <YearsSelect isCreatable selected={date.year} setSelected={setSelectedYear} possibleYears={years} isLoadingYears={isLoadingYears} /> */}
      <MonthSelect required selected={date.month} setSelected={setSelectedMonth} maxMonth={maxMonth} />
      <DayComponent />
    </div>
  );
}

export const PickDate = React.memo(ThisPickDate);
PickDate.displayName = 'PickDate';

function getDateByDay(date: DateType, day: number | undefined, maxDate: DateType & DateDayType): DateType {
  let newDay: number | '' = day || '';
  const thisDate: DateType = { ...date };

  // переназначаем введенное значение
  if (newDay <= 0) {
    newDay = '';
  } else if (newDay > 31) newDay = 31;
  // переназначаем день с учетом текущей даты
  if (
    thisDate.year === maxDate.year
    && thisDate.month === maxDate.month
    && newDay > maxDate.day
  ) {
    newDay = maxDate.day;
  }

  thisDate.day = newDay;

  return thisDate;
}
