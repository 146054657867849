import { generatedApi } from './phoebeApi.generated';

// все ресты actuator не находятся в файле generated, поэтому вручную
const actuatorApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<GetVersionApiResponse, void>({
      query: () => ({ url: '/actuator/info' }),
    }),
  }),
});

export const {
  useGetVersionQuery,
} = actuatorApi;

export type GetVersionApiResponse = {
  build: {
    /** версия сборки */
    version: string,
    artifact: string,
    name: string,
    group: string,
    /** дата сборки */
    time: string,
  }
}
