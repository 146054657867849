import { t } from 'locale';
import React from 'react';
import Select, { SingleValue } from 'react-select';

import { VocRegion } from 'services/phoebeApi.generated';
import { getRegionCodesMap, RegionCodesMapType, useGetVocRegionsAllQuery } from 'services/vocLists';
import { controlRequired } from 'Shared';

/**
 * Атрибуты компонента {@link SelectRegion}
 */
type SelectRegionProps = {
  /** значение code */
  // selected: VocRegion['code'] | null,
  selected: number | undefined | null,
  setSelected: SetSelectedRegionFunction,
  className?: string,
  required?: boolean,
  disabled?: boolean,
  name?: string,
  label?: string,
  index?: string | number, // полезно при изменении данных в массиве
}
export type SetSelectedRegionFunction = (args: {
  name?: string,
  value: VocRegion['code'], // выбранный код
  item: VocRegion,
  index?: string | number,
}) => void;

/**
 * Выбор региона из списка, где code - значение выбора (selected)
 * @function
 * @param {SelectRegionProps} props
 */
export const SelectRegion = React.memo(({
  selected,
  setSelected,
  className,
  required,
  disabled,
  name,
  label,
  index,
}: SelectRegionProps) => {
  const customStyles = React.useMemo(() => (required ? { control: controlRequired } : {}), [required]);

  const { data: regions = [], isLoading, isFetching } = useGetVocRegionsAllQuery();

  // todo: вынести в трансформацию запроса
  const mapRegions: RegionCodesMapType | undefined = React.useMemo(() => (
    regions.length ? getRegionCodesMap(regions) : undefined
  ), [regions]);

  const options = React.useMemo(() => regions.map((item: VocRegion) => ({
    value: item.code!,
    item,
  })), [regions]);

  const selectedOption = React.useMemo(() => (
    selected && mapRegions
      ? ({
        value: selected,
        item: mapRegions[selected],
      })
      : null // * если указать undefined, то визуальное выбранное значение в некоторых случаях не сбрасывается..
  ), [mapRegions, selected]);

  const onChangeSelected = React.useCallback((option: SingleValue<typeof selectedOption>) => {
    setSelected({
      name,
      value: option?.value,
      item: option?.item || {},
      index,
    });
  }, [index, name, setSelected]);

  const noOptionsMessage = () => t('key_828');
  const loadingMessage = () => t('key_829');
  const getOptionLabel = (option: typeof selectedOption) => {
    if (option) return `${option.value} - ${option.item.name}`;
    return '';
  };

  return (
    <div className={`flex gap-1 flex-col ${className}`}>
      {label && <span className="font-medium self-start">{label}</span>}
      <Select
        name={name}
        isClearable
        classNamePrefix="react-select"
        isDisabled={disabled}
        isLoading={isLoading || isFetching}
        placeholder={t('key_618')}
        options={isLoading || isFetching ? undefined : options}
        getOptionLabel={getOptionLabel}
        value={selectedOption}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onChange={onChangeSelected}
        styles={customStyles}
      />
    </div>
  );
});
SelectRegion.displayName = 'SelectRegion';
