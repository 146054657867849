import { format, parse } from 'date-fns';

export const FULL_DATE_FORMAT = 'dd.MM.yyyy';
export const FULL_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const FULL_DATE_TIME_SHORT_FORMAT = 'dd.MM.yy HH:mm';
export const FROM_SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const FROM_SERVER_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const parseFromServerDate = (date: string) => parse(date, FROM_SERVER_DATE_FORMAT, new Date());
export const parseFromServerDateTime = (date: string) => parse(date, FROM_SERVER_DATE_TIME_FORMAT, new Date());
export const parseDate = (date: string) => parse(date, FULL_DATE_FORMAT, new Date());
export const dateToClientFormat = (date: Date) => format(date, FULL_DATE_FORMAT);
/**
 * @param date строка подходящая для new Date()
 * @func
 * @memberof utils
 */
export const getClientDateFormat = (date?: string) => (date ? dateToClientFormat(new Date(date)) : '');

export const dateToServerFormat = (date: Date) => format(date, FROM_SERVER_DATE_FORMAT);
export const dateTimeToClientFormat = (date: Date) => format(date, FULL_DATE_TIME_FORMAT);
export const dateTimeToClientShortFormat = (date: Date) => format(date, FULL_DATE_TIME_SHORT_FORMAT);

export const DAY = 86400000; // ms in day
export const getYear = (date: Date) => format(date, 'yyyy');
export const getDaysBetween = (date0: number, date1: number) => Math.abs(date1 - date0) / DAY;

export function generateDaysForYear(year: number) {
  const days = [];
  const date = new Date(year, 0, 1);
  while (date.getFullYear() === year) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
