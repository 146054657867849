import { generatedApi, GetCalculatedDataByStationIdAndDateApiArg, GetCalculatedDataByStationIdAndDateApiResponse } from 'services/phoebeApi.generated';
import { getMonthlyTablesData } from 'services/utils';
import { CALCULATED_DATA_TAG, KSVO_MONTH_TAG } from '../tags';
import { calculatedDataTransformResponse } from './transformResponse';

export const calculatedDataApi = generatedApi.enhanceEndpoints({
  addTagTypes: [CALCULATED_DATA_TAG, KSVO_MONTH_TAG],
  endpoints: {
    getCalculatedDataByStationIdAndDate: {
      // @ts-ignore если есть isMonthlyTablesData, то получем преобразованные данные
      transformResponse: (response: GetCalculatedDataByStationIdAndDateApiResponse, meta, { isMonthlyData, isMonthlyTablesData }: GetCalculatedDataByStationIdAndDateApiArg & { isMonthlyData?: boolean, isMonthlyTablesData?: boolean }) => {
        // !delete
        if (isMonthlyData) {
          return calculatedDataTransformResponse(response);
        }
        if (isMonthlyTablesData) {
          return getMonthlyTablesData(response);
        }
        return response;
      },
      providesTags: [CALCULATED_DATA_TAG],
    },
    getKsvoMonthByStationIdAndDate: {
      providesTags: [KSVO_MONTH_TAG],
    },
    updateKsvoMonth: {
      invalidatesTags: [KSVO_MONTH_TAG, CALCULATED_DATA_TAG],
    },
    deleteCalculatedDataById: {
      invalidatesTags: [KSVO_MONTH_TAG, CALCULATED_DATA_TAG],
    },
    getCalculatedDataForMonthlyData: {
      providesTags: [CALCULATED_DATA_TAG],
    },
  },
});

export const {
  useGetCalculatedDataByStationIdAndDateBetweenQuery,
  useLazyGetCalculatedDataByStationIdAndDateQuery,
  useGetCalculatedDataByStationIdAndDateQuery,
  useGetCalculatedDataYearByStationIdAndDateBetweenQuery,

  // ksvo_month
  useGetKsvoMonthByStationIdAndDateQuery,
  useUpdateKsvoMonthMutation,
  useDeleteCalculatedDataByIdMutation,
} = calculatedDataApi;
