import { generatedApi } from '../phoebeApi.generated';
import { LAKE_OBSERVATION_POINTS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_OBSERVATION_POINTS_TAG],
  endpoints: {
    getLakeObservationPointsByPostId: {
      providesTags: [LAKE_OBSERVATION_POINTS_TAG],
    },
    updateLakeObservationPoints: {
      invalidatesTags: [LAKE_OBSERVATION_POINTS_TAG],
    },
    deleteLakeObservationPointById: {
      invalidatesTags: [LAKE_OBSERVATION_POINTS_TAG],
    },
  },
});

export const {
  useGetLakeObservationPointsByPostIdQuery,
  useUpdateLakeObservationPointsMutation,
  useDeleteLakeObservationPointByIdMutation,
} = api;
