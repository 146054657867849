import { generatedApi, GetIceMeasurementByStationIdAndDateApiArg } from 'services/phoebeApi.generated';
import { getIceMeasurementsForMonthlyTablesData } from 'services/utils';
import { ICE_MEASUREMENTS_TAG, CALCULATED_DATA_TAG, STATION_YEARS_TAG } from '../tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [ICE_MEASUREMENTS_TAG, STATION_YEARS_TAG, CALCULATED_DATA_TAG],
  endpoints: {
    getIceMeasurementByStationIdAndDate: {
      // @ts-ignore
      transformResponse: (response, meta, { isMonthlyTablesData }: GetIceMeasurementByStationIdAndDateApiArg & { isMonthlyData?: boolean, isMonthlyTablesData?: boolean }) => {
        if (isMonthlyTablesData) {
          return getIceMeasurementsForMonthlyTablesData(response.data);
        }
        return response;
      },
      providesTags: [ICE_MEASUREMENTS_TAG],
    },
    updateFormIceMeasurements: {
      invalidatesTags: (result, error) => {
        if (error) return [];
        return [ICE_MEASUREMENTS_TAG, STATION_YEARS_TAG, CALCULATED_DATA_TAG];
      },
    },
  },
});

export const {
  useGetIceMeasurementByStationIdAndDateBetweenQuery,
  useGetIceMeasurementByStationIdAndDateQuery,
  useLazyGetIceMeasurementByStationIdAndDateQuery,
  useUpdateFormIceMeasurementsMutation,
} = api;
