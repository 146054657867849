export type Side = 'right' | 'bottom' | 'left' | 'top'
export type Clocks = 12 | 11 | 10 | 9 | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1
export type ClockSide = {
  clock: Clocks
  stickySide: Side
  cornerSide: Side | false
  axis: 'y' | 'x'
  flowSides: Side[]
  concurrentSides: [Side, Side] | false
}

export function getValidCLock(
  clockTime: Clocks,
  placeability: (time: Clocks) => boolean,
) {
  let validClockSolution = clockTime;
  for (let i = 0; i < 12; i += 1) {
    const timeMixing = ((i % 2) === 0) ? (i / 2) : ((i - 1) / -2);
    let iterationTime = clockTime + timeMixing;
    if (iterationTime > 12) iterationTime -= 12;
    if (iterationTime < 1) iterationTime += 12;
    const valid = placeability(iterationTime as Clocks);
    if (valid) {
      validClockSolution = iterationTime as Clocks;
      break;
    }
  }
  return validClockSolution;
}

const allSides = ['right', 'bottom', 'left', 'top'] as const;
const sidesByAxis = { x: ['top', 'bottom'], y: ['right', 'left'] } as const;

export function getClockMeta(clock: Clocks) {
  // Transform clock (movedTime)
  // FROM                             TO                                         |
  //                                  3# side(top)                               |
  //                                                                             |
  //   11 12 01                         09 10 11                                 |
  // 10        02                     08        00                               |
  // 09   ==   03     2# side(left)   07   ==   01    0# side(right)             |
  // 08        04                     06        02                               |
  //   07 06 05                         05 04 03                                 |
  //                                                                             |
  //                                 1# side(bottom)                             |
  //                   corners: 00, 02, 03, 05, 06, 08, 09, 11                   |
  //                   starts: 00, 03, 06, 09                                    |
  let movedTime = clock - 1;
  if (movedTime === 0) {
    movedTime = 12;
  }
  movedTime -= 1;

  const sideIndex = Math.trunc(movedTime / 3);
  const axis: 'y' | 'x' = sideIndex % 2 ? 'y' : 'x';
  const isCorner = !(movedTime % 3 === 1);
  const isCornerStart = movedTime - sideIndex * 3 === 0;

  return {
    clock,
    movedTime: movedTime as 0|1|2|3|4|5|6|7|8|9|10|11,
    sideIndex,
    axis,
    isCorner,
    isCornerStart,
  };
}
getClockMeta.sideIndexes = [0, 1, 2, 3] as const;
getClockMeta.movedClocks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;
getClockMeta.corners = [0, 2, 3, 5, 6, 8, 9, 11] as const;
getClockMeta.cornerStarts = [0, 3, 6, 9] as const;
getClockMeta.cornerEnds = [2, 5, 8, 11] as const;
getClockMeta.nonCorners = [1, 4, 7, 10] as const;

export function getSideByClock(clock: Clocks): ClockSide {
  const { axis, sideIndex, isCorner, isCornerStart } = getClockMeta(clock);

  // Transform to string
  const isCornerReverse = (sideIndex < 2);
  const cornerIndex = Number(isCornerReverse ? !isCornerStart : isCornerStart);
  const cornerSide = isCorner ? sidesByAxis[axis][cornerIndex] : false;
  const stickySide = allSides[sideIndex];
  const flowSides: Side[] = [stickySide];
  if (cornerSide) {
    flowSides.push(cornerSide);
  } else {
    flowSides.push(...sidesByAxis[axis]);
  }

  return {
    clock,
    stickySide,
    axis,
    cornerSide,
    flowSides,
    concurrentSides: isCorner ? false : flowSides.slice(1) as [Side, Side],
  };
}

export function safeClocks(value: any): Clocks {
  return Number(value) as Clocks || 6;
}
