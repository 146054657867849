import { Icon } from '@mono/ui-components';
import React from 'react';
import { possibleLocales, t, setLocale, getCurrentLocale } from 'locale';

function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
  const confirmed = window.confirm(t('settings.language.confirm'));

  if (!confirmed) {
    return;
  }

  const locale = event.target.value;
  window.location.reload();
  setLocale(locale);
}

export function SelectLanguage() {
  return (
    <div className="absolute left-0 top-0 right-auto bottom-auto w-min flex flex-row m-1.5 rounded-md shadow-sm bg-white p-1 z-50">
      <Icon size={13} icon="globe" className="text-gray-500 mt-1.5 mr-1 ml-0.5" />
      <select
        className="p-1 pl-1.5 text-xs bg-gray-200 rounded-md appearance-none focus:outline-none"
        onChange={onChange}
        value={getCurrentLocale()}
      >
        {
          possibleLocales.map((locale) => (
            <option key={locale.value} value={locale.value}>{locale.label}</option>
          ))
        }
      </select>
    </div>
  );
}
