import { changeDomTitle, changeDomLocale } from './changeDom';
import ru from './ru.json';
import en from './en.json';

const locales: Record<string, any> = {
  ru,
  en,
};

export function getCurrentLocale() {
  return localStorage.getItem('locale') || 'en';
}

export function setLocale(locale: string) {
  localStorage.setItem('locale', locale);
}

export const possibleLocales = Object.keys(locales).map((key) => ({
  value: key,
  label: locales[key].name as string,
}));

const currentLocale = getCurrentLocale();

Object.keys(locales).forEach((key) => {
  if (key !== currentLocale) {
    delete locales[key];
  }
});

export function t(key: string, params?: Record<string, any>): string {
  const locale = locales[currentLocale].data;
  let value = locale[key];

  if (!value) {
    value = key;
    // eslint-disable-next-line no-console
    console.warn(`Translation for key "${key}" not found`);
  }

  if (params) {
    // example: {{name}} {{surname}}
    return value.replace(/\{\{(\w+)\}\}/g, (_: any, p: string) => {
      const param = params[p];

      if (param === undefined) {
        // eslint-disable-next-line no-console
        console.warn(`Translation param "${p}" not found`);
        return '';
      }

      return param;
    });
  }

  return value;
}

changeDomLocale(currentLocale);
changeDomTitle(t('key_0'));
