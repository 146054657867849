import React from 'react';
import { Spinner } from '@mono/ui-components';
import { TechnicalWork } from 'Shared/TechnicalWork';
import AppRouter from 'Features/App/Router.App';
import store from 'Features/App/store';
import authReducer, { AUTH_SLICE } from 'Features/Login/authSlice';
import { useCheckServiceUnavailable } from './useCheckServiceUnavailable';

store.injectReducer(AUTH_SLICE, authReducer);

/**
 * Главный компонент приложения
 */
function App() {
  const { isFetching, isServiceUnavailable } = useCheckServiceUnavailable();

  if (isFetching) return <Spinner />;
  if (isServiceUnavailable) return <TechnicalWork />;
  return (
    <div className="h-full w-full">
      <AppRouter />
    </div>
  );
}

export default App;
