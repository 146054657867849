import classnames from 'classnames';
import React from 'react';
import { IPop, Pop } from './Pop';
import { IPopupStatic, PopStatic } from './PopStatic';

type DropdownProps<IS_DYNAMIC extends boolean = false> = {
  isShow: boolean
  className?: string
  origin?: 'center' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'top-left' | string
  duration?: number
  dynamic?: boolean
  time?: number
} & (IS_DYNAMIC extends true ? IPop : IPopupStatic);

export function DropdownThis({ content, isShow, children, className, origin = 'top', duration = 200, dynamic, ...rest }: DropdownProps) {
  const [preventHide, setPreventHide] = React.useState(false);

  const classNames = classnames(
    `flex flex-col bg-gray-50 shadow-md transform transition duration-${duration} rounded origin-${origin}`,
    isShow ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
    className,
  );

  React.useEffect(() => { // eslint-disable-line consistent-return
    if (!isShow && preventHide) {
      const t = setTimeout(() => {
        setPreventHide(false);
      }, duration);

      return () => clearTimeout(t);
    }

    if (isShow && !preventHide) setPreventHide(true);
  }, [duration, isShow, preventHide]);

  const UsedPop = dynamic ? Pop : PopStatic;

  const originalTime = (rest as IPopupStatic).time;

  return (
    // @ts-ignore
    <UsedPop
      originalTime={originalTime}
      {...rest}
      content={(
        <div
          className={classNames}
        >
          { isShow || preventHide ? content : null }
        </div>
      )}
    >
      { children }
    </UsedPop>
  );
}

export const Dropdown = React.memo(DropdownThis) as <IS_DYNAMIC extends boolean = false>(props: DropdownProps<IS_DYNAMIC>) => JSX.Element;
// @ts-ignore
Dropdown.displayName = 'Dropdown';
