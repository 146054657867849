import {
  generatedApi,
  WaterFlowChannelEntity,
  WaterFlowEntity,
} from 'services/phoebeApi.generated';
import { waterFlowApi } from './api';

const customApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    getWaterFlowChannelCombinedList: build.query<WaterFlowChannelEntity[], number[]>({
      async queryFn(ids: number[], { dispatch }) {
        try {
          const dispatcher = (id: number) => dispatch(waterFlowApi.endpoints.getWaterFlowChannelById.initiate({ id }));
          const promises = ids.map(async (id) => {
            const query = await dispatcher(id);

            return query.data!;
          });
          const result = await Promise.all(promises);

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getWaterFlowCombinedList: build.query<WaterFlowEntity[], number[]>({
      async queryFn(ids: number[], { dispatch }) {
        try {
          const dispatcher = (id: number) => dispatch(waterFlowApi.endpoints.getWaterFlowById.initiate({ id }));
          const promises = ids.map(async (id) => {
            const query = await dispatcher(id);

            return query.data!;
          });
          const result = await Promise.all(promises);

          return { data: result };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});

export const {
  useGetWaterFlowChannelCombinedListQuery,
} = customApi;
