import React from 'react';
import { Spinner } from './Spinner';
import './block-spinner.css';

export type BlockSpinnerProps = {
  // true - вращается, false - не отображается
  isSpun: boolean,
  text?: string
}
function ThisBlockSpinner({ isSpun, text }: BlockSpinnerProps) {
  if (!isSpun) return null;
  return (
    <div className="block-spinner-wrapper">
      {
        text
          ? <p className="h-full w-full flex justify-center items-center text-slate-400">{text}</p>
          : <Spinner />
      }
    </div>
  );
}

export const BlockSpinner = React.memo(ThisBlockSpinner);
BlockSpinner.displayName = 'BlockSpinner';
