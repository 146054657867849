import {
  combineReducers,
  configureStore, EnhancedStore, Reducer,
} from '@reduxjs/toolkit';
import { generatedApi } from '../../services/phoebeApi.generated';

type ReducersType = { [key: string]: Reducer }

export interface AppStore extends EnhancedStore {
  asyncReducers: ReducersType
  injectReducer: (key: string, reducer: Reducer) => void
}
function createAsyncStore() {
  const store = configureStore({
    // Нужен по дефолту хоть один редюсер (затирается инжектом)
    reducer: { init: () => null },
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: false,
      // immutableCheck: {
      //   warnAfter: 100,
      // },
      serializableCheck: {
        warnAfter: 180,
      },
    }).concat(generatedApi.middleware),
  }) as AppStore;
  // для отслеживания зарегистрированных асинхронных редюсеров
  store.asyncReducers = {};

  // добавляет асинхронный редюсер и создает новый комбинированный редюсер
  store.injectReducer = (key, reducer) => {
    if (store.asyncReducers![key]) {
      return;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };
  return store;
}
const createReducer = (asyncReducers: ReducersType) => combineReducers({
  ...asyncReducers,
});

const store = createAsyncStore();

store.injectReducer(generatedApi.reducerPath, generatedApi.reducer);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
