import React from 'react';
import { IconName, IconSvgPaths16, iconNameToPathsRecordKey } from '@blueprintjs/icons';
import classNames from 'classnames';

export type IconProps = {
  children?: never,
  className?: string,
  icon: IconName,
  intent?: IntentType,
  /** @default 16 */
  size?: number,
  /** подсказка при наведении */
  htmlTitle?: string,
  /** css-color: будет перекрывать intent */
  color?: string,
}

export const Intent = {
  NONE: 'none' as 'none',
  DEFAULT: 'default' as 'default',
  INFO: 'info' as 'info',
  SUCCESS: 'success' as 'success',
  WARNING: 'warning' as 'warning',
  DANGER: 'danger' as 'danger',
};
export type IntentType = typeof Intent[keyof typeof Intent];

/** обертка для иконок из @blueprintjs/icons
 * @see https://blueprintjs.com/docs/#icons
 *
 * Параметр color будет перекрывать intent
 * */
export const Icon: React.FC<IconProps> = React.memo(({
  className,
  icon,
  intent,
  size = 16,
  htmlTitle,
  color = 'default',
}) => {
  const classes = classNames(
    'fill-current',
    {
      [`text-${intent}`]: intent !== Intent.NONE,
    },
    className,
  );
  // render path elements, or nothing if icon name is unknown.
  const svgPaths = React.useMemo(() => renderSvgPaths(icon), [icon]);

  return (
    <span title={htmlTitle} className={classes}>
      {
        icon && svgPaths
          ? (
            <svg
              fill={color}
              data-icon={icon}
              width={size}
              height={size}
              viewBox="0 0 16 16"
              role="img"
            >
              {svgPaths}
            </svg>
          )
          : 'нет иконки'
      }
    </span>

  );
});
Icon.displayName = 'Icon';

function renderSvgPaths(iconName: IconName): JSX.Element[] | undefined {
  const paths = IconSvgPaths16[iconNameToPathsRecordKey(iconName)];
  return (
    paths
      // eslint-disable-next-line react/no-array-index-key
      ? paths.map((path, idx) => <path key={idx} d={path} fillRule="evenodd" />)
      : undefined
  );
}
