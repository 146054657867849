import { generatedApi } from '../phoebeApi.generated';
import { LAKE_POST_YEARS_TAG, LAKE_BASIC_OBSERVATIONS_TAG } from './tags';

const lakeBasicObservationsApi = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_BASIC_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
  endpoints: {
    getLakeBasicObservationsByPostIdAndDate: {
      providesTags: [LAKE_BASIC_OBSERVATIONS_TAG],
    },
    updateFormLakeBasicObservations: {
      invalidatesTags: [LAKE_BASIC_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
    },
  },
});

export const {
  useGetLakeBasicObservationsByPostIdAndDateQuery,
  useLazyGetLakeBasicObservationsByPostIdAndDateQuery,
  useUpdateFormLakeBasicObservationsMutation,
} = lakeBasicObservationsApi;
