import { StationYearParametersEntity } from 'services/phoebeApi.generated';

export type YearParametersByTagsType = {
  [tag: string]: Array<StationYearParametersEntity> | undefined,
}

/**
 * составляем карту параметров по тегам (каким тегам сколько наборов значений принадлежит)
 * @param parameters
 */
export function getYearParametersByTags(parameters: StationYearParametersEntity[]) {
  const parametersByTags = parameters.reduce((acc, current: StationYearParametersEntity) => {
    const { tag } = current;
    if (tag) {
      if (acc[tag] === undefined) acc[tag] = [current];
      else acc[tag]!.push(current);
    }
    return acc;
  }, {} as YearParametersByTagsType);

  return parametersByTags;
}
