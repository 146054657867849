import { generatedApi } from '../phoebeApi.generated';
import { LAKE_POST_YEARS_TAG, LAKE_WAVE_OBSERVATIONS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_WAVE_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
  endpoints: {
    getWaveBasicObservationsByPostIdAndDate: {
      providesTags: [LAKE_WAVE_OBSERVATIONS_TAG],
    },
    updateFormLakeWaveObservations: {
      invalidatesTags: [LAKE_WAVE_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
    },
  },
});

export const {
  useGetWaveBasicObservationsByPostIdAndDateQuery,
  useLazyGetWaveBasicObservationsByPostIdAndDateQuery,
  useUpdateFormLakeWaveObservationsMutation,
} = api;
