// getGranulometryByStationIdAndDateAndTag
import { generatedApi } from 'services/phoebeApi.generated';
import { GRANULOMETRY_TAG, STATION_YEARS_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [GRANULOMETRY_TAG, STATION_YEARS_TAG],
  endpoints: {
    getGranulometryByStationIdAndDateAndTag: {
      providesTags: [GRANULOMETRY_TAG],
    },
    updateFormGranulometry: {
      invalidatesTags: [GRANULOMETRY_TAG, STATION_YEARS_TAG],
    },
  },
});

export const {
  useGetGranulometryByStationIdAndDateAndTagQuery,
  useLazyGetGranulometryByStationIdAndDateAndTagQuery,
  useUpdateFormGranulometryMutation,
} = api;
