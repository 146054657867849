/** 
 * THIS FILE WAS AUTO GENERATE, DONT EDIT IT 
 * CLI OPTIONS: --auth 
 * GENERATED TIME: 
 * Mon Dec 04 2023 17:33:13 GMT+0300 (Москва, стандартное время)
 */
import { createApi } from "@reduxjs/toolkit/query/react";
import { phoebeBaseQuery } from "./phoebeBaseQuery";
export const generatedApi = createApi({
  reducerPath: "generatedApi",
  baseQuery: phoebeBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    getWaterObjectById: build.query<
      GetWaterObjectByIdApiResponse,
      GetWaterObjectByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-objects/${queryArg.code}` }),
    }),
    updateWaterObject: build.mutation<
      UpdateWaterObjectApiResponse,
      UpdateWaterObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/water-objects/${queryArg.code}`,
        method: "PUT",
        body: queryArg.waterObjectsEntity,
      }),
    }),
    deleteWaterObjectById: build.mutation<
      DeleteWaterObjectByIdApiResponse,
      DeleteWaterObjectByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-objects/${queryArg.code}`,
        method: "DELETE",
      }),
    }),
    getWaterFlowById: build.query<
      GetWaterFlowByIdApiResponse,
      GetWaterFlowByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow/${queryArg.id}` }),
    }),
    updateWaterFlow: build.mutation<
      UpdateWaterFlowApiResponse,
      UpdateWaterFlowApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowEntity,
      }),
    }),
    deleteWaterFlowById: build.mutation<
      DeleteWaterFlowByIdApiResponse,
      DeleteWaterFlowByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getWaterByStationIdAndDate: build.query<
      GetWaterByStationIdAndDateApiResponse,
      GetWaterByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    updateFormWaterFlow: build.mutation<
      UpdateFormWaterFlowApiResponse,
      UpdateFormWaterFlowApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowEntity,
      }),
    }),
    getWaterFlowTempAll: build.query<
      GetWaterFlowTempAllApiResponse,
      GetWaterFlowTempAllApiArg
    >({
      query: () => ({ url: `/water-flow-temp` }),
    }),
    updateWaterFlowTempList: build.mutation<
      UpdateWaterFlowTempListApiResponse,
      UpdateWaterFlowTempListApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    createWaterFlowTemp: build.mutation<
      CreateWaterFlowTempApiResponse,
      CreateWaterFlowTempApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp`,
        method: "POST",
        body: queryArg.waterFlowTempEntity,
      }),
    }),
    getWaterFlowTempById: build.query<
      GetWaterFlowTempByIdApiResponse,
      GetWaterFlowTempByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-temp/${queryArg.id}` }),
    }),
    updateWaterFlowTemp: build.mutation<
      UpdateWaterFlowTempApiResponse,
      UpdateWaterFlowTempApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowTempEntity,
      }),
    }),
    deleteWaterFlowTempById: build.mutation<
      DeleteWaterFlowTempByIdApiResponse,
      DeleteWaterFlowTempByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    createInitialWaterFlowTempByStationIdAndYear: build.mutation<
      CreateInitialWaterFlowTempByStationIdAndYearApiResponse,
      CreateInitialWaterFlowTempByStationIdAndYearApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp-first/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        params: { years: queryArg.years },
      }),
    }),
    calculateWaterFLowStoutCorrect: build.mutation<
      CalculateWaterFLowStoutCorrectApiResponse,
      CalculateWaterFLowStoutCorrectApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-stout-correct-calculation`,
        method: "PUT",
        params: {
          id: queryArg.id,
          year: queryArg.year,
          curveId: queryArg.curveId,
          periodId: queryArg.periodId,
          usedIds: queryArg.usedIds,
        },
      }),
    }),
    createWaterFlowPointsAndQCurve: build.mutation<
      CreateWaterFlowPointsAndQCurveApiResponse,
      CreateWaterFlowPointsAndQCurveApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
        params: { periodId: queryArg.periodId },
      }),
    }),
    getWaterFlowPointById: build.query<
      GetWaterFlowPointByIdApiResponse,
      GetWaterFlowPointByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-points/${queryArg.id}` }),
    }),
    updateWaterFlowPoint: build.mutation<
      UpdateWaterFlowPointApiResponse,
      UpdateWaterFlowPointApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowPointEntity,
      }),
    }),
    deleteWaterFlowPointById: build.mutation<
      DeleteWaterFlowPointByIdApiResponse,
      DeleteWaterFlowPointByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    updateWaterFlowPoints: build.mutation<
      UpdateWaterFlowPointsApiResponse,
      UpdateWaterFlowPointsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points/curves/${queryArg.curveId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    concavityCheck: build.mutation<
      ConcavityCheckApiResponse,
      ConcavityCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points-concavity-check`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getWaterFlowPeriodsByStationIdAndYear: build.query<
      GetWaterFlowPeriodsByStationIdAndYearApiResponse,
      GetWaterFlowPeriodsByStationIdAndYearApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-periods/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    updateWaterFlowPeriods: build.mutation<
      UpdateWaterFlowPeriodsApiResponse,
      UpdateWaterFlowPeriodsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-periods/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getWaterFlowPeriodById: build.query<
      GetWaterFlowPeriodByIdApiResponse,
      GetWaterFlowPeriodByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-periods/${queryArg.id}` }),
    }),
    updateWaterFlowPeriod: build.mutation<
      UpdateWaterFlowPeriodApiResponse,
      UpdateWaterFlowPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-periods/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowPeriodEntity,
      }),
    }),
    deleteWaterFlowPeriodById: build.mutation<
      DeleteWaterFlowPeriodByIdApiResponse,
      DeleteWaterFlowPeriodByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-periods/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getWaterFlowCurvesAll: build.query<
      GetWaterFlowCurvesAllApiResponse,
      GetWaterFlowCurvesAllApiArg
    >({
      query: () => ({ url: `/water-flow-curves` }),
    }),
    updateWaterFlowCurveName: build.mutation<
      UpdateWaterFlowCurveNameApiResponse,
      UpdateWaterFlowCurveNameApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves`,
        method: "PUT",
        body: queryArg.waterFlowCurveEntity,
      }),
    }),
    createWaterFlowCurve: build.mutation<
      CreateWaterFlowCurveApiResponse,
      CreateWaterFlowCurveApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves`,
        method: "POST",
        body: queryArg.waterFlowCurveEntity,
      }),
    }),
    createCurveByCoefficients: build.mutation<
      CreateCurveByCoefficientsApiResponse,
      CreateCurveByCoefficientsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowCurveDto,
        params: {
          periodId: queryArg.periodId,
          curveTypeId: queryArg.curveTypeId,
        },
      }),
    }),
    getWaterFlowCurveById: build.query<
      GetWaterFlowCurveByIdApiResponse,
      GetWaterFlowCurveByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-curves/${queryArg.id}` }),
    }),
    updateWaterFlowCurve: build.mutation<
      UpdateWaterFlowCurveApiResponse,
      UpdateWaterFlowCurveApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowCurveEntity,
      }),
    }),
    deleteWaterFlowCurveById: build.mutation<
      DeleteWaterFlowCurveByIdApiResponse,
      DeleteWaterFlowCurveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getWaterFlowCurveXlsx: build.query<
      GetWaterFlowCurveXlsxApiResponse,
      GetWaterFlowCurveXlsxApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-curves-xlsx/${queryArg.id}` }),
    }),
    createWaterFlowCurveXlsx: build.mutation<
      CreateWaterFlowCurveXlsxApiResponse,
      CreateWaterFlowCurveXlsxApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves-xlsx/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    calculateWaterFlowCoefficients: build.mutation<
      CalculateWaterFlowCoefficientsApiResponse,
      CalculateWaterFlowCoefficientsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-coefficients-calculation`,
        method: "PUT",
        params: {
          id: queryArg.id,
          year: queryArg.year,
          curveId: queryArg.curveId,
          periodId: queryArg.periodId,
        },
      }),
    }),
    getWaterFlowChannelById: build.query<
      GetWaterFlowChannelByIdApiResponse,
      GetWaterFlowChannelByIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow-channels/${queryArg.id}` }),
    }),
    updateWaterFlowChannel: build.mutation<
      UpdateWaterFlowChannelApiResponse,
      UpdateWaterFlowChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channels/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowChannelEntity,
      }),
    }),
    deleteWaterFlowChannelById: build.mutation<
      DeleteWaterFlowChannelByIdApiResponse,
      DeleteWaterFlowChannelByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channels/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getWaterFlowChannelMeasurementById: build.query<
      GetWaterFlowChannelMeasurementByIdApiResponse,
      GetWaterFlowChannelMeasurementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channel-measurements/${queryArg.id}`,
      }),
    }),
    updateWaterFlowChannelMeasurement: build.mutation<
      UpdateWaterFlowChannelMeasurementApiResponse,
      UpdateWaterFlowChannelMeasurementApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channel-measurements/${queryArg.id}`,
        method: "PUT",
        body: queryArg.waterFlowChannelMeasurementEntity,
      }),
    }),
    deleteWaterFlowChannelMeasurementById: build.mutation<
      DeleteWaterFlowChannelMeasurementByIdApiResponse,
      DeleteWaterFlowChannelMeasurementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channel-measurements/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocWaterFlowCurveTypeById: build.query<
      GetVocWaterFlowCurveTypeByIdApiResponse,
      GetVocWaterFlowCurveTypeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-curve-types/${queryArg.id}`,
      }),
    }),
    updateVocWaterFlowCurveType: build.mutation<
      UpdateVocWaterFlowCurveTypeApiResponse,
      UpdateVocWaterFlowCurveTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-curve-types/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocWaterFlowCurveType,
      }),
    }),
    deleteVocWaterFlowCurveTypeById: build.mutation<
      DeleteVocWaterFlowCurveTypeByIdApiResponse,
      DeleteVocWaterFlowCurveTypeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-curve-types/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocWaterFlowCalculationMethodById: build.query<
      GetVocWaterFlowCalculationMethodByIdApiResponse,
      GetVocWaterFlowCalculationMethodByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-calculation-methods/${queryArg.id}`,
      }),
    }),
    updateVocWaterFlowCalculationMethod: build.mutation<
      UpdateVocWaterFlowCalculationMethodApiResponse,
      UpdateVocWaterFlowCalculationMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-calculation-methods/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocWaterFlowCalculationMethod,
      }),
    }),
    deleteVocWaterFlowCalculationMethodById: build.mutation<
      DeleteVocWaterFlowCalculationMethodByIdApiResponse,
      DeleteVocWaterFlowCalculationMethodByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-calculation-methods/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocRegionsByCode: build.query<
      GetVocRegionsByCodeApiResponse,
      GetVocRegionsByCodeApiArg
    >({
      query: (queryArg) => ({ url: `/voc-regions/${queryArg.code}` }),
    }),
    updateVocRegion: build.mutation<
      UpdateVocRegionApiResponse,
      UpdateVocRegionApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-regions/${queryArg.code}`,
        method: "PUT",
        body: queryArg.vocRegion,
      }),
    }),
    deleteVocRegionsByCode: build.mutation<
      DeleteVocRegionsByCodeApiResponse,
      DeleteVocRegionsByCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-regions/${queryArg.code}`,
        method: "DELETE",
      }),
    }),
    getVocListsById: build.query<
      GetVocListsByIdApiResponse,
      GetVocListsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/voc-lists/${queryArg.id}` }),
    }),
    updateVocLists: build.mutation<
      UpdateVocListsApiResponse,
      UpdateVocListsApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lists/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocListsEntity,
      }),
    }),
    deleteVocListsById: build.mutation<
      DeleteVocListsByIdApiResponse,
      DeleteVocListsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lists/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocLakeWaterAreaPostById: build.query<
      GetVocLakeWaterAreaPostByIdApiResponse,
      GetVocLakeWaterAreaPostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-water-area-posts/${queryArg.id}`,
      }),
    }),
    updateVocLakeWaterAreaPost: build.mutation<
      UpdateVocLakeWaterAreaPostApiResponse,
      UpdateVocLakeWaterAreaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-water-area-posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocLakeWaterAreaPost,
      }),
    }),
    deleteVocLakeWaterAreaPostById: build.mutation<
      DeleteVocLakeWaterAreaPostByIdApiResponse,
      DeleteVocLakeWaterAreaPostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-water-area-posts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocLakeTypeById: build.query<
      GetVocLakeTypeByIdApiResponse,
      GetVocLakeTypeByIdApiArg
    >({
      query: (queryArg) => ({ url: `/voc-lake-types/${queryArg.id}` }),
    }),
    updateVocLakeType: build.mutation<
      UpdateVocLakeTypeApiResponse,
      UpdateVocLakeTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-types/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocLakeType,
      }),
    }),
    deleteVocLakeTypeById: build.mutation<
      DeleteVocLakeTypeByIdApiResponse,
      DeleteVocLakeTypeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-types/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getVocLakeRegulationNatureById: build.query<
      GetVocLakeRegulationNatureByIdApiResponse,
      GetVocLakeRegulationNatureByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-regulation-natures/${queryArg.id}`,
      }),
    }),
    updateVocLakeRegulationNature: build.mutation<
      UpdateVocLakeRegulationNatureApiResponse,
      UpdateVocLakeRegulationNatureApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-regulation-natures/${queryArg.id}`,
        method: "PUT",
        body: queryArg.vocLakeRegulationNature,
      }),
    }),
    deleteVocLakeRegulationNatureById: build.mutation<
      DeleteVocLakeRegulationNatureByIdApiResponse,
      DeleteVocLakeRegulationNatureByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-regulation-natures/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getStationsAll: build.query<
      GetStationsAllApiResponse,
      GetStationsAllApiArg
    >({
      query: () => ({ url: `/stations` }),
    }),
    updateStation: build.mutation<
      UpdateStationApiResponse,
      UpdateStationApiArg
    >({
      query: (queryArg) => ({
        url: `/stations`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getStationYearById: build.query<
      GetStationYearByIdApiResponse,
      GetStationYearByIdApiArg
    >({
      query: (queryArg) => ({ url: `/station-years/${queryArg.id}` }),
    }),
    updateStationYear: build.mutation<
      UpdateStationYearApiResponse,
      UpdateStationYearApiArg
    >({
      query: (queryArg) => ({
        url: `/station-years/${queryArg.id}`,
        method: "PUT",
        body: queryArg.stationYearEntity,
      }),
    }),
    deleteStationYearById: build.mutation<
      DeleteStationYearByIdApiResponse,
      DeleteStationYearByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/station-years/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getStationYearParametersByYearAndStationId: build.query<
      GetStationYearParametersByYearAndStationIdApiResponse,
      GetStationYearParametersByYearAndStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    updateStationYearParameters: build.mutation<
      UpdateStationYearParametersApiResponse,
      UpdateStationYearParametersApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getStationYearParameterById: build.query<
      GetStationYearParameterByIdApiResponse,
      GetStationYearParameterByIdApiArg
    >({
      query: (queryArg) => ({ url: `/station-year-parameters/${queryArg.id}` }),
    }),
    updateStationYearParameter: build.mutation<
      UpdateStationYearParameterApiResponse,
      UpdateStationYearParameterApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/${queryArg.id}`,
        method: "PUT",
        body: queryArg.stationYearParametersEntity,
      }),
    }),
    deleteStationYearParameterById: build.mutation<
      DeleteStationYearParameterByIdApiResponse,
      DeleteStationYearParameterByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getInhomogeneityLevelDateByIdAndDate: build.query<
      GetInhomogeneityLevelDateByIdAndDateApiResponse,
      GetInhomogeneityLevelDateByIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/inhomogeneity-level-date/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    updateInhomogeneityLevelDateByIdAndDate: build.mutation<
      UpdateInhomogeneityLevelDateByIdAndDateApiResponse,
      UpdateInhomogeneityLevelDateByIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters/inhomogeneity-level-date/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.stationYearParametersDto,
      }),
    }),
    getSiltChargeById: build.query<
      GetSiltChargeByIdApiResponse,
      GetSiltChargeByIdApiArg
    >({
      query: (queryArg) => ({ url: `/silt-charge/${queryArg.id}` }),
    }),
    updateSiltCharge: build.mutation<
      UpdateSiltChargeApiResponse,
      UpdateSiltChargeApiArg
    >({
      query: (queryArg) => ({
        url: `/silt-charge/${queryArg.id}`,
        method: "PUT",
        body: queryArg.siltChargeEntity,
      }),
    }),
    deleteSiltChargeById: build.mutation<
      DeleteSiltChargeByIdApiResponse,
      DeleteSiltChargeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/silt-charge/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSiltChargeByStationIdAndDate: build.query<
      GetSiltChargeByStationIdAndDateApiResponse,
      GetSiltChargeByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/silt-charge/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    updateFormSiltCharges: build.mutation<
      UpdateFormSiltChargesApiResponse,
      UpdateFormSiltChargesApiArg
    >({
      query: (queryArg) => ({
        url: `/silt-charge/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getSeasonsWaterManagementById: build.query<
      GetSeasonsWaterManagementByIdApiResponse,
      GetSeasonsWaterManagementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements/${queryArg.id}`,
      }),
    }),
    updateSeasonsWaterManagement: build.mutation<
      UpdateSeasonsWaterManagementApiResponse,
      UpdateSeasonsWaterManagementApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements/${queryArg.id}`,
        method: "PUT",
        body: queryArg.seasonsWaterManagementEntity,
      }),
    }),
    deleteSeasonsWaterManagementById: build.mutation<
      DeleteSeasonsWaterManagementByIdApiResponse,
      DeleteSeasonsWaterManagementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSeasonsWaterManagementsByStationId: build.query<
      GetSeasonsWaterManagementsByStationIdApiResponse,
      GetSeasonsWaterManagementsByStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements/stations/${queryArg.id}`,
      }),
    }),
    updateSeasonsWaterManagements: build.mutation<
      UpdateSeasonsWaterManagementsApiResponse,
      UpdateSeasonsWaterManagementsApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLengthwiseSlopeById: build.query<
      GetLengthwiseSlopeByIdApiResponse,
      GetLengthwiseSlopeByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lengthwise-slope/${queryArg.id}` }),
    }),
    updateLengthwiseSlope: build.mutation<
      UpdateLengthwiseSlopeApiResponse,
      UpdateLengthwiseSlopeApiArg
    >({
      query: (queryArg) => ({
        url: `/lengthwise-slope/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lengthwiseSlopeEntity,
      }),
    }),
    deleteLengthwiseSlopeById: build.mutation<
      DeleteLengthwiseSlopeByIdApiResponse,
      DeleteLengthwiseSlopeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lengthwise-slope/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLengthwiseSlopeByStationIdAndDate: build.query<
      GetLengthwiseSlopeByStationIdAndDateApiResponse,
      GetLengthwiseSlopeByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lengthwise-slope/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    updateFormLengthwiseSlope: build.mutation<
      UpdateFormLengthwiseSlopeApiResponse,
      UpdateFormLengthwiseSlopeApiArg
    >({
      query: (queryArg) => ({
        url: `/lengthwise-slope/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakeWaveObservationById: build.query<
      GetLakeWaveObservationByIdApiResponse,
      GetLakeWaveObservationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-wave-observations/${queryArg.id}` }),
    }),
    updateLakeWaveObservation: build.mutation<
      UpdateLakeWaveObservationApiResponse,
      UpdateLakeWaveObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-wave-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeWaveObservation,
      }),
    }),
    deleteLakeWaveObservationById: build.mutation<
      DeleteLakeWaveObservationByIdApiResponse,
      DeleteLakeWaveObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-wave-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getWaveBasicObservationsByPostIdAndDate: build.query<
      GetWaveBasicObservationsByPostIdAndDateApiResponse,
      GetWaveBasicObservationsByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-wave-observations/${queryArg.date}/posts/${queryArg.id}`,
      }),
    }),
    updateFormLakeWaveObservations: build.mutation<
      UpdateFormLakeWaveObservationsApiResponse,
      UpdateFormLakeWaveObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-wave-observations/${queryArg.date}/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakeWaterTemperatureObservationById: build.query<
      GetLakeWaterTemperatureObservationByIdApiResponse,
      GetLakeWaterTemperatureObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-temperature-observations/${queryArg.id}`,
      }),
    }),
    updateLakeWaterTemperatureObservation: build.mutation<
      UpdateLakeWaterTemperatureObservationApiResponse,
      UpdateLakeWaterTemperatureObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-temperature-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeWaterTemperatureObservation,
      }),
    }),
    deleteLakeWaterTemperatureObservationById: build.mutation<
      DeleteLakeWaterTemperatureObservationByIdApiResponse,
      DeleteLakeWaterTemperatureObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-temperature-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeWaterAreaPostById: build.query<
      GetLakeWaterAreaPostByIdApiResponse,
      GetLakeWaterAreaPostByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-water-area-posts/${queryArg.id}` }),
    }),
    updateLakeWaterAreaPost: build.mutation<
      UpdateLakeWaterAreaPostApiResponse,
      UpdateLakeWaterAreaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-area-posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeWaterAreaPost,
      }),
    }),
    deleteLakeWaterAreaPostById: build.mutation<
      DeleteLakeWaterAreaPostByIdApiResponse,
      DeleteLakeWaterAreaPostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-area-posts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeSeasonsWaterManagementById: build.query<
      GetLakeSeasonsWaterManagementByIdApiResponse,
      GetLakeSeasonsWaterManagementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements/${queryArg.id}`,
      }),
    }),
    updateLakeSeasonsWaterManagement: build.mutation<
      UpdateLakeSeasonsWaterManagementApiResponse,
      UpdateLakeSeasonsWaterManagementApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeSeasonsWaterManagement,
      }),
    }),
    deleteLakeSeasonsWaterManagementById: build.mutation<
      DeleteLakeSeasonsWaterManagementByIdApiResponse,
      DeleteLakeSeasonsWaterManagementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeSeasonsWaterManagementsByPostId: build.query<
      GetLakeSeasonsWaterManagementsByPostIdApiResponse,
      GetLakeSeasonsWaterManagementsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements/posts/${queryArg.id}`,
      }),
    }),
    updateLakeSeasonsWaterManagements: build.mutation<
      UpdateLakeSeasonsWaterManagementsApiResponse,
      UpdateLakeSeasonsWaterManagementsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakePostsAll: build.query<
      GetLakePostsAllApiResponse,
      GetLakePostsAllApiArg
    >({
      query: () => ({ url: `/lake-posts` }),
    }),
    updateLakePost: build.mutation<
      UpdateLakePostApiResponse,
      UpdateLakePostApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-posts`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakePostYearById: build.query<
      GetLakePostYearByIdApiResponse,
      GetLakePostYearByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-post-years/${queryArg.id}` }),
    }),
    updateLakePostYear: build.mutation<
      UpdateLakePostYearApiResponse,
      UpdateLakePostYearApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-years/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakePostYear,
      }),
    }),
    deleteLakePostYearById: build.mutation<
      DeleteLakePostYearByIdApiResponse,
      DeleteLakePostYearByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-years/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakePostYearParameterById: build.query<
      GetLakePostYearParameterByIdApiResponse,
      GetLakePostYearParameterByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/${queryArg.id}`,
      }),
    }),
    updateLakePostYearParameter: build.mutation<
      UpdateLakePostYearParameterApiResponse,
      UpdateLakePostYearParameterApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakePostYearParameter,
      }),
    }),
    deleteLakePostYearParameterById: build.mutation<
      DeleteLakePostYearParameterByIdApiResponse,
      DeleteLakePostYearParameterByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeInhomogeneityLevelDateByPostIdAndDate: build.query<
      GetLakeInhomogeneityLevelDateByPostIdAndDateApiResponse,
      GetLakeInhomogeneityLevelDateByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/inhomogeneity-level-date/${queryArg.date}/posts/${queryArg.id}`,
      }),
    }),
    updateLakeInhomogeneityLevelDateByPostIdAndDate: build.mutation<
      UpdateLakeInhomogeneityLevelDateByPostIdAndDateApiResponse,
      UpdateLakeInhomogeneityLevelDateByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/inhomogeneity-level-date/${queryArg.date}/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.stationYearParametersDto,
      }),
    }),
    getLakePassportById: build.query<
      GetLakePassportByIdApiResponse,
      GetLakePassportByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-passports/${queryArg.id}` }),
    }),
    updateLakePassport: build.mutation<
      UpdateLakePassportApiResponse,
      UpdateLakePassportApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-passports/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakePassport,
      }),
    }),
    deleteLakePassportById: build.mutation<
      DeleteLakePassportByIdApiResponse,
      DeleteLakePassportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-passports/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeObservationPointById: build.query<
      GetLakeObservationPointByIdApiResponse,
      GetLakeObservationPointByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-observation-points/${queryArg.id}` }),
    }),
    updateLakeObservationPoint: build.mutation<
      UpdateLakeObservationPointApiResponse,
      UpdateLakeObservationPointApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-observation-points/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeObservationPoint,
      }),
    }),
    deleteLakeObservationPointById: build.mutation<
      DeleteLakeObservationPointByIdApiResponse,
      DeleteLakeObservationPointByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-observation-points/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeObservationPointsByPostId: build.query<
      GetLakeObservationPointsByPostIdApiResponse,
      GetLakeObservationPointsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-observation-points/posts/${queryArg.id}`,
      }),
    }),
    updateLakeObservationPoints: build.mutation<
      UpdateLakeObservationPointsApiResponse,
      UpdateLakeObservationPointsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-observation-points/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakeMinMaxWaveObservationById: build.query<
      GetLakeMinMaxWaveObservationByIdApiResponse,
      GetLakeMinMaxWaveObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-min-max-wave-observations/${queryArg.id}`,
      }),
    }),
    updateLakeMinMaxWaveObservation: build.mutation<
      UpdateLakeMinMaxWaveObservationApiResponse,
      UpdateLakeMinMaxWaveObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-min-max-wave-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeMinMaxWaveObservation,
      }),
    }),
    deleteLakeMinMaxWaveObservationById: build.mutation<
      DeleteLakeMinMaxWaveObservationByIdApiResponse,
      DeleteLakeMinMaxWaveObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-min-max-wave-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeIceProfilesObservationById: build.query<
      GetLakeIceProfilesObservationByIdApiResponse,
      GetLakeIceProfilesObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-profiles-observations/${queryArg.id}`,
      }),
    }),
    updateLakeIceProfilesObservation: build.mutation<
      UpdateLakeIceProfilesObservationApiResponse,
      UpdateLakeIceProfilesObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-profiles-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeIceProfilesObservation,
      }),
    }),
    deleteLakeIceProfilesObservationById: build.mutation<
      DeleteLakeIceProfilesObservationByIdApiResponse,
      DeleteLakeIceProfilesObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-profiles-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeIceObservationById: build.query<
      GetLakeIceObservationByIdApiResponse,
      GetLakeIceObservationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-ice-observations/${queryArg.id}` }),
    }),
    updateLakeIceObservations: build.mutation<
      UpdateLakeIceObservationsApiResponse,
      UpdateLakeIceObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeIceObservation,
      }),
    }),
    deleteLakeIceObservationById: build.mutation<
      DeleteLakeIceObservationByIdApiResponse,
      DeleteLakeIceObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeIceObservationsByPostIdAndDate: build.query<
      GetLakeIceObservationsByPostIdAndDateApiResponse,
      GetLakeIceObservationsByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations/${queryArg.date}/posts/${queryArg.id}`,
        params: { interval: queryArg.interval },
      }),
    }),
    updateFormLakeIceObservations: build.mutation<
      UpdateFormLakeIceObservationsApiResponse,
      UpdateFormLakeIceObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations/${queryArg.date}/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakeHydrologicalObservationById: build.query<
      GetLakeHydrologicalObservationByIdApiResponse,
      GetLakeHydrologicalObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-hydrological-observations/${queryArg.id}`,
      }),
    }),
    updateLakeHydrologicalObservation: build.mutation<
      UpdateLakeHydrologicalObservationApiResponse,
      UpdateLakeHydrologicalObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-hydrological-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeHydrologicalObservation,
      }),
    }),
    deleteLakeHydrologicalObservationById: build.mutation<
      DeleteLakeHydrologicalObservationByIdApiResponse,
      DeleteLakeHydrologicalObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-hydrological-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeFormDescriptionsById: build.query<
      GetLakeFormDescriptionsByIdApiResponse,
      GetLakeFormDescriptionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-form-descriptions/${queryArg.id}` }),
    }),
    updateLakeFormDescriptions: build.mutation<
      UpdateLakeFormDescriptionsApiResponse,
      UpdateLakeFormDescriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-form-descriptions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeFormDescription,
      }),
    }),
    deleteLakeFormDescriptionsById: build.mutation<
      DeleteLakeFormDescriptionsByIdApiResponse,
      DeleteLakeFormDescriptionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-form-descriptions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeDirectionSpeedObservationById: build.query<
      GetLakeDirectionSpeedObservationByIdApiResponse,
      GetLakeDirectionSpeedObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-direction-speed-observations/${queryArg.id}`,
      }),
    }),
    updateLakeDirectionSpeedObservation: build.mutation<
      UpdateLakeDirectionSpeedObservationApiResponse,
      UpdateLakeDirectionSpeedObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-direction-speed-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeDirectionSpeedObservation,
      }),
    }),
    deleteLakeDirectionSpeedObservationById: build.mutation<
      DeleteLakeDirectionSpeedObservationByIdApiResponse,
      DeleteLakeDirectionSpeedObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-direction-speed-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeConfinesById: build.query<
      GetLakeConfinesByIdApiResponse,
      GetLakeConfinesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-confines/${queryArg.id}` }),
    }),
    updateLakeConfines: build.mutation<
      UpdateLakeConfinesApiResponse,
      UpdateLakeConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-confines/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeConfines,
      }),
    }),
    deleteLakeConfinesById: build.mutation<
      DeleteLakeConfinesByIdApiResponse,
      DeleteLakeConfinesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-confines/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeConfinesByPostId: build.query<
      GetLakeConfinesByPostIdApiResponse,
      GetLakeConfinesByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-confines/posts/${queryArg.id}`,
        params: { showSeasons: queryArg.showSeasons },
      }),
    }),
    updateFormLakeConfines: build.mutation<
      UpdateFormLakeConfinesApiResponse,
      UpdateFormLakeConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-confines/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getLakeCalculatedDatumById: build.query<
      GetLakeCalculatedDatumByIdApiResponse,
      GetLakeCalculatedDatumByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-calculated-data/${queryArg.id}` }),
    }),
    updateLakeCalculatedDatum: build.mutation<
      UpdateLakeCalculatedDatumApiResponse,
      UpdateLakeCalculatedDatumApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeCalculatedDatum,
      }),
    }),
    deleteLakeCalculatedDatumById: build.mutation<
      DeleteLakeCalculatedDatumByIdApiResponse,
      DeleteLakeCalculatedDatumByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeKsvoMonthByPostIdAndDate: build.query<
      GetLakeKsvoMonthByPostIdAndDateApiResponse,
      GetLakeKsvoMonthByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/ksvo-month/${queryArg.date}/posts/${queryArg.id}`,
      }),
    }),
    updateLakeKsvoMonth: build.mutation<
      UpdateLakeKsvoMonthApiResponse,
      UpdateLakeKsvoMonthApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/ksvo-month/${queryArg.date}/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.calculatedDataDto,
      }),
    }),
    getLakeCalculatedDataYearById: build.query<
      GetLakeCalculatedDataYearByIdApiResponse,
      GetLakeCalculatedDataYearByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data-years/${queryArg.id}`,
      }),
    }),
    updateLakeCalculatedDataYear: build.mutation<
      UpdateLakeCalculatedDataYearApiResponse,
      UpdateLakeCalculatedDataYearApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data-years/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeCalculatedDataYear,
      }),
    }),
    deleteLakeCalculatedDataYearById: build.mutation<
      DeleteLakeCalculatedDataYearByIdApiResponse,
      DeleteLakeCalculatedDataYearByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data-years/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeBookPostById: build.query<
      GetLakeBookPostByIdApiResponse,
      GetLakeBookPostByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-book-posts/${queryArg.id}` }),
    }),
    updateLakeBookPost: build.mutation<
      UpdateLakeBookPostApiResponse,
      UpdateLakeBookPostApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeBookPost,
      }),
    }),
    deleteLakeBookPostById: build.mutation<
      DeleteLakeBookPostByIdApiResponse,
      DeleteLakeBookPostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-posts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeBookLogById: build.query<
      GetLakeBookLogByIdApiResponse,
      GetLakeBookLogByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-book-log/${queryArg.id}` }),
    }),
    updateLakeBookLog: build.mutation<
      UpdateLakeBookLogApiResponse,
      UpdateLakeBookLogApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-log/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeBookLog,
      }),
    }),
    deleteLakeBookLogById: build.mutation<
      DeleteLakeBookLogByIdApiResponse,
      DeleteLakeBookLogByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-log/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeBasicObservationById: build.query<
      GetLakeBasicObservationByIdApiResponse,
      GetLakeBasicObservationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-basic-observations/${queryArg.id}` }),
    }),
    updateLakeBasicObservation: build.mutation<
      UpdateLakeBasicObservationApiResponse,
      UpdateLakeBasicObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-basic-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.lakeBasicObservation,
      }),
    }),
    deleteLakeBasicObservationById: build.mutation<
      DeleteLakeBasicObservationByIdApiResponse,
      DeleteLakeBasicObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-basic-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakeBasicObservationsByPostIdAndDate: build.query<
      GetLakeBasicObservationsByPostIdAndDateApiResponse,
      GetLakeBasicObservationsByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-basic-observations/${queryArg.date}/posts/${queryArg.id}`,
        params: { time: queryArg.time },
      }),
    }),
    updateFormLakeBasicObservations: build.mutation<
      UpdateFormLakeBasicObservationsApiResponse,
      UpdateFormLakeBasicObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-basic-observations/${queryArg.date}/posts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getKsvoRegimeById: build.query<
      GetKsvoRegimeByIdApiResponse,
      GetKsvoRegimeByIdApiArg
    >({
      query: (queryArg) => ({ url: `/ksvo-regimes/${queryArg.id}` }),
    }),
    updateKsvoRegime: build.mutation<
      UpdateKsvoRegimeApiResponse,
      UpdateKsvoRegimeApiArg
    >({
      query: (queryArg) => ({
        url: `/ksvo-regimes/${queryArg.id}`,
        method: "PUT",
        body: queryArg.ksvoRegimeEntity,
      }),
    }),
    deleteKsvoRegimeById: build.mutation<
      DeleteKsvoRegimeByIdApiResponse,
      DeleteKsvoRegimeByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/ksvo-regimes/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getIceMeasurementById: build.query<
      GetIceMeasurementByIdApiResponse,
      GetIceMeasurementByIdApiArg
    >({
      query: (queryArg) => ({ url: `/ice-measurements/${queryArg.id}` }),
    }),
    updateIceMeasurement: build.mutation<
      UpdateIceMeasurementApiResponse,
      UpdateIceMeasurementApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements/${queryArg.id}`,
        method: "PUT",
        body: queryArg.iceMeasurementEntity,
      }),
    }),
    deleteIceMeasurementById: build.mutation<
      DeleteIceMeasurementByIdApiResponse,
      DeleteIceMeasurementByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getIceMeasurementByStationIdAndDate: build.query<
      GetIceMeasurementByStationIdAndDateApiResponse,
      GetIceMeasurementByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements/${queryArg.date}/stations/${queryArg.id}`,
        params: { interval: queryArg.interval },
      }),
    }),
    updateFormIceMeasurements: build.mutation<
      UpdateFormIceMeasurementsApiResponse,
      UpdateFormIceMeasurementsApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getGranulometryById: build.query<
      GetGranulometryByIdApiResponse,
      GetGranulometryByIdApiArg
    >({
      query: (queryArg) => ({ url: `/granulometry/${queryArg.id}` }),
    }),
    updateGranulometry: build.mutation<
      UpdateGranulometryApiResponse,
      UpdateGranulometryApiArg
    >({
      query: (queryArg) => ({
        url: `/granulometry/${queryArg.id}`,
        method: "PUT",
        body: queryArg.granulometryEntity,
      }),
    }),
    deleteGranulometryById: build.mutation<
      DeleteGranulometryByIdApiResponse,
      DeleteGranulometryByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/granulometry/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getGranulometryByStationIdAndDateAndTag: build.query<
      GetGranulometryByStationIdAndDateAndTagApiResponse,
      GetGranulometryByStationIdAndDateAndTagApiArg
    >({
      query: (queryArg) => ({
        url: `/granulometry/${queryArg.date}/stations/${queryArg.id}`,
        params: { tag: queryArg.tag },
      }),
    }),
    updateFormGranulometry: build.mutation<
      UpdateFormGranulometryApiResponse,
      UpdateFormGranulometryApiArg
    >({
      query: (queryArg) => ({
        url: `/granulometry/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
        params: { tag: queryArg.tag },
      }),
    }),
    getFormDescriptionsById: build.query<
      GetFormDescriptionsByIdApiResponse,
      GetFormDescriptionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/form-descriptions/${queryArg.id}` }),
    }),
    updateFormDescriptions: build.mutation<
      UpdateFormDescriptionsApiResponse,
      UpdateFormDescriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/form-descriptions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.formDescriptionsEntity,
      }),
    }),
    deleteFormDescriptionsById: build.mutation<
      DeleteFormDescriptionsByIdApiResponse,
      DeleteFormDescriptionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/form-descriptions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getFileStorageById: build.query<
      GetFileStorageByIdApiResponse,
      GetFileStorageByIdApiArg
    >({
      query: (queryArg) => ({ url: `/file-storage/${queryArg.id}` }),
    }),
    updateFileStorage: build.mutation<
      UpdateFileStorageApiResponse,
      UpdateFileStorageApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage/${queryArg.id}`,
        method: "PUT",
        body: queryArg.fileStorageEntity,
      }),
    }),
    deleteFileStorageById: build.mutation<
      DeleteFileStorageByIdApiResponse,
      DeleteFileStorageByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getErrorTextByCode: build.query<
      GetErrorTextByCodeApiResponse,
      GetErrorTextByCodeApiArg
    >({
      query: (queryArg) => ({ url: `/error-text/${queryArg.code}` }),
    }),
    updateErrorText: build.mutation<
      UpdateErrorTextApiResponse,
      UpdateErrorTextApiArg
    >({
      query: (queryArg) => ({
        url: `/error-text/${queryArg.code}`,
        method: "PUT",
        body: queryArg.errorTextEntity,
      }),
    }),
    deleteErrorTextById: build.mutation<
      DeleteErrorTextByIdApiResponse,
      DeleteErrorTextByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/error-text/${queryArg.code}`,
        method: "DELETE",
      }),
    }),
    updateDailyAvgWaterFlowRate: build.mutation<
      UpdateDailyAvgWaterFlowRateApiResponse,
      UpdateDailyAvgWaterFlowRateApiArg
    >({
      query: (queryArg) => ({
        url: `/daily-water-flow/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    calculateWaterFlowByCurve: build.mutation<
      CalculateWaterFlowByCurveApiResponse,
      CalculateWaterFlowByCurveApiArg
    >({
      query: (queryArg) => ({
        url: `/daily-water-flow-calculation-by-curve`,
        method: "PUT",
        params: {
          id: queryArg.id,
          year: queryArg.year,
          curveId: queryArg.curveId,
          periodId: queryArg.periodId,
          useStoutCorrect: queryArg.useStoutCorrect,
        },
      }),
    }),
    getConfinesById: build.query<
      GetConfinesByIdApiResponse,
      GetConfinesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/confines/${queryArg.id}` }),
    }),
    updateConfines: build.mutation<
      UpdateConfinesApiResponse,
      UpdateConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/confines/${queryArg.id}`,
        method: "PUT",
        body: queryArg.confinesEntity,
      }),
    }),
    deleteConfinesById: build.mutation<
      DeleteConfinesByIdApiResponse,
      DeleteConfinesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/confines/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getConfinesByStationId: build.query<
      GetConfinesByStationIdApiResponse,
      GetConfinesByStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/confines/stations/${queryArg.id}`,
        params: { showSeasons: queryArg.showSeasons },
      }),
    }),
    updateFormConfines: build.mutation<
      UpdateFormConfinesApiResponse,
      UpdateFormConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/confines/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getConfinesLogById: build.query<
      GetConfinesLogByIdApiResponse,
      GetConfinesLogByIdApiArg
    >({
      query: (queryArg) => ({ url: `/confines-log/${queryArg.id}` }),
    }),
    updateConfinesLog: build.mutation<
      UpdateConfinesLogApiResponse,
      UpdateConfinesLogApiArg
    >({
      query: (queryArg) => ({
        url: `/confines-log/${queryArg.id}`,
        method: "PUT",
        body: queryArg.confinesLogEntity,
      }),
    }),
    deleteConfinesLogById: build.mutation<
      DeleteConfinesLogByIdApiResponse,
      DeleteConfinesLogByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/confines-log/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getCalculatedDataById: build.query<
      GetCalculatedDataByIdApiResponse,
      GetCalculatedDataByIdApiArg
    >({
      query: (queryArg) => ({ url: `/calculated-data/${queryArg.id}` }),
    }),
    updateCalculatedData: build.mutation<
      UpdateCalculatedDataApiResponse,
      UpdateCalculatedDataApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/${queryArg.id}`,
        method: "PUT",
        body: queryArg.calculatedDataEntity,
      }),
    }),
    deleteCalculatedDataById: build.mutation<
      DeleteCalculatedDataByIdApiResponse,
      DeleteCalculatedDataByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getKsvoMonthByStationIdAndDate: build.query<
      GetKsvoMonthByStationIdAndDateApiResponse,
      GetKsvoMonthByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/ksvo-month/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    updateKsvoMonth: build.mutation<
      UpdateKsvoMonthApiResponse,
      UpdateKsvoMonthApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/ksvo-month/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.calculatedDataDto,
      }),
    }),
    getCalculatedDataYearById: build.query<
      GetCalculatedDataYearByIdApiResponse,
      GetCalculatedDataYearByIdApiArg
    >({
      query: (queryArg) => ({ url: `/calculated-data-years/${queryArg.id}` }),
    }),
    updateCalculatedDataYear: build.mutation<
      UpdateCalculatedDataYearApiResponse,
      UpdateCalculatedDataYearApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years/${queryArg.id}`,
        method: "PUT",
        body: queryArg.calculatedDataYearEntity,
      }),
    }),
    deleteCalculatedDataYearById: build.mutation<
      DeleteCalculatedDataYearByIdApiResponse,
      DeleteCalculatedDataYearByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSedimentDischargeByStationIdAndYearAndTag: build.query<
      GetSedimentDischargeByStationIdAndYearAndTagApiResponse,
      GetSedimentDischargeByStationIdAndYearAndTagApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years/sediment-discharge/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    updateSedimentDischarge: build.mutation<
      UpdateSedimentDischargeApiResponse,
      UpdateSedimentDischargeApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years/sediment-discharge/${queryArg.year}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getBookById: build.query<GetBookByIdApiResponse, GetBookByIdApiArg>({
      query: (queryArg) => ({ url: `/books/${queryArg.id}` }),
    }),
    updateBook: build.mutation<UpdateBookApiResponse, UpdateBookApiArg>({
      query: (queryArg) => ({
        url: `/books/${queryArg.id}`,
        method: "PUT",
        body: queryArg.booksEntity,
      }),
    }),
    deleteBookById: build.mutation<
      DeleteBookByIdApiResponse,
      DeleteBookByIdApiArg
    >({
      query: (queryArg) => ({ url: `/books/${queryArg.id}`, method: "DELETE" }),
    }),
    getBookStationById: build.query<
      GetBookStationByIdApiResponse,
      GetBookStationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/books-stations/${queryArg.id}` }),
    }),
    updateBookStation: build.mutation<
      UpdateBookStationApiResponse,
      UpdateBookStationApiArg
    >({
      query: (queryArg) => ({
        url: `/books-stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.bookStationEntity,
      }),
    }),
    deleteBookStationById: build.mutation<
      DeleteBookStationByIdApiResponse,
      DeleteBookStationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/books-stations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBooksLogById: build.query<
      GetBooksLogByIdApiResponse,
      GetBooksLogByIdApiArg
    >({
      query: (queryArg) => ({ url: `/books-log/${queryArg.id}` }),
    }),
    updateBooksLog: build.mutation<
      UpdateBooksLogApiResponse,
      UpdateBooksLogApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log/${queryArg.id}`,
        method: "PUT",
        body: queryArg.booksLogEntity,
      }),
    }),
    deleteBooksLogById: build.mutation<
      DeleteBooksLogByIdApiResponse,
      DeleteBooksLogByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBookLogFileById: build.query<
      GetBookLogFileByIdApiResponse,
      GetBookLogFileByIdApiArg
    >({
      query: (queryArg) => ({ url: `/books-log-file/${queryArg.id}` }),
    }),
    updateBookLogFile: build.mutation<
      UpdateBookLogFileApiResponse,
      UpdateBookLogFileApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log-file/${queryArg.id}`,
        method: "PUT",
        body: queryArg.bookLogFileEntity,
      }),
    }),
    deleteBookLogFileById: build.mutation<
      DeleteBookLogFileByIdApiResponse,
      DeleteBookLogFileByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log-file/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBasicObservationById: build.query<
      GetBasicObservationByIdApiResponse,
      GetBasicObservationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/basic-observations/${queryArg.id}` }),
    }),
    updateBasicObservation: build.mutation<
      UpdateBasicObservationApiResponse,
      UpdateBasicObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.basicObservationsEntity,
      }),
    }),
    deleteBasicObservationById: build.mutation<
      DeleteBasicObservationByIdApiResponse,
      DeleteBasicObservationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBasicObservationsByStationIdAndDate: build.query<
      GetBasicObservationsByStationIdAndDateApiResponse,
      GetBasicObservationsByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations/${queryArg.date}/stations/${queryArg.id}`,
        params: { time: queryArg.time },
      }),
    }),
    updateFormBasicObservations: build.mutation<
      UpdateFormBasicObservationsApiResponse,
      UpdateFormBasicObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations/${queryArg.date}/stations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getWaterObjectsAll: build.query<
      GetWaterObjectsAllApiResponse,
      GetWaterObjectsAllApiArg
    >({
      query: () => ({ url: `/water-objects` }),
    }),
    createWaterObject: build.mutation<
      CreateWaterObjectApiResponse,
      CreateWaterObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/water-objects`,
        method: "POST",
        body: queryArg.waterObjectsEntity,
      }),
    }),
    getWaterFlowAll: build.query<
      GetWaterFlowAllApiResponse,
      GetWaterFlowAllApiArg
    >({
      query: () => ({ url: `/water-flow` }),
    }),
    createWaterFlow: build.mutation<
      CreateWaterFlowApiResponse,
      CreateWaterFlowApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow`,
        method: "POST",
        body: queryArg.waterFlowEntity,
      }),
    }),
    waterFlowProcess: build.mutation<
      WaterFlowProcessApiResponse,
      WaterFlowProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-processing`,
        method: "POST",
        body: queryArg.pythonInput,
      }),
    }),
    getWaterFlowPointsAll: build.query<
      GetWaterFlowPointsAllApiResponse,
      GetWaterFlowPointsAllApiArg
    >({
      query: () => ({ url: `/water-flow-points` }),
    }),
    createWaterFlowPoint: build.mutation<
      CreateWaterFlowPointApiResponse,
      CreateWaterFlowPointApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-points`,
        method: "POST",
        body: queryArg.waterFlowPointEntity,
      }),
    }),
    getWaterFlowPeriodsAll: build.query<
      GetWaterFlowPeriodsAllApiResponse,
      GetWaterFlowPeriodsAllApiArg
    >({
      query: () => ({ url: `/water-flow-periods` }),
    }),
    createWaterFlowPeriod: build.mutation<
      CreateWaterFlowPeriodApiResponse,
      CreateWaterFlowPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-periods`,
        method: "POST",
        body: queryArg.waterFlowPeriodEntity,
      }),
    }),
    getWaterFlowChannelsAll: build.query<
      GetWaterFlowChannelsAllApiResponse,
      GetWaterFlowChannelsAllApiArg
    >({
      query: () => ({ url: `/water-flow-channels` }),
    }),
    createWaterFlowChannel: build.mutation<
      CreateWaterFlowChannelApiResponse,
      CreateWaterFlowChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channels`,
        method: "POST",
        body: queryArg.waterFlowChannelEntity,
      }),
    }),
    getWaterFlowChannelMeasurementsAll: build.query<
      GetWaterFlowChannelMeasurementsAllApiResponse,
      GetWaterFlowChannelMeasurementsAllApiArg
    >({
      query: () => ({ url: `/water-flow-channel-measurements` }),
    }),
    createWaterFlowChannelMeasurement: build.mutation<
      CreateWaterFlowChannelMeasurementApiResponse,
      CreateWaterFlowChannelMeasurementApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-channel-measurements`,
        method: "POST",
        body: queryArg.waterFlowChannelMeasurementEntity,
      }),
    }),
    getVocWaterFlowCurveTypesAll: build.query<
      GetVocWaterFlowCurveTypesAllApiResponse,
      GetVocWaterFlowCurveTypesAllApiArg
    >({
      query: () => ({ url: `/voc-water-flow-curve-types` }),
    }),
    createVocWaterFlowCurveType: build.mutation<
      CreateVocWaterFlowCurveTypeApiResponse,
      CreateVocWaterFlowCurveTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-curve-types`,
        method: "POST",
        body: queryArg.vocWaterFlowCurveType,
      }),
    }),
    getVocWaterFlowCalculationMethodsAll: build.query<
      GetVocWaterFlowCalculationMethodsAllApiResponse,
      GetVocWaterFlowCalculationMethodsAllApiArg
    >({
      query: () => ({ url: `/voc-water-flow-calculation-methods` }),
    }),
    createVocWaterFlowCalculationMethod: build.mutation<
      CreateVocWaterFlowCalculationMethodApiResponse,
      CreateVocWaterFlowCalculationMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-water-flow-calculation-methods`,
        method: "POST",
        body: queryArg.vocWaterFlowCalculationMethod,
      }),
    }),
    getVocRegionsAll: build.query<
      GetVocRegionsAllApiResponse,
      GetVocRegionsAllApiArg
    >({
      query: () => ({ url: `/voc-regions` }),
    }),
    createVocRegion: build.mutation<
      CreateVocRegionApiResponse,
      CreateVocRegionApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-regions`,
        method: "POST",
        body: queryArg.vocRegion,
      }),
    }),
    getVocListsAll: build.query<
      GetVocListsAllApiResponse,
      GetVocListsAllApiArg
    >({
      query: () => ({ url: `/voc-lists` }),
    }),
    createVocLists: build.mutation<
      CreateVocListsApiResponse,
      CreateVocListsApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lists`,
        method: "POST",
        body: queryArg.vocListsEntity,
      }),
    }),
    getVocLakeWaterAreaPostsAll: build.query<
      GetVocLakeWaterAreaPostsAllApiResponse,
      GetVocLakeWaterAreaPostsAllApiArg
    >({
      query: () => ({ url: `/voc-lake-water-area-posts` }),
    }),
    createVocLakeWaterAreaPost: build.mutation<
      CreateVocLakeWaterAreaPostApiResponse,
      CreateVocLakeWaterAreaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-water-area-posts`,
        method: "POST",
        body: queryArg.vocLakeWaterAreaPost,
      }),
    }),
    getVocLakeTypesAll: build.query<
      GetVocLakeTypesAllApiResponse,
      GetVocLakeTypesAllApiArg
    >({
      query: () => ({ url: `/voc-lake-types` }),
    }),
    createVocLakeType: build.mutation<
      CreateVocLakeTypeApiResponse,
      CreateVocLakeTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-types`,
        method: "POST",
        body: queryArg.vocLakeType,
      }),
    }),
    getVocLakeRegulationNatureAll: build.query<
      GetVocLakeRegulationNatureAllApiResponse,
      GetVocLakeRegulationNatureAllApiArg
    >({
      query: () => ({ url: `/voc-lake-regulation-natures` }),
    }),
    createVocLakeRegulationNature: build.mutation<
      CreateVocLakeRegulationNatureApiResponse,
      CreateVocLakeRegulationNatureApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lake-regulation-natures`,
        method: "POST",
        body: queryArg.vocLakeRegulationNature,
      }),
    }),
    getStationYearsAll: build.query<
      GetStationYearsAllApiResponse,
      GetStationYearsAllApiArg
    >({
      query: () => ({ url: `/station-years` }),
    }),
    createStationYear: build.mutation<
      CreateStationYearApiResponse,
      CreateStationYearApiArg
    >({
      query: (queryArg) => ({
        url: `/station-years`,
        method: "POST",
        body: queryArg.stationYearEntity,
      }),
    }),
    getXml: build.mutation<GetXmlApiResponse, GetXmlApiArg>({
      query: (queryArg) => ({
        url: `/station-year`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getXmlTxt: build.mutation<GetXmlTxtApiResponse, GetXmlTxtApiArg>({
      query: (queryArg) => ({
        url: `/station-year-xml`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getStationYearParametersAll: build.query<
      GetStationYearParametersAllApiResponse,
      GetStationYearParametersAllApiArg
    >({
      query: () => ({ url: `/station-year-parameters` }),
    }),
    createStationYearParameter: build.mutation<
      CreateStationYearParameterApiResponse,
      CreateStationYearParameterApiArg
    >({
      query: (queryArg) => ({
        url: `/station-year-parameters`,
        method: "POST",
        body: queryArg.stationYearParametersEntity,
      }),
    }),
    getOgx: build.mutation<GetOgxApiResponse, GetOgxApiArg>({
      query: (queryArg) => ({
        url: `/station-year-ogx`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getOgxTxt: build.mutation<GetOgxTxtApiResponse, GetOgxTxtApiArg>({
      query: (queryArg) => ({
        url: `/station-year-ogx-xml`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getSiltChargeAll: build.query<
      GetSiltChargeAllApiResponse,
      GetSiltChargeAllApiArg
    >({
      query: () => ({ url: `/silt-charge` }),
    }),
    createSiltCharge: build.mutation<
      CreateSiltChargeApiResponse,
      CreateSiltChargeApiArg
    >({
      query: (queryArg) => ({
        url: `/silt-charge`,
        method: "POST",
        body: queryArg.siltChargeEntity,
      }),
    }),
    getSeasonsWaterManagementsAll: build.query<
      GetSeasonsWaterManagementsAllApiResponse,
      GetSeasonsWaterManagementsAllApiArg
    >({
      query: () => ({ url: `/seasons-water-managements` }),
    }),
    createSeasonsWaterManagement: build.mutation<
      CreateSeasonsWaterManagementApiResponse,
      CreateSeasonsWaterManagementApiArg
    >({
      query: (queryArg) => ({
        url: `/seasons-water-managements`,
        method: "POST",
        body: queryArg.seasonsWaterManagementEntity,
      }),
    }),
    monthlyProcess: build.mutation<
      MonthlyProcessApiResponse,
      MonthlyProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/monthly-processing`,
        method: "POST",
        body: queryArg.pythonInput,
      }),
    }),
    exportMds: build.mutation<ExportMdsApiResponse, ExportMdsApiArg>({
      query: (queryArg) => ({
        url: `/mds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    getLengthwiseSlopeAll: build.query<
      GetLengthwiseSlopeAllApiResponse,
      GetLengthwiseSlopeAllApiArg
    >({
      query: () => ({ url: `/lengthwise-slope` }),
    }),
    createLengthwiseSlope: build.mutation<
      CreateLengthwiseSlopeApiResponse,
      CreateLengthwiseSlopeApiArg
    >({
      query: (queryArg) => ({
        url: `/lengthwise-slope`,
        method: "POST",
        body: queryArg.lengthwiseSlopeEntity,
      }),
    }),
    getLakeXml: build.mutation<GetLakeXmlApiResponse, GetLakeXmlApiArg>({
      query: (queryArg) => ({
        url: `/lakepost-year`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getLakeXmlTxt: build.mutation<
      GetLakeXmlTxtApiResponse,
      GetLakeXmlTxtApiArg
    >({
      query: (queryArg) => ({
        url: `/lakepost-year-xml`,
        method: "POST",
        body: queryArg.stationInput,
      }),
    }),
    getLakeWaveObservationsAll: build.query<
      GetLakeWaveObservationsAllApiResponse,
      GetLakeWaveObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-wave-observations` }),
    }),
    createLakeWaveObservation: build.mutation<
      CreateLakeWaveObservationApiResponse,
      CreateLakeWaveObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-wave-observations`,
        method: "POST",
        body: queryArg.lakeWaveObservation,
      }),
    }),
    getLakeWaterTemperatureObservationsAll: build.query<
      GetLakeWaterTemperatureObservationsAllApiResponse,
      GetLakeWaterTemperatureObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-water-temperature-observations` }),
    }),
    createLakeWaterTemperatureObservation: build.mutation<
      CreateLakeWaterTemperatureObservationApiResponse,
      CreateLakeWaterTemperatureObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-temperature-observations`,
        method: "POST",
        body: queryArg.lakeWaterTemperatureObservation,
      }),
    }),
    getLakeWaterAreaPostsAll: build.query<
      GetLakeWaterAreaPostsAllApiResponse,
      GetLakeWaterAreaPostsAllApiArg
    >({
      query: () => ({ url: `/lake-water-area-posts` }),
    }),
    createLakeWaterAreaPost: build.mutation<
      CreateLakeWaterAreaPostApiResponse,
      CreateLakeWaterAreaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-water-area-posts`,
        method: "POST",
        body: queryArg.lakeWaterAreaPost,
      }),
    }),
    getLakeSeasonsWaterManagementsAll: build.query<
      GetLakeSeasonsWaterManagementsAllApiResponse,
      GetLakeSeasonsWaterManagementsAllApiArg
    >({
      query: () => ({ url: `/lake-seasons-water-managements` }),
    }),
    createLakeSeasonsWaterManagement: build.mutation<
      CreateLakeSeasonsWaterManagementApiResponse,
      CreateLakeSeasonsWaterManagementApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-seasons-water-managements`,
        method: "POST",
        body: queryArg.lakeSeasonsWaterManagement,
      }),
    }),
    getLakePostYearsAll: build.query<
      GetLakePostYearsAllApiResponse,
      GetLakePostYearsAllApiArg
    >({
      query: () => ({ url: `/lake-post-years` }),
    }),
    createLakePostYear: build.mutation<
      CreateLakePostYearApiResponse,
      CreateLakePostYearApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-years`,
        method: "POST",
        body: queryArg.lakePostYear,
      }),
    }),
    getLakePostYearParametersAll: build.query<
      GetLakePostYearParametersAllApiResponse,
      GetLakePostYearParametersAllApiArg
    >({
      query: () => ({ url: `/lake-post-year-parameters` }),
    }),
    createLakePostYearParameter: build.mutation<
      CreateLakePostYearParameterApiResponse,
      CreateLakePostYearParameterApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters`,
        method: "POST",
        body: queryArg.lakePostYearParameter,
      }),
    }),
    getLakePassportsAll: build.query<
      GetLakePassportsAllApiResponse,
      GetLakePassportsAllApiArg
    >({
      query: () => ({ url: `/lake-passports` }),
    }),
    createLakePassport: build.mutation<
      CreateLakePassportApiResponse,
      CreateLakePassportApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-passports`,
        method: "POST",
        body: queryArg.lakePassport,
      }),
    }),
    getLakeObservationPointsAll: build.query<
      GetLakeObservationPointsAllApiResponse,
      GetLakeObservationPointsAllApiArg
    >({
      query: () => ({ url: `/lake-observation-points` }),
    }),
    createLakeObservationPoint: build.mutation<
      CreateLakeObservationPointApiResponse,
      CreateLakeObservationPointApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-observation-points`,
        method: "POST",
        body: queryArg.lakeObservationPoint,
      }),
    }),
    lakeMonthlyProcess: build.mutation<
      LakeMonthlyProcessApiResponse,
      LakeMonthlyProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-monthly-processing`,
        method: "POST",
        body: queryArg.pythonInput,
      }),
    }),
    getLakeMinMaxWaveObservationsAll: build.query<
      GetLakeMinMaxWaveObservationsAllApiResponse,
      GetLakeMinMaxWaveObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-min-max-wave-observations` }),
    }),
    createLakeMinMaxWaveObservation: build.mutation<
      CreateLakeMinMaxWaveObservationApiResponse,
      CreateLakeMinMaxWaveObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-min-max-wave-observations`,
        method: "POST",
        body: queryArg.lakeMinMaxWaveObservation,
      }),
    }),
    lakeExportMds: build.mutation<
      LakeExportMdsApiResponse,
      LakeExportMdsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-mds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    getLakeIceProfilesObservationsAll: build.query<
      GetLakeIceProfilesObservationsAllApiResponse,
      GetLakeIceProfilesObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-ice-profiles-observations` }),
    }),
    createLakeIceProfilesObservation: build.mutation<
      CreateLakeIceProfilesObservationApiResponse,
      CreateLakeIceProfilesObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-profiles-observations`,
        method: "POST",
        body: queryArg.lakeIceProfilesObservation,
      }),
    }),
    getLakeIceObservationsAll: build.query<
      GetLakeIceObservationsAllApiResponse,
      GetLakeIceObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-ice-observations` }),
    }),
    createLakeIceObservations: build.mutation<
      CreateLakeIceObservationsApiResponse,
      CreateLakeIceObservationsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations`,
        method: "POST",
        body: queryArg.lakeIceObservation,
      }),
    }),
    getLakeHydrologicalObservationsAll: build.query<
      GetLakeHydrologicalObservationsAllApiResponse,
      GetLakeHydrologicalObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-hydrological-observations` }),
    }),
    createLakeHydrologicalObservation: build.mutation<
      CreateLakeHydrologicalObservationApiResponse,
      CreateLakeHydrologicalObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-hydrological-observations`,
        method: "POST",
        body: queryArg.lakeHydrologicalObservation,
      }),
    }),
    getLakeFormDescriptionsAll: build.query<
      GetLakeFormDescriptionsAllApiResponse,
      GetLakeFormDescriptionsAllApiArg
    >({
      query: () => ({ url: `/lake-form-descriptions` }),
    }),
    createLakeFormDescriptions: build.mutation<
      CreateLakeFormDescriptionsApiResponse,
      CreateLakeFormDescriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-form-descriptions`,
        method: "POST",
        body: queryArg.lakeFormDescription,
      }),
    }),
    lakeExportEmds: build.mutation<
      LakeExportEmdsApiResponse,
      LakeExportEmdsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-emds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    lakeExportEds: build.mutation<
      LakeExportEdsApiResponse,
      LakeExportEdsApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-eds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    getLakeDirectionSpeedObservationsAll: build.query<
      GetLakeDirectionSpeedObservationsAllApiResponse,
      GetLakeDirectionSpeedObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-direction-speed-observations` }),
    }),
    createLakeDirectionSpeedObservation: build.mutation<
      CreateLakeDirectionSpeedObservationApiResponse,
      CreateLakeDirectionSpeedObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-direction-speed-observations`,
        method: "POST",
        body: queryArg.lakeDirectionSpeedObservation,
      }),
    }),
    getLakeConfinesAll: build.query<
      GetLakeConfinesAllApiResponse,
      GetLakeConfinesAllApiArg
    >({
      query: () => ({ url: `/lake-confines` }),
    }),
    createLakeConfines: build.mutation<
      CreateLakeConfinesApiResponse,
      CreateLakeConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-confines`,
        method: "POST",
        body: queryArg.lakeConfines,
      }),
    }),
    getLakeCalculatedDatumAll: build.query<
      GetLakeCalculatedDatumAllApiResponse,
      GetLakeCalculatedDatumAllApiArg
    >({
      query: () => ({ url: `/lake-calculated-data` }),
    }),
    createLakeCalculatedDatum: build.mutation<
      CreateLakeCalculatedDatumApiResponse,
      CreateLakeCalculatedDatumApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data`,
        method: "POST",
        body: queryArg.lakeCalculatedDatum,
      }),
    }),
    getLakeCalculatedDataYearsAll: build.query<
      GetLakeCalculatedDataYearsAllApiResponse,
      GetLakeCalculatedDataYearsAllApiArg
    >({
      query: () => ({ url: `/lake-calculated-data-years` }),
    }),
    createLakeCalculatedDataYear: build.mutation<
      CreateLakeCalculatedDataYearApiResponse,
      CreateLakeCalculatedDataYearApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data-years`,
        method: "POST",
        body: queryArg.lakeCalculatedDataYear,
      }),
    }),
    getLakeBookPostsAll: build.query<
      GetLakeBookPostsAllApiResponse,
      GetLakeBookPostsAllApiArg
    >({
      query: () => ({ url: `/lake-book-posts` }),
    }),
    createLakeBookPost: build.mutation<
      CreateLakeBookPostApiResponse,
      CreateLakeBookPostApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-posts`,
        method: "POST",
        body: queryArg.lakeBookPost,
      }),
    }),
    getLakeBookLogAll: build.query<
      GetLakeBookLogAllApiResponse,
      GetLakeBookLogAllApiArg
    >({
      query: () => ({ url: `/lake-book-log` }),
    }),
    createLakeBookLog: build.mutation<
      CreateLakeBookLogApiResponse,
      CreateLakeBookLogApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-log`,
        method: "POST",
        body: queryArg.lakeBookLog,
      }),
    }),
    getLakeBasicObservationsAll: build.query<
      GetLakeBasicObservationsAllApiResponse,
      GetLakeBasicObservationsAllApiArg
    >({
      query: () => ({ url: `/lake-basic-observations` }),
    }),
    createLakeBasicObservation: build.mutation<
      CreateLakeBasicObservationApiResponse,
      CreateLakeBasicObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-basic-observations`,
        method: "POST",
        body: queryArg.lakeBasicObservation,
      }),
    }),
    lakeAnnualProcess: build.mutation<
      LakeAnnualProcessApiResponse,
      LakeAnnualProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-annual-processing`,
        method: "POST",
        body: queryArg.annualInput,
      }),
    }),
    getKsvoRegimesAll: build.query<
      GetKsvoRegimesAllApiResponse,
      GetKsvoRegimesAllApiArg
    >({
      query: () => ({ url: `/ksvo-regimes` }),
    }),
    createKsvoRegime: build.mutation<
      CreateKsvoRegimeApiResponse,
      CreateKsvoRegimeApiArg
    >({
      query: (queryArg) => ({
        url: `/ksvo-regimes`,
        method: "POST",
        body: queryArg.ksvoRegimeEntity,
      }),
    }),
    importXml: build.mutation<ImportXmlApiResponse, ImportXmlApiArg>({
      query: (queryArg) => ({
        url: `/importxml`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    importTxt: build.mutation<ImportTxtApiResponse, ImportTxtApiArg>({
      query: (queryArg) => ({
        url: `/importtxt`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    importOgxXml: build.mutation<ImportOgxXmlApiResponse, ImportOgxXmlApiArg>({
      query: (queryArg) => ({
        url: `/importogxxml`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    importLakeXml: build.mutation<
      ImportLakeXmlApiResponse,
      ImportLakeXmlApiArg
    >({
      query: (queryArg) => ({
        url: `/importlakexml`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    importFolder: build.mutation<ImportFolderApiResponse, ImportFolderApiArg>({
      query: (queryArg) => ({
        url: `/importfolder`,
        method: "POST",
        body: queryArg.textInput,
      }),
    }),
    getIceMeasurementsAll: build.query<
      GetIceMeasurementsAllApiResponse,
      GetIceMeasurementsAllApiArg
    >({
      query: () => ({ url: `/ice-measurements` }),
    }),
    createIceMeasurement: build.mutation<
      CreateIceMeasurementApiResponse,
      CreateIceMeasurementApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements`,
        method: "POST",
        body: queryArg.iceMeasurementEntity,
      }),
    }),
    getGranulometryAll: build.query<
      GetGranulometryAllApiResponse,
      GetGranulometryAllApiArg
    >({
      query: () => ({ url: `/granulometry` }),
    }),
    createGranulometry: build.mutation<
      CreateGranulometryApiResponse,
      CreateGranulometryApiArg
    >({
      query: (queryArg) => ({
        url: `/granulometry`,
        method: "POST",
        body: queryArg.granulometryEntity,
      }),
    }),
    getFormDescriptionsAll: build.query<
      GetFormDescriptionsAllApiResponse,
      GetFormDescriptionsAllApiArg
    >({
      query: () => ({ url: `/form-descriptions` }),
    }),
    createFormDescriptions: build.mutation<
      CreateFormDescriptionsApiResponse,
      CreateFormDescriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/form-descriptions`,
        method: "POST",
        body: queryArg.formDescriptionsEntity,
      }),
    }),
    getFileStorageAll: build.query<
      GetFileStorageAllApiResponse,
      GetFileStorageAllApiArg
    >({
      query: () => ({ url: `/file-storage` }),
    }),
    createFileStorage: build.mutation<
      CreateFileStorageApiResponse,
      CreateFileStorageApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage`,
        method: "POST",
        body: queryArg.fileStorageEntity,
      }),
    }),
    generateFiles: build.mutation<
      GenerateFilesApiResponse,
      GenerateFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage-generate`,
        method: "POST",
        body: queryArg.pythonInput,
      }),
    }),
    getErrorTextAll: build.query<
      GetErrorTextAllApiResponse,
      GetErrorTextAllApiArg
    >({
      query: () => ({ url: `/error-text` }),
    }),
    createErrorText: build.mutation<
      CreateErrorTextApiResponse,
      CreateErrorTextApiArg
    >({
      query: (queryArg) => ({
        url: `/error-text`,
        method: "POST",
        body: queryArg.errorTextEntity,
      }),
    }),
    exportEmds: build.mutation<ExportEmdsApiResponse, ExportEmdsApiArg>({
      query: (queryArg) => ({
        url: `/emds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    exportEds: build.mutation<ExportEdsApiResponse, ExportEdsApiArg>({
      query: (queryArg) => ({
        url: `/eds-export`,
        method: "POST",
        body: queryArg.bookInput,
      }),
    }),
    calculateWaterFlowByLinearInterpolation: build.mutation<
      CalculateWaterFlowByLinearInterpolationApiResponse,
      CalculateWaterFlowByLinearInterpolationApiArg
    >({
      query: (queryArg) => ({
        url: `/daily-water-flow-calculation-by-linear-interpolation`,
        method: "POST",
        body: queryArg.pythonInput,
      }),
    }),
    getConfinesAll: build.query<
      GetConfinesAllApiResponse,
      GetConfinesAllApiArg
    >({
      query: () => ({ url: `/confines` }),
    }),
    createConfines: build.mutation<
      CreateConfinesApiResponse,
      CreateConfinesApiArg
    >({
      query: (queryArg) => ({
        url: `/confines`,
        method: "POST",
        body: queryArg.confinesEntity,
      }),
    }),
    getConfinesLogAll: build.query<
      GetConfinesLogAllApiResponse,
      GetConfinesLogAllApiArg
    >({
      query: () => ({ url: `/confines-log` }),
    }),
    createConfinesLog: build.mutation<
      CreateConfinesLogApiResponse,
      CreateConfinesLogApiArg
    >({
      query: (queryArg) => ({
        url: `/confines-log`,
        method: "POST",
        body: queryArg.confinesLogEntity,
      }),
    }),
    getCalculatedDataAll: build.query<
      GetCalculatedDataAllApiResponse,
      GetCalculatedDataAllApiArg
    >({
      query: () => ({ url: `/calculated-data` }),
    }),
    createCalculatedData: build.mutation<
      CreateCalculatedDataApiResponse,
      CreateCalculatedDataApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data`,
        method: "POST",
        body: queryArg.calculatedDataEntity,
      }),
    }),
    getCalculatedDataYearsAll: build.query<
      GetCalculatedDataYearsAllApiResponse,
      GetCalculatedDataYearsAllApiArg
    >({
      query: () => ({ url: `/calculated-data-years` }),
    }),
    createCalculatedDataYear: build.mutation<
      CreateCalculatedDataYearApiResponse,
      CreateCalculatedDataYearApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years`,
        method: "POST",
        body: queryArg.calculatedDataYearEntity,
      }),
    }),
    getBooksAll: build.query<GetBooksAllApiResponse, GetBooksAllApiArg>({
      query: () => ({ url: `/books` }),
    }),
    createBook: build.mutation<CreateBookApiResponse, CreateBookApiArg>({
      query: (queryArg) => ({
        url: `/books`,
        method: "POST",
        body: queryArg.booksEntity,
      }),
    }),
    getBooksStationsAll: build.query<
      GetBooksStationsAllApiResponse,
      GetBooksStationsAllApiArg
    >({
      query: () => ({ url: `/books-stations` }),
    }),
    createBookStation: build.mutation<
      CreateBookStationApiResponse,
      CreateBookStationApiArg
    >({
      query: (queryArg) => ({
        url: `/books-stations`,
        method: "POST",
        body: queryArg.bookStationEntity,
      }),
    }),
    getBooksLogAll: build.query<
      GetBooksLogAllApiResponse,
      GetBooksLogAllApiArg
    >({
      query: () => ({ url: `/books-log` }),
    }),
    createBooksLog: build.mutation<
      CreateBooksLogApiResponse,
      CreateBooksLogApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log`,
        method: "POST",
        body: queryArg.booksLogEntity,
      }),
    }),
    getBooksLogFileAll: build.query<
      GetBooksLogFileAllApiResponse,
      GetBooksLogFileAllApiArg
    >({
      query: () => ({ url: `/books-log-file` }),
    }),
    createBookLogFile: build.mutation<
      CreateBookLogFileApiResponse,
      CreateBookLogFileApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log-file`,
        method: "POST",
        body: queryArg.bookLogFileEntity,
      }),
    }),
    getBasicObservationsAll: build.query<
      GetBasicObservationsAllApiResponse,
      GetBasicObservationsAllApiArg
    >({
      query: () => ({ url: `/basic-observations` }),
    }),
    createBasicObservation: build.mutation<
      CreateBasicObservationApiResponse,
      CreateBasicObservationApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations`,
        method: "POST",
        body: queryArg.basicObservationsEntity,
      }),
    }),
    annualProcessWithErrors: build.mutation<
      AnnualProcessWithErrorsApiResponse,
      AnnualProcessWithErrorsApiArg
    >({
      query: (queryArg) => ({
        url: `/annual-processing`,
        method: "POST",
        body: queryArg.annualInput,
      }),
    }),
    getWaterByStationIdAndDateBetween: build.query<
      GetWaterByStationIdAndDateBetweenApiResponse,
      GetWaterByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow/${queryArg.date1}/${queryArg.date2}/stations/${queryArg.id}`,
      }),
    }),
    getWaterFlowByStationId: build.query<
      GetWaterFlowByStationIdApiResponse,
      GetWaterFlowByStationIdApiArg
    >({
      query: (queryArg) => ({ url: `/water-flow/stations/${queryArg.id}` }),
    }),
    getWaterFlowTempByStationIdAndYear: build.query<
      GetWaterFlowTempByStationIdAndYearApiResponse,
      GetWaterFlowTempByStationIdAndYearApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp/${queryArg.year}/stations/${queryArg.id}`,
        params: { periodId: queryArg.periodId },
      }),
    }),
    getWaterFlowTempByStationIdAndYears: build.query<
      GetWaterFlowTempByStationIdAndYearsApiResponse,
      GetWaterFlowTempByStationIdAndYearsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp/stations/${queryArg.id}`,
        params: { years: queryArg.years },
      }),
    }),
    getMaxDepthByStationIdAndYear: build.query<
      GetMaxDepthByStationIdAndYearApiResponse,
      GetMaxDepthByStationIdAndYearApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-temp-max-depth`,
        params: { usedIds: queryArg.usedIds },
      }),
    }),
    getWaterFlowDays: build.query<
      GetWaterFlowDaysApiResponse,
      GetWaterFlowDaysApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-days/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    getWaterFlowDaysWithWaterFlowNumbers: build.query<
      GetWaterFlowDaysWithWaterFlowNumbersApiResponse,
      GetWaterFlowDaysWithWaterFlowNumbersApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-days-with-numbers/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    getWaterFlowCurveByStationId: build.query<
      GetWaterFlowCurveByStationIdApiResponse,
      GetWaterFlowCurveByStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves/stations/${queryArg.id}`,
      }),
    }),
    getWaterFlowCurveByPeriodId: build.query<
      GetWaterFlowCurveByPeriodIdApiResponse,
      GetWaterFlowCurveByPeriodIdApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves/periods/${queryArg.periodId}`,
      }),
    }),
    getPolynomialCoefficients: build.query<
      GetPolynomialCoefficientsApiResponse,
      GetPolynomialCoefficientsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves-polynomial-coefficients`,
        params: {
          curveTypeId: queryArg.curveTypeId,
          usedIds: queryArg.usedIds,
        },
      }),
    }),
    getGlushCoefficients: build.query<
      GetGlushCoefficientsApiResponse,
      GetGlushCoefficientsApiArg
    >({
      query: (queryArg) => ({
        url: `/water-flow-curves-glush-coefficients`,
        params: {
          maxDepth: queryArg.maxDepth,
          id: queryArg.id,
          year: queryArg.year,
          usedIds: queryArg.usedIds,
        },
      }),
    }),
    getVocListsByTagAndAttribute: build.query<
      GetVocListsByTagAndAttributeApiResponse,
      GetVocListsByTagAndAttributeApiArg
    >({
      query: (queryArg) => ({
        url: `/voc-lists/tag`,
        params: { tag: queryArg.tag, attribute: queryArg.attribute },
      }),
    }),
    getStationById: build.query<
      GetStationByIdApiResponse,
      GetStationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/stations/${queryArg.id}` }),
    }),
    deleteStationById: build.mutation<
      DeleteStationByIdApiResponse,
      DeleteStationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/stations/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getStationsAllWithWaterLevelDayAvg: build.query<
      GetStationsAllWithWaterLevelDayAvgApiResponse,
      GetStationsAllWithWaterLevelDayAvgApiArg
    >({
      query: (queryArg) => ({
        url: `/stations/water-level-day-avg`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getStationsAllWithWaterFlow: build.query<
      GetStationsAllWithWaterFlowApiResponse,
      GetStationsAllWithWaterFlowApiArg
    >({
      query: () => ({ url: `/stations/water-flow` }),
    }),
    getStationsAllWithTotalThicknessIce: build.query<
      GetStationsAllWithTotalThicknessIceApiResponse,
      GetStationsAllWithTotalThicknessIceApiArg
    >({
      query: () => ({ url: `/stations/total-thickness-ice` }),
    }),
    getStationsAllWithAnnualWaterTemperature: build.query<
      GetStationsAllWithAnnualWaterTemperatureApiResponse,
      GetStationsAllWithAnnualWaterTemperatureApiArg
    >({
      query: () => ({ url: `/stations/annual-water-temperature` }),
    }),
    getStationsAllForMap: build.query<
      GetStationsAllForMapApiResponse,
      GetStationsAllForMapApiArg
    >({
      query: () => ({ url: `/stations-for-map` }),
    }),
    getYearsByStationId: build.query<
      GetYearsByStationIdApiResponse,
      GetYearsByStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/station-years/years/stations/${queryArg.id}`,
      }),
    }),
    getStationTsByIdxForGraphs: build.query<
      GetStationTsByIdxForGraphsApiResponse,
      GetStationTsByIdxForGraphsApiArg
    >({
      query: (queryArg) => ({
        url: `/ross/station-ts/station/${queryArg.stationIdx}/date/${queryArg.date}/meteocodes/${queryArg.meteocodes}`,
        params: {
          interval: queryArg.interval,
          round: queryArg.round,
          timezone: queryArg.timezone,
        },
      }),
    }),
    getLakePostById: build.query<
      GetLakePostByIdApiResponse,
      GetLakePostByIdApiArg
    >({
      query: (queryArg) => ({ url: `/lake-posts/${queryArg.id}` }),
    }),
    deleteLakePostById: build.mutation<
      DeleteLakePostByIdApiResponse,
      DeleteLakePostByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-posts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getLakePostsAllWithWaterLevelDayAvg: build.query<
      GetLakePostsAllWithWaterLevelDayAvgApiResponse,
      GetLakePostsAllWithWaterLevelDayAvgApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-posts/water-level-day-avg`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getLakePostsAllWithTotalThicknessIce: build.query<
      GetLakePostsAllWithTotalThicknessIceApiResponse,
      GetLakePostsAllWithTotalThicknessIceApiArg
    >({
      query: () => ({ url: `/lake-posts/total-thickness-ice` }),
    }),
    getLakePostsAllWithAnnualWaterTemperature: build.query<
      GetLakePostsAllWithAnnualWaterTemperatureApiResponse,
      GetLakePostsAllWithAnnualWaterTemperatureApiArg
    >({
      query: () => ({ url: `/lake-posts/annual-water-temperature` }),
    }),
    getYearsByPostId: build.query<
      GetYearsByPostIdApiResponse,
      GetYearsByPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-years/years/posts/${queryArg.id}`,
      }),
    }),
    getLakePostYearParametersByYearAndPostId: build.query<
      GetLakePostYearParametersByYearAndPostIdApiResponse,
      GetLakePostYearParametersByYearAndPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-post-year-parameters/${queryArg.year}/posts/${queryArg.id}`,
      }),
    }),
    getLakeIceObservationsByPostIdAndDateBetween: build.query<
      GetLakeIceObservationsByPostIdAndDateBetweenApiResponse,
      GetLakeIceObservationsByPostIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-ice-observations/${queryArg.date1}/${queryArg.date2}/posts/${queryArg.id}`,
      }),
    }),
    hasLakeMonthlyProcessing: build.query<
      HasLakeMonthlyProcessingApiResponse,
      HasLakeMonthlyProcessingApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-has-monthly-processing/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    getLakeFormDescriptionsByFormIdAndPostId: build.query<
      GetLakeFormDescriptionsByFormIdAndPostIdApiResponse,
      GetLakeFormDescriptionsByFormIdAndPostIdApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-form-descriptions/${queryArg.formId}/posts/${queryArg.id}`,
        params: { month: queryArg.month },
      }),
    }),
    hasLakeErrors: build.query<HasLakeErrorsApiResponse, HasLakeErrorsApiArg>({
      query: (queryArg) => ({
        url: `/lake-confines-log-has-errors/${queryArg.date}/posts/${queryArg.id}`,
      }),
    }),
    getLakeCalculatedDataByPostIdAndDate: build.query<
      GetLakeCalculatedDataByPostIdAndDateApiResponse,
      GetLakeCalculatedDataByPostIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/${queryArg.date}/posts/${queryArg.id}`,
      }),
    }),
    getLakeCalculatedDataByPostIdAndDateBetween: build.query<
      GetLakeCalculatedDataByPostIdAndDateBetweenApiResponse,
      GetLakeCalculatedDataByPostIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data/${queryArg.date1}/${queryArg.date2}/${queryArg.tag}/posts/${queryArg.id}`,
      }),
    }),
    getLakeCalculatedDataYearByPostIdAndDateBetween: build.query<
      GetLakeCalculatedDataYearByPostIdAndDateBetweenApiResponse,
      GetLakeCalculatedDataYearByPostIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-calculated-data-years/${queryArg.date1}/${queryArg.date2}/${queryArg.tag}/posts/${queryArg.id}`,
      }),
    }),
    getLakeBookPostsByIssueNumber: build.query<
      GetLakeBookPostsByIssueNumberApiResponse,
      GetLakeBookPostsByIssueNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/lake-book-posts/issue/${queryArg.issueNumber}`,
      }),
    }),
    getKsvoRegimeByStationIdAndDateBetween: build.query<
      GetKsvoRegimeByStationIdAndDateBetweenApiResponse,
      GetKsvoRegimeByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/ksvo-regimes/${queryArg.date1}/${queryArg.date2}/stations/${queryArg.id}`,
      }),
    }),
    getIceMeasurementByStationIdAndDateBetween: build.query<
      GetIceMeasurementByStationIdAndDateBetweenApiResponse,
      GetIceMeasurementByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/ice-measurements/${queryArg.date1}/${queryArg.date2}/stations/${queryArg.id}`,
      }),
    }),
    hasMonthlyProcessing: build.query<
      HasMonthlyProcessingApiResponse,
      HasMonthlyProcessingApiArg
    >({
      query: (queryArg) => ({
        url: `/has-monthly-processing/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    getFormDescriptionsByFormId: build.query<
      GetFormDescriptionsByFormIdApiResponse,
      GetFormDescriptionsByFormIdApiArg
    >({
      query: (queryArg) => ({
        url: `/form-descriptions/${queryArg.formId}/stations/${queryArg.id}`,
        params: { month: queryArg.month },
      }),
    }),
    getFileStorageByYearAndStationId: build.query<
      GetFileStorageByYearAndStationIdApiResponse,
      GetFileStorageByYearAndStationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage/${queryArg.year}/stations/${queryArg.id}`,
      }),
    }),
    getFileStorageInZip: build.query<
      GetFileStorageInZipApiResponse,
      GetFileStorageInZipApiArg
    >({
      query: (queryArg) => ({
        url: `/file-storage-zip`,
        params: { fileIds: queryArg.fileIds },
      }),
    }),
    cowSay: build.query<CowSayApiResponse, CowSayApiArg>({
      query: (queryArg) => ({ url: `/cowsay/${queryArg.message}` }),
    }),
    getByEntityAndDate: build.query<
      GetByEntityAndDateApiResponse,
      GetByEntityAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/confines-log/${queryArg.entity}/${queryArg.date}/${queryArg.stationId}`,
      }),
    }),
    hasErrors: build.query<HasErrorsApiResponse, HasErrorsApiArg>({
      query: (queryArg) => ({
        url: `/confines-log-has-errors/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    getCalculatedDataByStationIdAndDate: build.query<
      GetCalculatedDataByStationIdAndDateApiResponse,
      GetCalculatedDataByStationIdAndDateApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
    getCalculatedDataByStationIdAndDateBetween: build.query<
      GetCalculatedDataByStationIdAndDateBetweenApiResponse,
      GetCalculatedDataByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data/${queryArg.date1}/${queryArg.date2}/${queryArg.tag}/stations/${queryArg.id}`,
      }),
    }),
    getCalculatedDataYearByStationIdAndDateBetween: build.query<
      GetCalculatedDataYearByStationIdAndDateBetweenApiResponse,
      GetCalculatedDataYearByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/calculated-data-years/${queryArg.date1}/${queryArg.date2}/${queryArg.tag}/stations/${queryArg.id}`,
      }),
    }),
    getBookStationsByIssueNumber: build.query<
      GetBookStationsByIssueNumberApiResponse,
      GetBookStationsByIssueNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/books-stations/issue/${queryArg.issueNumber}`,
      }),
    }),
    getAllBookLogFilesInZip: build.query<
      GetAllBookLogFilesInZipApiResponse,
      GetAllBookLogFilesInZipApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log-file-zip`,
        params: { fileIds: queryArg.fileIds },
      }),
    }),
    getBookLogFileInHtml: build.query<
      GetBookLogFileInHtmlApiResponse,
      GetBookLogFileInHtmlApiArg
    >({
      query: (queryArg) => ({
        url: `/books-log-file-html`,
        params: { fileId: queryArg.fileId },
      }),
    }),
    getBasicObservationsByStationIdAndDateBetween: build.query<
      GetBasicObservationsByStationIdAndDateBetweenApiResponse,
      GetBasicObservationsByStationIdAndDateBetweenApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations/${queryArg.date1}/${queryArg.date2}/stations/${queryArg.id}`,
      }),
    }),
    getBasicObservationsFromWaterFlow: build.query<
      GetBasicObservationsFromWaterFlowApiResponse,
      GetBasicObservationsFromWaterFlowApiArg
    >({
      query: (queryArg) => ({
        url: `/basic-observations-from-water-flow/${queryArg.date}/stations/${queryArg.id}`,
      }),
    }),
  }),
});
export type GetWaterObjectByIdApiResponse =
  /** status 200 OK */ WaterObjectsEntity;
export type GetWaterObjectByIdApiArg = {
  /** Код водного объекта */
  code: number;
};
export type UpdateWaterObjectApiResponse =
  /** status 200 OK */ WaterObjectsEntity;
export type UpdateWaterObjectApiArg = {
  /** Код водного объекта */
  code: number;
  waterObjectsEntity: WaterObjectsEntity;
};
export type DeleteWaterObjectByIdApiResponse = unknown;
export type DeleteWaterObjectByIdApiArg = {
  /** Код водного объекта */
  code: number;
};
export type GetWaterFlowByIdApiResponse = /** status 200 OK */ WaterFlowEntity;
export type GetWaterFlowByIdApiArg = {
  /** Идентификатор расхода воды */
  id: number;
};
export type UpdateWaterFlowApiResponse = /** status 200 OK */ WaterFlowEntity;
export type UpdateWaterFlowApiArg = {
  /** Идентификатор расхода воды */
  id: number;
  waterFlowEntity: WaterFlowEntity;
};
export type DeleteWaterFlowByIdApiResponse = unknown;
export type DeleteWaterFlowByIdApiArg = {
  /** Идентификатор расхода воды */
  id: number;
};
export type GetWaterByStationIdAndDateApiResponse =
  /** status 200 OK */ WaterFlowConfinesLogDto;
export type GetWaterByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата измерения расхода в формате dd.MM.yyyy или MM.yyyy */
  date: string;
};
export type UpdateFormWaterFlowApiResponse =
  /** status 200 OK */ WaterFlowNumberDto;
export type UpdateFormWaterFlowApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата измерения расхода */
  date: string;
  waterFlowEntity: WaterFlowEntity;
};
export type GetWaterFlowTempAllApiResponse =
  /** status 200 OK */ WaterFlowTempEntity[];
export type GetWaterFlowTempAllApiArg = void;
export type UpdateWaterFlowTempListApiResponse = unknown;
export type UpdateWaterFlowTempListApiArg = {
  body: WaterFlowTempDto[];
};
export type CreateWaterFlowTempApiResponse =
  /** status 200 OK */ WaterFlowTempEntity;
export type CreateWaterFlowTempApiArg = {
  waterFlowTempEntity: WaterFlowTempEntity;
};
export type GetWaterFlowTempByIdApiResponse =
  /** status 200 OK */ WaterFlowTempEntity;
export type GetWaterFlowTempByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateWaterFlowTempApiResponse =
  /** status 200 OK */ WaterFlowTempEntity;
export type UpdateWaterFlowTempApiArg = {
  /** Идентификатор */
  id: number;
  waterFlowTempEntity: WaterFlowTempEntity;
};
export type DeleteWaterFlowTempByIdApiResponse = unknown;
export type DeleteWaterFlowTempByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type CreateInitialWaterFlowTempByStationIdAndYearApiResponse = unknown;
export type CreateInitialWaterFlowTempByStationIdAndYearApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Список лет (можно указать до 10 лет) для отображения данных для построения кривой */
  years: string[];
};
export type CalculateWaterFLowStoutCorrectApiResponse = unknown;
export type CalculateWaterFLowStoutCorrectApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор кривой */
  curveId: number;
  /** Идентификатор периода, если не указать, то расчет будет произведен за весь год */
  periodId?: number;
  /** Идентификаторы объектов с used = true */
  usedIds: number[];
};
export type CreateWaterFlowPointsAndQCurveApiResponse =
  /** status 200 OK */ WaterFlowCurveDto;
export type CreateWaterFlowPointsAndQCurveApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор периода, идентификатор дефолтного периода тоже надо указывать */
  periodId?: number;
  body: WaterFlowPointEntity[];
};
export type GetWaterFlowPointByIdApiResponse =
  /** status 200 OK */ WaterFlowPointEntity;
export type GetWaterFlowPointByIdApiArg = {
  /** Идентификатор координат */
  id: number;
};
export type UpdateWaterFlowPointApiResponse =
  /** status 200 OK */ WaterFlowPointEntity;
export type UpdateWaterFlowPointApiArg = {
  /** Идентификатор координат */
  id: number;
  waterFlowPointEntity: WaterFlowPointEntity;
};
export type DeleteWaterFlowPointByIdApiResponse = unknown;
export type DeleteWaterFlowPointByIdApiArg = {
  /** Идентификатор координат */
  id: number;
};
export type UpdateWaterFlowPointsApiResponse = unknown;
export type UpdateWaterFlowPointsApiArg = {
  /** Идентификатор кривой */
  curveId: number;
  body: WaterFlowPointEntity[];
};
export type ConcavityCheckApiResponse = /** status 200 OK */ boolean[];
export type ConcavityCheckApiArg = {
  body: WaterFlowPointEntity[];
};
export type GetWaterFlowPeriodsByStationIdAndYearApiResponse =
  /** status 200 OK */ WaterFlowPeriodDto[];
export type GetWaterFlowPeriodsByStationIdAndYearApiArg = {
  /** Расчетный год */
  year: string;
  /** Идентификатор поста */
  id: number;
};
export type UpdateWaterFlowPeriodsApiResponse = unknown;
export type UpdateWaterFlowPeriodsApiArg = {
  /** Расчетный год */
  year: string;
  /** Идентификатор поста */
  id: number;
  body: WaterFlowPeriodDto[];
};
export type GetWaterFlowPeriodByIdApiResponse =
  /** status 200 OK */ WaterFlowPeriodEntity;
export type GetWaterFlowPeriodByIdApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type UpdateWaterFlowPeriodApiResponse =
  /** status 200 OK */ WaterFlowPeriodEntity;
export type UpdateWaterFlowPeriodApiArg = {
  /** Идентификатор кривой */
  id: number;
  waterFlowPeriodEntity: WaterFlowPeriodEntity;
};
export type DeleteWaterFlowPeriodByIdApiResponse = unknown;
export type DeleteWaterFlowPeriodByIdApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type GetWaterFlowCurvesAllApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity[];
export type GetWaterFlowCurvesAllApiArg = void;
export type UpdateWaterFlowCurveNameApiResponse = unknown;
export type UpdateWaterFlowCurveNameApiArg = {
  waterFlowCurveEntity: WaterFlowCurveEntity;
};
export type CreateWaterFlowCurveApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity;
export type CreateWaterFlowCurveApiArg = {
  waterFlowCurveEntity: WaterFlowCurveEntity;
};
export type CreateCurveByCoefficientsApiResponse =
  /** status 200 OK */ WaterFlowCurveDto;
export type CreateCurveByCoefficientsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор периода, идентификатор дефолтного периода тоже надо указывать */
  periodId?: number;
  /** Идентификатор типа кривой */
  curveTypeId: number;
  waterFlowCurveDto: WaterFlowCurveDto;
};
export type GetWaterFlowCurveByIdApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity;
export type GetWaterFlowCurveByIdApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type UpdateWaterFlowCurveApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity;
export type UpdateWaterFlowCurveApiArg = {
  /** Идентификатор кривой */
  id: number;
  waterFlowCurveEntity: WaterFlowCurveEntity;
};
export type DeleteWaterFlowCurveByIdApiResponse = unknown;
export type DeleteWaterFlowCurveByIdApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type GetWaterFlowCurveXlsxApiResponse = /** status 200 OK */ string[];
export type GetWaterFlowCurveXlsxApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type CreateWaterFlowCurveXlsxApiResponse = unknown;
export type CreateWaterFlowCurveXlsxApiArg = {
  /** Идентификатор кривой */
  id: number;
};
export type CalculateWaterFlowCoefficientsApiResponse = unknown;
export type CalculateWaterFlowCoefficientsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор кривой */
  curveId: number;
  /** Идентификатор периода, если не указать, то расчет будет произведен за весь год */
  periodId?: number;
};
export type GetWaterFlowChannelByIdApiResponse =
  /** status 200 OK */ WaterFlowChannelEntity;
export type GetWaterFlowChannelByIdApiArg = {
  /** Идентификатор номера */
  id: number;
};
export type UpdateWaterFlowChannelApiResponse =
  /** status 200 OK */ WaterFlowChannelEntity;
export type UpdateWaterFlowChannelApiArg = {
  /** Идентификатор номера */
  id: number;
  waterFlowChannelEntity: WaterFlowChannelEntity;
};
export type DeleteWaterFlowChannelByIdApiResponse = unknown;
export type DeleteWaterFlowChannelByIdApiArg = {
  /** Идентификатор номера */
  id: number;
};
export type GetWaterFlowChannelMeasurementByIdApiResponse =
  /** status 200 OK */ WaterFlowChannelMeasurementEntity;
export type GetWaterFlowChannelMeasurementByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type UpdateWaterFlowChannelMeasurementApiResponse =
  /** status 200 OK */ WaterFlowChannelMeasurementEntity;
export type UpdateWaterFlowChannelMeasurementApiArg = {
  /** Идентификатор измерения */
  id: number;
  waterFlowChannelMeasurementEntity: WaterFlowChannelMeasurementEntity;
};
export type DeleteWaterFlowChannelMeasurementByIdApiResponse = unknown;
export type DeleteWaterFlowChannelMeasurementByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type GetVocWaterFlowCurveTypeByIdApiResponse =
  /** status 200 OK */ VocWaterFlowCurveType;
export type GetVocWaterFlowCurveTypeByIdApiArg = {
  /** Идентификатор типа */
  id: number;
};
export type UpdateVocWaterFlowCurveTypeApiResponse =
  /** status 200 OK */ VocWaterFlowCurveType;
export type UpdateVocWaterFlowCurveTypeApiArg = {
  /** Идентификатор типа */
  id: number;
  vocWaterFlowCurveType: VocWaterFlowCurveType;
};
export type DeleteVocWaterFlowCurveTypeByIdApiResponse = unknown;
export type DeleteVocWaterFlowCurveTypeByIdApiArg = {
  /** Идентификатор типа */
  id: number;
};
export type GetVocWaterFlowCalculationMethodByIdApiResponse =
  /** status 200 OK */ VocWaterFlowCalculationMethod;
export type GetVocWaterFlowCalculationMethodByIdApiArg = {
  /** Идентификатор метода */
  id: number;
};
export type UpdateVocWaterFlowCalculationMethodApiResponse =
  /** status 200 OK */ VocWaterFlowCalculationMethod;
export type UpdateVocWaterFlowCalculationMethodApiArg = {
  /** Идентификатор метода */
  id: number;
  vocWaterFlowCalculationMethod: VocWaterFlowCalculationMethod;
};
export type DeleteVocWaterFlowCalculationMethodByIdApiResponse = unknown;
export type DeleteVocWaterFlowCalculationMethodByIdApiArg = {
  /** Идентификатор метода */
  id: number;
};
export type GetVocRegionsByCodeApiResponse = /** status 200 OK */ VocRegion;
export type GetVocRegionsByCodeApiArg = {
  /** Код УГМС */
  code: number;
};
export type UpdateVocRegionApiResponse = /** status 200 OK */ VocRegion;
export type UpdateVocRegionApiArg = {
  /** Код УГМС */
  code: number;
  vocRegion: VocRegion;
};
export type DeleteVocRegionsByCodeApiResponse = unknown;
export type DeleteVocRegionsByCodeApiArg = {
  /** Код УГМС */
  code: number;
};
export type GetVocListsByIdApiResponse = /** status 200 OK */ VocListsEntity;
export type GetVocListsByIdApiArg = {
  /** Идентификатор записи */
  id: number;
};
export type UpdateVocListsApiResponse = /** status 200 OK */ VocListsEntity;
export type UpdateVocListsApiArg = {
  /** Идентификатор записи */
  id: number;
  vocListsEntity: VocListsEntity;
};
export type DeleteVocListsByIdApiResponse = unknown;
export type DeleteVocListsByIdApiArg = {
  /** Идентификатор записи */
  id: number;
};
export type GetVocLakeWaterAreaPostByIdApiResponse =
  /** status 200 OK */ VocLakeWaterAreaPost;
export type GetVocLakeWaterAreaPostByIdApiArg = {
  /** Идентификатор вида */
  id: number;
};
export type UpdateVocLakeWaterAreaPostApiResponse =
  /** status 200 OK */ VocLakeWaterAreaPost;
export type UpdateVocLakeWaterAreaPostApiArg = {
  /** Идентификатор вида */
  id: number;
  vocLakeWaterAreaPost: VocLakeWaterAreaPost;
};
export type DeleteVocLakeWaterAreaPostByIdApiResponse = unknown;
export type DeleteVocLakeWaterAreaPostByIdApiArg = {
  /** Идентификатор вида */
  id: number;
};
export type GetVocLakeTypeByIdApiResponse = /** status 200 OK */ VocLakeType;
export type GetVocLakeTypeByIdApiArg = {
  /** Идентификатор типа */
  id: number;
};
export type UpdateVocLakeTypeApiResponse = /** status 200 OK */ VocLakeType;
export type UpdateVocLakeTypeApiArg = {
  /** Идентификатор типа */
  id: number;
  vocLakeType: VocLakeType;
};
export type DeleteVocLakeTypeByIdApiResponse = unknown;
export type DeleteVocLakeTypeByIdApiArg = {
  /** Идентификатор типа */
  id: number;
};
export type GetVocLakeRegulationNatureByIdApiResponse =
  /** status 200 OK */ VocLakeRegulationNature;
export type GetVocLakeRegulationNatureByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateVocLakeRegulationNatureApiResponse =
  /** status 200 OK */ VocLakeRegulationNature;
export type UpdateVocLakeRegulationNatureApiArg = {
  /** Идентификатор */
  id: number;
  vocLakeRegulationNature: VocLakeRegulationNature;
};
export type DeleteVocLakeRegulationNatureByIdApiResponse = unknown;
export type DeleteVocLakeRegulationNatureByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetStationsAllApiResponse = /** status 200 OK */ StationDto[];
export type GetStationsAllApiArg = void;
export type UpdateStationApiResponse = /** status 200 OK */ StationDto;
export type UpdateStationApiArg = {
  body: StationDto;
};
export type GetStationYearByIdApiResponse =
  /** status 200 OK */ StationYearEntity;
export type GetStationYearByIdApiArg = {
  /** Идентификатор года */
  id: number;
};
export type UpdateStationYearApiResponse =
  /** status 200 OK */ StationYearEntity;
export type UpdateStationYearApiArg = {
  /** Идентификатор года */
  id: number;
  stationYearEntity: StationYearEntity;
};
export type DeleteStationYearByIdApiResponse = unknown;
export type DeleteStationYearByIdApiArg = {
  /** Идентификатор года */
  id: number;
};
export type GetStationYearParametersByYearAndStationIdApiResponse =
  /** status 200 OK */ StationYearParametersConfinesLogDto;
export type GetStationYearParametersByYearAndStationIdApiArg = {
  /** Год */
  year: string;
  /** Идентификатор поста */
  id: number;
};
export type UpdateStationYearParametersApiResponse = unknown;
export type UpdateStationYearParametersApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Год */
  year: string;
  body: StationYearParametersEntity[];
};
export type GetStationYearParameterByIdApiResponse =
  /** status 200 OK */ StationYearParametersEntity;
export type GetStationYearParameterByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateStationYearParameterApiResponse =
  /** status 200 OK */ StationYearParametersEntity;
export type UpdateStationYearParameterApiArg = {
  /** Идентификатор */
  id: number;
  stationYearParametersEntity: StationYearParametersEntity;
};
export type DeleteStationYearParameterByIdApiResponse = unknown;
export type DeleteStationYearParameterByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetInhomogeneityLevelDateByIdAndDateApiResponse =
  /** status 200 OK */ StationYearParametersDto;
export type GetInhomogeneityLevelDateByIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
};
export type UpdateInhomogeneityLevelDateByIdAndDateApiResponse = unknown;
export type UpdateInhomogeneityLevelDateByIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
  stationYearParametersDto: StationYearParametersDto;
};
export type GetSiltChargeByIdApiResponse =
  /** status 200 OK */ SiltChargeEntity;
export type GetSiltChargeByIdApiArg = {
  /** Идентификатор мутности */
  id: number;
};
export type UpdateSiltChargeApiResponse = /** status 200 OK */ SiltChargeEntity;
export type UpdateSiltChargeApiArg = {
  /** Идентификатор мутности */
  id: number;
  siltChargeEntity: SiltChargeEntity;
};
export type DeleteSiltChargeByIdApiResponse = unknown;
export type DeleteSiltChargeByIdApiArg = {
  /** Идентификатор мутности */
  id: number;
};
export type GetSiltChargeByStationIdAndDateApiResponse =
  /** status 200 OK */ SiltChargeConfinesLogDto;
export type GetSiltChargeByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
};
export type UpdateFormSiltChargesApiResponse = unknown;
export type UpdateFormSiltChargesApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: SiltChargeEntity[];
};
export type GetSeasonsWaterManagementByIdApiResponse =
  /** status 200 OK */ SeasonsWaterManagementEntity;
export type GetSeasonsWaterManagementByIdApiArg = {
  /** Идентификатор сезона */
  id: number;
};
export type UpdateSeasonsWaterManagementApiResponse =
  /** status 200 OK */ SeasonsWaterManagementEntity;
export type UpdateSeasonsWaterManagementApiArg = {
  /** Идентификатор сезона */
  id: number;
  seasonsWaterManagementEntity: SeasonsWaterManagementEntity;
};
export type DeleteSeasonsWaterManagementByIdApiResponse = unknown;
export type DeleteSeasonsWaterManagementByIdApiArg = {
  /** Идентификатор сезона */
  id: number;
};
export type GetSeasonsWaterManagementsByStationIdApiResponse =
  /** status 200 OK */ SeasonsWaterManagementConfinesLogDto;
export type GetSeasonsWaterManagementsByStationIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type UpdateSeasonsWaterManagementsApiResponse = unknown;
export type UpdateSeasonsWaterManagementsApiArg = {
  /** Идентификатор поста */
  id: number;
  body: SeasonsWaterManagementEntity[];
};
export type GetLengthwiseSlopeByIdApiResponse =
  /** status 200 OK */ LengthwiseSlopeEntity;
export type GetLengthwiseSlopeByIdApiArg = {
  /** Идентификатор уклона */
  id: number;
};
export type UpdateLengthwiseSlopeApiResponse =
  /** status 200 OK */ LengthwiseSlopeEntity;
export type UpdateLengthwiseSlopeApiArg = {
  /** Идентификатор уклона */
  id: number;
  lengthwiseSlopeEntity: LengthwiseSlopeEntity;
};
export type DeleteLengthwiseSlopeByIdApiResponse = unknown;
export type DeleteLengthwiseSlopeByIdApiArg = {
  /** Идентификатор уклона */
  id: number;
};
export type GetLengthwiseSlopeByStationIdAndDateApiResponse =
  /** status 200 OK */ LengthwiseSlopeConfinesLogDto;
export type GetLengthwiseSlopeByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
};
export type UpdateFormLengthwiseSlopeApiResponse = unknown;
export type UpdateFormLengthwiseSlopeApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: LengthwiseSlopeEntity[];
};
export type GetLakeWaveObservationByIdApiResponse =
  /** status 200 OK */ LakeWaveObservation;
export type GetLakeWaveObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type UpdateLakeWaveObservationApiResponse =
  /** status 200 OK */ LakeWaveObservation;
export type UpdateLakeWaveObservationApiArg = {
  /** Идентификатор наблюдения */
  id: number;
  lakeWaveObservation: LakeWaveObservation;
};
export type DeleteLakeWaveObservationByIdApiResponse = unknown;
export type DeleteLakeWaveObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type GetWaveBasicObservationsByPostIdAndDateApiResponse =
  /** status 200 OK */ LakeWaveObservationConfinesLogDto;
export type GetWaveBasicObservationsByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата волномерных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
};
export type UpdateFormLakeWaveObservationsApiResponse = unknown;
export type UpdateFormLakeWaveObservationsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата волномерных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: LakeWaveObservation[];
};
export type GetLakeWaterTemperatureObservationByIdApiResponse =
  /** status 200 OK */ LakeWaterTemperatureObservation;
export type GetLakeWaterTemperatureObservationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateLakeWaterTemperatureObservationApiResponse =
  /** status 200 OK */ LakeWaterTemperatureObservation;
export type UpdateLakeWaterTemperatureObservationApiArg = {
  /** Идентификатор */
  id: number;
  lakeWaterTemperatureObservation: LakeWaterTemperatureObservation;
};
export type DeleteLakeWaterTemperatureObservationByIdApiResponse = unknown;
export type DeleteLakeWaterTemperatureObservationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetLakeWaterAreaPostByIdApiResponse =
  /** status 200 OK */ LakeWaterAreaPost;
export type GetLakeWaterAreaPostByIdApiArg = {
  /** Идентификатор пункта */
  id: number;
};
export type UpdateLakeWaterAreaPostApiResponse =
  /** status 200 OK */ LakeWaterAreaPost;
export type UpdateLakeWaterAreaPostApiArg = {
  /** Идентификатор пункта */
  id: number;
  lakeWaterAreaPost: LakeWaterAreaPost;
};
export type DeleteLakeWaterAreaPostByIdApiResponse = unknown;
export type DeleteLakeWaterAreaPostByIdApiArg = {
  /** Идентификатор пункта */
  id: number;
};
export type GetLakeSeasonsWaterManagementByIdApiResponse =
  /** status 200 OK */ LakeSeasonsWaterManagement;
export type GetLakeSeasonsWaterManagementByIdApiArg = {
  /** Идентификатор сезона */
  id: number;
};
export type UpdateLakeSeasonsWaterManagementApiResponse =
  /** status 200 OK */ LakeSeasonsWaterManagement;
export type UpdateLakeSeasonsWaterManagementApiArg = {
  /** Идентификатор сезона */
  id: number;
  lakeSeasonsWaterManagement: LakeSeasonsWaterManagement;
};
export type DeleteLakeSeasonsWaterManagementByIdApiResponse = unknown;
export type DeleteLakeSeasonsWaterManagementByIdApiArg = {
  /** Идентификатор сезона */
  id: number;
};
export type GetLakeSeasonsWaterManagementsByPostIdApiResponse =
  /** status 200 OK */ LakeSeasonsWaterManagementConfinesLogDto;
export type GetLakeSeasonsWaterManagementsByPostIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type UpdateLakeSeasonsWaterManagementsApiResponse = unknown;
export type UpdateLakeSeasonsWaterManagementsApiArg = {
  /** Идентификатор поста */
  id: number;
  body: LakeSeasonsWaterManagement[];
};
export type GetLakePostsAllApiResponse = /** status 200 OK */ LakePostDto[];
export type GetLakePostsAllApiArg = void;
export type UpdateLakePostApiResponse = /** status 200 OK */ LakePostDto;
export type UpdateLakePostApiArg = {
  body: LakePostDto;
};
export type GetLakePostYearByIdApiResponse = /** status 200 OK */ LakePostYear;
export type GetLakePostYearByIdApiArg = {
  /** Идентификатор года */
  id: number;
};
export type UpdateLakePostYearApiResponse = /** status 200 OK */ LakePostYear;
export type UpdateLakePostYearApiArg = {
  /** Идентификатор года */
  id: number;
  lakePostYear: LakePostYear;
};
export type DeleteLakePostYearByIdApiResponse = unknown;
export type DeleteLakePostYearByIdApiArg = {
  /** Идентификатор года */
  id: number;
};
export type GetLakePostYearParameterByIdApiResponse =
  /** status 200 OK */ LakePostYearParameter;
export type GetLakePostYearParameterByIdApiArg = {
  /** Идентификатор параметра */
  id: number;
};
export type UpdateLakePostYearParameterApiResponse =
  /** status 200 OK */ LakePostYearParameter;
export type UpdateLakePostYearParameterApiArg = {
  /** Идентификатор параметра */
  id: number;
  lakePostYearParameter: LakePostYearParameter;
};
export type DeleteLakePostYearParameterByIdApiResponse = unknown;
export type DeleteLakePostYearParameterByIdApiArg = {
  /** Идентификатор параметра */
  id: number;
};
export type GetLakeInhomogeneityLevelDateByPostIdAndDateApiResponse =
  /** status 200 OK */ StationYearParametersDto;
export type GetLakeInhomogeneityLevelDateByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
};
export type UpdateLakeInhomogeneityLevelDateByPostIdAndDateApiResponse =
  unknown;
export type UpdateLakeInhomogeneityLevelDateByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
  stationYearParametersDto: StationYearParametersDto;
};
export type GetLakePassportByIdApiResponse = /** status 200 OK */ LakePassport;
export type GetLakePassportByIdApiArg = {
  /** Идентификатор водоема */
  id: number;
};
export type UpdateLakePassportApiResponse = /** status 200 OK */ LakePassport;
export type UpdateLakePassportApiArg = {
  /** Идентификатор водоема */
  id: number;
  lakePassport: LakePassport;
};
export type DeleteLakePassportByIdApiResponse = unknown;
export type DeleteLakePassportByIdApiArg = {
  /** Идентификатор водоема */
  id: number;
};
export type GetLakeObservationPointByIdApiResponse =
  /** status 200 OK */ LakeObservationPoint;
export type GetLakeObservationPointByIdApiArg = {
  /** Идентификатор участка */
  id: number;
};
export type UpdateLakeObservationPointApiResponse =
  /** status 200 OK */ LakeObservationPoint;
export type UpdateLakeObservationPointApiArg = {
  /** Идентификатор участка */
  id: number;
  lakeObservationPoint: LakeObservationPoint;
};
export type DeleteLakeObservationPointByIdApiResponse = unknown;
export type DeleteLakeObservationPointByIdApiArg = {
  /** Идентификатор участка */
  id: number;
};
export type GetLakeObservationPointsByPostIdApiResponse =
  /** status 200 OK */ LakeObservationPoint[];
export type GetLakeObservationPointsByPostIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type UpdateLakeObservationPointsApiResponse = unknown;
export type UpdateLakeObservationPointsApiArg = {
  /** Идентификатор поста */
  id: number;
  body: LakeObservationPoint[];
};
export type GetLakeMinMaxWaveObservationByIdApiResponse =
  /** status 200 OK */ LakeMinMaxWaveObservation;
export type GetLakeMinMaxWaveObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type UpdateLakeMinMaxWaveObservationApiResponse =
  /** status 200 OK */ LakeMinMaxWaveObservation;
export type UpdateLakeMinMaxWaveObservationApiArg = {
  /** Идентификатор наблюдения */
  id: number;
  lakeMinMaxWaveObservation: LakeMinMaxWaveObservation;
};
export type DeleteLakeMinMaxWaveObservationByIdApiResponse = unknown;
export type DeleteLakeMinMaxWaveObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type GetLakeIceProfilesObservationByIdApiResponse =
  /** status 200 OK */ LakeIceProfilesObservation;
export type GetLakeIceProfilesObservationByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type UpdateLakeIceProfilesObservationApiResponse =
  /** status 200 OK */ LakeIceProfilesObservation;
export type UpdateLakeIceProfilesObservationApiArg = {
  /** Идентификатор измерения */
  id: number;
  lakeIceProfilesObservation: LakeIceProfilesObservation;
};
export type DeleteLakeIceProfilesObservationByIdApiResponse = unknown;
export type DeleteLakeIceProfilesObservationByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type GetLakeIceObservationByIdApiResponse =
  /** status 200 OK */ LakeIceObservation;
export type GetLakeIceObservationByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type UpdateLakeIceObservationsApiResponse =
  /** status 200 OK */ LakeIceObservation;
export type UpdateLakeIceObservationsApiArg = {
  /** Идентификатор измерения */
  id: number;
  lakeIceObservation: LakeIceObservation;
};
export type DeleteLakeIceObservationByIdApiResponse = unknown;
export type DeleteLakeIceObservationByIdApiArg = {
  /** Идентификатор измерения */
  id: number;
};
export type GetLakeIceObservationsByPostIdAndDateApiResponse =
  /** status 200 OK */ LakeIceObservationConfinesLogDto;
export type GetLakeIceObservationsByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата ледовых измерений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Интервал времени, равный 5 или 10 дням */
  interval?: number;
};
export type UpdateFormLakeIceObservationsApiResponse = unknown;
export type UpdateFormLakeIceObservationsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата ледовых наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: LakeIceObservation[];
};
export type GetLakeHydrologicalObservationByIdApiResponse =
  /** status 200 OK */ LakeHydrologicalObservation;
export type GetLakeHydrologicalObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type UpdateLakeHydrologicalObservationApiResponse =
  /** status 200 OK */ LakeHydrologicalObservation;
export type UpdateLakeHydrologicalObservationApiArg = {
  /** Идентификатор наблюдения */
  id: number;
  lakeHydrologicalObservation: LakeHydrologicalObservation;
};
export type DeleteLakeHydrologicalObservationByIdApiResponse = unknown;
export type DeleteLakeHydrologicalObservationByIdApiArg = {
  /** Идентификатор наблюдения */
  id: number;
};
export type GetLakeFormDescriptionsByIdApiResponse =
  /** status 200 OK */ LakeFormDescription;
export type GetLakeFormDescriptionsByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateLakeFormDescriptionsApiResponse =
  /** status 200 OK */ LakeFormDescription;
export type UpdateLakeFormDescriptionsApiArg = {
  /** Идентификатор */
  id: number;
  lakeFormDescription: LakeFormDescription;
};
export type DeleteLakeFormDescriptionsByIdApiResponse = unknown;
export type DeleteLakeFormDescriptionsByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetLakeDirectionSpeedObservationByIdApiResponse =
  /** status 200 OK */ LakeDirectionSpeedObservation;
export type GetLakeDirectionSpeedObservationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateLakeDirectionSpeedObservationApiResponse =
  /** status 200 OK */ LakeDirectionSpeedObservation;
export type UpdateLakeDirectionSpeedObservationApiArg = {
  /** Идентификатор */
  id: number;
  lakeDirectionSpeedObservation: LakeDirectionSpeedObservation;
};
export type DeleteLakeDirectionSpeedObservationByIdApiResponse = unknown;
export type DeleteLakeDirectionSpeedObservationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetLakeConfinesByIdApiResponse = /** status 200 OK */ LakeConfines;
export type GetLakeConfinesByIdApiArg = {
  /** Идентификатор предела */
  id: number;
};
export type UpdateLakeConfinesApiResponse = /** status 200 OK */ LakeConfines;
export type UpdateLakeConfinesApiArg = {
  /** Идентификатор предела */
  id: number;
  lakeConfines: LakeConfines;
};
export type DeleteLakeConfinesByIdApiResponse = unknown;
export type DeleteLakeConfinesByIdApiArg = {
  /** Идентификатор предела */
  id: number;
};
export type GetLakeConfinesByPostIdApiResponse =
  /** status 200 OK */ ConfinesDto[];
export type GetLakeConfinesByPostIdApiArg = {
  /** Идентификатор поста, для дефолтного указывать id = 0 */
  id: number;
  /** Показывать сезонные пределы (true) или годовые пределы (false) */
  showSeasons?: boolean;
};
export type UpdateFormLakeConfinesApiResponse = unknown;
export type UpdateFormLakeConfinesApiArg = {
  /** Идентификатор поста */
  id: number;
  body: ConfinesDto[];
};
export type GetLakeCalculatedDatumByIdApiResponse =
  /** status 200 OK */ LakeCalculatedDatum;
export type GetLakeCalculatedDatumByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type UpdateLakeCalculatedDatumApiResponse =
  /** status 200 OK */ LakeCalculatedDatum;
export type UpdateLakeCalculatedDatumApiArg = {
  /** Идентификатор данных */
  id: number;
  lakeCalculatedDatum: LakeCalculatedDatum;
};
export type DeleteLakeCalculatedDatumByIdApiResponse = unknown;
export type DeleteLakeCalculatedDatumByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type GetLakeKsvoMonthByPostIdAndDateApiResponse =
  /** status 200 OK */ KsvoMonthWithConfinesLogDto;
export type GetLakeKsvoMonthByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
};
export type UpdateLakeKsvoMonthApiResponse = unknown;
export type UpdateLakeKsvoMonthApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
  calculatedDataDto: CalculatedDataDto;
};
export type GetLakeCalculatedDataYearByIdApiResponse =
  /** status 200 OK */ LakeCalculatedDataYear;
export type GetLakeCalculatedDataYearByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type UpdateLakeCalculatedDataYearApiResponse =
  /** status 200 OK */ LakeCalculatedDataYear;
export type UpdateLakeCalculatedDataYearApiArg = {
  /** Идентификатор данных */
  id: number;
  lakeCalculatedDataYear: LakeCalculatedDataYear;
};
export type DeleteLakeCalculatedDataYearByIdApiResponse = unknown;
export type DeleteLakeCalculatedDataYearByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type GetLakeBookPostByIdApiResponse = /** status 200 OK */ LakeBookPost;
export type GetLakeBookPostByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateLakeBookPostApiResponse = /** status 200 OK */ LakeBookPost;
export type UpdateLakeBookPostApiArg = {
  /** Идентификатор */
  id: number;
  lakeBookPost: LakeBookPost;
};
export type DeleteLakeBookPostByIdApiResponse = unknown;
export type DeleteLakeBookPostByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetLakeBookLogByIdApiResponse = /** status 200 OK */ LakeBookLog;
export type GetLakeBookLogByIdApiArg = {
  /** Идентификатор записи */
  id: number;
};
export type UpdateLakeBookLogApiResponse = /** status 200 OK */ LakeBookLog;
export type UpdateLakeBookLogApiArg = {
  /** Идентификатор записи */
  id: number;
  lakeBookLog: LakeBookLog;
};
export type DeleteLakeBookLogByIdApiResponse = unknown;
export type DeleteLakeBookLogByIdApiArg = {
  /** Идентификатор записи */
  id: number;
};
export type GetLakeBasicObservationByIdApiResponse =
  /** status 200 OK */ LakeBasicObservation;
export type GetLakeBasicObservationByIdApiArg = {
  /** Идентификатор основного наблюдения */
  id: number;
};
export type UpdateLakeBasicObservationApiResponse =
  /** status 200 OK */ LakeBasicObservation;
export type UpdateLakeBasicObservationApiArg = {
  /** Идентификатор основного наблюдения */
  id: number;
  lakeBasicObservation: LakeBasicObservation;
};
export type DeleteLakeBasicObservationByIdApiResponse = unknown;
export type DeleteLakeBasicObservationByIdApiArg = {
  /** Идентификатор основного наблюдения */
  id: number;
};
export type GetLakeBasicObservationsByPostIdAndDateApiResponse =
  /** status 200 OK */ LakeBasicObservationConfinesLogDto;
export type GetLakeBasicObservationsByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата основных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Фильтр по времени: '08:00', '08:00,20:00', 'hourly' */
  time?: string;
};
export type UpdateFormLakeBasicObservationsApiResponse = unknown;
export type UpdateFormLakeBasicObservationsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата основных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: LakeBasicObservation[];
};
export type GetKsvoRegimeByIdApiResponse =
  /** status 200 OK */ KsvoRegimeEntity;
export type GetKsvoRegimeByIdApiArg = {
  /** Идентификатор режимной группы СВО */
  id: number;
};
export type UpdateKsvoRegimeApiResponse = /** status 200 OK */ KsvoRegimeEntity;
export type UpdateKsvoRegimeApiArg = {
  /** Идентификатор режимной группы СВО */
  id: number;
  ksvoRegimeEntity: KsvoRegimeEntity;
};
export type DeleteKsvoRegimeByIdApiResponse = unknown;
export type DeleteKsvoRegimeByIdApiArg = {
  /** Идентификатор режимной группы СВО */
  id: number;
};
export type GetIceMeasurementByIdApiResponse =
  /** status 200 OK */ IceMeasurementEntity;
export type GetIceMeasurementByIdApiArg = {
  /** Идентификатор измерений */
  id: number;
};
export type UpdateIceMeasurementApiResponse =
  /** status 200 OK */ IceMeasurementEntity;
export type UpdateIceMeasurementApiArg = {
  /** Идентификатор измерений */
  id: number;
  iceMeasurementEntity: IceMeasurementEntity;
};
export type DeleteIceMeasurementByIdApiResponse = unknown;
export type DeleteIceMeasurementByIdApiArg = {
  /** Идентификатор измерений */
  id: number;
};
export type GetIceMeasurementByStationIdAndDateApiResponse =
  /** status 200 OK */ IceMeasurementConfinesLogDto;
export type GetIceMeasurementByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата ледовых измерений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Интервал времени, равный 5 или 10 дням */
  interval?: number;
};
export type UpdateFormIceMeasurementsApiResponse = unknown;
export type UpdateFormIceMeasurementsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата ледовых измерений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: IceMeasurementEntity[];
};
export type GetGranulometryByIdApiResponse =
  /** status 200 OK */ GranulometryEntity;
export type GetGranulometryByIdApiArg = {
  /** Идентификатор гранулометрии */
  id: number;
};
export type UpdateGranulometryApiResponse =
  /** status 200 OK */ GranulometryEntity;
export type UpdateGranulometryApiArg = {
  /** Идентификатор гранулометрии */
  id: number;
  granulometryEntity: GranulometryEntity;
};
export type DeleteGranulometryByIdApiResponse = unknown;
export type DeleteGranulometryByIdApiArg = {
  /** Идентификатор гранулометрии */
  id: number;
};
export type GetGranulometryByStationIdAndDateAndTagApiResponse =
  /** status 200 OK */ GranulometryDto;
export type GetGranulometryByStationIdAndDateAndTagApiArg = {
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Идентификатор поста */
  id: number;
  /** Тег взвешенных/донных наносов */
  tag: string;
};
export type UpdateFormGranulometryApiResponse = unknown;
export type UpdateFormGranulometryApiArg = {
  /** Дата наблюдения в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Идентификатор поста */
  id: number;
  /** Тег взвешенных/донных наносов */
  tag: string;
  body: GranulometryEntity[];
};
export type GetFormDescriptionsByIdApiResponse =
  /** status 200 OK */ FormDescriptionsEntity;
export type GetFormDescriptionsByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateFormDescriptionsApiResponse =
  /** status 200 OK */ FormDescriptionsEntity;
export type UpdateFormDescriptionsApiArg = {
  /** Идентификатор */
  id: number;
  formDescriptionsEntity: FormDescriptionsEntity;
};
export type DeleteFormDescriptionsByIdApiResponse = unknown;
export type DeleteFormDescriptionsByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetFileStorageByIdApiResponse =
  /** status 200 OK */ FileStorageEntity;
export type GetFileStorageByIdApiArg = {
  /** Идентификатор файла долговременного хранения */
  id: number;
};
export type UpdateFileStorageApiResponse =
  /** status 200 OK */ FileStorageEntity;
export type UpdateFileStorageApiArg = {
  /** Идентификатор файла долговременного хранения */
  id: number;
  fileStorageEntity: FileStorageEntity;
};
export type DeleteFileStorageByIdApiResponse = unknown;
export type DeleteFileStorageByIdApiArg = {
  /** Идентификатор файла долговременного хранения */
  id: number;
};
export type GetErrorTextByCodeApiResponse =
  /** status 200 OK */ ErrorTextEntity;
export type GetErrorTextByCodeApiArg = {
  /** Код ошибки */
  code: number;
};
export type UpdateErrorTextApiResponse = /** status 200 OK */ ErrorTextEntity;
export type UpdateErrorTextApiArg = {
  /** Код ошибки */
  code: number;
  errorTextEntity: ErrorTextEntity;
};
export type DeleteErrorTextByIdApiResponse = unknown;
export type DeleteErrorTextByIdApiArg = {
  /** Код ошибки */
  code: number;
};
export type UpdateDailyAvgWaterFlowRateApiResponse = unknown;
export type UpdateDailyAvgWaterFlowRateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
};
export type CalculateWaterFlowByCurveApiResponse = unknown;
export type CalculateWaterFlowByCurveApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор кривой */
  curveId: number;
  /** Идентификатор периода, если не указать, то расчет будет произведен за весь год */
  periodId?: number;
  /** Учитывать поправки Стаута */
  useStoutCorrect: boolean;
};
export type GetConfinesByIdApiResponse = /** status 200 OK */ ConfinesEntity;
export type GetConfinesByIdApiArg = {
  /** Идентификатор предела */
  id: number;
};
export type UpdateConfinesApiResponse = /** status 200 OK */ ConfinesEntity;
export type UpdateConfinesApiArg = {
  /** Идентификатор предела */
  id: number;
  confinesEntity: ConfinesEntity;
};
export type DeleteConfinesByIdApiResponse = unknown;
export type DeleteConfinesByIdApiArg = {
  /** Идентификатор предела */
  id: number;
};
export type GetConfinesByStationIdApiResponse =
  /** status 200 OK */ ConfinesDto[];
export type GetConfinesByStationIdApiArg = {
  /** Идентификатор поста, для дефолтного указывать id = 0 */
  id: number;
  /** Показывать сезонные пределы (true) или годовые пределы (false) */
  showSeasons?: boolean;
};
export type UpdateFormConfinesApiResponse = unknown;
export type UpdateFormConfinesApiArg = {
  /** Идентификатор поста */
  id: number;
  body: ConfinesDto[];
};
export type GetConfinesLogByIdApiResponse =
  /** status 200 OK */ ConfinesLogEntity;
export type GetConfinesLogByIdApiArg = {
  /** Идентификатор события */
  id: number;
};
export type UpdateConfinesLogApiResponse =
  /** status 200 OK */ ConfinesLogEntity;
export type UpdateConfinesLogApiArg = {
  /** Идентификатор события */
  id: number;
  confinesLogEntity: ConfinesLogEntity;
};
export type DeleteConfinesLogByIdApiResponse = unknown;
export type DeleteConfinesLogByIdApiArg = {
  /** Идентификатор события */
  id: number;
};
export type GetCalculatedDataByIdApiResponse =
  /** status 200 OK */ CalculatedDataEntity;
export type GetCalculatedDataByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type UpdateCalculatedDataApiResponse =
  /** status 200 OK */ CalculatedDataEntity;
export type UpdateCalculatedDataApiArg = {
  /** Идентификатор данных */
  id: number;
  calculatedDataEntity: CalculatedDataEntity;
};
export type DeleteCalculatedDataByIdApiResponse = unknown;
export type DeleteCalculatedDataByIdApiArg = {
  /** Идентификатор данных */
  id: number;
};
export type GetKsvoMonthByStationIdAndDateApiResponse =
  /** status 200 OK */ KsvoMonthWithConfinesLogDto;
export type GetKsvoMonthByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
};
export type UpdateKsvoMonthApiResponse = unknown;
export type UpdateKsvoMonthApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
  calculatedDataDto: CalculatedDataDto;
};
export type GetCalculatedDataYearByIdApiResponse =
  /** status 200 OK */ CalculatedDataYearEntity;
export type GetCalculatedDataYearByIdApiArg = {
  /** Идентификатор данных за год */
  id: number;
};
export type UpdateCalculatedDataYearApiResponse =
  /** status 200 OK */ CalculatedDataYearEntity;
export type UpdateCalculatedDataYearApiArg = {
  /** Идентификатор данных за год */
  id: number;
  calculatedDataYearEntity: CalculatedDataYearEntity;
};
export type DeleteCalculatedDataYearByIdApiResponse = unknown;
export type DeleteCalculatedDataYearByIdApiArg = {
  /** Идентификатор данных за год */
  id: number;
};
export type GetSedimentDischargeByStationIdAndYearAndTagApiResponse =
  /** status 200 OK */ CalculatedDataYearDto[];
export type GetSedimentDischargeByStationIdAndYearAndTagApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Год */
  year: string;
};
export type UpdateSedimentDischargeApiResponse = unknown;
export type UpdateSedimentDischargeApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Год */
  year: string;
  body: CalculatedDataYearDto[];
};
export type GetBookByIdApiResponse = /** status 200 OK */ BooksEntity;
export type GetBookByIdApiArg = {
  /** Идентификатор книги */
  id: number;
};
export type UpdateBookApiResponse = /** status 200 OK */ BooksEntity;
export type UpdateBookApiArg = {
  /** Идентификатор книги */
  id: number;
  booksEntity: BooksEntity;
};
export type DeleteBookByIdApiResponse = unknown;
export type DeleteBookByIdApiArg = {
  /** Идентификатор книги */
  id: number;
};
export type GetBookStationByIdApiResponse =
  /** status 200 OK */ BookStationEntity;
export type GetBookStationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type UpdateBookStationApiResponse =
  /** status 200 OK */ BookStationEntity;
export type UpdateBookStationApiArg = {
  /** Идентификатор */
  id: number;
  bookStationEntity: BookStationEntity;
};
export type DeleteBookStationByIdApiResponse = unknown;
export type DeleteBookStationByIdApiArg = {
  /** Идентификатор */
  id: number;
};
export type GetBooksLogByIdApiResponse = /** status 200 OK */ BooksLogEntity;
export type GetBooksLogByIdApiArg = {
  /** Идентификатор лога */
  id: number;
};
export type UpdateBooksLogApiResponse = /** status 200 OK */ BooksLogEntity;
export type UpdateBooksLogApiArg = {
  /** Идентификатор лога */
  id: number;
  booksLogEntity: BooksLogEntity;
};
export type DeleteBooksLogByIdApiResponse = unknown;
export type DeleteBooksLogByIdApiArg = {
  /** Идентификатор лога */
  id: number;
};
export type GetBookLogFileByIdApiResponse =
  /** status 200 OK */ BookLogFileEntity;
export type GetBookLogFileByIdApiArg = {
  /** Идентификатор файла */
  id: number;
};
export type UpdateBookLogFileApiResponse =
  /** status 200 OK */ BookLogFileEntity;
export type UpdateBookLogFileApiArg = {
  /** Идентификатор файла */
  id: number;
  bookLogFileEntity: BookLogFileEntity;
};
export type DeleteBookLogFileByIdApiResponse = unknown;
export type DeleteBookLogFileByIdApiArg = {
  /** Идентификатор файла */
  id: number;
};
export type GetBasicObservationByIdApiResponse =
  /** status 200 OK */ BasicObservationsEntity;
export type GetBasicObservationByIdApiArg = {
  /** Идентификатор наблюдений */
  id: number;
};
export type UpdateBasicObservationApiResponse =
  /** status 200 OK */ BasicObservationsEntity;
export type UpdateBasicObservationApiArg = {
  /** Идентификатор наблюдений */
  id: number;
  basicObservationsEntity: BasicObservationsEntity;
};
export type DeleteBasicObservationByIdApiResponse = unknown;
export type DeleteBasicObservationByIdApiArg = {
  /** Идентификатор наблюдений */
  id: number;
};
export type GetBasicObservationsByStationIdAndDateApiResponse =
  /** status 200 OK */ BasicObservationsConfinesLogDto;
export type GetBasicObservationsByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата основных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Фильтр по времени: '08:00', '08:00,20:00', 'hourly' */
  time?: string;
};
export type UpdateFormBasicObservationsApiResponse = unknown;
export type UpdateFormBasicObservationsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата основных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
  body: BasicObservationsEntity[];
};
export type GetWaterObjectsAllApiResponse =
  /** status 200 OK */ WaterObjectsEntity[];
export type GetWaterObjectsAllApiArg = void;
export type CreateWaterObjectApiResponse =
  /** status 200 OK */ WaterObjectsEntity;
export type CreateWaterObjectApiArg = {
  waterObjectsEntity: WaterObjectsEntity;
};
export type GetWaterFlowAllApiResponse = /** status 200 OK */ WaterFlowEntity[];
export type GetWaterFlowAllApiArg = void;
export type CreateWaterFlowApiResponse = /** status 200 OK */ WaterFlowEntity;
export type CreateWaterFlowApiArg = {
  waterFlowEntity: WaterFlowEntity;
};
export type WaterFlowProcessApiResponse = /** status 200 OK */ string;
export type WaterFlowProcessApiArg = {
  pythonInput: PythonInput;
};
export type GetWaterFlowPointsAllApiResponse =
  /** status 200 OK */ WaterFlowPointEntity[];
export type GetWaterFlowPointsAllApiArg = void;
export type CreateWaterFlowPointApiResponse =
  /** status 200 OK */ WaterFlowPointEntity;
export type CreateWaterFlowPointApiArg = {
  waterFlowPointEntity: WaterFlowPointEntity;
};
export type GetWaterFlowPeriodsAllApiResponse =
  /** status 200 OK */ WaterFlowPeriodEntity[];
export type GetWaterFlowPeriodsAllApiArg = void;
export type CreateWaterFlowPeriodApiResponse =
  /** status 200 OK */ WaterFlowPeriodEntity;
export type CreateWaterFlowPeriodApiArg = {
  waterFlowPeriodEntity: WaterFlowPeriodEntity;
};
export type GetWaterFlowChannelsAllApiResponse =
  /** status 200 OK */ WaterFlowChannelEntity[];
export type GetWaterFlowChannelsAllApiArg = void;
export type CreateWaterFlowChannelApiResponse =
  /** status 200 OK */ WaterFlowChannelEntity;
export type CreateWaterFlowChannelApiArg = {
  waterFlowChannelEntity: WaterFlowChannelEntity;
};
export type GetWaterFlowChannelMeasurementsAllApiResponse =
  /** status 200 OK */ WaterFlowChannelMeasurementEntity[];
export type GetWaterFlowChannelMeasurementsAllApiArg = void;
export type CreateWaterFlowChannelMeasurementApiResponse =
  /** status 200 OK */ WaterFlowChannelMeasurementEntity;
export type CreateWaterFlowChannelMeasurementApiArg = {
  waterFlowChannelMeasurementEntity: WaterFlowChannelMeasurementEntity;
};
export type GetVocWaterFlowCurveTypesAllApiResponse =
  /** status 200 OK */ VocWaterFlowCurveType[];
export type GetVocWaterFlowCurveTypesAllApiArg = void;
export type CreateVocWaterFlowCurveTypeApiResponse =
  /** status 200 OK */ VocWaterFlowCurveType;
export type CreateVocWaterFlowCurveTypeApiArg = {
  vocWaterFlowCurveType: VocWaterFlowCurveType;
};
export type GetVocWaterFlowCalculationMethodsAllApiResponse =
  /** status 200 OK */ VocWaterFlowCalculationMethod[];
export type GetVocWaterFlowCalculationMethodsAllApiArg = void;
export type CreateVocWaterFlowCalculationMethodApiResponse =
  /** status 200 OK */ VocWaterFlowCalculationMethod;
export type CreateVocWaterFlowCalculationMethodApiArg = {
  vocWaterFlowCalculationMethod: VocWaterFlowCalculationMethod;
};
export type GetVocRegionsAllApiResponse = /** status 200 OK */ VocRegion[];
export type GetVocRegionsAllApiArg = void;
export type CreateVocRegionApiResponse = /** status 200 OK */ VocRegion;
export type CreateVocRegionApiArg = {
  vocRegion: VocRegion;
};
export type GetVocListsAllApiResponse = /** status 200 OK */ VocListsEntity[];
export type GetVocListsAllApiArg = void;
export type CreateVocListsApiResponse = /** status 200 OK */ VocListsEntity;
export type CreateVocListsApiArg = {
  vocListsEntity: VocListsEntity;
};
export type GetVocLakeWaterAreaPostsAllApiResponse =
  /** status 200 OK */ VocLakeWaterAreaPost[];
export type GetVocLakeWaterAreaPostsAllApiArg = void;
export type CreateVocLakeWaterAreaPostApiResponse =
  /** status 200 OK */ VocLakeWaterAreaPost;
export type CreateVocLakeWaterAreaPostApiArg = {
  vocLakeWaterAreaPost: VocLakeWaterAreaPost;
};
export type GetVocLakeTypesAllApiResponse = /** status 200 OK */ VocLakeType[];
export type GetVocLakeTypesAllApiArg = void;
export type CreateVocLakeTypeApiResponse = /** status 200 OK */ VocLakeType;
export type CreateVocLakeTypeApiArg = {
  vocLakeType: VocLakeType;
};
export type GetVocLakeRegulationNatureAllApiResponse =
  /** status 200 OK */ VocLakeRegulationNature[];
export type GetVocLakeRegulationNatureAllApiArg = void;
export type CreateVocLakeRegulationNatureApiResponse =
  /** status 200 OK */ VocLakeRegulationNature;
export type CreateVocLakeRegulationNatureApiArg = {
  vocLakeRegulationNature: VocLakeRegulationNature;
};
export type GetStationYearsAllApiResponse =
  /** status 200 OK */ StationYearEntity[];
export type GetStationYearsAllApiArg = void;
export type CreateStationYearApiResponse =
  /** status 200 OK */ StationYearEntity;
export type CreateStationYearApiArg = {
  stationYearEntity: StationYearEntity;
};
export type GetXmlApiResponse = /** status 200 OK */ object;
export type GetXmlApiArg = {
  stationInput: StationInput;
};
export type GetXmlTxtApiResponse = /** status 200 OK */ object;
export type GetXmlTxtApiArg = {
  stationInput: StationInput;
};
export type GetStationYearParametersAllApiResponse =
  /** status 200 OK */ StationYearParametersEntity[];
export type GetStationYearParametersAllApiArg = void;
export type CreateStationYearParameterApiResponse =
  /** status 200 OK */ StationYearParametersEntity;
export type CreateStationYearParameterApiArg = {
  stationYearParametersEntity: StationYearParametersEntity;
};
export type GetOgxApiResponse = /** status 200 OK */ object;
export type GetOgxApiArg = {
  stationInput: StationInput;
};
export type GetOgxTxtApiResponse = /** status 200 OK */ object;
export type GetOgxTxtApiArg = {
  stationInput: StationInput;
};
export type GetSiltChargeAllApiResponse =
  /** status 200 OK */ SiltChargeEntity[];
export type GetSiltChargeAllApiArg = void;
export type CreateSiltChargeApiResponse = /** status 200 OK */ SiltChargeEntity;
export type CreateSiltChargeApiArg = {
  siltChargeEntity: SiltChargeEntity;
};
export type GetSeasonsWaterManagementsAllApiResponse =
  /** status 200 OK */ SeasonsWaterManagementEntity[];
export type GetSeasonsWaterManagementsAllApiArg = void;
export type CreateSeasonsWaterManagementApiResponse =
  /** status 200 OK */ SeasonsWaterManagementEntity;
export type CreateSeasonsWaterManagementApiArg = {
  seasonsWaterManagementEntity: SeasonsWaterManagementEntity;
};
export type MonthlyProcessApiResponse = /** status 200 OK */ string;
export type MonthlyProcessApiArg = {
  pythonInput: PythonInput;
};
export type ExportMdsApiResponse = /** status 200 OK */ TableResult;
export type ExportMdsApiArg = {
  bookInput: BookInput;
};
export type GetLengthwiseSlopeAllApiResponse =
  /** status 200 OK */ LengthwiseSlopeEntity[];
export type GetLengthwiseSlopeAllApiArg = void;
export type CreateLengthwiseSlopeApiResponse =
  /** status 200 OK */ LengthwiseSlopeEntity;
export type CreateLengthwiseSlopeApiArg = {
  lengthwiseSlopeEntity: LengthwiseSlopeEntity;
};
export type GetLakeXmlApiResponse = /** status 200 OK */ object;
export type GetLakeXmlApiArg = {
  stationInput: StationInput;
};
export type GetLakeXmlTxtApiResponse = /** status 200 OK */ object;
export type GetLakeXmlTxtApiArg = {
  stationInput: StationInput;
};
export type GetLakeWaveObservationsAllApiResponse =
  /** status 200 OK */ LakeWaveObservation[];
export type GetLakeWaveObservationsAllApiArg = void;
export type CreateLakeWaveObservationApiResponse =
  /** status 200 OK */ LakeWaveObservation;
export type CreateLakeWaveObservationApiArg = {
  lakeWaveObservation: LakeWaveObservation;
};
export type GetLakeWaterTemperatureObservationsAllApiResponse =
  /** status 200 OK */ LakeWaterTemperatureObservation[];
export type GetLakeWaterTemperatureObservationsAllApiArg = void;
export type CreateLakeWaterTemperatureObservationApiResponse =
  /** status 200 OK */ LakeWaterTemperatureObservation;
export type CreateLakeWaterTemperatureObservationApiArg = {
  lakeWaterTemperatureObservation: LakeWaterTemperatureObservation;
};
export type GetLakeWaterAreaPostsAllApiResponse =
  /** status 200 OK */ LakeWaterAreaPost[];
export type GetLakeWaterAreaPostsAllApiArg = void;
export type CreateLakeWaterAreaPostApiResponse =
  /** status 200 OK */ LakeWaterAreaPost;
export type CreateLakeWaterAreaPostApiArg = {
  lakeWaterAreaPost: LakeWaterAreaPost;
};
export type GetLakeSeasonsWaterManagementsAllApiResponse =
  /** status 200 OK */ LakeSeasonsWaterManagement[];
export type GetLakeSeasonsWaterManagementsAllApiArg = void;
export type CreateLakeSeasonsWaterManagementApiResponse =
  /** status 200 OK */ LakeSeasonsWaterManagement;
export type CreateLakeSeasonsWaterManagementApiArg = {
  lakeSeasonsWaterManagement: LakeSeasonsWaterManagement;
};
export type GetLakePostYearsAllApiResponse =
  /** status 200 OK */ LakePostYear[];
export type GetLakePostYearsAllApiArg = void;
export type CreateLakePostYearApiResponse = /** status 200 OK */ LakePostYear;
export type CreateLakePostYearApiArg = {
  lakePostYear: LakePostYear;
};
export type GetLakePostYearParametersAllApiResponse =
  /** status 200 OK */ LakePostYearParameter[];
export type GetLakePostYearParametersAllApiArg = void;
export type CreateLakePostYearParameterApiResponse =
  /** status 200 OK */ LakePostYearParameter;
export type CreateLakePostYearParameterApiArg = {
  lakePostYearParameter: LakePostYearParameter;
};
export type GetLakePassportsAllApiResponse =
  /** status 200 OK */ LakePassport[];
export type GetLakePassportsAllApiArg = void;
export type CreateLakePassportApiResponse = /** status 200 OK */ LakePassport;
export type CreateLakePassportApiArg = {
  lakePassport: LakePassport;
};
export type GetLakeObservationPointsAllApiResponse =
  /** status 200 OK */ LakeObservationPoint[];
export type GetLakeObservationPointsAllApiArg = void;
export type CreateLakeObservationPointApiResponse =
  /** status 200 OK */ LakeObservationPoint;
export type CreateLakeObservationPointApiArg = {
  lakeObservationPoint: LakeObservationPoint;
};
export type LakeMonthlyProcessApiResponse = /** status 200 OK */ string;
export type LakeMonthlyProcessApiArg = {
  pythonInput: PythonInput;
};
export type GetLakeMinMaxWaveObservationsAllApiResponse =
  /** status 200 OK */ LakeMinMaxWaveObservation[];
export type GetLakeMinMaxWaveObservationsAllApiArg = void;
export type CreateLakeMinMaxWaveObservationApiResponse =
  /** status 200 OK */ LakeMinMaxWaveObservation;
export type CreateLakeMinMaxWaveObservationApiArg = {
  lakeMinMaxWaveObservation: LakeMinMaxWaveObservation;
};
export type LakeExportMdsApiResponse = /** status 200 OK */ TableResult;
export type LakeExportMdsApiArg = {
  bookInput: BookInput;
};
export type GetLakeIceProfilesObservationsAllApiResponse =
  /** status 200 OK */ LakeIceProfilesObservation[];
export type GetLakeIceProfilesObservationsAllApiArg = void;
export type CreateLakeIceProfilesObservationApiResponse =
  /** status 200 OK */ LakeIceProfilesObservation;
export type CreateLakeIceProfilesObservationApiArg = {
  lakeIceProfilesObservation: LakeIceProfilesObservation;
};
export type GetLakeIceObservationsAllApiResponse =
  /** status 200 OK */ LakeIceObservation[];
export type GetLakeIceObservationsAllApiArg = void;
export type CreateLakeIceObservationsApiResponse =
  /** status 200 OK */ LakeIceObservation;
export type CreateLakeIceObservationsApiArg = {
  lakeIceObservation: LakeIceObservation;
};
export type GetLakeHydrologicalObservationsAllApiResponse =
  /** status 200 OK */ LakeHydrologicalObservation[];
export type GetLakeHydrologicalObservationsAllApiArg = void;
export type CreateLakeHydrologicalObservationApiResponse =
  /** status 200 OK */ LakeHydrologicalObservation;
export type CreateLakeHydrologicalObservationApiArg = {
  lakeHydrologicalObservation: LakeHydrologicalObservation;
};
export type GetLakeFormDescriptionsAllApiResponse =
  /** status 200 OK */ LakeFormDescription[];
export type GetLakeFormDescriptionsAllApiArg = void;
export type CreateLakeFormDescriptionsApiResponse =
  /** status 200 OK */ LakeFormDescription;
export type CreateLakeFormDescriptionsApiArg = {
  lakeFormDescription: LakeFormDescription;
};
export type LakeExportEmdsApiResponse = /** status 200 OK */ TableResult;
export type LakeExportEmdsApiArg = {
  bookInput: BookInput;
};
export type LakeExportEdsApiResponse = /** status 200 OK */ TableResult;
export type LakeExportEdsApiArg = {
  bookInput: BookInput;
};
export type GetLakeDirectionSpeedObservationsAllApiResponse =
  /** status 200 OK */ LakeDirectionSpeedObservation[];
export type GetLakeDirectionSpeedObservationsAllApiArg = void;
export type CreateLakeDirectionSpeedObservationApiResponse =
  /** status 200 OK */ LakeDirectionSpeedObservation;
export type CreateLakeDirectionSpeedObservationApiArg = {
  lakeDirectionSpeedObservation: LakeDirectionSpeedObservation;
};
export type GetLakeConfinesAllApiResponse = /** status 200 OK */ LakeConfines[];
export type GetLakeConfinesAllApiArg = void;
export type CreateLakeConfinesApiResponse = /** status 200 OK */ LakeConfines;
export type CreateLakeConfinesApiArg = {
  lakeConfines: LakeConfines;
};
export type GetLakeCalculatedDatumAllApiResponse =
  /** status 200 OK */ LakeCalculatedDatum[];
export type GetLakeCalculatedDatumAllApiArg = void;
export type CreateLakeCalculatedDatumApiResponse =
  /** status 200 OK */ LakeCalculatedDatum;
export type CreateLakeCalculatedDatumApiArg = {
  lakeCalculatedDatum: LakeCalculatedDatum;
};
export type GetLakeCalculatedDataYearsAllApiResponse =
  /** status 200 OK */ LakeCalculatedDataYear[];
export type GetLakeCalculatedDataYearsAllApiArg = void;
export type CreateLakeCalculatedDataYearApiResponse =
  /** status 200 OK */ LakeCalculatedDataYear;
export type CreateLakeCalculatedDataYearApiArg = {
  lakeCalculatedDataYear: LakeCalculatedDataYear;
};
export type GetLakeBookPostsAllApiResponse =
  /** status 200 OK */ LakeBookPost[];
export type GetLakeBookPostsAllApiArg = void;
export type CreateLakeBookPostApiResponse = /** status 200 OK */ LakeBookPost;
export type CreateLakeBookPostApiArg = {
  lakeBookPost: LakeBookPost;
};
export type GetLakeBookLogAllApiResponse = /** status 200 OK */ LakeBookLog[];
export type GetLakeBookLogAllApiArg = void;
export type CreateLakeBookLogApiResponse = /** status 200 OK */ LakeBookLog;
export type CreateLakeBookLogApiArg = {
  lakeBookLog: LakeBookLog;
};
export type GetLakeBasicObservationsAllApiResponse =
  /** status 200 OK */ LakeBasicObservation[];
export type GetLakeBasicObservationsAllApiArg = void;
export type CreateLakeBasicObservationApiResponse =
  /** status 200 OK */ LakeBasicObservation;
export type CreateLakeBasicObservationApiArg = {
  lakeBasicObservation: LakeBasicObservation;
};
export type LakeAnnualProcessApiResponse =
  /** status 200 OK */ AnnualProcessConfinesLogDto;
export type LakeAnnualProcessApiArg = {
  annualInput: AnnualInput;
};
export type GetKsvoRegimesAllApiResponse =
  /** status 200 OK */ KsvoRegimeEntity[];
export type GetKsvoRegimesAllApiArg = void;
export type CreateKsvoRegimeApiResponse = /** status 200 OK */ KsvoRegimeEntity;
export type CreateKsvoRegimeApiArg = {
  ksvoRegimeEntity: KsvoRegimeEntity;
};
export type ImportXmlApiResponse = /** status 200 OK */ object;
export type ImportXmlApiArg = {
  body: string;
};
export type ImportTxtApiResponse = /** status 200 OK */ object;
export type ImportTxtApiArg = {
  body: string;
};
export type ImportOgxXmlApiResponse = /** status 200 OK */ object;
export type ImportOgxXmlApiArg = {
  body: string;
};
export type ImportLakeXmlApiResponse = /** status 200 OK */ object;
export type ImportLakeXmlApiArg = {
  body: string;
};
export type ImportFolderApiResponse = /** status 200 OK */ object;
export type ImportFolderApiArg = {
  textInput: TextInput;
};
export type GetIceMeasurementsAllApiResponse =
  /** status 200 OK */ IceMeasurementEntity[];
export type GetIceMeasurementsAllApiArg = void;
export type CreateIceMeasurementApiResponse =
  /** status 200 OK */ IceMeasurementEntity;
export type CreateIceMeasurementApiArg = {
  iceMeasurementEntity: IceMeasurementEntity;
};
export type GetGranulometryAllApiResponse =
  /** status 200 OK */ GranulometryEntity[];
export type GetGranulometryAllApiArg = void;
export type CreateGranulometryApiResponse =
  /** status 200 OK */ GranulometryEntity;
export type CreateGranulometryApiArg = {
  granulometryEntity: GranulometryEntity;
};
export type GetFormDescriptionsAllApiResponse =
  /** status 200 OK */ FormDescriptionsEntity[];
export type GetFormDescriptionsAllApiArg = void;
export type CreateFormDescriptionsApiResponse =
  /** status 200 OK */ FormDescriptionsEntity;
export type CreateFormDescriptionsApiArg = {
  formDescriptionsEntity: FormDescriptionsEntity;
};
export type GetFileStorageAllApiResponse =
  /** status 200 OK */ FileStorageEntity[];
export type GetFileStorageAllApiArg = void;
export type CreateFileStorageApiResponse =
  /** status 200 OK */ FileStorageEntity;
export type CreateFileStorageApiArg = {
  fileStorageEntity: FileStorageEntity;
};
export type GenerateFilesApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GenerateFilesApiArg = {
  pythonInput: PythonInput;
};
export type GetErrorTextAllApiResponse = /** status 200 OK */ ErrorTextEntity[];
export type GetErrorTextAllApiArg = void;
export type CreateErrorTextApiResponse = /** status 200 OK */ ErrorTextEntity;
export type CreateErrorTextApiArg = {
  errorTextEntity: ErrorTextEntity;
};
export type ExportEmdsApiResponse = /** status 200 OK */ TableResult;
export type ExportEmdsApiArg = {
  bookInput: BookInput;
};
export type ExportEdsApiResponse = /** status 200 OK */ TableResult;
export type ExportEdsApiArg = {
  bookInput: BookInput;
};
export type CalculateWaterFlowByLinearInterpolationApiResponse =
  /** status 200 OK */ string;
export type CalculateWaterFlowByLinearInterpolationApiArg = {
  pythonInput: PythonInput;
};
export type GetConfinesAllApiResponse = /** status 200 OK */ ConfinesEntity[];
export type GetConfinesAllApiArg = void;
export type CreateConfinesApiResponse = /** status 200 OK */ ConfinesEntity;
export type CreateConfinesApiArg = {
  confinesEntity: ConfinesEntity;
};
export type GetConfinesLogAllApiResponse =
  /** status 200 OK */ ConfinesLogEntity[];
export type GetConfinesLogAllApiArg = void;
export type CreateConfinesLogApiResponse =
  /** status 200 OK */ ConfinesLogEntity;
export type CreateConfinesLogApiArg = {
  confinesLogEntity: ConfinesLogEntity;
};
export type GetCalculatedDataAllApiResponse =
  /** status 200 OK */ CalculatedDataEntity[];
export type GetCalculatedDataAllApiArg = void;
export type CreateCalculatedDataApiResponse =
  /** status 200 OK */ CalculatedDataEntity;
export type CreateCalculatedDataApiArg = {
  calculatedDataEntity: CalculatedDataEntity;
};
export type GetCalculatedDataYearsAllApiResponse =
  /** status 200 OK */ CalculatedDataYearEntity[];
export type GetCalculatedDataYearsAllApiArg = void;
export type CreateCalculatedDataYearApiResponse =
  /** status 200 OK */ CalculatedDataYearEntity;
export type CreateCalculatedDataYearApiArg = {
  calculatedDataYearEntity: CalculatedDataYearEntity;
};
export type GetBooksAllApiResponse = /** status 200 OK */ BooksEntity[];
export type GetBooksAllApiArg = void;
export type CreateBookApiResponse = /** status 200 OK */ BooksEntity;
export type CreateBookApiArg = {
  booksEntity: BooksEntity;
};
export type GetBooksStationsAllApiResponse =
  /** status 200 OK */ BookStationEntity[];
export type GetBooksStationsAllApiArg = void;
export type CreateBookStationApiResponse =
  /** status 200 OK */ BookStationEntity;
export type CreateBookStationApiArg = {
  bookStationEntity: BookStationEntity;
};
export type GetBooksLogAllApiResponse = /** status 200 OK */ BooksLogEntity[];
export type GetBooksLogAllApiArg = void;
export type CreateBooksLogApiResponse = /** status 200 OK */ BooksLogEntity;
export type CreateBooksLogApiArg = {
  booksLogEntity: BooksLogEntity;
};
export type GetBooksLogFileAllApiResponse =
  /** status 200 OK */ BookLogFileEntity[];
export type GetBooksLogFileAllApiArg = void;
export type CreateBookLogFileApiResponse =
  /** status 200 OK */ BookLogFileEntity;
export type CreateBookLogFileApiArg = {
  bookLogFileEntity: BookLogFileEntity;
};
export type GetBasicObservationsAllApiResponse =
  /** status 200 OK */ BasicObservationsEntity[];
export type GetBasicObservationsAllApiArg = void;
export type CreateBasicObservationApiResponse =
  /** status 200 OK */ BasicObservationsEntity;
export type CreateBasicObservationApiArg = {
  basicObservationsEntity: BasicObservationsEntity;
};
export type AnnualProcessWithErrorsApiResponse =
  /** status 200 OK */ AnnualProcessConfinesLogDto;
export type AnnualProcessWithErrorsApiArg = {
  annualInput: AnnualInput;
};
export type GetWaterByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ WaterFlowEntity[];
export type GetWaterByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата измерения расхода */
  date1: string;
  /** Конечная дата измерения расхода */
  date2: string;
};
export type GetWaterFlowByStationIdApiResponse =
  /** status 200 OK */ WaterFlowEntity[];
export type GetWaterFlowByStationIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type GetWaterFlowTempByStationIdAndYearApiResponse =
  /** status 200 OK */ WaterFlowTempDto[];
export type GetWaterFlowTempByStationIdAndYearApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификатор периода, если не указать, то будет взят дефолтный период за весь год */
  periodId?: number;
};
export type GetWaterFlowTempByStationIdAndYearsApiResponse =
  /** status 200 OK */ WaterFlowTempDto[];
export type GetWaterFlowTempByStationIdAndYearsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Список лет (можно указать до 10 лет) для отображения данных для построения кривой */
  years: string[];
};
export type GetMaxDepthByStationIdAndYearApiResponse =
  /** status 200 OK */ number;
export type GetMaxDepthByStationIdAndYearApiArg = {
  /** Идентификаторы объектов с used = true */
  usedIds: number[];
};
export type GetWaterFlowDaysApiResponse = /** status 200 OK */ number[];
export type GetWaterFlowDaysApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
};
export type GetWaterFlowDaysWithWaterFlowNumbersApiResponse =
  /** status 200 OK */ {
    [key: string]: number[];
  };
export type GetWaterFlowDaysWithWaterFlowNumbersApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата в формате MM.yyyy */
  date: string;
};
export type GetWaterFlowCurveByStationIdApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity[];
export type GetWaterFlowCurveByStationIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type GetWaterFlowCurveByPeriodIdApiResponse =
  /** status 200 OK */ WaterFlowCurveEntity;
export type GetWaterFlowCurveByPeriodIdApiArg = {
  /** Идентификатор периода */
  periodId: number;
};
export type GetPolynomialCoefficientsApiResponse =
  /** status 200 OK */ WaterFlowCurveDto;
export type GetPolynomialCoefficientsApiArg = {
  /** Идентификатор типа кривой */
  curveTypeId: number;
  /** Идентификаторы объектов с used = true */
  usedIds: number[];
};
export type GetGlushCoefficientsApiResponse =
  /** status 200 OK */ WaterFlowCurveDto;
export type GetGlushCoefficientsApiArg = {
  /** Наибольшая глубина, требуется для расчетов, задается пользователем или присылается из базы */
  maxDepth: number;
  /** Идентификатор поста */
  id: number;
  /** Расчетный год */
  year: string;
  /** Идентификаторы объектов с used = true */
  usedIds: number[];
};
export type GetVocListsByTagAndAttributeApiResponse =
  /** status 200 OK */ VocListsEntity[];
export type GetVocListsByTagAndAttributeApiArg = {
  /** Уникальный тег */
  tag: string;
  /** Атрибут тега (может отсутствовать) */
  attribute?: string;
};
export type GetStationByIdApiResponse = /** status 200 OK */ StationDto;
export type GetStationByIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type DeleteStationByIdApiResponse = unknown;
export type DeleteStationByIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type GetStationsAllWithWaterLevelDayAvgApiResponse =
  /** status 200 OK */ StationEntity[];
export type GetStationsAllWithWaterLevelDayAvgApiArg = {
  /** Начальная дата в формате dd.MM.yyyy */
  startDate: string;
  /** Конечная дата в формате dd.MM.yyyy */
  endDate: string;
};
export type GetStationsAllWithWaterFlowApiResponse =
  /** status 200 OK */ StationEntity[];
export type GetStationsAllWithWaterFlowApiArg = void;
export type GetStationsAllWithTotalThicknessIceApiResponse =
  /** status 200 OK */ StationEntity[];
export type GetStationsAllWithTotalThicknessIceApiArg = void;
export type GetStationsAllWithAnnualWaterTemperatureApiResponse =
  /** status 200 OK */ StationEntity[];
export type GetStationsAllWithAnnualWaterTemperatureApiArg = void;
export type GetStationsAllForMapApiResponse = /** status 200 OK */ StationDto[];
export type GetStationsAllForMapApiArg = void;
export type GetYearsByStationIdApiResponse = /** status 200 OK */ string[];
export type GetYearsByStationIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type GetStationTsByIdxForGraphsApiResponse = /** status 200 OK */ {
  [key: string]: ResponseValue[][];
};
export type GetStationTsByIdxForGraphsApiArg = {
  /** id поста */
  stationIdx: string;
  /** Дата выгрузки dd.MM.yyyy или MM.yyyy */
  date: string;
  /** Метеокод */
  meteocodes: string[];
  /** Интервал 1H/3H/12H/24H или null если нужно выгрузить все данные */
  interval?: string;
  /** Произвести округление  данных */
  round?: boolean;
  /** Часовой пояс пользователя. UTC+00 по умолчанию */
  timezone?: number;
};
export type GetLakePostByIdApiResponse = /** status 200 OK */ LakePostDto;
export type GetLakePostByIdApiArg = {
  /** Идентификатор озерного поста */
  id: number;
};
export type DeleteLakePostByIdApiResponse = unknown;
export type DeleteLakePostByIdApiArg = {
  /** Идентификатор озерного поста */
  id: number;
};
export type GetLakePostsAllWithWaterLevelDayAvgApiResponse =
  /** status 200 OK */ LakePostDto[];
export type GetLakePostsAllWithWaterLevelDayAvgApiArg = {
  /** Начальная дата в формате dd.MM.yyyy */
  startDate: string;
  /** Конечная дата в формате dd.MM.yyyy */
  endDate: string;
};
export type GetLakePostsAllWithTotalThicknessIceApiResponse =
  /** status 200 OK */ LakePostDto[];
export type GetLakePostsAllWithTotalThicknessIceApiArg = void;
export type GetLakePostsAllWithAnnualWaterTemperatureApiResponse =
  /** status 200 OK */ LakePostDto[];
export type GetLakePostsAllWithAnnualWaterTemperatureApiArg = void;
export type GetYearsByPostIdApiResponse = /** status 200 OK */ string[];
export type GetYearsByPostIdApiArg = {
  /** Идентификатор поста */
  id: number;
};
export type GetLakePostYearParametersByYearAndPostIdApiResponse =
  /** status 200 OK */ LakePostYearParameterConfinesLogDto;
export type GetLakePostYearParametersByYearAndPostIdApiArg = {
  /** Год */
  year: string;
  /** Идентификатор поста */
  id: number;
};
export type GetLakeIceObservationsByPostIdAndDateBetweenApiResponse =
  /** status 200 OK */ LakeIceObservation[];
export type GetLakeIceObservationsByPostIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата ледовых измерений в формате dd.MM.yyyy */
  date1: string;
  /** Конечная дата ледовых измерений в формате dd.MM.yyyy */
  date2: string;
};
export type HasLakeMonthlyProcessingApiResponse = /** status 200 OK */ boolean;
export type HasLakeMonthlyProcessingApiArg = {
  /** Год */
  year: string;
  /** Идентификатор озёрного поста */
  id: number;
};
export type GetLakeFormDescriptionsByFormIdAndPostIdApiResponse =
  /** status 200 OK */ LakeFormDescription[];
export type GetLakeFormDescriptionsByFormIdAndPostIdApiArg = {
  /** Идентификатор формы */
  formId: number;
  /** Идентификатор поста */
  id: number;
  /** Месяц наблюдения */
  month: number;
};
export type HasLakeErrorsApiResponse = /** status 200 OK */ boolean;
export type HasLakeErrorsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате MM.yyyy */
  date: string;
};
export type GetLakeCalculatedDataByPostIdAndDateApiResponse =
  /** status 200 OK */ LakeCalculatedDatum[];
export type GetLakeCalculatedDataByPostIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
};
export type GetLakeCalculatedDataByPostIdAndDateBetweenApiResponse =
  /** status 200 OK */ LakeCalculatedDatum[];
export type GetLakeCalculatedDataByPostIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата расчетных данных в формате dd.MM.yyyy */
  date1: string;
  /** Конечная дата расчетных данных формате dd.MM.yyyy */
  date2: string;
  /** Тег расчетных данных */
  tag: string;
};
export type GetLakeCalculatedDataYearByPostIdAndDateBetweenApiResponse =
  /** status 200 OK */ LakeCalculatedDataYear[];
export type GetLakeCalculatedDataYearByPostIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата расчетных данных в формате dd.MM.yyyy */
  date1: string;
  /** Конечная дата расчетных данных в формате dd.MM.yyyy */
  date2: string;
  /** Тег расчетных данных */
  tag: string;
};
export type GetLakeBookPostsByIssueNumberApiResponse =
  /** status 200 OK */ BookStationDto[];
export type GetLakeBookPostsByIssueNumberApiArg = {
  /** Номер выпуска */
  issueNumber: number;
};
export type GetKsvoRegimeByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ KsvoRegimeEntity[];
export type GetKsvoRegimeByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата в формате dd.MM.yyyy */
  date1: string;
  /** Конечная дата в формате dd.MM.yyyy */
  date2: string;
};
export type GetIceMeasurementByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ IceMeasurementEntity[];
export type GetIceMeasurementByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата ледовых измерений */
  date1: string;
  /** Конечная дата ледовых измерений */
  date2: string;
};
export type HasMonthlyProcessingApiResponse = /** status 200 OK */ boolean;
export type HasMonthlyProcessingApiArg = {
  /** Год */
  year: string;
  /** Идентификатор поста */
  id: number;
};
export type GetFormDescriptionsByFormIdApiResponse =
  /** status 200 OK */ FormDescriptionsEntity[];
export type GetFormDescriptionsByFormIdApiArg = {
  /** Идентификатор формы */
  formId: number;
  /** Идентификатор поста */
  id: number;
  /** Месяц наблюдения */
  month: number;
};
export type GetFileStorageByYearAndStationIdApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GetFileStorageByYearAndStationIdApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Год */
  year: string;
};
export type GetFileStorageInZipApiResponse = /** status 200 OK */ string[];
export type GetFileStorageInZipApiArg = {
  /** Список идентификаторов файлов */
  fileIds: number[];
};
export type CowSayApiResponse = /** status 200 OK */ string;
export type CowSayApiArg = {
  message: string;
};
export type GetByEntityAndDateApiResponse =
  /** status 200 OK */ ConfinesLogEntity[];
export type GetByEntityAndDateApiArg = {
  /** Сущность (basic-observation напр.) */
  entity: string;
  /** Дата наблюдения в формате MM.yyyy или dd.MM.yyyy */
  date: string;
  /** Идентификатор станции */
  stationId: number;
};
export type HasErrorsApiResponse = /** status 200 OK */ boolean;
export type HasErrorsApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата наблюдения в формате MM.yyyy */
  date: string;
};
export type GetCalculatedDataByStationIdAndDateApiResponse =
  /** status 200 OK */ CalculatedDataEntity[];
export type GetCalculatedDataByStationIdAndDateApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата расчетных данных в формате MM.yyyy */
  date: string;
};
export type GetCalculatedDataByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ CalculatedDataEntity[];
export type GetCalculatedDataByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата расчетных данных */
  date1: string;
  /** Конечная дата расчетных данных */
  date2: string;
  /** Тег расчетных данных */
  tag: string;
};
export type GetCalculatedDataYearByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ CalculatedDataYearEntity[];
export type GetCalculatedDataYearByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата расчетных данных */
  date1: string;
  /** Конечная дата расчетных данных */
  date2: string;
  /** Тег расчетных данных */
  tag: string;
};
export type GetBookStationsByIssueNumberApiResponse =
  /** status 200 OK */ BookStationDto[];
export type GetBookStationsByIssueNumberApiArg = {
  /** Номер выпуска */
  issueNumber: number;
};
export type GetAllBookLogFilesInZipApiResponse = /** status 200 OK */ string[];
export type GetAllBookLogFilesInZipApiArg = {
  /** Список идентификаторов */
  fileIds: number[];
};
export type GetBookLogFileInHtmlApiResponse = /** status 200 OK */ string[];
export type GetBookLogFileInHtmlApiArg = {
  /** Идентификатор */
  fileId: number;
};
export type GetBasicObservationsByStationIdAndDateBetweenApiResponse =
  /** status 200 OK */ BasicObservationsEntity[];
export type GetBasicObservationsByStationIdAndDateBetweenApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Начальная дата основных наблюдений */
  date1: string;
  /** Конечная дата основных наблюдений */
  date2: string;
};
export type GetBasicObservationsFromWaterFlowApiResponse =
  /** status 200 OK */ BasicObservationsEntity[];
export type GetBasicObservationsFromWaterFlowApiArg = {
  /** Идентификатор поста */
  id: number;
  /** Дата основных наблюдений в формате dd.MM.yyyy или MM.yyyy */
  date: string;
};
export type BasicObservationsEntity = {
  id?: number;
  date1?: string;
  waterLevel?: number;
  waterLevelChar?: number;
  ksvo1?: number;
  coverage1?: number;
  ksvo2?: number;
  coverage2?: number;
  ksvo3?: number;
  coverage3?: number;
  ksvo4?: number;
  coverage4?: number;
  ksvo5?: number;
  coverage5?: number;
  waterTemperature?: number;
  waterTemperatureChar?: number;
  airTemperature?: number;
  airTemperatureChar?: number;
  precipitation?: number;
  precipitationType?: number;
  precipitationChar?: number;
  stationYearId?: StationYearEntity;
};
export type SiltChargeEntity = {
  id?: number;
  date1?: string;
  date2?: string;
  siltCharge?: number;
  siltChargeChar?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  stationYear?: StationYearEntity;
};
export type LengthwiseSlopeEntity = {
  id?: number;
  date?: string;
  lengthwiseSlope?: number;
  lengthwiseSlopeDrop?: number;
  lengthwiseSlopeChar?: number;
  stationYear?: StationYearEntity;
};
export type LocalTime = {
  hour?: number;
  minute?: number;
  second?: number;
  nano?: number;
};
export type KsvoRegimeEntity = {
  id?: number;
  date1?: string;
  ksvoRegime?: number;
  ksvoRegimePriority?: number;
  stationYearId?: StationYearEntity;
  calculatedData?: CalculatedDataEntity;
};
export type CalculatedDataEntity = {
  id?: number;
  tag?: string;
  code?: string;
  proc?: string;
  t?: string;
  date1?: string;
  date2?: string;
  time?: LocalTime;
  year?: string;
  decade?: number;
  value?: number;
  value2?: number;
  value3?: number;
  value4?: number;
  charact?: number;
  count?: number;
  backwater?: boolean;
  method?: string;
  type?: number;
  belongAggregation?: number;
  one?: boolean;
  anotherPlace?: boolean;
  place?: number;
  extremumMonth?: boolean;
  extremumMonthRate?: number;
  combine?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  overhangingIce?: boolean;
  moreThen?: number;
  duration?: number;
  calendarDuration?: number;
  actualDuration?: number;
  stationYearId?: StationYearEntity;
  ksvoRegimes?: KsvoRegimeEntity[];
};
export type IceMeasurementEntity = {
  id?: number;
  iceMeasurmentDate?: string;
  iceMeasurmentPlace?: number;
  totalThicknessIce?: number;
  totalThicknessIceChar?: string;
  thicknessIceSubmerged?: number;
  thicknessIceSubmergedChar?: string;
  thicknessFrazil?: number;
  thicknessFrazilChar?: string;
  snowDepth?: number;
  snowDepthChar?: string;
  snowDensity?: number;
  snowDensityChar?: string;
  icyWater?: number;
  icyWaterChar?: string;
  icyIce?: number;
  icyIceChar?: string;
  stationYearId?: StationYearEntity;
};
export type VocLakeType = {
  id?: number;
  typeName?: string;
  lakePassports?: LakePassport[];
};
export type VocLakeRegulationNature = {
  id?: number;
  natureName?: string;
  lakePassports?: LakePassport[];
};
export type VocListsEntity = {
  id?: number;
  code?: number;
  name?: string;
  description?: string;
  tag?: string;
  attribute?: string;
  symbol?: string;
  ksvo?: string;
};
export type LakeFormDescription = {
  id?: number;
  columnName?: string;
  unit?: string;
  disabled?: boolean;
  formId?: number;
  ord?: number;
  required?: boolean;
  tagName?: string;
  pattern?: string;
  hasChar?: boolean;
  confines?: LakeConfines[];
  reduceAccuracy?: boolean;
  slash?: boolean;
  dash?: boolean;
};
export type LakeSeasonsWaterManagement = {
  id?: number;
  code?: number;
  name?: string;
  monthBegin?: number;
  monthEnd?: number;
  lakePost?: LakePost;
  confines?: LakeConfines[];
};
export type LakeConfines = {
  id?: number;
  maxValue?: number;
  minValue?: number;
  tag?: string;
  attribute?: string;
  allowableValues?: VocListsEntity[];
  formDescriptions?: LakeFormDescription;
  lakePost?: LakePost;
  lakeWaterAreaPost?: LakeWaterAreaPost;
  seasonsWaterManagement?: LakeSeasonsWaterManagement;
};
export type LakeCalculatedDataYear = {
  id?: number;
  actualDuration?: number;
  anotherPlace?: boolean;
  backwater?: boolean;
  belongAggregation?: number;
  calendarDuration?: number;
  charact?: number;
  code?: string;
  combine?: number;
  count?: number;
  date1?: string;
  date2?: string;
  time?: LocalTime;
  decade?: number;
  extremumMonth?: boolean;
  method?: string;
  moreThen100?: number;
  moreThen1000?: number;
  moreThen10000?: number;
  moreThen200?: number;
  moreThen20000?: number;
  moreThen50?: number;
  moreThen500?: number;
  moreThen5000?: number;
  moreThen50000?: number;
  observationType?: string;
  one?: boolean;
  overhangingIce?: boolean;
  place?: number;
  tag?: string;
  type?: number;
  value?: number;
  year?: string;
  lakePost?: LakePost;
  lakeWaterAreaPost?: LakeWaterAreaPost;
};
export type VocLakeWaterAreaPost = {
  id?: number;
  typeName?: string;
  lakeWaterAreaPosts?: LakeWaterAreaPost[];
};
export type LakeWaterAreaPost = {
  id?: number;
  serialNumber?: number;
  code?: number;
  supervisoryUnitCode?: number;
  openingDate?: string;
  closingDate?: string;
  ugmsNumber?: number;
  latitude?: number;
  longitude?: number;
  startingGauge?: number;
  startingGaugeDistance?: number;
  startingGaugeAzimuth?: number;
  lakeConfines?: LakeConfines[];
  lakeCalculatedDataYears?: LakeCalculatedDataYear[];
  type?: VocLakeWaterAreaPost;
  lakePassport?: LakePassport;
};
export type LakePassport = {
  id?: number;
  ggiNumber?: string;
  vniigmiNumber?: string;
  category?: string;
  lakeName?: string;
  flowsInto?: number;
  issueNumber?: BooksEntity;
  lakeType?: VocLakeType;
  regulationNature?: VocLakeRegulationNature;
  lakeArea?: number;
  catchmentArea?: number;
  heightRetainingLevel?: number;
  heightSystem?: number;
  volumeFull?: number;
  volumeUsable?: number;
  aveDepth?: number;
  maxDepth?: number;
  length?: number;
  maxWidth?: number;
  mineralizationDegree?: number;
  heightDeadVolumeLevel?: number;
  lakePosts?: LakePost[];
  lakeWaterAreaPosts?: LakeWaterAreaPost[];
};
export type LakeObservationPoint = {
  id?: number;
  shoreDistance?: number;
  azimuthFromPost?: number;
  depth?: number;
  nearPost?: boolean;
  lakePost?: LakePost;
};
export type LakePostYearParameter = {
  id?: number;
  a?: number;
  charact?: number;
  code?: string;
  date1?: string;
  date2?: string;
  k?: number;
  name?: string;
  system?: number;
  tag?: string;
  val?: number;
  year?: string;
  lakePostYear?: LakePostYear;
};
export type LakeBookLog = {
  id?: number;
  tableName?: string;
  tag?: string;
  userName?: string;
  bookPost?: LakeBookPost;
};
export type LakeBookPost = {
  id?: number;
  book?: BooksEntity;
  lakePostYear?: LakePostYear;
  bookLogs?: LakeBookLog[];
};
export type LakeBasicObservation = {
  id?: number;
  date?: string;
  waterLevel?: number;
  waterLevelChar?: number;
  waterTemperature?: number;
  waterTemperatureChar?: number;
  airTemperature?: number;
  airTemperatureChar?: number;
  precipitation?: number;
  precipitationChar?: number;
  precipitationType?: number;
  ksvo1?: number;
  coverage1?: number;
  ksvo2?: number;
  coverage2?: number;
  ksvo3?: number;
  coverage3?: number;
  ksvo4?: number;
  coverage4?: number;
  ksvo5?: number;
  coverage5?: number;
  lakePostYear?: LakePostYear;
};
export type LakeIceObservation = {
  id?: number;
  date?: string;
  place?: number;
  totalThicknessIce?: number;
  totalThicknessIceChar?: number;
  thicknessIceSubmerged?: number;
  thicknessIceSubmergedChar?: number;
  snowDepth?: number;
  snowDepthChar?: number;
  thicknessFrazil?: number;
  thicknessFrazilChar?: number;
  icyWater?: number;
  icyWaterChar?: number;
  snowDensity?: number;
  snowDensityChar?: number;
  kslp?: number;
  kzsp?: number;
  lakePostYear?: LakePostYear;
};
export type LakeWaveObservation = {
  id?: number;
  buoyDistance?: number;
  buoyDirection?: number;
  waveDirection?: number;
  aveWavePeriod?: number;
  largestWaveDifference?: number;
  windDirection?: number;
  windSpeed?: number;
  waveLevel?: number;
  surfaceState?: number;
  waveChar?: number;
  date?: string;
  lakePostYear?: LakePostYear;
};
export type LakeKsvoRegime = {
  id?: number;
  date1?: string;
  ksvoRegime?: number;
  ksvoRegimePriority?: number;
  lakePostYear?: LakePostYear;
  lakeCalculatedDatum?: LakeCalculatedDatum;
};
export type LakeCalculatedDatum = {
  id?: number;
  actualDuration?: number;
  anotherPlace?: boolean;
  backwater?: boolean;
  belongAggregation?: number;
  calendarDuration?: number;
  charact?: number;
  code?: string;
  combine?: number;
  count?: number;
  date1?: string;
  date2?: string;
  decade?: number;
  duration?: number;
  extremumMonth?: boolean;
  extremumMonthRate?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  method?: string;
  moreThen?: number;
  one?: boolean;
  overhangingIce?: boolean;
  place?: number;
  proc?: string;
  t?: string;
  tag?: string;
  time?: LocalTime;
  type?: number;
  value?: number;
  value2?: number;
  value3?: number;
  value4?: number;
  year?: string;
  lakePostYear?: LakePostYear;
  ksvoRegimes?: LakeKsvoRegime[];
};
export type LakeDirectionSpeedObservation = {
  id?: number;
  verticalCode?: number;
  verticalDepth?: number;
  flowDirection?: number;
  maxDirectionDifference?: number;
  aveFlowSpeed?: number;
  maxFlowSpeed?: number;
  windDirection?: number;
  windSpeed?: number;
  date?: string;
  lakePostYear?: LakePostYear;
};
export type LakeIceProfilesObservation = {
  id?: number;
  date?: string;
  thicknessIceSubmerged?: number;
  totalThicknessIce?: number;
  profileCode?: number;
  profileStartDistance?: number;
  snowDepth?: number;
  thicknessFrazil?: number;
  icyWater?: number;
  snowDensity?: number;
  measuringPointStatus?: number;
  iceStructure?: number;
  crystallineIceThickness?: number;
  slurryIceThickness?: number;
  snowWaterLayersThickness?: number;
  kslp?: number;
  kzsp?: number;
  lakePostYear?: LakePostYear;
};
export type LakeMinMaxWaveObservation = {
  id?: number;
  date?: string;
  markCode?: number;
  depth?: string;
  waveHorizonDifference?: number;
  windDirection?: number;
  windSpeed?: number;
  lakePostYear?: LakePostYear;
};
export type LakeWaterTemperatureObservation = {
  id?: number;
  date?: string;
  verticalCode?: number;
  verticalDepth?: number;
  horizon?: number;
  waterTemperature?: number;
  lakePostYear?: LakePostYear;
};
export type LakeHydrologicalObservation = {
  id?: number;
  date?: number;
  airTemperature?: number;
  steamPressure?: number;
  verticalCode?: number;
  windDirection?: number;
  windSpeed?: number;
  waterTransparency?: number;
  waterColor?: number;
  lakePostYear?: LakePostYear;
};
export type LakePostYear = {
  id?: number;
  year?: string;
  fn?: string;
  lakePostYearParameters?: LakePostYearParameter[];
  lakeBookPosts?: LakeBookPost[];
  lakeBasicObservations?: LakeBasicObservation[];
  lakeIceObservations?: LakeIceObservation[];
  lakeWaveObservations?: LakeWaveObservation[];
  lakeCalculatedData?: LakeCalculatedDatum[];
  lakeDirectionSpeedObservations?: LakeDirectionSpeedObservation[];
  lakeIceProfilesObservations?: LakeIceProfilesObservation[];
  lakeMinMaxWaveObservations?: LakeMinMaxWaveObservation[];
  lakeWaterTemperatureObservations?: LakeWaterTemperatureObservation[];
  lakeHydrologicalObservations?: LakeHydrologicalObservation[];
  lakePost?: LakePost;
  lakeWaterAreaPost?: LakeWaterAreaPost;
};
export type LakePost = {
  id?: number;
  ggiNumber?: string;
  vniigmiNumber?: string;
  postName?: string;
  lakePassport?: LakePassport;
  firstOpeningDate?: string;
  lastOpeningDate?: string;
  closingDate?: string;
  ugmsNumber?: VocRegion;
  latitude?: number;
  longitude?: number;
  gaugeDatum?: number;
  gaugeDatumSystem?: number;
  ord?: number;
  ordMds?: number;
  confines?: LakeConfines[];
  observationPoints?: LakeObservationPoint[];
  lakeCalculatedDataYears?: LakeCalculatedDataYear[];
  stabilityFreezing?: number;
  lakePostYears?: LakePostYear[];
  lakeSeasonsWaterManagements?: LakeSeasonsWaterManagement[];
  book?: BooksEntity;
};
export type VocRegion = {
  code?: number;
  fullName?: string;
  name?: string;
  stations?: StationEntity[];
  lakePosts?: LakePost[];
};
export type CalculatedDataYearEntity = {
  id?: number;
  tag?: string;
  date1?: string;
  time?: LocalTime;
  date2?: string;
  year?: string;
  decade?: number;
  value?: number;
  charact?: number;
  count?: number;
  backwater?: boolean;
  method?: string;
  type?: number;
  belongAggregation?: number;
  one?: boolean;
  anotherPlace?: boolean;
  place?: number;
  extremumMonth?: boolean;
  extremumMonthRate?: number;
  overhangingIce?: boolean;
  moreThen50?: number;
  moreThen100?: number;
  moreThen200?: number;
  moreThen500?: number;
  moreThen1000?: number;
  moreThen5000?: number;
  moreThen10000?: number;
  moreThen20000?: number;
  moreThen50000?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  calendarDuration?: number;
  actualDuration?: number;
  duration?: number;
  combine?: number;
  observationType?: string;
  stationId?: StationEntity;
  code?: string;
};
export type FormDescriptionsEntity = {
  id?: number;
  formId?: number;
  tagName?: string;
  columnName?: string;
  unit?: string;
  pattern?: string;
  disabled?: boolean;
  required?: boolean;
  ord?: number;
  hasChar?: boolean;
  confines?: ConfinesEntity[];
  reduceAccuracy?: boolean;
  slash?: boolean;
  dash?: boolean;
};
export type SeasonsWaterManagementEntity = {
  id?: number;
  code?: number;
  name?: string;
  monthBegin?: number;
  monthEnd?: number;
  station?: StationEntity;
  confines?: ConfinesEntity[];
};
export type ConfinesEntity = {
  id?: number;
  minValue?: number;
  maxValue?: number;
  tag?: string;
  attribute?: string;
  allowableValues?: VocListsEntity[];
  formDescriptions?: FormDescriptionsEntity;
  station?: StationEntity;
  seasonsWaterManagement?: SeasonsWaterManagementEntity;
};
export type StationEntity = {
  id?: number;
  stationIdx?: string;
  stationName?: string;
  ugmsNumber?: VocRegion;
  latitude?: number;
  longitude?: number;
  catchmentArea?: number;
  distanceSource?: number;
  distanceMouse?: number;
  stabilityFreezing?: number;
  waterFlowUnits?: number;
  suspendedSedimentDischargeUnits?: number;
  sedimentRateUnits?: number;
  signAccountingFlood?: number;
  timezone?: string;
  siltChargeMonitoringProgram?: number;
  siltChargeRelationStream?: number;
  openingDate?: string;
  lastOpeningDate?: string;
  closingDate?: string;
  enabled?: boolean;
  ord?: number;
  ordMds?: number;
  gaugeDatum?: number;
  gaugeDatumSystem?: number;
  gaugeDatumDate?: string;
  waterbodyCode?: WaterObjectsEntity;
  stationYearsById?: StationYearEntity[];
  calculatedDataYearsById?: CalculatedDataYearEntity[];
  confines?: ConfinesEntity[];
  seasonsWaterManagements?: SeasonsWaterManagementEntity[];
  adversePhenomenaLevel?: number;
  dangerousPhenomenaLevel?: number;
  book?: BooksEntity;
};
export type StationYearParametersEntity = {
  id?: number;
  tag?: string;
  name?: string;
  code?: string;
  date1?: string;
  date2?: string;
  val?: number;
  valBool?: boolean;
  k?: number;
  a?: number;
  year?: string;
  charact?: number;
  system?: number;
  stationYearId?: StationYearEntity;
};
export type WaterFlowChannelEntity = {
  id?: number;
  dischargeChannel?: number;
  dischargeChannelChar?: number;
  gaugeLineNumber?: number;
  gaugeLineNumberDistance?: number;
  ksvoGaugeLine1?: number;
  ksvoGaugeLine2?: number;
  ksvoGaugeLineCoverage1?: number;
  ksvoGaugeLineCoverage2?: number;
  waterCrossSectionalArea?: number;
  waterCrossSectionalAreaChar?: number;
  deadSpaceArea?: number;
  deadSpaceAreaChar?: number;
  iceArea?: number;
  iceAreaChar?: number;
  frazilArea?: number;
  frazilAreaChar?: number;
  bridgeBearingsArea?: number;
  bridgeBearingsAreaChar?: number;
  commonArea?: number;
  commonAreaChar?: number;
  averageSpeed?: number;
  averageSpeedChar?: number;
  maxSpeed?: number;
  maxSpeedChar?: number;
  widthWaterLevel?: number;
  widthWaterLevelChar?: number;
  widthUndersideIce?: number;
  widthUndersideIceChar?: number;
  averageDepth?: number;
  averageDepthChar?: number;
  maxDepth?: number;
  maxDepthChar?: number;
  slope?: number;
  slopeChar?: number;
  deviceWaterFlow?: number;
  countVert?: number;
  countPoint?: number;
  countFloat?: number;
  transects?: number;
  usageCode?: number;
  methodWaterFlow?: number;
  featuresMeasurementWaterFlowChannel?: number;
  unaccountedWaterFlow?: string;
  suspendedSedimentDischargeChannel?: number;
  suspendedSedimentDischargeChannelChar?: number;
  averageChargeChannel?: number;
  averageChargeChannelChar?: number;
  siltChargeSample?: number;
  siltChargeSampleChar?: number;
  organicMatter?: number;
  organicMatterChar?: number;
  deviceSuspendedSedimentDischarge?: number;
  deviceSuspendedCountVert?: number;
  deviceSuspendedCountPoint?: number;
  deviceSuspendedUsageCode?: number;
  methodSuspendedSedimentDischarge?: number;
  featuresMeasurementSedimentDischargeChannel?: number;
  unaccountedSedimentDischarge?: string;
  transitionCoefficient?: number;
  transitionCoefficientSiltCharge?: number;
  waterFlowNumber?: number;
  channelNumber?: number;
  waterFlowId?: WaterFlowEntity;
};
export type WaterFlowChannelMeasurementEntity = {
  id?: number;
  distanceInitialPoint?: number;
  workingDepth?: number;
  coefficientPointVertical?: number;
  thicknessSubmergedIce?: number;
  thicknessSubmergedFrazil?: number;
  velocity?: number;
  siltChargeVertical?: number;
  averageVelocityVertical?: number;
  measuringNumber?: number;
  verticalCode?: number;
  depthFractions?: string;
  channelNumber?: number;
  waterFlowId?: WaterFlowEntity;
  mark?: boolean;
};
export type WaterFlowEntity = {
  id?: number;
  waterFlowNumber?: number;
  waterFlowDate?: string;
  waterFlowFlag?: number;
  featuresMeasurementWaterFlow?: number;
  unaccountedChannel?: string;
  unaccountedWaterFlow?: string;
  waterLevelStation?: number;
  waterLevelStationChar?: string;
  waterLevelGaugeLine?: number;
  waterLevelGaugeNumber?: number;
  waterLevelGaugeLineChar?: string;
  waterFlow?: number;
  waterFlowChar?: string;
  suspendedSedimentDischargeNumber?: number;
  suspendedSedimentDischargeFlag?: number;
  featuresMeasurementSedimentDischarge?: number;
  unaccountedSedimentDischarge?: string;
  unaccountedChannelSedimentDischarge?: string;
  suspendedSedimentDischarge?: number;
  suspendedSedimentDischargeChar?: string;
  waterFlowSiltCharge?: number;
  waterFlowSiltChargeChar?: string;
  siltChargeSuspendedSedimentDischarge?: number;
  siltChargeSuspendedSedimentDischargeChar?: string;
  organicMatter?: number;
  organicMatterChar?: string;
  mainstream?: number;
  stationYearId?: StationYearEntity;
  waterFlowChannelsById?: WaterFlowChannelEntity[];
  waterFlowChannelMeasurementsById?: WaterFlowChannelMeasurementEntity[];
};
export type GranulometryEntity = {
  id?: number;
  tag?: string;
  date?: string;
  modePhase?: number;
  sedimentDischargeNumber?: number;
  gaugeLineNumber?: number;
  samplingMethod?: number;
  percentageParticlesMore100?: number;
  percentageParticles10050?: number;
  percentageParticles5020?: number;
  percentageParticles2010?: number;
  percentageParticles10d5?: number;
  percentageParticles52?: number;
  percentageParticles21?: number;
  percentageParticles105?: number;
  percentageParticles0502?: number;
  percentageParticles0201?: number;
  percentageParticles01005?: number;
  percentageParticles005001?: number;
  percentageParticles0010005?: number;
  percentageParticles00050001?: number;
  percentageParticlesLess0001?: number;
  diameterMax?: number;
  lengthMax?: number;
  widthMax?: number;
  heightMax?: number;
  percentFrequency50?: number;
  method?: number;
  organicMatter?: number;
  particleDensity?: number;
  mixtureDensity?: number;
  poreVolume?: number;
  stationYear?: StationYearEntity;
};
export type FileStorageEntity = {
  id?: number;
  fileName?: string;
  data?: string[];
  stationYear?: StationYearEntity;
};
export type WaterFlowTempEntity = {
  id?: number;
  waterFlowNumber?: number;
  waterFlowDate?: string;
  waterLevelStation?: number;
  waterFlow?: number;
  calculatedWaterFlow?: number;
  calculatedWaterFlowChar?: number;
  waterFlowStatus?: boolean;
  ksvoGaugeLine1?: number;
  ksvoGaugeLine2?: number;
  ksvoGaugeState?: number;
  maxDepth?: number;
  waterCrossSectionalArea?: number;
  averageSpeed?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  waterLevel?: number;
  waterLevelDayAvg?: number;
  ksvoRegime1?: number;
  ksvoRegime2?: number;
  ksvoRegime3?: number;
  ksvoRegime4?: number;
  ksvoRegime5?: number;
  used?: boolean;
  coefficient?: number;
  stoutCorrect?: number;
  stationYear?: StationYearEntity;
};
export type VocWaterFlowCurveType = {
  id?: number;
  typeName?: string;
  typeStringCode?: string;
  polynomialDegree?: number;
  function?: boolean;
};
export type WaterFlowPointEntity = {
  id?: number;
  waterFlow?: number;
  waterLevel?: number;
  sort?: number;
  waterFlowCurve?: WaterFlowCurveEntity;
};
export type WaterFlowPeriodEntity = {
  id?: number;
  startDate?: string;
  endDate?: string;
  waterFlowCurve?: WaterFlowCurveEntity;
  stationYear?: StationYearEntity;
};
export type WaterFlowCurveEntity = {
  id?: number;
  curveName?: string;
  note?: string;
  sort?: number;
  a?: number;
  b?: number;
  c?: number;
  d?: number;
  h0?: number;
  data?: string[];
  stationYear?: StationYearEntity;
  vocWaterFlowCurveType?: VocWaterFlowCurveType;
  waterFlowPoints?: WaterFlowPointEntity[];
  waterFlowPeriods?: WaterFlowPeriodEntity[];
};
export type StationYearEntity = {
  id?: number;
  year?: string;
  fn?: string;
  basicObservationsById?: BasicObservationsEntity[];
  siltCharge?: SiltChargeEntity[];
  lengthwiseSlope?: LengthwiseSlopeEntity[];
  bookStationsById?: BookStationEntity[];
  calculatedDataById?: CalculatedDataEntity[];
  iceMeasurementsById?: IceMeasurementEntity[];
  ksvoRegimesById?: KsvoRegimeEntity[];
  stationId?: StationEntity;
  stationYearParametersById?: StationYearParametersEntity[];
  waterFlowsById?: WaterFlowEntity[];
  granulometry?: GranulometryEntity[];
  fileStorage?: FileStorageEntity[];
  waterFlowTemps?: WaterFlowTempEntity[];
  waterFlowCurves?: WaterFlowCurveEntity[];
  waterFlowTPeriods?: WaterFlowPeriodEntity[];
};
export type BooksLogEntity = {
  id?: number;
  tableName?: string;
  tag?: string;
  userName?: string;
  bookStation?: BookStationEntity;
};
export type BookStationEntity = {
  id?: number;
  booksId?: BooksEntity;
  stationYearId?: StationYearEntity;
  booksLogs?: BooksLogEntity[];
};
export type BooksEntity = {
  id?: number;
  name?: string;
  issueNumber?: number;
  bookStationsById?: BookStationEntity[];
  waterObjects?: WaterObjectsEntity[];
  lakeBookPosts?: LakeBookPost[];
  lakePassports?: LakePassport[];
  stationEntities?: StationEntity[];
  lakePosts?: LakePost[];
};
export type WaterObjectsEntity = {
  code?: number;
  name?: string;
  side?: string;
  vipusk?: BooksEntity;
  ref?: string;
  nameMain?: string;
  refMain?: string;
  waterObjectsCode?: number;
  stationByCode?: StationEntity[];
};
export type ConfinesLogDto = {
  id?: number;
  errorDate?: string;
  message?: string;
  error?: boolean;
};
export type WaterFlowConfinesLogDto = {
  data?: WaterFlowEntity[];
  errors?: ConfinesLogDto[];
};
export type WaterFlowNumberDto = {
  id?: number;
  waterFlowNumber?: number;
};
export type WaterFlowTempDto = {
  id?: number;
  waterFlowNumber?: number;
  waterFlowDate?: string;
  waterLevelStation?: number;
  waterFlow?: number;
  calculatedWaterFlow?: string;
  calculatedWaterFlowChar?: number;
  waterFlowFlag?: number;
  waterFlowStatus?: boolean;
  ksvoGaugeLine1?: number;
  ksvoGaugeLine2?: number;
  ksvoGaugeLine3?: number;
  ksvoState1?: number;
  ksvoState2?: number;
  ksvoState3?: number;
  ksvoGaugeState?: number;
  maxDepth?: number;
  waterCrossSectionalArea?: number;
  averageSpeed?: number;
  ksvo1?: number;
  ksvo2?: number;
  ksvo3?: number;
  waterLevel?: number;
  waterLevelDayAvg?: number;
  ksvoRegime1?: number;
  ksvoRegime2?: number;
  ksvoRegime3?: number;
  ksvoRegime4?: number;
  ksvoRegime5?: number;
  waterLevelAnnualMax?: number;
  waterLevelAnnualMin?: number;
  used?: boolean;
  coefficient?: number;
  stoutCorrect?: number;
  precipitationDay?: number;
};
export type WaterFlowCurveDto = {
  id?: number;
  curveName?: string;
  a?: number;
  b?: number;
  c?: number;
  d?: number;
  h0?: number;
};
export type WaterFlowPeriodDto = {
  id?: number;
  startDate?: string;
  endDate?: string;
  waterFlowCurveId?: number;
};
export type VocWaterFlowCalculationMethod = {
  id?: number;
  methodName?: string;
};
export type StationDto = {
  id?: number;
  stationIdx?: string;
  stationName?: string;
  ugmsCode?: number;
  latitude?: number;
  longitude?: number;
  catchmentArea?: number;
  distanceSource?: number;
  distanceMouse?: number;
  gaugeDatum?: number;
  gaugeDatumSystem?: number;
  gaugeDatumDate?: string;
  stabilityFreezing?: number;
  waterFlowUnits?: number;
  suspendedSedimentDischargeUnits?: number;
  sedimentRateUnits?: number;
  signAccountingFlood?: number;
  timezone?: string;
  siltChargeMonitoringProgram?: number;
  siltChargeRelationStream?: number;
  openingDate?: string;
  lastOpeningDate?: string;
  closingDate?: string;
  ord?: number;
  ordMds?: number;
  code?: number;
  issueNumber?: number;
  ref?: string;
  adversePhenomenaLevel?: number;
  dangerousPhenomenaLevel?: number;
  isAdverse?: boolean;
  isDangerous?: boolean;
};
export type StationYearParametersConfinesLogDto = {
  data?: StationYearParametersEntity[];
  errors?: ConfinesLogDto[];
};
export type StationYearParametersDto = {
  id?: number;
  tag?: string;
  date1?: string;
  year?: string;
};
export type SiltChargeConfinesLogDto = {
  data?: SiltChargeEntity[];
  errors?: ConfinesLogDto[];
};
export type SeasonsWaterManagementConfinesLogDto = {
  data?: SeasonsWaterManagementEntity[];
  errors?: ConfinesLogDto[];
};
export type LengthwiseSlopeConfinesLogDto = {
  data?: LengthwiseSlopeEntity[];
  errors?: ConfinesLogDto[];
};
export type LakeWaveObservationConfinesLogDto = {
  data?: LakeWaveObservation[];
  errors?: ConfinesLogDto[];
};
export type LakeSeasonsWaterManagementConfinesLogDto = {
  data?: LakeSeasonsWaterManagement[];
  errors?: ConfinesLogDto[];
};
export type LakePostDto = {
  id?: number;
  ggiNumber?: string;
  vniigmiNumber?: string;
  postName?: string;
  firstOpeningDate?: string;
  lastOpeningDate?: string;
  closingDate?: string;
  ugmsCode?: number;
  latitude?: number;
  longitude?: number;
  gaugeDatum?: number;
  gaugeDatumSystem?: number;
  ord?: number;
  ordMds?: number;
  ggiNumberPassport?: string;
  vniigmiNumberPassport?: string;
  stabilityFreezing?: number;
  issueNumber?: number;
};
export type LakeIceObservationConfinesLogDto = {
  data?: LakeIceObservation[];
  errors?: ConfinesLogDto[];
};
export type ConfinesDto = {
  id?: number;
  minValue?: number;
  maxValue?: number;
  formDescriptionsId?: number;
  formDescriptionsColumnName?: string;
  formDescriptionsTagName?: string;
  formDescriptionsUnit?: string;
  seasonsWaterManagementId?: number;
  seasonsWaterManagementCode?: number;
};
export type CalculatedDataDto = {
  id?: number;
  tag?: string;
  code?: string;
  t?: string;
  date1?: string;
  value?: number;
  value2?: number;
  value3?: number;
  value4?: number;
};
export type KsvoMonthWithConfinesLogDto = {
  data?: CalculatedDataDto;
  errors?: ConfinesLogDto[];
};
export type LakeBasicObservationConfinesLogDto = {
  data?: LakeBasicObservation[];
  errors?: ConfinesLogDto[];
};
export type IceMeasurementConfinesLogDto = {
  data?: IceMeasurementEntity[];
  errors?: ConfinesLogDto[];
};
export type GranulometryDto = {
  data?: GranulometryEntity[];
  errors?: ConfinesLogDto[];
};
export type ConfinesLogEntity = {
  id?: number;
  entity?: string;
  errorText?: ErrorTextEntity;
  errorParameters?: string;
  measurementDate?: string;
  errorUser?: string;
  status?: boolean;
  error?: boolean;
  errorDate?: string;
  stationId?: number;
  entityId?: number;
};
export type ErrorTextEntity = {
  code?: number;
  text?: string;
  error?: boolean;
  confinesLogs?: ConfinesLogEntity[];
};
export type CalculatedDataYearDto = {
  id?: number;
  tag?: string;
  date1?: string;
  date2?: string;
  year?: string;
  value?: string;
  charact?: number;
};
export type BookLogFileEntity = {
  id?: number;
  data?: string[];
  fileName?: string;
  creationDate?: string;
};
export type BasicObservationsConfinesLogDto = {
  data?: BasicObservationsEntity[];
  errors?: ConfinesLogDto[];
};
export type PythonInput = {
  idx: string;
  year: number;
  periodId?: number;
  format: number;
  month?: number;
  water_flow_number?: number;
  coefficient?: number;
  author?: string;
};
export type StationInput = {
  idx?: string;
  year?: number;
  format?: number;
};
export type TableResult = {
  tableFiles?: {
    [key: string]: number;
  };
  tableErrors?: {
    [key: string]: string;
  };
};
export type BookInput = {
  issue?: number;
  stations?: string;
  startYear?: string;
  endYear?: string;
  startYearByYear?: string;
  tables?: string;
  title?: boolean;
  byYear?: boolean;
  fullTable112?: boolean;
  fullTable110?: boolean;
  fullTable211?: boolean;
  fullTable206?: boolean;
};
export type AnnualProcessConfinesLogDto = {
  data?: string;
  errors?: ConfinesLogDto[];
};
export type AnnualInput = {
  id: number;
  year: string;
};
export type TextInput = {
  input?: string;
};
export type ResponseValue = {
  value?: string;
  valueRound?: string;
  valueType?: string;
};
export type LakePostYearParameterConfinesLogDto = {
  data?: LakePostYearParameter[];
  errors?: ConfinesLogDto[];
};
export type BookStationDto = {
  ord?: number;
  ordMds?: number;
  stationIdx?: string;
  stationName?: string;
};

