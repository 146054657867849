import { t } from 'locale';
// !delete this file
import { format } from 'date-fns';
import { CalculatedDataEntity, KsvoRegimeEntity } from 'services/phoebeApi.generated';

const WATER_LEVEL = 'water_level';
const WATER_TEMP = 'water_temp';

export type CalculatedDataTransformResponseType = {
  basicObservations: {
    [key: string]: {
      [key: string]: CalculatedDataEntity
    }
  },
  decadeObservation: {
    [WATER_LEVEL]: CalculatedDataEntity[],
    [WATER_TEMP]: CalculatedDataEntity[],
  },
  notes: any
  waterFactors: CalculatedDataEntity[],
  decadeSlit: CalculatedDataEntity[],
  slitNotes: {
    charact?: boolean,
    ksvo?: number[]
  },
}
/** Выборка объектов с реста для таблицы
 * Колонки для уровня воды и колонки для температуры
*/
const dataTable = (cur: CalculatedDataEntity, result: CalculatedDataTransformResponseType['decadeObservation']) => {
  switch (cur.tag) {
    case 'water_level_dec_avg':
    case 'water_level_mon_avg':
    case 'water_level_mon_max':
    case 'water_level_mon_min':
      result[WATER_LEVEL].push(cur);
      break;
    case 'water_temp_mon_max':
    case 'water_temp_dec_avg':
    case 'water_temp_mon_avg':
      result[WATER_TEMP].push(cur);
      break;

    default:
      break;
  }
};

export function calculatedDataTransformResponse(response: CalculatedDataEntity[]) {
  const transformResponse = response.reduce((acc: CalculatedDataTransformResponseType, cur: CalculatedDataEntity) => {
    const result = { ...acc };
    const date1format = cur.date1 ? format(new Date(cur.date1), 'dd') : '';
    dataTable(cur, result.decadeObservation); // формирование данных для второй таблицы
    // декады для мутности
    if (cur.tag === 'silt_charge_dec_avg'
      || cur.tag === 'silt_charge_mon_avg'
      || cur.tag === 'silt_charge_mon_max'
      || cur.tag === 'silt_charge_mon_min ') {
      result.decadeSlit.push(cur);
      if (cur.charact === 1) result.slitNotes.charact = true;
      if (cur.ksvo1 || cur.ksvo2 || cur.ksvo3) {
        const ksvo = [];
        if (cur.ksvo1) ksvo.push(cur.ksvo1);
        if (cur.ksvo2) ksvo.push(cur.ksvo2);
        if (cur.ksvo3) ksvo.push(cur.ksvo3);
        result.slitNotes.ksvo = ksvo;
      }
    }
    if (cur.tag === 'ksvo_month') {
      // 1.4.1
      if (cur.value === 677 || cur.value === 678 || cur.value === 681 || cur.value === 682 || cur.value === 685 || cur.value === 686 || cur.value === 690 || cur.value === 691) {
        if (result.notes[141]) result.notes[141].push(t('key_374'));
        else {
          result.notes[141] = [];
          result.notes[141].push(t('key_374'));
        }
        return result;
      }
      if (cur.value === 672 || cur.value === 689) {
        if (result.notes[141]) result.notes[141].push(t('key_375'));
        else {
          result.notes[141] = [];
          result.notes[141].push(t('key_375'));
        }
        return result;
      }

      // table slit
      result.waterFactors.push(cur);
      return result;
    }
    // 1.4.2
    if (cur.tag === 'water_level_day_avg') {
      const findedRegime = cur.ksvoRegimes?.find((v: KsvoRegimeEntity) => v.ksvoRegime === 39 || v.ksvoRegime === 19);
      const bothRegimes = cur.ksvoRegimes?.every((v: KsvoRegimeEntity) => v.ksvoRegime === 39 || v.ksvoRegime === 19);
      if (cur.method === '4' && cur.count === 0) {
        if (!result.notes[142]) result.notes[142] = { 39: { msg: t('key_775'), days: [] }, 19: { msg: t('key_776'), days: [] } };
        if (findedRegime?.ksvoRegime === 39) result.notes[142][39].days.push(date1format);
        if (findedRegime?.ksvoRegime === 19) result.notes[142][19].days.push(date1format);
        if (bothRegimes) {
          result.notes[142][39].days.push(date1format);
          result.notes[142][19].days.push(date1format);
        }
      }
      // 1.4.3
      if (cur.method === '4' && cur.count !== 0) {
        if (!result.notes[143]) result.notes[143] = { 39: { msg: t('key_777'), days: [] }, 19: { msg: t('key_778'), days: [] } };
        if (findedRegime?.ksvoRegime === 39) result.notes[143][39].days.push(date1format);
        if (findedRegime?.ksvoRegime === 19) result.notes[143][19].days.push(date1format);
        if (bothRegimes) {
          result.notes[143][39].days.push(date1format);
          result.notes[143][19].days.push(date1format);
        }
      }
    }
    // 1.4.5
    // вопрос остался открытым, как это делать

    // 1.4.6
    if (cur.ksvoRegimes?.find((v: KsvoRegimeEntity) => v.ksvoRegime === 51)) {
      if (result.notes[146]) result.notes[146] = { msg: t('key_779'), days: [] };
      result.notes[146].days.push(date1format);
    }
    // 1.4.7
    if (cur.tag === 'water_temp_day_avg' && cur.one) {
      if (!result.notes[147]) result.notes[147] = { msg: t('key_780'), days: [] };
      result.notes[147].days.push(date1format);
    }

    if (acc.basicObservations[date1format]) result.basicObservations[date1format][cur.tag || 'noTag'] = cur;
    else {
      result.basicObservations[date1format] = {};
      result.basicObservations[date1format][cur.tag || 'noTag'] = cur;
    }
    return result;
  }, {
    basicObservations: {},
    decadeObservation: {
      [WATER_LEVEL]: [],
      [WATER_TEMP]: [],
    },
    notes: {},
    waterFactors: [],
    decadeSlit: [],
    slitNotes: {},
  });

  return transformResponse;
}
