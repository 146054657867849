import { t } from 'locale';
import { TokenExpiredError } from 'services/errors/TokenExpiredError';
import { UnauthorizedError } from 'services/errors/UnauthorizedError';
import { InternalServerError } from 'services/errors/InternalServerError';
import { NotFoundError } from 'services/errors/NotFoundError';
import { ForbiddenError } from 'services/errors/ForbiddenError';
import { ExistError } from 'services/errors/ExistError';
import { ResponseErrorType } from 'services/types/serverResponse';
import { localErrors } from './localErrors';

export function throwErrorByCodeStatus(response: any, path: string, errorObj: ResponseErrorType): undefined | null | never {
  if (response.status === 401) {
    // тут нас интересует invalid_token
    // можно перебрать хидеры for (let entry of response.headers.entries())
    // хидер будет в таком виде "Bearer realm="ross", error="invalid_token", error_description="Token is not active"
    // если причина токен просрочился
    if (
      response.headers.has('www-authenticate')
      && (response.headers.get('www-authenticate').includes('invalid_token')
        || response.headers.get('www-authenticate') === 'Bearer realm="Unknown"')
    ) {
      throw new TokenExpiredError();
    }
    /**
     * - Неверное имя/пароль
     *   error: "invalid_grant"
     *   error_description: "Invalid user credentials"
     *
     * - Истекло время токена
     *   error: "invalid_token"
     *   error_description: "Token is not active"
     *
     * - Или еще что-то...
     *   Надо делать нужный throw
     */

    // Возможно придется еще дописать if'ов,
    // но пока про все остальные причины будем думать, что они якобы неверные имя/пароль
    throw new UnauthorizedError();
  } else if (response.status === 500) {
    // @ts-ignore
    const errorMessage = localErrors[errorObj.data?.error]
      || errorObj.data?.message
      || errorObj.message
      || t('key_772');
    throw new InternalServerError(errorMessage);
  } else if (response.status === 204 || response.status === 201) {
    return null;
  } else if (response.status === 404) {
    // TODO
    throw new NotFoundError(path);
  } else if (response.status === 403) {
    throw new ForbiddenError();
  } else if (response.status === 409) {
    throw new ExistError();
  } else if (response.status === 400) {
    throw new Error('Bad Request');
  } else if (response.status === 504) {
    throw new Error('Gateway Timeout'); // 29.12.2020 сервер не отвечает... 504 gateway timeout
  } else if (response.status === 405) {
    throw new Error('Method not Allowed');
  }
  return null;
}
