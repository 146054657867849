import { t } from 'locale';
import {
  CHARTS, DATA_CORRECTION, LAKE, MAP, METADATA, RIVER, REPORTS, ANNUAL_DATA, FILES, DAILY_WATER_FLOW,
} from 'constants/paths';
import { PlacesPath } from 'Features/Main/places';
import { LinkType } from 'Shared/types';

type MenuTab = LinkType & {
  shortName: string,
  places: Array<PlacesPath | string>,
  // places: Array<string>,
}

export const menu: Array<MenuTab> = [
  {
    to: METADATA,
    name: t('key_548'),
    shortName: t('key_548'),
    places: [RIVER, LAKE],
  },
  {
    to: DATA_CORRECTION,
    name: t('key_549'),
    shortName: t('key_550'),
    places: [RIVER, LAKE],

  },
  {
    to: CHARTS,
    name: t('key_551'),
    shortName: t('key_551'),
    places: [RIVER, LAKE],
  },
  {
    to: DAILY_WATER_FLOW,
    name: t('key_552'),
    shortName: t('key_553'),
    places: [RIVER],
  },
  {
    to: ANNUAL_DATA,
    name: t('key_8'),
    shortName: t('key_554'),
    places: [RIVER, LAKE],
  },
  {
    to: MAP,
    name: t('key_555'),
    shortName: t('key_555'),
    places: [RIVER, LAKE],
  },
  {
    to: REPORTS,
    name: t('key_556'),
    shortName: t('key_557'),
    places: [RIVER, LAKE],
  },
  {
    to: FILES,
    name: t('key_536'),
    shortName: t('key_558'),
    places: [RIVER],
  },
];
