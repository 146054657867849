import { QueryDefinition } from '@reduxjs/toolkit/query';
import { generatedApi } from 'services/phoebeApi.generated';
import {
  CALCULATED_DATA_TAG,
  WATER_FLOW_COEFFICIENTS_TAG,
  WATER_FLOW_CURVE_TAG,
  WATER_FLOW_TEMP_PERIODS_TAG,
  WATER_FLOW_TEMP_TAG,
} from '../tags';

export type { WaterFlowTempEntity } from 'services/phoebeApi.generated';

export const waterFlowTempApi = generatedApi.enhanceEndpoints({
  addTagTypes: [WATER_FLOW_TEMP_TAG, WATER_FLOW_TEMP_PERIODS_TAG, WATER_FLOW_CURVE_TAG, WATER_FLOW_COEFFICIENTS_TAG, CALCULATED_DATA_TAG],
  endpoints: {
    createInitialWaterFlowTempByStationIdAndYear: {
      invalidatesTags: () => [WATER_FLOW_TEMP_TAG, WATER_FLOW_TEMP_PERIODS_TAG, WATER_FLOW_CURVE_TAG, WATER_FLOW_COEFFICIENTS_TAG],
    },
    updateWaterFlowTempList: {
      invalidatesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    calculateWaterFlowByCurve: {
      // invalidatesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    calculateWaterFlowByLinearInterpolation: {
      // invalidatesTags: () => [WATER_FLOW_TEMP_TAG],
      extraOptions: {
        xml: true,
      },
    },
    getWaterFlowTempByStationIdAndYears: {
      providesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    getWaterFlowTempByStationIdAndYear: {
      providesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    calculateWaterFlowCoefficients: {
      invalidatesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    calculateWaterFLowStoutCorrect: {
      invalidatesTags: () => [WATER_FLOW_TEMP_TAG],
    },
    updateWaterFlowPeriods: {
      invalidatesTags: () => [WATER_FLOW_TEMP_PERIODS_TAG],
    },
    getWaterFlowPeriodsByStationIdAndYear: {
      providesTags: () => [WATER_FLOW_TEMP_PERIODS_TAG],
    },
    updateWaterFlowCurveName: {
      invalidatesTags: () => [WATER_FLOW_CURVE_TAG, WATER_FLOW_TEMP_PERIODS_TAG],
    },
    createWaterFlowPointsAndQCurve: {
      invalidatesTags: () => [WATER_FLOW_CURVE_TAG, WATER_FLOW_TEMP_PERIODS_TAG],
    },
    updateWaterFlowPoints: {
      invalidatesTags: () => [WATER_FLOW_CURVE_TAG],
    },
    createCurveByCoefficients: {
      invalidatesTags: () => [WATER_FLOW_CURVE_TAG, WATER_FLOW_TEMP_PERIODS_TAG],
    },
    deleteWaterFlowCurveById: {
      invalidatesTags: () => [WATER_FLOW_CURVE_TAG, WATER_FLOW_TEMP_PERIODS_TAG],
    },
    getWaterFlowCurveByStationId: {
      providesTags: () => [WATER_FLOW_CURVE_TAG],
    },
    updateDailyAvgWaterFlowRate: {
      invalidatesTags: () => [CALCULATED_DATA_TAG],
    },
    // createWaterFlowCurveXlsxEndpoint: {},
    // @ts-ignore TODO: что-то с типами rtk-query, проверить в будущем
    getWaterFlowCurveXlsx(endpoint: QueryDefinition) {
      const originalQuery = endpoint.query;
      // eslint-disable-next-line no-param-reassign
      endpoint.query = (args: any) => ({
        ...originalQuery(args),
        async responseHandler(response: Response) {
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(await response.blob());
          const fileName = response.headers.get('Content-Disposition')?.split('filename=')[1];
          a.download = fileName || 'curve_points.xlsx';
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        },
        cache: 'no-cache',
      });
    },
  },
});

// первичное заполнение таблицы
export const createInitialWaterFlowTempByStationIdAndYearEndpoint = waterFlowTempApi.endpoints.createInitialWaterFlowTempByStationIdAndYear;
// получение объектов этой таблицы, можно указать id периода
export const getWaterFlowTempByStationIdAndYearEndpoint = waterFlowTempApi.endpoints.getWaterFlowTempByStationIdAndYear;
// получение объектов этой таблицы, для нескольких лет
export const getWaterFlowTempByStationIdAndYearsEndpoint = waterFlowTempApi.endpoints.getWaterFlowTempByStationIdAndYears;
// список периодов для кривой
export const getWaterFlowPeriodsByStationIdAndYearEndpoint = waterFlowTempApi.endpoints.getWaterFlowPeriodsByStationIdAndYear;
// добавление периодов
export const updateWaterFlowPeriodsEndpoint = waterFlowTempApi.endpoints.updateWaterFlowPeriods;
// обновление объектов временной таблицы
export const updateWaterFlowTempListEndpoint = waterFlowTempApi.endpoints.updateWaterFlowTempList;
// обновление имени кривой
export const updateWaterFlowCurveNameEndpoint = waterFlowTempApi.endpoints.updateWaterFlowCurveName;
// получение кривых для поста
export const getWaterFlowCurveByStationIdEndpoint = waterFlowTempApi.endpoints.getWaterFlowCurveByStationId;
// список методов для расчета
export const getVocWaterFlowCalculationMethodsAllEndpoint = waterFlowTempApi.endpoints.getVocWaterFlowCalculationMethodsAll;
// рест для типов кривой
export const getVocWaterFlowCurveTypesAllEndpoint = waterFlowTempApi.endpoints.getVocWaterFlowCurveTypesAll;
// получение коэффициентов
export const getPolynomialCoefficientsEndpoint = waterFlowTempApi.endpoints.getPolynomialCoefficients;
// Расчет переходных коэффициентов замерзания и зарастания
export const calculateWaterFlowCoefficientsEndpoint = waterFlowTempApi.endpoints.calculateWaterFlowCoefficients;
// рест для кривой Глушкова
export const getGlushCoefficientsEndpoint = waterFlowTempApi.endpoints.getGlushCoefficients;
// рест только для расчета по кривой
export const calculateWaterFlowByCurveEndpoint = waterFlowTempApi.endpoints.calculateWaterFlowByCurve;
// рест для линейной интерполяции, работает с питоновским модулем, поэтому там отправляем IDX
export const calculateWaterFlowByLinearInterpolationEndpoint = waterFlowTempApi.endpoints.calculateWaterFlowByLinearInterpolation;
// getMaxDepthByStationIdAndYear
export const getMaxDepthByStationIdAndYearEndpoint = waterFlowTempApi.endpoints.getMaxDepthByStationIdAndYear;
// удаление кривой
export const deleteWaterFlowCurveByIdEndpoint = waterFlowTempApi.endpoints.deleteWaterFlowCurveById;
// Расчет поправок Стаута
export const calculateWaterFLowStoutCorrectEndpoint = waterFlowTempApi.endpoints.calculateWaterFLowStoutCorrect;
// Экспорт из временной таблицы в основную
export const updateDailyAvgWaterFlowRateEndpoint = waterFlowTempApi.endpoints.updateDailyAvgWaterFlowRate;
// Первая и последние не выдаются. Если concave = true, то кривая вогнута в этой точке
export const concavityCheckEndpoint = waterFlowTempApi.endpoints.concavityCheck;
//  на создание эксель файла (хранится в бд в таблице с кривой)
export const createWaterFlowCurveXlsxEndpoint = waterFlowTempApi.endpoints.createWaterFlowCurveXlsx;
// скачивание этого файла
export const getWaterFlowCurveXlsxEndpoint = waterFlowTempApi.endpoints.getWaterFlowCurveXlsx;
