import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Features/App/store';
import {
  generatedApi,
  GetWaterByStationIdAndDateApiArg,
  WaterFlowConfinesLogDto,
} from 'services/phoebeApi.generated';
import { waterFlowTransformResponse } from './transformResponse';

const waterFlowSelect = (state: RootState, params: GetWaterByStationIdAndDateApiArg) => generatedApi.endpoints.getWaterByStationIdAndDate.select(params)(state);
const WaterFlowSelector = (state: RootState, params: GetWaterByStationIdAndDateApiArg) => waterFlowSelect(state, params).data || {};

const responseWaterFlowSelect = (state: RootState, params: GetWaterByStationIdAndDateApiArg) => {
  const response = WaterFlowSelector(state, params);
  return response;
};

const formatWaterFlowByStationIdAndDateSelector = createSelector(
  (state: RootState, params: GetWaterByStationIdAndDateApiArg) => responseWaterFlowSelect(state, params),
  (response: WaterFlowConfinesLogDto) => waterFlowTransformResponse(response),
);

export const transformWaterFlowSelector = (params: GetWaterByStationIdAndDateApiArg) => (state: RootState) => formatWaterFlowByStationIdAndDateSelector(state, params);
