/* eslint-disable no-param-reassign */
import { AnyObject, DeepPartial } from '../types';
import { isNumber } from './utils';

/**
 * Расширенный Object.assign().
 * Итерирует переданные объекты, таким образом, что в первом, оказываются все значения, любой вложенности, из последующих.
 * @param target - Объект, в который будут переданы значения
 * @param source - Объекты, которые будут по значениям скопированы в target
 * @returns {object} - target
 * @type {(target: object, ...source: Array<object | undefined>) => object}
 * @example
 * valueAssign({}, {a: {b0: 0}}, {a: {b1: 1}}}) // {a:{b0: 0, b1: 1}}
 * Object.assign({}, {a: {b0: 0}}, {a: {b1: 1}}}) // {a:{b1: 1}}
 * valueAssign({a0: null, a1: {b0: null, b1: null}}, {a0: {b: 123}}, {a1: {b0: 123}}) // {a0: {b: null}, a1: {b0: 123, b1: null}}
 */
export function valueAssign<T extends AnyObject = AnyObject, P extends AnyObject = DeepPartial<T>>(target: T | {}, ...source: P[]): T {
  source.forEach((obj) => {
    if (typeof obj === 'object' && obj && typeof target === 'object') {
      Object.keys(obj).forEach((key) => {
        const newObl = obj[key];
        if (typeof newObl === 'object' && newObl) {
          if (key in target) {
            // @ts-ignore
            valueAssign(target[key], newObl);
          } else {
            valueAssign(
              // @ts-ignore
              (target[key] = isNumber(newObl.length) ? [] : {}),
              newObl,
            );
          }
        } else {
          // @ts-ignore
          target[key] = newObl;
        }
      });
    }
    // else {
    //   throw Error('[Ошибка] В valueAssign передан не объект!');
    // }
  });

  // @ts-ignore
  return target;
}

export function valueAssignSkipArrays<T extends AnyObject = AnyObject, P extends AnyObject = DeepPartial<T>>(target: T | {}, ...source: P[]): T {
  source.forEach((obj) => {
    if (typeof obj === 'object' && obj && typeof target === 'object') {
      Object.keys(obj).forEach((key) => {
        const newObl = obj[key];
        if (typeof newObl === 'object' && newObl && !Array.isArray(newObl)) {
          if (key in target) {
            // @ts-ignore
            valueAssign(target[key], newObl);
          } else {
            valueAssign(
              // @ts-ignore
              target[key] = {},
              newObl,
            );
          }
        } else {
          // @ts-ignore
          target[key] = newObl;
        }
      });
    }
  });

  // @ts-ignore
  return target;
}
