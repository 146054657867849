import React from 'react';
import { useSelector } from 'react-redux';

import { selectedPlaceSelector } from 'Features/App/appSlice';
import { CardLink, CardsLinkGrid } from 'Shared';
import { menu } from './menu';
import { HeaderMainPage } from './HeaderMainPage';
import { AppVersion } from './AppVersion';
import { SelectLanguage } from './SelectLanguage';

export default function MainMenu() {
  const place = useSelector(selectedPlaceSelector);

  return (
    <>
      <SelectLanguage />
      <AppVersion />

      <div>
        <HeaderMainPage />
        <CardsLinkGrid className="m-3">
          {
            React.Children.toArray(menu.map(({ name, to, places: possiblePlaces }) => (
              place && possiblePlaces.includes(place)
                ? <><CardLink name={name} to={`${place}/${to}`} /></>
                : null
            )))
          }
        </CardsLinkGrid>
      </div>
    </>
  );
}
