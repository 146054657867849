import { generatedApi, GetLakeIceObservationsByPostIdAndDateApiArg } from 'services/phoebeApi.generated';
import { LAKE_POST_YEARS_TAG, LAKE_ICE_OBSERVATIONS_TAG } from 'services/lake/tags';
import { getIceMeasurementsForMonthlyTablesData } from 'services/utils';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [LAKE_ICE_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
  endpoints: {
    getLakeIceObservationsByPostIdAndDate: {
      // @ts-ignore
      transformResponse: (response, meta, { isMonthlyTablesData }: GetLakeIceObservationsByPostIdAndDateApiArg & { isMonthlyTablesData?: boolean }) => {
        if (isMonthlyTablesData) {
          return getIceMeasurementsForMonthlyTablesData(response.data);
        }
        return response;
      },
      providesTags: [LAKE_ICE_OBSERVATIONS_TAG],
    },
    getLakeIceObservationsByPostIdAndDateBetween: {
      // @ts-ignore
      transformResponse: (response) => response.map((measurement) => ({ ...measurement, iceMeasurmentPlace: measurement.place, iceMeasurmentDate: measurement.date })),
      providesTags: [LAKE_ICE_OBSERVATIONS_TAG],
    },
    updateFormLakeIceObservations: {
      invalidatesTags: [LAKE_ICE_OBSERVATIONS_TAG, LAKE_POST_YEARS_TAG],
    },
  },
});

export const {
  useGetLakeIceObservationsByPostIdAndDateQuery,
  useLazyGetLakeIceObservationsByPostIdAndDateQuery,
  useUpdateFormLakeIceObservationsMutation,
  useGetLakeIceObservationsByPostIdAndDateBetweenQuery,
} = api;
