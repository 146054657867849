import React, { HTMLAttributes, MouseEvent } from 'react';
import classNames from 'classnames';

export interface CardsLinkListProps extends HTMLAttributes<HTMLDivElement> {
  list: {
    name: string
    to: string
    img?: string
    forceColor?: string
  }[]
  onToClick: (e: MouseEvent<HTMLAnchorElement>, to: string) => void
  aClassName?: string
  divClassName?: string
  colorFn?: (index: number) => string
}

const CardsLinkListThis = React.forwardRef<HTMLDivElement, CardsLinkListProps>((props, ref) => {
  const {
    className,
    divClassName,
    aClassName,
    list,
    onToClick,
    colorFn,
    ...restProps
  } = props;

  const wrapper = classNames(
    'none grid',
    'grid-cols-1 gap-y-4',
    'md:mx-6 md:grid-cols-2 md:gap-x-12 md:gap-y-10',
    'xl:mx-12 xl:grid-cols-3 xl:gap-x-24 xl:gap-y-20',
    className,
  );

  const transition = 'transition ease-in-out delay-50';
  const listElement = classNames(
    'h-72 min-h-max rounded-lg shadow-lg',
    'bg-cover bg-center',
    'brightness-75',
    transition,
    divClassName,
  );
  const aElement = classNames(
    'h-full w-full flex justify-center text-center items-center rounded-lg px-10 py-5',
    'select-text text-gray-100 hover:text-gray-50',
    'bg-black/20 hover:bg-black/50',
    transition,
    aClassName,
  );

  const textShadow = '-.5px -.5px 0 rgba(250, 250, 250, .4), .5px -.5px 0 rgba(250, 250, 250, .4), -.5px .5px 0 rgba(250, 250, 250, .4), .5px .5px 0 rgba(250, 250, 250, .4)'

  return (
    <div ref={ref} {...restProps} className={wrapper}>
      {list.map((el, i) => (
        <div
          key={el.name}
          style={'img' in el ? { backgroundImage: `url(${el.img})`, textShadow } : undefined}
          className={classNames(colorFn ? colorFn(i) : null, listElement)}
        >
          <a
            href={el.to}
            className={aElement}
            onClick={(e) => onToClick(e, el.to)}
          >
            {el.name}
          </a>
        </div>
      ))}
    </div>
  );
});

CardsLinkListThis.displayName = 'CardsLinkListThis';
const getIndexInLength = (i: number, l: number): number => (i > l ? getIndexInLength(i - l, l) : i);
const colors = ['bg-red', 'bg-indigo', 'bg-blue', 'bg-purple', 'bg-green', 'bg-yellow', 'bg-pink', 'bg-gray'].map((el) => `${el}-400`);
CardsLinkListThis.defaultProps = {
  colorFn: (index) => {
    const { length } = colors;
    return colors[getIndexInLength(index, length - 1)];
  },
};

/** лист, обернутый в forwardRef (div) и memo */
export const CardsLinkList = React.memo(CardsLinkListThis);
CardsLinkList.displayName = 'CardList';
