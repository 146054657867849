// данные для приложения в части "Озера"
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Features/App/store';
import { LatLngExpression } from 'leaflet';
import { LakePostDto, LakeWaterAreaPost } from 'services/phoebeApi.generated';

export const LAKE_APP_SLICE = 'lakeApp';

export type LakeAppSliceState = {
  selectedPost: LakePostDto,
  selectedWaterAreaPost: LakeWaterAreaPost,
  position: LatLngExpression,
  zoom: number,
}

const initialState: LakeAppSliceState = {
  selectedPost: { id: undefined },
  selectedWaterAreaPost: { id: undefined },
  position: [61.587928, 90.018971],
  zoom: 4,
};

const lakeAppSlice = createSlice({
  name: LAKE_APP_SLICE,
  initialState,
  reducers: {
    setSelectedPost: (state, { payload }: PayloadAction<LakePostDto>) => Object.assign(state, { selectedPost: payload }),
    setSelectedLakeWaterAreaPost: (state, { payload }: PayloadAction<LakeWaterAreaPost>) => Object.assign(state, { selectedWaterAreaPost: payload }),
    setPosition: (state, { payload }: PayloadAction<LatLngExpression>) => Object.assign(state, { position: payload, zoom: 7 }),
    // для logout - обновляет весь стейт слайса observations
    resetLakeAppSlice: () => initialState,
  },
});

export const selectedPostSelector = (state: RootState): LakePostDto => state[LAKE_APP_SLICE].selectedPost;
export const selectedWaterAreaPostSelector = (state: RootState): LakeWaterAreaPost => state[LAKE_APP_SLICE].selectedWaterAreaPost;

export const selectedPostIdSelector = (state: RootState): number | -1 => selectedPostSelector(state).id || -1;
export const selectedWaterAreaPostIdSelector = (state: RootState): number | -1 => selectedWaterAreaPostSelector(state).id || -1;

export const selectedPostIssueNumberSelector = (state: RootState): number | undefined => selectedPostSelector(state).issueNumber;

export const positionSelector = (state: RootState) => ({ position: state[LAKE_APP_SLICE].position || [61.587928, 90.018971], zoom: state[LAKE_APP_SLICE].zoom });
export const {
  setSelectedPost,
  setSelectedLakeWaterAreaPost,
  resetLakeAppSlice,
  setPosition,
} = lakeAppSlice.actions;
export default lakeAppSlice.reducer;
