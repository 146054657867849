import { WaterFlowChannelEntity, WaterFlowConfinesLogDto, WaterFlowEntity } from 'services/phoebeApi.generated';

type NoteType = {
  channelNumber?: number;
  key: string;
  featuresMeasurementWaterFlow: number;
  unaccountedWaterFlow: string | undefined;
  unaccountedChannel?: string | undefined;
  featuresMeasurementWaterFlowChannel?: number
}

type WaterFlowTransformResponseType = {
  rows: WaterFlowChannelEntity[];
  notes: Array<NoteType>
  notesSediment: {
    unaccountedChannel?: string | undefined
    key: string;
    featuresMeasurementSedimentDischargeChannel?: number;
    unaccountedWaterFlow: string | undefined;
    featuresMeasurementSedimentDischarge?: number | undefined
  }[]
}

export type WaterFlowResponseType = {
  data: WaterFlowEntity[] | undefined,
  errors: WaterFlowConfinesLogDto['errors'],
  rows: WaterFlowTransformResponseType['rows'] | undefined,
  notes: WaterFlowTransformResponseType['notes'] | undefined,
  notesSediment: WaterFlowTransformResponseType['notesSediment'] | undefined
}
export function waterFlowTransformResponse(response: WaterFlowConfinesLogDto): WaterFlowResponseType {
  const reduceData = response.data?.reduce((acc: WaterFlowTransformResponseType, cur: WaterFlowEntity, index) => {
    const result = { ...acc };
    result.rows.push(...cur.waterFlowChannelsById ?? []);

    cur.waterFlowChannelsById?.forEach((channel, channelIndex: number) => {
      const channelNumber = channel.channelNumber?.toString()?.[0] || null;
      let note = {}; // примечания по протоке
      // 3.2.15.1
      const key = `${index}${channelIndex}`;
      if (channelNumber && channelNumber !== '1') {
        note = { channelNumber, key };
      }
      // 3.2.15.2
      if (channel.featuresMeasurementWaterFlowChannel) {
        note = { ...note, key, featuresMeasurementWaterFlowChannel: channel.featuresMeasurementWaterFlowChannel, unaccountedWaterFlow: channel.unaccountedWaterFlow };
      }
      if (channel.featuresMeasurementSedimentDischargeChannel) {
        // const noteSediment = { key, featuresMeasurementSedimentDischargeChannel: channel.featuresMeasurementSedimentDischargeChannel, unaccountedWaterFlow: channel.unaccountedWaterFlow };
        const noteSediment = { key, featuresMeasurementSedimentDischargeChannel: channel.featuresMeasurementSedimentDischargeChannel, unaccountedWaterFlow: channel.unaccountedWaterFlow };
        result.notesSediment.push(noteSediment);
      }
      if (Object.keys(note).length > 1) result.notes.push(note as NoteType);
    });

    if (cur.waterFlowChannelsById?.length && cur.waterFlowChannelsById?.length > 1) {
      if (cur.featuresMeasurementWaterFlow) {
        const key = `${index}`;
        const n = { key, featuresMeasurementWaterFlow: cur.featuresMeasurementWaterFlow, unaccountedWaterFlow: cur.unaccountedWaterFlow, unaccountedChannel: cur.unaccountedChannel };
        result.notes.push(n);
      }
      if (cur.featuresMeasurementSedimentDischarge) {
        const key = `${index}`;
        // result.notesSediment.push({ key, featuresMeasurementSedimentDischarge: cur.featuresMeasurementSedimentDischarge, unaccountedWaterFlow: cur.unaccountedWaterFlow, unaccountedChannel: cur.unaccountedChannel });
        result.notesSediment.push({ key, featuresMeasurementSedimentDischarge: cur.featuresMeasurementSedimentDischarge, unaccountedWaterFlow: cur.unaccountedWaterFlow, unaccountedChannel: cur.unaccountedChannel });
      }
    }
    return result;
  }, { rows: [], notes: [], notesSediment: [] });
  return { data: response.data, errors: response.errors, rows: reduceData?.rows, notes: reduceData?.notes, notesSediment: reduceData?.notesSediment };
}
