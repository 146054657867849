import { t } from 'locale';
import { useLocation } from 'react-router-dom';
import serviceUnavailable from 'images/503_Service_Unavailable.png';

/**
 * Экран, который отображается при маршруте, который не обозначен в приложении
 */
export function NotFound() {
  const location = useLocation();
  return (
    <div className="overflow-hidden flex flex-col items-center  h-full w-full">
      <span className="p-2 bg-primary-bg text-primary-text text-[clamp(1rem,_-0.25rem_+_5.333vw,_1.5rem)]">
        <b>{t('key_810')}</b>
        {' '}
        <span>{location.pathname}</span>
      </span>
      <img className="max-w-9/10 max-h-9/10" alt="serviceUnavailable" src={serviceUnavailable} />
    </div>
  );
}
