import { generatedApi } from '../phoebeApi.generated';
import { SEDIMENT_DISCHARGE_TAG } from './tags';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: [SEDIMENT_DISCHARGE_TAG],
  endpoints: {
    getSedimentDischargeByStationIdAndYearAndTag: {
      providesTags: [SEDIMENT_DISCHARGE_TAG],
    },
    updateSedimentDischarge: {
      invalidatesTags: [SEDIMENT_DISCHARGE_TAG],
    },
  },
});

export const {
  useGetSedimentDischargeByStationIdAndYearAndTagQuery,
  useUpdateSedimentDischargeMutation,
} = api;
